import { Divider, ListItem, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { ticketCurrencies } from "../../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../../utils/functions/getFormattedNumber";
import { convertUtcToLocal } from "../../../../../../../../../utils/functions/timeConversion";
import pluralize from "pluralize";

const InvitationPaymentItem = ({ item }) => {
    // Not needed currently details of one payment item
    // const handleClick = () => {
    //     setOpen(!open);
    //     if (!open) {
    //         setLoader(true);
    //         getEventPaidPaymentDetails(eventDetailsObj?.id, item.id).then((res) => {
    //             setLoader(false);
    //             setDetails(res.data.data);
    //         }).catch((err) => {
    //             console.log(err);
    //             setLoader(false);
    //         })
    //     }
    // };

    return (
        <React.Fragment>
            <ListItem
                sx={{
                    px: { xs: 1, md: 1.5 },
                    py: 0.5,
                    backgroundColor: 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}
            >
                <ListItemText
                    primary={
                        <Stack direction='row'>
                            {item ?
                                <Typography>
                                    Paid for delivery of
                                    {item.sms_message ? ' sms' : ''}{item.sms_message && (item.whatsapp_message || item.email_message) ? ', ' : ''}
                                    {item.whatsapp_message ? ' whatsapp' : ''}{item.whatsapp_message && item.email_message ? ', ' : ''}
                                    {item.email_message ? ' email' : ''} to {item.total_guests} {pluralize('guest', item.total_guests)}
                                </Typography>
                                : <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '90%', sm: '80%' }, transform: 'scale(1,1)' }} />
                            }
                        </Stack>
                    }
                    secondary={item ? `${convertUtcToLocal(item.payment_time).format("Do MMMM YYYY, h:mm A")}` :
                        <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '70%', sm: '65%' }, mt: 1 }} />}
                />
                {item ?
                    <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                        {parseDecimalNumber(item.amount_paid, 3)}&nbsp;
                        <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === item.currency)?.symbol }} />&nbsp;
                    </Typography>
                    : <Skeleton animation="wave" variant="circular"
                        width='2.5rem' height='2.5rem' />
                }
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>

            {/* <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {loader ?
                        <Skeleton height='10rem' variant='wave' sx={{
                            m: 2, transform: 'scale(1,1)',
                        }} />
                        :
                        <OneInvitationPaymentDetails details={details} />
                    }
                </List>
            </Collapse> */}
            <Divider variant="inset" component="li" sx={{ ml: 0 }} />
        </React.Fragment>
    )
};

export default InvitationPaymentItem;
