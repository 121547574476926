import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { parseDecimalNumber } from "../../../../../../utils/functions/getFormattedNumber";
import { ticketCurrencies } from "../../../../../../utils/constants/ticketCurrency";
import { getOrdinal } from "../../../../../../utils/functions/getOrdinal";
import pluralize from "pluralize";
import { getEventGuestCheckInRecords } from "../../../../../../services/wEvents/eventGuests";
import { convertUtcToLocal } from "../../../../../../utils/functions/timeConversion";
import SuccessfulCheckin from '../../../../../../assets/checkin-successful.gif'

const SelfCheckInSuccessful = ({ eventDetailsObj, guestData, handleCheckInClose, tickets, eventTicketType }) => {
    const [selfCheckInData, setCheckInData] = useState();
    useEffect(() => {
        getEventGuestCheckInRecords({ eventId: eventDetailsObj.id, guestId: eventDetailsObj.guest_id }).then((res) => {
            setCheckInData(res.data);
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            })
        })
    }, [])

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                disableEnforceFocus
                disableEscapeKeyDown
            >
                <DialogTitle sx={{ p: '0.75rem 1rem' }}>
                    <Typography
                        sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}>
                        Self Check-In Details
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ p: { xs: '1rem', md: '1rem' } }} dividers>
                    {
                        <>
                            {
                                <Stack sx={{ alignItems: "center", margin: "20px 0" }}>
                                    <img
                                        src={SuccessfulCheckin}
                                        alt="Check in Successful"
                                        width="65"
                                        height="65"
                                    />
                                </Stack>
                            }
                            {
                                guestData &&
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography><b>Name:</b>&nbsp; {guestData.name}</Typography>
                                        {
                                            <>
                                                <Typography><b>Contact:</b>&nbsp; +{guestData.dialing_code} {guestData.mobile}</Typography>
                                                {guestData.email ?
                                                    <Typography><b>Email:</b>&nbsp; {guestData.email}</Typography>
                                                    : null
                                                }
                                                {
                                                    eventDetailsObj?.has_registration && !(eventTicketType === 'free' && tickets?.length === 0) ?
                                                        <>
                                                            <Typography><b>Ticket Title:</b>&nbsp; {guestData.ticket_title}</Typography>
                                                            {guestData.description ?
                                                                <Typography><b>Ticket Description:</b>&nbsp; {guestData.ticket_description}</Typography>
                                                                : null
                                                            }
                                                            <Typography><b>Tickets Purchased:</b>&nbsp; {guestData.tickets_bought - guestData.tickets_distributed}</Typography>
                                                        </> :
                                                        <></>
                                                }

                                                {eventDetailsObj?.is_paid ?
                                                    <>
                                                        <Typography>
                                                            <b>Amount Paid:</b>&nbsp; {parseDecimalNumber(guestData.amount_to_pay, 3)}&nbsp;
                                                            <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === guestData.currency)?.symbol }} />
                                                        </Typography>
                                                    </>
                                                    : null
                                                }
                                                {selfCheckInData?.map((cd, index) =>
                                                    <Box key={index} sx={{ display: "flex", flexWrap: "wrap" }}>
                                                        <Typography sx={{ width: "40%" }}>
                                                            <b>{getOrdinal(index + 1)} Check-in:</b>&nbsp; {cd.check_in_count} {pluralize('guest', cd.check_in_count)}
                                                        </Typography>
                                                        <Typography sx={{ width: "12%" }}>
                                                            <b>Id:</b>&nbsp; {cd.id}
                                                        </Typography>
                                                        <Typography sx={{ width: "48%" }}>
                                                            <b>Check-in Time:</b>&nbsp;
                                                            {convertUtcToLocal(cd.check_in_time).format("Do MMMM YYYY, h:mm A")}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </>
                                        }
                                    </Stack>

                                    <Box sx={{ textAlign: 'center' }}>
                                        <LoadingButton
                                            type="submit"
                                            color="warning"
                                            variant="contained"
                                            onClick={handleCheckInClose}
                                        >
                                            Done
                                        </LoadingButton>
                                    </Box>
                                </Stack>
                            }
                        </>
                    }
                </DialogContent>
            </Dialog>

        </>
    )
};

export default SelfCheckInSuccessful;
