import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import noGuests from '../../../../../../../../assets/NoGuests.webp';
import { GroupMembersContext } from "../../GroupMembersContext";
import MemberBox from "./MemberBox";
import MemberPagination from "./MemberPagination";

const MemberList = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { members } = useContext(GroupMembersContext)
    return (
        <>
            {members?.length === 0 ?
                <Box sx={{ textAlign: 'center' }}>
                    <Box
                        component='img'
                        src={noGuests}
                        width={isSmallDevice ? '100%' : '30rem'}
                        height='25rem'
                    />
                </Box> :
                <>
                    <Grid
                        container
                        spacing={{ xs: 0, md: 3 }}
                        sx={{ px: '0.5rem', height: 'auto', maxHeight: '20rem', overflow: 'auto' }}
                    >
                        {(members ?? Array.from(new Array(6))).map((m, index) => (
                            <MemberBox key={index} memberDetails={m} />
                        ))}
                    </Grid>
                    <MemberPagination />
                </>
            }
        </>
    )
};

export default MemberList;
