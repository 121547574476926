import React, { useContext, useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, Tooltip, useMediaQuery } from "@mui/material";
import SetupReminder from "./SetupReminder";
import InvitationHistory from "./InvitationHistory";
import AddGuest from "../../components/AddDeleteOptions/AddGuest";
import { EventDetailsContext } from "../../../../../../../..";

const InvitationOptions = () => {
    const isSmallDevices = useMediaQuery("(max-width:600px)"); //Mobiles

    const [anchorEl, setAnchorEl] = useState(null);
    const { refs } = useContext(EventDetailsContext);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleClose); //To hide menu on scroll
    }, [anchorEl]);

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Tooltip title="Options">
                    <IconButton
                        ref={refs.invitationOptionsRef}
                        onClick={handleClick}
                        size="small"
                        sx={{ p: 0 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon color='warning' />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    MenuListProps={{
                        sx: {
                            py: '0.8rem'
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {isSmallDevices ? <AddGuest title='Add Guest' /> : null}
                    <InvitationHistory title='Scheduled Invitations' invitationType='scheduled' />
                    <InvitationHistory title='Completed Invitations' invitationType='completed' />
                    <SetupReminder title='Setup Reminder' handleClose={handleClose} />
                </Menu>
            </Box>
        </>
    )
};

export default InvitationOptions;
