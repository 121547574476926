import * as React from "react";
import { Box, Typography, Backdrop, CircularProgress } from "@mui/material";

const FullScreenLoader = (
    ({
        text,
        size = 32,
        backdropPosition = "fixed",
        backdropBackgroundColor = "rgba(0, 0, 0, 0.5)",
    }) => {
        return (
            <Backdrop
                sx={{
                    backgroundColor: backdropBackgroundColor,
                    zIndex: 10000,
                    position: backdropPosition,
                }}
                open={true}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <CircularProgress size={size} color='warning' />
                    {/* <ReactLoading type="bars" color={BACKGROUND_COLOR} /> */}
                    {text ? (
                        <Typography typoFor={"Please wait"}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            ></span>
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Box>
            </Backdrop>
        );
    }
);

FullScreenLoader.displayName = "Loader";
export default FullScreenLoader;