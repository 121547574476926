import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Checkbox, DialogActions, DialogContent, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GroupSettingsContext } from "../..";
import { groupDetails } from '../../../../../../../../redux/slices/groupDetailSlice';
import { bulkDeleteGroupFormFields, getGroupForm, insertOrUpdateGroupFormFields } from '../../../../../../../../services/wGroups/groupForm';
import { manageEventFormPayload } from "../../../../../../../../utils/functions/managePayload";
import AddFormQuestionsDialog from "./AddFormQuestionsDialog";
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

const EditGroupRegistrationForm = () => {
    const groupData = useSelector(groupDetails);
    const { setSlide, setSlideType, registrationForm, setRegistrationForm } = useContext(GroupSettingsContext);
    const [add, setAdd] = useState(false);
    const [loader, setLoader] = useState(false);

    let initialValues = {} // For assigning initial values to use form
    let initialDetails = registrationForm?.fields // For loop through all items and to manage add+delete items
    initialDetails?.forEach((r, index) => {
        initialValues[index] = { ...r, exists: true } // True because these fields are already there in form and fetched from db.
        r.name = index
        r.exists = true
        r.mandatory = Boolean(r.mandatory)
    })

    const [fieldDetails, setFieldDetails] = useState(initialDetails)
    const [idsToDelete, setIdsToDelete] = useState([]);

    const handleAddFormQuestion = (data, chipData) => {
        const lastElementId = fieldDetails?.slice(-1)[0]?.id ?? (fieldDetails?.slice(-1)[0]?.name ?? 0)
        setValue(`${lastElementId + 1}`, { type: data.questionType, options: chipData.length > 0 ? chipData : undefined, mandatory: false, exists: false })
        setFieldDetails((details) => [...details, { name: lastElementId + 1, question: data.question, type: data.questionType, mandatory: false, exists: false }])
        setAdd(false);
        toast.success("Question added", {
            position: "top-right",
            theme: "dark"
        });
    }

    const handleDeleteItem = (field_name) => {
        const fieldToDelete = fieldDetails.find(d => d.name === field_name) //Because every field has unique name
        setFieldDetails((details) => details.filter((d) => d !== fieldToDelete))
        if (fieldToDelete.exists) {
            setIdsToDelete((prev) => [...prev, fieldToDelete.id])
        }
        unregister(`${field_name}`);
    }

    const { handleSubmit, register, setValue,
        formState: { errors }, reset, unregister
    } = useForm({
        defaultValues: initialValues
    });

    const mySubmit = (data) => {
        setLoader(true);
        const deletePayload = {};
        const insertUpdatePayload = manageEventFormPayload(data);

        const deleteFormFields = () => {
            deletePayload['form_field_ids'] = idsToDelete;
            bulkDeleteGroupFormFields(groupData?.id, deletePayload).then((res) => {
                console.log("Deleted", res.data);
            });
        }

        const insertOrUpdateFormFields = () => {
            insertOrUpdateGroupFormFields(groupData?.id, registrationForm?.id, insertUpdatePayload).then((res) => {
                console.log("Updated", res.data);
            });
        }

        Promise.allSettled([deleteFormFields(), insertOrUpdateFormFields()]).then((results) => {
            getGroupForm({ groupId: groupData?.id, formId: registrationForm?.id }).then((res) => {
                console.log(res.data);
                setRegistrationForm(res.data.data)
                setLoader(false);
                toast.success("Registration form updated", {
                    position: "top-right",
                    theme: "dark"
                });
                setSlide(1);
                setSlideType('editGroupRegistration');
                setIdsToDelete([]);
            }).catch((err) => {
                console.log(err);
                setLoader(false);
                toast.error("Something went wrong in updating registration form", {
                    position: "top-right",
                    theme: "dark"
                });
            })
        });
    }

    const handleCancel = () => {
        setFieldDetails(initialDetails);
        setIdsToDelete([]);
        setSlide(1);
        reset();
    }

    const renderItem = (item) => {
        const fieldName = item.name

        switch (item.type) {
            case 'textarea':
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Checkbox color='warning'
                                checked={item.mandatory}
                                onChange={(e) => {
                                    let index = fieldDetails.indexOf(item)
                                    fieldDetails[index].mandatory = e.target.checked
                                    setFieldDetails([...fieldDetails])
                                    setValue(`${fieldName}.mandatory`, e.target.checked)
                                }} />
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                size="small"
                                color="warning"
                                defaultValue={item.question}
                                {...register(`${fieldName}.question`)}
                            />
                            <IconButton size='small' onClick={() => handleDeleteItem(item.name)} >
                                <DeleteIcon color="warning" />
                            </IconButton>
                        </Stack>
                    </>
                );
            default:
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            {!([0, 1, 2, 3].includes(fieldName)) &&
                                <Checkbox color='warning'
                                    checked={item.mandatory}
                                    onChange={(e) => {
                                        let index = fieldDetails.indexOf(item)
                                        fieldDetails[index].mandatory = e.target.checked
                                        setFieldDetails([...fieldDetails])
                                        setValue(`${fieldName}.mandatory`, e.target.checked)
                                    }} />
                            }
                            <TextField
                                fullWidth
                                size="small"
                                color="warning"
                                defaultValue={item.question}
                                inputProps={{ readOnly: ([0, 1, 2, 3].includes(fieldName)) ? true : false }}
                                {...register(`${fieldName}.question`)}
                            />
                            {!([0, 1, 2, 3].includes(fieldName)) &&
                                <IconButton size='small' onClick={() => handleDeleteItem(item.name)} >
                                    <DeleteIcon color="warning" />
                                </IconButton>
                            }
                        </Stack>
                    </>
                );
        }
    }

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={true} sx={{
                    p: { xs: '1rem', md: '1.5rem 1.5rem' },
                    height: { xs: '26rem', md: '31rem' }, overflow: 'auto'
                }}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            color="warning"
                            label="Form title"
                            defaultValue={registrationForm?.title}
                            error={errors?.formTitle ? true : false}
                            helperText={
                                errors?.formTitle ? "This is required field" : null
                            }
                            {...register("formTitle", { required: true })}
                        />
                        <Box>
                            <Typography sx={{ color: '#6F738B', fontSize: '0.8rem' }}>Mark as mandatory fields by clicking on the check boxes</Typography>
                            <Typography sx={{ fontWeight: 'bold', mt: 2 }}>Form Details</Typography>
                        </Box>
                        {fieldDetails.map((item, i) => (
                            // Given this key to unmount checkbox state on unmounting component
                            <React.Fragment key={Math.random() + '_' + i}>
                                {renderItem(item, i)}
                            </React.Fragment>
                        ))}
                        <Divider sx={{ my: 1 }} />
                        {add ? <Box sx={{
                            margin: '1rem auto !important',
                            width: '90%',
                            borderRadius: '5px',
                            boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
                        }}>
                            <AddFormQuestionsDialog setAdd={setAdd} handleAddFormQuestion={handleAddFormQuestion} />
                        </Box> :
                            <Button variant="text" color="warning" sx={{ width: 'auto' }} onClick={() => setAdd(true)}>+ Add Question</Button>
                        }

                    </Stack>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button onClick={handleCancel} color='warning' variant="outlined"
                        sx={{ boxShadow: 'none' }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {loader ? 'Saving...' : 'Save'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
};

export default EditGroupRegistrationForm;
