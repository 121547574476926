import { codes as countryCodes } from './countries.codes'

export const createGuestForm = {
    "title": "Guest Create",
    "description": "Event guest add form",
    "method": "POST",
    "data": [
        {
            "type": "text",
            "required": true,
            "label": "Name",
            "placeholder": "Enter name",
            "className": "form-control",
            "name": "name",
            "access": false,
            "subtype": "text"
        },
        {
            "type": "text",
            "required": false,
            "label": "Email ID",
            "placeholder": "Email ID",
            "className": "form-control",
            "name": "email",
            "access": false,
            "subtype": "text"
        },
        {
            "type": "phone_number",
            "required": true,
            "label": "Phone Number",
            "placeholder": "Phone Number",
            "className": "form-control",
            "name": "dialing_code mobile",
            "access": false,
            "subtype": "text",
            "country_codes": countryCodes
        },
    ]
}