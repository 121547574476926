import React from "react";
import { Fab } from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import { manageAlbumMediaPayload } from "../../../../../../../../../utils/functions/managePayload";
import { createGroupAlbumMedia } from "../../../../../../../../../services/wGroups/groupDetails";

const AddPhotos = ({ handleAlbumMedia, albumDetails, setPreviewFile, handleUploadChanges }) => {
    const fabStyle = {
        mr: 2,
        width: '2rem',
        height: '2rem',
        minHeight: 0,
        backgroundImage: 'linear-gradient(#FF9966, #FF5E62)',
    };
    const fab = {
        sx: fabStyle,
        icon: <AddIcon />,
    };

    const handleImageChange = (event) => {
        handleUploadChanges(true);
        let payload = manageAlbumMediaPayload(event.target.files)

        createGroupAlbumMedia(payload, albumDetails?.id).then((res) => {
            console.log(res?.data);
            setPreviewFile && setPreviewFile(event.target.files[0]);
            handleAlbumMedia(albumDetails?.id, res?.data?.data, { isDeleted: false })
            handleUploadChanges(false);
            toast.success("Images added successfully", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
            // setPreviewFile(null);
            handleUploadChanges(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    return (
        <Fab sx={fab.sx} className='album-card-options' component='label'>
            {fab.icon}
            <input
                hidden
                multiple
                type="file"
                onChange={handleImageChange}
                accept="image/png, image/jpg, image/jpeg"
            />
        </Fab>
    )
};

export default AddPhotos;
