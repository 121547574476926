import { Box } from "@mui/material";
import React from "react";
import GroupDetailTabs from "./GroupDetailTabs";

const GroupMenubar = ({ tabValue, handleTabChange }) => {
    return (
        <Box sx={{ boxSizing: 'border-box' }}>
            <GroupDetailTabs tabValue={tabValue} handleTabChange={handleTabChange} />
        </Box>
    )
};

export default GroupMenubar;
