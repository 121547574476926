import { FormControl, Stack, Typography } from "@mui/material";
import React from "react";

const OneGuestRsvpView = ({ activeGuest }) => {
    console.log(activeGuest);

    return (
        <>
            <Stack spacing={3} sx={{ p: 1 }}>
                {activeGuest?.QA?.map((g, i) => (
                    <FormControl key={i}>
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Q. &nbsp;&nbsp; {g.question}</Typography>
                        <Typography>A. &nbsp;&nbsp; {g.answer ?? '---'}</Typography>
                    </FormControl>
                ))}
            </Stack>
        </>
    )
};

export default OneGuestRsvpView;
