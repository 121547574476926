import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ViewRsvpQuestions from "./ViewRsvpQuestions";
import EditRsvpQuestions from "./EditRsvpQuestions";
import FullScreenLoader from "../../../../../../../../components/Loader";
import { updateEventDetails } from "../../../../../../../../services/wEvents/event";
import { manageEventRsvpPayload } from "../../../../../../../../utils/functions/managePayload";
import { eventDetails, update_event_details } from "../../../../../../../../redux/slices/eventDataSlice";
import { bulkDeleteEventRsvpQues, insertOrUpdateEventRsvpQues } from "../../../../../../../../services/wEvents/eventDetails";

const SetupRsvpDialog = ({ rsvpQuestions, setRsvpQuestions, handleClose }) => {
    const dispatch = useDispatch();

    const {
        handleSubmit, register, unregister, reset, setValue, watch, formState: { errors }
    } = useForm();

    const eventData = useSelector(eventDetails);
    const [edit, setEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [idsToDelete, setIdsToDelete] = useState([]);
    const [isRsvpRequired, setRsvpRequired] = useState(eventData?.is_rsvp_required ? true : false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true); 

    useEffect(() => {
        if (rsvpQuestions?.length > 0) {
            setLoadingSkeleton(false);
            rsvpQuestions.forEach((rq, index) => {
                setValue(`${index}`, { ...rq });
                rq.name = index;
                rq.mandatory = Boolean(rq.mandatory);
            });
        }
    }, [rsvpQuestions]);

    const handleRsvpRequiredClick = (e) => {
        setRsvpRequired(e.target.checked);
    };

    const mySubmit = (data) => {
        setLoader(true);
        const deletePayload = {};
        const insertUpdatePayload = manageEventRsvpPayload(data);

        const updateIsRsvpRequired = () => {
            const updateRsvpRequiredPayload = {
                '_method': 'PUT',
                'is_rsvp_required': isRsvpRequired,
            };
            updateEventDetails(updateRsvpRequiredPayload, eventData?.id)
                .then((res) => {
                    dispatch(update_event_details(res.data.data));
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                    setLoader(false);
                });
        };

        const updateWelcomeNote = () => {
            const updateWelcomeNotePayload = {
                '_method': 'PUT',
                'welcome_note': data.welcome_note,
            };
            updateEventDetails(updateWelcomeNotePayload, eventData?.id)
                .then((res) => {
                    dispatch(update_event_details(res.data.data));
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                    setLoader(false);
                });
        };

        const deleteQuestions = () => {
            deletePayload['rsvp_question_ids'] = idsToDelete;
            bulkDeleteEventRsvpQues(eventData?.id, deletePayload)
                .then((res) => {
                    console.log("Deleted===>", res.data);
                });
        };

        const insertOrUpdateQuestions = () => {
            insertOrUpdateEventRsvpQues(eventData?.id, insertUpdatePayload)
                .then((res) => {
                    console.log("Updated===>", res.data);
                });
        };

        Promise.allSettled([updateIsRsvpRequired(), updateWelcomeNote(), deleteQuestions(), insertOrUpdateQuestions()])
            .then((results) => {
                setLoader(false);
                setIdsToDelete([]);
                handleClose();
                toast.success("Event RSVP updated", {
                    position: "top-right",
                    theme: "dark"
                });
            })
            .catch((err) => {
                setLoader(false);
                console.log(err);
                toast.error("Something went wrong in updating event RSVP", {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    useEffect(() => {
        return () => {
            reset();
        };
    }, [handleClose]);

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                PaperProps={{ sx: { width: { xs: '90%', md: '60%' }, m: { xs: 0 } } }}
                BackdropComponent={loader ? FullScreenLoader : null}
            >
                <DialogTitle>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>Setup Rsvp</Typography>
                </DialogTitle>
                <Divider />
                {loadingSkeleton ? (
                    <DialogContent sx={{ p: '1rem' }}>
                        <Stack spacing={2}>
                            <Skeleton variant="text" width="100%" height={48} />
                            <Skeleton variant="rectangular" width="100%" height={200} />
                        </Stack>
                    </DialogContent>
                ) : (
                <form autoComplete="off" onSubmit={handleSubmit(mySubmit)}>
                    <Box sx={{ p: { xs: '1rem', md: `${isRsvpRequired ? '1rem 1.5rem 0' : '1.5rem'}` } }}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                RSVP Required
                            </Typography>
                            <Switch
                                color='warning'
                                checked={isRsvpRequired}
                                onChange={(e) => handleRsvpRequiredClick(e)}
                            />
                        </Stack>
                    </Box>
                    {
                        isRsvpRequired ?
                            <DialogContent sx={{ p: { xs: '1rem', md: '1rem 1.5rem 1.5rem' } }}>
                                <Stack spacing={2}>
                                    <TextField
                                        fullWidth
                                        rows={3}
                                        multiline
                                        color="warning"
                                        label="Welcome Message For Guests"
                                        defaultValue={eventData?.welcome_note ?? null}
                                        error={!!errors?.welcome_note}
                                        helperText={errors?.welcome_note ? "This is a required field" : null}
                                        {...register("welcome_note", { required: true })}
                                    />
                                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                                        <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }}>Manage Questions</Typography>
                                        {
                                            !edit ?
                                                <IconButton onClick={() => setEdit(true)}>
                                                    <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                                </IconButton> :
                                                null
                                        }
                                    </Stack>

                                    {edit ?
                                        <>
                                            <EditRsvpQuestions unregister={unregister} setValue={setValue}
                                                rsvpQuestions={rsvpQuestions} setRsvpQuestions={setRsvpQuestions}
                                                setIdsToDelete={setIdsToDelete} watch={watch} />
                                        </> :
                                        <ViewRsvpQuestions rsvpQuestions={rsvpQuestions} />
                                    }
                                </Stack>
                            </DialogContent> :
                            null
                    }
                    <Divider sx={{ my: 1 }} />
                    <DialogActions sx={{ mr: 1, my: 0.5 }}>
                        <LoadingButton
                            type="submit"
                            color='warning'
                            variant="contained"
                            loading={loader}
                            startIcon={loader ? <SaveIcon /> : null}
                            loadingPosition={loader ? "start" : null}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>
                </form>
                )}
            </Dialog>
        </>
    );
}

export default SetupRsvpDialog;
