import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { convertUtcToLocal } from "../../../../../../../../../../utils/functions/timeConversion";
import DeleteInvitationDialog from './DeleteInvitationDialog';

const InvitationItem = ({ item, setData, isInvitation, handleItemClick, invitationType }) => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const handleDialogOpen = () => {
        setDeleteDialog(true);
    }
    const handleDialogClose = () => {
        setDeleteDialog(false);
    }

    return (
        <>
            <ListItem
                sx={{
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}>
                <ListItemAvatar>
                    {item ?
                        <Avatar sx={{ backgroundColor: '#FFEBE4' }}>
                            <CalendarMonthIcon color='warning' />
                        </Avatar>
                        : <Skeleton variant="circular" width={40} height={40} />
                    }
                </ListItemAvatar>
                <ListItemText onClick={(e) => handleItemClick(e, item)} primary={
                    item ? convertUtcToLocal(item.scheduled_date).format("dddd, Do MMMM YYYY") :
                        <Skeleton width='90%' />
                }
                    secondary={
                        item ? convertUtcToLocal(item.scheduled_date?.split(' ')[1], 'HH:mm:ss').format("h:mm A") :
                            <Skeleton width='60%' />
                    }
                />
                {invitationType === 'scheduled' ?
                    <>
                        {item ?
                            <IconButton onClick={handleDialogOpen}
                                sx={{ p: 0 }}>
                                <DeleteIcon />
                            </IconButton>
                            : <Skeleton variant="rounded" width={25} height={25} />
                        }
                    </>
                    : null
                }
            </ListItem>
            {deleteDialog ?
                <DeleteInvitationDialog
                    setData={setData}
                    invitationId={item?.id}
                    isInvitation={isInvitation}
                    handleDialogClose={handleDialogClose}
                /> : null
            }
        </>
    )
};

export default InvitationItem;
