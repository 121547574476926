import { GROUP_API, GROUP_WEBLINK } from "../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const getGroups = ({ page }) => {
    return axiosInstance.get(`${GROUP_API}?page=${page}`)
};

export const getGroupDetails = ({ groupId, memberId }) => {
    if (memberId) {
        return axiosInstance.get(`${GROUP_WEBLINK}?member_uuid=${memberId}`)
    }
    return axiosInstance.get(`${GROUP_API}/${groupId}`)
};

export const createGroup = (payload) => {
    return axiosInstance.post(GROUP_API, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const updateGroupDetails = (payload, groupId) => {
    return axiosInstance.post(`${GROUP_API}/${groupId}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};