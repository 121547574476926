import { useContext } from "react";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, Stack, TextField, Typography, InputAdornment, useMediaQuery } from "@mui/material";
import { GroupMembersContext } from "../../../../GroupMembersContext";
import { groupDetails } from "../../../../../../../../../../redux/slices/groupDetailSlice";

const DynamicMessage = ({ isReminder, register, setValue, fieldName }) => {
    const { inviteMsg } = useContext(GroupMembersContext);
    const groupData = useSelector(groupDetails);
    const [edit, setEdit] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const isSmallDevices = useMediaQuery("(max-width:600px)");

    const group_title = groupData?.title;
    const group_owner = groupData?.owner?.first_name + ' ' + groupData?.owner?.last_name;

    const fieldsInitialValues = {
        'Group Name': group_title
    }
    if (fieldName.includes('whatsapp')) {
        fieldsInitialValues['Regards'] = group_owner;
    }
    const [msgContent, setMsgContent] = useState(inviteMsg ? (inviteMsg[`${fieldName}Fields`] ?? fieldsInitialValues) : fieldsInitialValues)
    const previousValue = useRef(msgContent); //To get previous values on cancel button

    const handleFieldChange = (element, inputValue) => {
        setMsgContent({ ...msgContent, [element[0]]: inputValue })
        if (errorFields.includes(element[0]) && inputValue.trim().length > 0) {
            setErrorFields((errorFields) => errorFields.filter(item => item !== element[0]));
        } else if (inputValue.trim().length === 0) {
            setErrorFields([...errorFields, element[0]]);
        }
    }
    const handleEdit = () => {
        setEdit(true)
        previousValue.current = msgContent
    }

    const handleCancel = () => {
        setMsgContent(inviteMsg ? (inviteMsg[`${fieldName}Fields`] ?? fieldsInitialValues) : previousValue.current)
        setErrorFields([]);
        setEdit(false);
    }

    const handleSave = () => {
        setMsgContent((items) => {
            Object.keys(items).forEach((i) => items[i] = items[i].trim())
            return items
        })
        const blankFields = Object.keys(msgContent).filter((key) => !/[0-9a-zA-Z!@#?$%^&*]+/.test(msgContent[key]))
        if (blankFields.length === 0) {
            setValue(fieldName, msg);
            setValue(`${fieldName}Fields`, msgContent)
            fieldName.includes('sms') && setValue('total_sms', Math.ceil(msg.length / 160))
            setEdit(false);
            setErrorFields([]);
        } else {
            setErrorFields(blankFields);
        }
    }

    let msg = `Dear <Member name>,
${isReminder ? "A gentle reminder" : "You are requested"} to join ${msgContent['Group Name']}.
For more details please click on this link: <Group weblink>
---
${fieldName.includes('whatsapp') ? msgContent['Regards'] : ''}
Wowsly.com`;

    useEffect(() => {
        if (fieldName.includes('sms')) {
            setValue('total_sms', Math.ceil(msg.length / 160));
            setValue(`${fieldName}Fields`, msgContent)
        }
        if (fieldName.includes('whatsapp')) {
            setValue(`${fieldName}Fields`, msgContent)
        }
    }, []);

    return (
        <Box sx={{ border: '1px solid lightgrey', borderRadius: '0.2rem', p: '0.5rem' }}>
            <Stack sx={{ mt: 1, px: 1 }} direction='row' alignItems='center' justifyContent='space-between'>
                <Box>
                    <Typography sx={{ display: 'inline-block', color: '#1F274A', fontSize: '0.9rem' }}>
                        Message
                    </Typography>
                    {fieldName.includes('sms') ?
                        <Typography sx={{ display: 'inline-block', color: 'darkblue', fontSize: '0.9rem' }}>
                            ({msg.length} characters ~ {Math.ceil(msg.length / 160)} SMS)
                        </Typography> : null
                    }
                </Box>
                {
                    edit ? <>
                        <Stack direction='row' spacing={1}>
                            <Button color='warning' variant='outlined' sx={{ p: { xs: 0.3, sm: 0.8 }, fontSize: { xs: '0.8rem', sm: '0.9rem' } }} onClick={handleCancel}>Cancel</Button>
                            <Button color='warning' variant='outlined' sx={{ p: { xs: 0.3, sm: 0.8 }, fontSize: { xs: '0.8rem', sm: '0.9rem' } }} onClick={handleSave}>Save</Button>
                        </Stack>
                    </> :
                        <IconButton sx={{ p: 0, color: '#1F274A' }} onClick={handleEdit}><EditIcon /></IconButton>
                }
            </Stack>
            {edit ?
                <Stack spacing={1.5} sx={{ mt: 1, p: '1rem' }}>
                    {Object.entries(msgContent).map((element, index) => (
                        <>
                            {!(fieldName.includes('sms') && index === 1) ? //To hide regards for sms message
                                <TextField
                                    key={index}
                                    required={true}
                                    fullWidth
                                    label={element[0]}
                                    color="warning"
                                    value={element[1]}
                                    onChange={(e) => handleFieldChange(element, e.target.value)}
                                    error={errorFields.includes(element[0]) ? true : false}
                                    helperText={errorFields.includes(element[0]) ? 'This is required field' : null}
                                    multiline
                                    minRows={isSmallDevices ? 1 : 1}
                                    maxRows={isSmallDevices ? 4 : 2}
                                    inputProps={{
                                        maxLength: 30,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Typography>
                                                {element[1].length}/30
                                            </Typography>
                                        </InputAdornment>
                                    }}
                                /> : null
                            }
                        </>
                    ))
                    }
                </Stack>
                :
                <TextField
                    fullWidth
                    multiline
                    color="warning"
                    sx={{ mt: 1 }}
                    value={msg}
                    inputProps={{
                        readOnly: true
                    }}
                    {...register(fieldName)}
                />
            }
        </Box>
    )
};

export default DynamicMessage;
