import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import EventBox from "./EventBox";
import { CreateEventContext } from "../CreateEventContext";
import { getCategories } from "../../../../../../services/categories";
import { toast } from 'react-toastify'

const WhatCelebratingDialog = () => {
    const [Events, setEvents] = useState([])
    useEffect(() => {
        getCategories({ type: 'event' }).then((res) => {
            const sortedParentEvents = res.data.data.sort((a, b) => a.sequence - b.sequence);
            console.log(sortedParentEvents);
            setEvents(res.data.data);
        }).catch((err) => {
            console.log("$$$$$$$$", err);
            toast.error(err.message || err.message?.errors, {
                position: "top-right",
                theme: "dark"
            });
        })
    }, []);

    const { formData, setFormData } = useContext(CreateEventContext);
    const [activeId, setActiveId] = useState('');

    const onSelect = (categoryObj) => {
        setActiveId(categoryObj.id);
        setFormData({ ...formData, category: categoryObj.title, category_id: categoryObj.id })
    };

    return (
        <>
            <Box sx={{ p: { xs: '1rem', sm: '1.5rem' } }}>
                {(Events.length > 0 ? Events : Array.from(new Array(4))).map((c, index) => (
                    <React.Fragment key={index}>
                        <Box>
                            {c ?
                                <Typography fontSize={{ xs: '1.2rem', sm: '1.5rem' }} sx={{ fontWeight: 500 }}>{c.title} Events</Typography>
                                : <Skeleton height='2rem' />
                            }
                            <Grid container
                                rowSpacing={2}
                                spacing={{ xs: '1rem', sm: '1.5rem', lg: '2.5rem' }}
                                sx={{ mt: { xs: 0.5, sm: 1 } }}>
                                {(c ? c.children.sort((a, b) => a.sequence - b.sequence) : Array.from(new Array(9))).map((sc, i) => (
                                    <EventBox key={i} subCategory={sc} activeId={activeId} onSelect={onSelect} />
                                ))}
                            </Grid>
                        </Box>
                        <br /><br />
                    </React.Fragment>
                )
                )}
            </Box>
        </>
    )
};

export default WhatCelebratingDialog;
