export const validateSpaces = (string) => {
    const len = string.split(' ').length;
    if(len>1){
        return false;
    }
    return true;
}

export const validateLocationLink = (locationLink) => {
    const regex = /^(https?:\/\/)?(www\.)?google\.[a-z]{2,}(\/maps\/)?(\S+\/[\w\d]+)/i;
    // Validate the input against the regular expression
    const isValidInput = regex.test(locationLink);
    return isValidInput;
}

export const validateOnlineEventLink = (eventLink) => {
    return true;
}