import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from 'moment';
import React, { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GIF from '../../../../../../../../../assets/celeb.gif';
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { saveEventGuestInvitation } from "../../../../../../../../../services/wEvents/eventGuests";
import { manageInviteMessagePayload } from "../../../../../../../../../utils/functions/managePayload";
import { EventGuestsContext } from "../../../EventGuestContext";
import SendLaterConfirmation from "./SendLaterConfirmation";
import SendNowConfirmation from "./SendNowConfirmation";

const ScheduleInvite = ({ isReminder, handleClose }) => {
    const eventData = useSelector(eventDetails);
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { inviteMsg, totalGuests, isCheckboxVisible, checkedGuests,
        setChecked, setVisible, scheduledDate, setScheduledDate, paymentId } = useContext(EventGuestsContext)
    const guestsToInvite = isCheckboxVisible ? checkedGuests.length : totalGuests.current

    const isPickerVisible = useRef(false);
    const [loading, setLoading] = useState(false);
    const [isDateError, setDateError] = useState(false);
    const [confirmationBox, setConfirmationBox] = useState(false);

    const handleCloseConfirmation = () => {
        isPickerVisible.current = false;
        setLoading(false);
        setDateError(false);
        setScheduledDate(null);
        setConfirmationBox(false);
    }
    const handleSendNow = (e) => {
        setConfirmationBox(true);
    }
    const handleSendLater = () => {
        isPickerVisible.current = true;
        setConfirmationBox(true);
    }

    const sendInvite = (inviteType) => {
        //Call schedule or send invitation API
        const model_type = 'event';
        let inviteFields = Object.entries(inviteMsg).filter((e) => e[1] === true);

        // Validating inputs
        if (!paymentId) {
            toast.error("Please do payment first!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        if (inviteType === 'sendLater') {
            if (!scheduledDate) {
                setDateError("This is required field")
                return;
            } else if (scheduledDate <= moment().add(1, 'minutes')) { // Kept 1 min gap to save and process scheduling task
                setDateError("Schedule time should be greater than current time")
                return;
            }
        }

        inviteMsg.scheduled_date = inviteType === 'sendLater' ? scheduledDate : null
        let payload = manageInviteMessagePayload(inviteFields, checkedGuests, inviteMsg, model_type);
        payload.set('invite_type', isReminder ? 'reminder' : 'invitation');
        payload.set('payment_id', paymentId);

        setLoading(true); // Start loader as everything is ok and api call is about to happen

        saveEventGuestInvitation(payload, eventData?.id).then((result) => {
            console.log(result.data, '------');
            setLoading(false);
            handleClose();
            // setInviteMsg(null);
            if (isCheckboxVisible) {
                setChecked([]);
                setVisible(false);
            }
            toast.success(`${isReminder ? 'Reminder' : 'Invitation'} ${inviteType === 'sendNow' ? "Sent" : "Scheduled"}`, {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        });

    }

    return (
        <>
            <Stack spacing={2} alignItems='center' justifyContent='center'
                sx={{ py: { xs: 2 }, textAlign: 'center' }}>
                <Box component='img'
                    src={GIF}
                    alt={'Loading'}
                    width={isSmallDevice ? '100%' : '25rem'}
                    height={isSmallDevice ? '10rem' : '15rem'}
                />
                <Typography>
                    Congratulations! You have successfully completed all the steps, including payment. You can now choose to send invitations to all the guests immediately or schedule them to be sent at a later time.
                </Typography>
                {confirmationBox ?
                    <Box sx={{
                        width: { xs: '95%', sm: '90%', md: '80%', lg: '60%' },
                        borderRadius: '5px',
                        boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
                    }}>
                        {
                            isSmallDevice ? <IconButton size='small' onClick={handleCloseConfirmation} sx={{ float: 'right' }}>
                                <CloseIcon fontSize='medium' />
                            </IconButton> : null
                        }
                        {isPickerVisible.current ?
                            <SendLaterConfirmation
                                loading={loading}
                                sendInvite={sendInvite}
                                handleCloseConfirmation={handleCloseConfirmation}
                                isDateError={isDateError}
                                setDateError={setDateError}
                            /> :
                            <SendNowConfirmation
                                loading={loading}
                                sendInvite={sendInvite}
                                guestsToInvite={guestsToInvite}
                                handleCloseConfirmation={handleCloseConfirmation}
                            />
                        }
                    </Box> :
                    <Stack direction='row' spacing={3} alignItems='center' justifyContent='center'>
                        <Button variant="contained"
                            onClick={handleSendNow}
                            sx={{ backgroundColor: '#1F274A', ":hover": { backgroundColor: '#1F274A' } }}>
                            Send now
                        </Button>
                        <Button variant="outlined"
                            onClick={handleSendLater}>
                            Send Later
                        </Button>
                    </Stack>
                }
            </Stack>
        </>
    )
};

export default ScheduleInvite;
