import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Button, Stack, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventTickets } from "../../../../../../../../services/wEvents/eventDetails";
import { EventGuestsContext } from "../../EventGuestContext";
import InviteConfirmationDialog from "./InviteConfirmationDialog";
import SetInvitationDialog from "./SetInvitationDialog";
import { toast } from 'react-toastify';

const EventGuestInvite = () => {
    const [loader, setLoader] = useState(false);
    const eventData = useSelector(eventDetails);
    const { isCheckboxVisible, checkedGuests, setVisible, tabValue,
        setChecked, setScheduledDate, setGuestInviteActiveStep, setPayment } = useContext(EventGuestsContext);

    const handleCancel = () => {
        setChecked([]);
        setVisible(false)
    }

    let [open, setOpen] = useState({
        confirmation: false,
        invitation: false
    });

    const handleConfirmationClose = () => {
        setOpen({ ...open, confirmation: false });
    }
    const handleConfirmationClick = () => {
        setOpen({ confirmation: false, invitation: true });
        document.body.classList.add("no-scroll");
    }

    const handleOpen = () => {
        // To show warning based on tickets and form existence at time of invite
        setLoader(true);
        getEventTickets({ eventId: eventData?.id, includeHiddenTickets: 0 }).then((res) => {
            setLoader(false);
            if ((eventData?.is_paid && res.data.data.length === 0) || (eventData?.has_registration && !eventData?.has_registration_form)) {
                setOpen({ ...open, confirmation: true });
            } else {
                document.body.classList.add("no-scroll");
                setOpen({ ...open, invitation: true });
            }
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error("Something went wrong in fetching tickets", {
                position: "top-right",
                theme: "dark"
            });
        });
    }
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        document.body.classList.remove("no-scroll");
        // setInviteMsg(null); To show last made changes on again opening modal
        setScheduledDate(null);
        setGuestInviteActiveStep(0);
        setPayment(false);
        setOpen({ ...open, invitation: false });
    }

    const CancelEditOptionComponent = () => {
        return (isCheckboxVisible ?
            <Button variant='outlined' color='warning' onClick={handleCancel}>
                Cancel
            </Button> : null)
    }

    return (
        <>
            {(tabValue === 1) ? // Show only cancel option for Registered guests tab
                <CancelEditOptionComponent />
                :
                <Stack direction='row' spacing={2}>
                    <CancelEditOptionComponent />
                    {
                        checkedGuests.length > 0 ? <Tooltip title="Invite selected guests" arrow>
                            <LoadingButton
                                onClick={handleOpen}
                                variant='outlined' color='warning'
                                loading={loader ? true : false}
                                startIcon={loader ? <SaveIcon /> : null}
                                loadingPosition={loader ? "start" : null}
                            >
                                Invite({checkedGuests.length})
                            </LoadingButton>
                        </Tooltip> : <Tooltip title="Invite all guests" arrow>
                            <LoadingButton
                                onClick={handleOpen}
                                variant='outlined' color='warning'
                                loading={loader ? true : false}
                                startIcon={loader ? <SaveIcon /> : null}
                                loadingPosition={loader ? "start" : null}
                            >
                                Invite
                            </LoadingButton>
                        </Tooltip>
                    }
                    {open.invitation ? <SetInvitationDialog handleClose={handleClose} /> : null}
                    {open.confirmation ?
                        <InviteConfirmationDialog
                            tag={eventData?.is_paid ? 'paid' : 'free'}
                            handleConfirmationClick={handleConfirmationClick}
                            handleConfirmationClose={handleConfirmationClose}
                        /> : null
                    }
                </Stack>
            }
        </>
    )
};

export default EventGuestInvite;
