import { Button, DialogActions, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { groupDetails } from "../../../../../../../../../redux/slices/groupDetailSlice";
import { editGroupKeyPerson } from "../../../../../../../../../services/wGroups/groupDetails";
import { editEventMemberForm } from "../../../../../../../../../utils/constants/editEventMember";
import { manageGroupKeyPersonPayload } from "../../../../../../../../../utils/functions/managePayload";

const EditMemberContent = (handleClose, setItems, groupKeyPersonDetails) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();
    const [loader, setLoader] = useState(false);
    const groupData = useSelector(groupDetails);
    const initialRender = useRef(true);
    const [personImage, setPersonImage] = useState(groupKeyPersonDetails?.person_photo_path);
    const onImageChoose = (img) => {
        setPersonImage(img);
    }

    const mySubmit = (data) => {
        setLoader(true);
        let payload = manageGroupKeyPersonPayload(data, true)
        // console.log(Object.fromEntries(payload))
        editGroupKeyPerson(payload, groupData?.id, groupKeyPersonDetails?.id).then((res) => {
            console.log('---------------->', res.data.data);
            setItems((items) => {
                let ele = items.find(i => i.id === res.data?.data?.id);
                items[items.indexOf(ele)] = res.data.data;
                return [...items];
            })
            toast.success("Event Person details Updated!!", {
                position: "top-right",
                theme: "dark"
            });
            setLoader(false);
            handleClose();
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            if (initialRender.current) {
                initialRender.current = false;
            } else {
                setPersonImage(groupKeyPersonDetails?.photo);
            }
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {editEventMemberForm.data.map((item, index) => (
                        <RenderFormFields
                            key={index}
                            item={item}
                            register={register}
                            errors={errors}
                            control={control}
                            editForm={true}
                            cardDetails={groupKeyPersonDetails}
                            file={personImage}
                            setFile={setPersonImage}
                            onImageChoose={onImageChoose}
                        />
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true:false}
                        startIcon={loader?<SaveIcon/>:null}
                        loadingPosition={loader?"start" :null}
                        sx={{
                            boxShadow: 'none'}}
                    >
                       Save Changes
                    </LoadingButton>
                            </DialogActions>
            </form>
        </>
    )
}

const EditKeyPersonDialog = ({ handleClose, setItems, groupKeyPersonDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Edit Person' content={EditMemberContent(handleClose, setItems, groupKeyPersonDetails)}
        />
    )
};

export default EditKeyPersonDialog;
