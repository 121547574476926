import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { update_event_details } from '../../../../../../redux/slices/eventDataSlice';
import { updateEventViewTitle } from '../../../../../../services/wEvents/eventDetails';

const EditEventViewTitleDialog = ({ viewId, viewTitle, handleClose }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(viewTitle);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = {
            title: inputValue
        }
        updateEventViewTitle(payload, viewId).then((result) => {
            dispatch(update_event_details(result.data.event_details))
            setLoading(false);
            toast.success("View title updated", {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        }).catch((err) => {
            console.log(err);
            handleClose();
        });
    }

    return (
        <>
            <Dialog
                open
                fullWidth
                onClose={handleClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleClose()}
                        sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        Edit View Title
                    </Typography>
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Stack spacing={1} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                color="warning"
                                value={inputValue}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction='row' spacing={1}>
                            <LoadingButton
                                type='submit'
                                color="warning"
                                variant="outlined"
                                loading={loading ? true : false}
                                startIcon={loading ? <SaveIcon /> : null}
                                loadingPosition={loading ? "start" : null}
                            >
                                {loading ? 'Saving..' : 'Save'}
                            </LoadingButton>
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
};

export default EditEventViewTitleDialog;