import { Box } from "@mui/material";
import React from "react";
import { mapEventViews } from "../../../../../../utils/functions/mapEventViews";

const SubEvents = ({ views }) => {
    return (
        <>
            <Box sx={{ pb: '1rem' }}>
                {views.map((v, i) => (
                    <React.Fragment key={i}>
                        {/* last param defines adding divider in every event view except last one */}
                        {mapEventViews(v?.id, v?.title, v?.type, i !== views.length - 1)}
                    </React.Fragment>
                ))}
            </Box>
        </>
    );
};

export default SubEvents;
