import React from "react";
import { Stack, TextField, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Switch } from "@mui/material";

const ViewRsvpQuestions = ({ rsvpQuestions }) => {

    const renderItemView = (item) => {
        switch (item.type) {
            case 'text':
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                )
            case 'textarea':
                return (
                    <>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                );
            case 'switch':
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                {item.question}
                            </Typography>
                            <Switch
                                color='warning'
                            />
                        </Stack>
                    </>
                )
            case 'radio':
                return (
                    <>
                        <FormControl>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                            <RadioGroup row>
                                {item.options.map((option, i) => (
                                    <FormControlLabel key={i} value={option} control={<Radio color="warning" />} label={option} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </>
                )
            default:
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                        />
                    </>
                );
        }
    }

    return (
        <>
            <Stack spacing={2}>
                {rsvpQuestions?.map((item, i) => (
                    <React.Fragment key={i}>
                        {renderItemView(item)}
                    </React.Fragment>
                ))}
            </Stack>
        </>
    )
};

export default ViewRsvpQuestions;
