import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react'
import { parseDecimalNumber } from "../../../../../../../../../../utils/functions/getFormattedNumber";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import EditFacilityDialog from "./EditFacilityDialog";
import DeleteFacilityDialog from "./DeleteFacilityDialog";

const FacilityCard = ({ facility, handleEditEventFacility, handleDeleteEventFacility }) => {
    const initialActions = {
        edit: false,
        delete: false
    };

    const [action, setAction] = useState(initialActions)
    const facilityPriceSymbol = ticketCurrencies.find((f) => f.value === facility.currency)?.symbol;

    return (
        <>
            <Stack spacing={1} sx={{
                p: 1,
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.1)'
            }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{facility.name}</Typography>
                    <Stack direction='row' spacing={0.5} alignItems='center'>
                        <IconButton onClick={() => setAction({ ...initialActions, edit: true })}>
                            <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                        <IconButton onClick={() => setAction({ ...initialActions, delete: true })} >
                            <DeleteIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                    </Stack>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontWeight: '500', fontSize: '16px', display: 'inline-block' }}>{
                        ((!facility.hasOwnProperty("scan_quantity") || facility.scan_quantity === null) ? "Multiple times" :
                            `${facility.scan_quantity} time${facility.scan_quantity > 1 ? "s" : ""}`)} scan
                    </Typography>
                    {
                        !(facility.is_free) ?
                            <Stack direction='row' spacing={1}>
                                <Typography sx={{ fontWeight: '500', fontSize: '16px', display: 'inline-block' }}>Amount:</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', display: 'inline-block', color: '#1F274A', }}>
                                    {parseDecimalNumber(facility.price, 3)} <span dangerouslySetInnerHTML={{ __html: facilityPriceSymbol }} />
                                </Typography>
                            </Stack> : 
                            null
                    }
                </Stack>

                {action.edit ? <EditFacilityDialog edit={true} setAction={setAction} handleEditEventFacility={handleEditEventFacility} facility={facility} /> : null}
                {action.delete ? <DeleteFacilityDialog setAction={setAction} facility={facility} handleDeleteEventFacility={handleDeleteEventFacility} /> : null}
            </Stack>
        </>
  )
}

export default FacilityCard;