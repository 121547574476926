export const validityPeriods = [
    {
        "label": "Day(s)",
        "value": "day",
    },
    {
        "label": "Month(s)",
        "value": "month",
    },
    {
        "label": "Year(s)",
        "value": "year",
    },
    {
        "label": "Lifetime",
        "value": "lifetime",
    }
]