import React from "react";
import { Stack, Button, DialogActions, Typography } from "@mui/material";
import DialogBox from "../../components/DialogBox";

const DeleteBankDialogContent = (handleDeleteClose, handleBankDelete) => {
    return (
        <>
            <Stack spacing={3}>
                <Typography>Are you sure want to delete this bank ?</Typography>
                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button color='warning' variant="contained" onClick={handleBankDelete}
                        sx={{ boxShadow: 'none' }}>
                        Ok
                    </Button>
                </DialogActions>
            </Stack>
        </>
    )
};

const DeleteBankDialog = ({ handleBankDelete, handleDeleteClose }) => {
    return (
        <>
            <DialogBox open={true} title='Delete Bank' handleClose={handleDeleteClose}
                content={DeleteBankDialogContent(handleDeleteClose, handleBankDelete)}
            />
        </>
    )
};

export default DeleteBankDialog;
