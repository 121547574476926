export const questionTypes = [
    {
        "label": "Short Answer",
        "value": "text",
    },
    {
        "label": "Long Answer",
        "value": "textarea",
    },
    {
        "label": "Yes/No Answer",
        "value": "switch",
    },
    {
        "label": "Multiple Choice, Single Answer",
        "value": "radio",
    },
    {
        "label": "Multiple Choice, Multiple Answer",
        "value": "checkbox",
    },
    {
        "label": "File Upload",
        "value": "file",
    },
]