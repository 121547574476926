import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import UploadImage from "../../../../../../../../components/UploadImage";

const GroupDocForm = ({ docData, register, errors, frontImage, setFrontImage, backImage, setBackImage }) => {

    return (
        <>
            <Stack alignItems="center" spacing={3}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                    {docData ?
                        <Typography sx={{
                            textAlign: 'center',
                            width: { xs: '80%', sm: '60%', md: '75%', lg: '60%' },
                        }}>Document front photo</Typography> : null
                    }
                    <Box
                        sx={{
                            backgroundColor: frontImage ? '#171717' : 'white',
                            borderRadius: "10px",
                            width: { xs: '60%', sm: '50%', lg: '40%' },
                            height: "9rem",
                            position: "relative",
                            border: errors.document_front_photo ? '1px solid red' : '1px solid lightgrey',
                        }}
                    >
                        <UploadImage fieldName='document_front_photo'
                            label='Upload front photo'
                            register={register} errors={errors}
                            selectedImage={frontImage} setSelectedImage={setFrontImage}
                            hideOptions={docData?.is_verified ? true : false}
                            hideVisibility />
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: { xs: 'center' },
                        justifyContent: { xs: 'center' },
                        flexDirection: 'column',
                    }}>
                    {docData ?
                        <Typography sx={{
                            textAlign: 'center',
                            width: { xs: '80%', sm: '60%', md: '75%', lg: '60%' },
                        }}>Document back photo</Typography> : null
                    }
                    <Box
                        sx={{
                            backgroundColor: backImage ? '#171717' : 'white',
                            borderRadius: "10px",
                            width: { xs: '60%', sm: '50%', lg: '40%' },
                            height: "9rem",
                            position: "relative",
                            border: errors.document_back_photo ? '1px solid red' : '1px solid lightgrey',
                        }}
                    >
                        <UploadImage fieldName='document_back_photo'
                            label='Upload back photo'
                            register={register} errors={errors}
                            selectedImage={backImage} setSelectedImage={setBackImage}
                            hideOptions={docData?.is_verified ? true : false}
                            hideVisibility />
                    </Box>
                </Box>
            </Stack>
        </>
    )
};

export default GroupDocForm;
