export const ticketCurrencies = [
    {
        "label": "₹ (Rupees)",
        "symbol": "&#8377;",
        "value": "rupees",
        "currency": 'INR'
    },
    {
        "label": "$ (Dollar)",
        "symbol": "&#36;",
        "value": "dollar",
        "currency": 'USD'
    },
    {
        "label": "€ (Euro)",
        "symbol": "&euro;",
        "value": "euro",
        "currency": 'EUR'
    },
    {
        "label": "£ (Pound)",
        "symbol": "&pound;",
        "value": "pound",
        "currency": 'GBP'
    }
]