import { toast } from "react-toastify";
import React, { useContext, useEffect, useState } from "react";
import { getCategories } from "../../../../../../services/categories";
import { Box, Stack } from "@mui/material";
import { CreateGroupContext } from "../..";
import GroupBox from "./GroupBox";

const GroupCategory = () => {
    const [groupCategories, setGroupCategories] = useState([])
    useEffect(() => {
        getCategories({ type: 'group' }).then((res) => {
            console.log(res.data.data);
            setGroupCategories(res.data.data)
        }).catch((err) => {
            console.log("$$$$$$$$", err);
            toast.error(err.message || err.message?.errors, {
                position: "top-right",
                theme: "dark"
            });
        })
    }, []);

    const { formData, setFormData } = useContext(CreateGroupContext);
    const [activeId, setActiveId] = useState('');

    const onSelect = (categoryObj) => {
        setActiveId(categoryObj.id);
        setFormData({ ...formData, category: categoryObj.title, category_id: categoryObj.id })
    };

    return (
        <>
            <Box sx={{ p: { xs: '1rem', sm: '1.5rem' } }}>
                <Stack direction='row' flexWrap='wrap' justifyContent='flex-start' alignItems='flex-start'
                    // spacing={{ xs: '1rem' }}
                    rowGap={2} sx={{ mt: { xs: 0.5, sm: 1 } }}>
                    {groupCategories.map((gc, i) => (
                        <GroupBox key={i} group={gc} activeId={activeId} onSelect={onSelect} />
                    ))}
                </Stack>
                <br />
            </Box>
        </>
    )
};

export default GroupCategory;
