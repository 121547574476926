import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import pluralize from 'pluralize';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventPrograms } from "../../../../../../../../services/wEvents/eventDetails";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import EditEventViewTitleDialog from '../../EditEventViewTitleDialog';
import HorizontalScrollingCards from "../HorizontalScrollMenu";
import CreateProgramDialog from "./components/CreateProgramDialog";

export const EventProgramContext = React.createContext({});

const EventProgramsSection = ({ viewId, title, addDivider }) => {
    const [dataItems, setItems] = useState([]);
    const eventData = useSelector(eventDetails);
    const [dialog, setDialog] = useState({
        add: false,
        editTitle: false
    });
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    const handleEdit = () => {
        setDialog({ ...dialog, editTitle: true });
    }
    const handleAdd = () => {
        if (!eventData?.is_online && (!eventData.multiple_venues && !eventData.address)) {
            toast.error("Please Add Event Venue First", {
                position: "top-right",
                theme: "dark"
            });
        } else {
            document.body.classList.add("no-scroll");
            setDialog({ ...dialog, add: true });
        }
    }
    const handleClose = (operation) => {
        document.body.classList.remove("no-scroll");
        setDialog({ ...dialog, [operation]: false });
    }

    useEffect(() => {
        getEventPrograms({ eventId: eventData?.id, viewId: viewId }).then((res) => {
            const eventPrograms = res.data.data
            setItems(eventPrograms)
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <EventProgramContext.Provider value={{ viewId, title }}>
            {(isAllPermission ? true : dataItems?.length > 0) ?
                <>
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ pb: 0.5 }}>
                        <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{title}</Typography>
                        {isAllPermission ?
                            <IconButton color='warning' onClick={handleEdit}
                                sx={{ ":hover": { backgroundColor: 'white' } }}>
                                <EditIcon sx={{ width: '1.25rem' }} />
                            </IconButton>
                            : null
                        }
                        {dialog.editTitle ?
                            <EditEventViewTitleDialog
                                viewId={viewId}
                                viewTitle={title}
                                handleClose={() => handleClose('editTitle')} /> : null
                        }
                    </Stack>
                    <HorizontalScrollingCards category='EventPrograms' items={dataItems} setItems={setItems} />

                    {isAllPermission ?
                        <Box>
                            <Button variant="text" color="warning" onClick={handleAdd} sx={{
                                textAlign: 'left', '&:hover': {
                                    background: 'none',
                                },
                            }}>
                                + Add {pluralize.singular(title)}
                            </Button>
                            {dialog.add ?
                                <CreateProgramDialog
                                    handleClose={() => handleClose('add')}
                                    setItems={setItems}
                                />
                                : null
                            }
                        </Box>
                        : null
                    }
                    {(addDivider && (isAllPermission ? true : dataItems?.length > 0)) ? <Divider sx={{ my: 2 }} /> : null}
                </>
                : null
            }
        </EventProgramContext.Provider>
    )
};

export default EventProgramsSection;
