import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography } from "@mui/material";
import _ from 'lodash';
import React, { useContext, useState } from "react";
import { GroupSettingsContext } from "../..";
import { ticketCurrencies } from '../../../../../../../../utils/constants/ticketCurrency';
import DeleteSubscription from "./DeleteSubscription";
import EditSubscription from "./EditSubscription";
import ViewSubscription from "./ViewSubscription";

const SubscriptionCard = ({ subscription }) => {
    const { subscriptions } = useContext(GroupSettingsContext);
    const initialActions = {
        view: false,
        edit: false,
        delete: false
    }
    const [action, setAction] = useState(initialActions)
    const subscriptionPriceSymbol = ticketCurrencies.find((t) => t.value === subscription.currency)?.symbol;

    return (
        <>
            <ListItem>
                <ListItemText
                    primary={subscription?.title}
                    primaryTypographyProps={{ fontWeight: 'bold' }}
                    secondary={
                        <React.Fragment>
                            {subscription?.validity_type === 'lifetime' ?
                                <Typography sx={{ fontSize: '15px', mt: 0.4 }}>
                                    Lifetime Validity
                                </Typography>
                                :
                                <Typography sx={{ fontSize: '15px', mt: 0.4 }}>
                                    {subscription?.validity_time} {subscription?.validity_type} Validity
                                </Typography>
                            }
                            <Typography sx={{ fontSize: '15px' }}>
                                {_.round(subscription?.purchase_price, 2)} <span dangerouslySetInnerHTML={{ __html: subscriptionPriceSymbol }} /> Charge
                            </Typography>
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction sx={{ right: { xs: 0, sm: '0.5rem' } }}>
                    <Stack direction='row' spacing={{ xs: 0, sm: 0.5 }} alignItems='center'>
                        <IconButton sx={{ p: 0.75 }} onClick={() => setAction({ ...initialActions, view: true })}>
                            <VisibilityIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                        <IconButton sx={{ p: 0.75 }} onClick={() => setAction({ ...initialActions, edit: true })}>
                            <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                        <IconButton sx={{ p: 0.75 }} onClick={() => { setAction({ ...initialActions, delete: true }) }}>
                            <DeleteIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                    </Stack>
                </ListItemSecondaryAction>
            </ListItem>
            {
                (subscriptions?.indexOf(subscription) !== subscriptions?.length - 1) ?
                    <Divider component="li" /> : null
            }
            {action.edit ? <EditSubscription setAction={setAction} subscription={subscription} /> : null}
            {action.view ? <ViewSubscription setAction={setAction} subscription={subscription} /> : null}
            {action.delete ? <DeleteSubscription setAction={setAction} subscription={subscription} /> : null}
        </>
    )
};

export default SubscriptionCard;
