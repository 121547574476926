import React, { useContext } from "react";
import { Stack } from "@mui/material";
import TicketCard from "./TicketCard";
import { EventSettingsContext } from "../..";

const TicketList = () => {
    const { tickets } = useContext(EventSettingsContext);
    return (
        <>
            <Stack spacing={4} justifyContent='center' alignItems='center' sx={{ pb: '1.5rem' }}>
                {tickets.map((t, i) => (
                    <TicketCard key={i} ticket={t} />
                ))}
            </Stack>
        </>
    )
};

export default TicketList;
