import './index.scss'
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography, Stack, TextField, InputAdornment, Tabs, Tab } from "@mui/material";
import EventSwitches from './EventSwitches';
import EventTimezone from './EventTimezone';
import EventRadioOptions from './EventRadioOptions';
import { CreateEventContext } from "../CreateEventContext";
import FormDateTimePicker from '../../../../../../components/FormDateTimePicker';

const EventDetails = () => {
    const { formData, setFormData, fieldErrors, setFieldErrors } = useContext(CreateEventContext);
    const [value, setValue] = useState(formData.current_tab_value || 'date');
    const startDateOptions = [{label: 'Event Date', value: 'date'}, {label:'Tentative', value: 'tentative'}];
    const tentativeRef = useRef(null);

    const handleEventName = (e) => {
        setFormData({ ...formData, title: e.target.value })
        if (e.target.value === '') {
            setFieldErrors({ ...fieldErrors, [e.target.name]: true })
        } else {
            setFieldErrors({ ...fieldErrors, [e.target.name]: false })
        }
    }

    const changeDate = (field) => (newValue) => {
        setFormData({ ...formData, [field]: dayjs(newValue).toDate() })
        if (newValue === '') {
            setFieldErrors({ ...fieldErrors, [field]: true })
        } else {
            setFieldErrors({ ...fieldErrors, [field]: false })
        }
    }

    const handleTentative = (e) => {
        setFormData({ ...formData, schedule_announcement_description: e.target.value })
        if (e.target.value === '') {
            setFieldErrors({ ...fieldErrors, [e.target.name]: true })
        } else {
            setFieldErrors({ ...fieldErrors, [e.target.name]: false })
        }
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setFormData({ ...formData, current_tab_value: newValue });
        if (newValue === 'tentative') {
            setTimeout(() => {
                tentativeRef.current.focus();
            }, 100);
            setFormData({
                ...formData,
                schedule_announcement_description: 'To be announced',
                start_date: null,
                end_date: null,
                current_tab_value: 'tentative'
            });
        } else {
            setFormData({
                ...formData,
                schedule_announcement_description: '',
                start_date: formData.start_date,
                end_date: formData.end_date,
                current_tab_value: 'date'
            });
        }
    };

    useEffect(() => {
        return (() => {
            setFieldErrors({ ...fieldErrors, title: false, start_date: false, end_date: false, schedule_announcement_description: false })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: { xs: '4rem', sm: '5rem' } }}>
                <Box sx={{ borderRadius: '10px', boxShadow: '0px 0px 25px #ccc', width: { xs: '95%', sm: '65%', lg: '45%' }, mt: { xs: 1, sm: 4 } }}>
                    <Stack sx={{ m: { xs: 1, sm: 2 } }} spacing={1}>
                        <Typography sx={{ mt: 1, ml: { xs: 1, sm: 0 }, fontSize: { xs: '1rem', sm: '1.5rem' } }}>
                            Event Details
                        </Typography>
                        <Stack spacing={2} sx={{ pt: 1 }}>
                            <TextField
                                required
                                label="Event Name"
                                color="warning"
                                name="title"
                                value={formData?.title}
                                onChange={handleEventName}
                                error={fieldErrors?.title}
                                helperText={fieldErrors?.title ? 'This is required field' : null}
                                inputProps={{
                                    maxLength: 30
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Typography>{formData?.title.length}/30</Typography>
                                    </InputAdornment>
                                }}
                            />
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                indicatorColor=""
                                sx={{
                                    border: '2px solid #FF8359',
                                    borderRadius: '10px',
                                    minHeight: '30px',
                                    width: {xs: '100%', md:'70%'},
                                    '.Mui-selected': {
                                        fontWeight: "bold !important",
                                        color: '#FFFFFF !important',
                                        backgroundColor: '#FF8359',
                                        opacity: '1 !important'
                                    },
                                }}
                            >
                                {startDateOptions.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        value={tab.value}
                                        label={<span style={{ textTransform: 'none' }}>{tab.label}</span>}
                                        sx={{
                                            color: '#FF8359',
                                            minHeight: '30px',
                                            textTransform: 'none',
                                            width: '50%',
                                        }}
                                    />
                                ))}
                            </Tabs>
                            {value === 'date' && (
                                <>
                                    <FormDateTimePicker
                                        label='Start Date & Time'
                                        fieldName='start_date'
                                        changeDate={changeDate}
                                        isStartDate
                                        error={fieldErrors?.start_date}
                                        isRequired={value === 'date' ? true : false}
                                    />
                                    <FormDateTimePicker
                                        label='End Date & Time'
                                        fieldName='end_date'
                                        changeDate={changeDate}
                                        isStartDate={false}
                                        isRequired={false}
                                    />
                                </>
                            )}
                            {value === 'tentative' && (
                                <TextField
                                    required={value === 'tentative' ? true : false}
                                    label="Description"
                                    color="warning"
                                    name="schedule_announcement_description"
                                    value={formData?.schedule_announcement_description}
                                    onChange={handleTentative}
                                    error={fieldErrors?.schedule_announcement_description}
                                    helperText={ fieldErrors?.schedule_announcement_description ? "This is required field" : null }
                                    inputRef={tentativeRef}
                                />
                            )}
                            <EventTimezone />
                            <EventRadioOptions />
                            <EventSwitches />

                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}
export default EventDetails;
