import React from "react";
import { Stack, TextField } from "@mui/material";

const EditPhysicalLocation = ({ eventDetailsObj, register }) => {
    return (
        <>
            <Stack spacing={2} sx={{ zIndex: 0 }}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    color="warning"
                    label="Event Address"
                    defaultValue={eventDetailsObj?.address}
                    InputLabelProps={{ shrink: true }}
                    {...register("address")}
                />
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="City"
                        defaultValue={eventDetailsObj?.city}
                        InputLabelProps={{ shrink: true }}
                        {...register("city")}
                    />
                    <TextField
                        fullWidth
                        color="warning"
                        label="Zip code"
                        defaultValue={eventDetailsObj?.zip}
                        InputLabelProps={{ shrink: true }}
                        {...register("zip")}
                    />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="State"
                        defaultValue={eventDetailsObj?.state}
                        InputLabelProps={{ shrink: true }}
                        {...register("state")}
                    />
                    <TextField
                        fullWidth
                        color="warning"
                        label="Country"
                        defaultValue={eventDetailsObj?.country}
                        InputLabelProps={{ shrink: true }}
                        {...register("country")}
                    />
                </Stack>
                <TextField
                    fullWidth
                    color="warning"
                    label="Google Map Link"
                    defaultValue={eventDetailsObj?.google_map_link}
                    InputLabelProps={{ shrink: true }}
                    {...register("google_map_link")}
                />
            </Stack>
        </>
    )
};

export default EditPhysicalLocation;
