import React, { useContext } from "react";
import { Stack, Typography, TextField } from "@mui/material";
import { CreateEventContext } from "../CreateEventContext";

const OnlineLocation = () => {
    const { formData, setFormData } = useContext(CreateEventContext);
    const handleOnlineLink = (event) => {
        setFormData({ ...formData, eventLink: event.target.value })
    }
    // const [count, setCount] = React.useState(1)
    // const handleCount = () => {
    //     setCount(count + 1)
    // }

    // const handleOnlineLinks = (event, i) => {
    //     if (event.target.value === '') {
    //         delete formData.eventLinks[i];
    //         setFormData({ ...formData, eventLinks: formData.eventLinks })
    //     } else {
    //         formData.eventLinks[i] = event.target.value
    //         setFormData({ ...formData, eventLinks: formData.eventLinks })
    //     }
    // }

    return (
        <>
            <Stack spacing={2} sx={{ pt: 1 }}>
                <Typography>Enter online event link</Typography>
                <TextField
                    color="warning"
                    label="Link of event"
                    onChange={(e) => handleOnlineLink(e)}
                    value={formData?.eventLink ? formData?.eventLink : ""}
                />
                {/* {[...Array(count)].map((_, i) => (
                    <TextField
                        key={i}
                        label="Link of event"
                        color="warning"
                        value={formData?.eventLinks[i] ? formData?.eventLinks[i] : ""}
                        onChange={(e) => handleOnlineLinks(e, i)}
                    />
                )
                )}
                <Box>
                    <Button variant="text" color="warning" onClick={handleCount} sx={{
                        textAlign: 'left', '&:hover': {
                            background: 'none',
                        },
                    }}>
                        + Add more link
                    </Button>
                </Box> */}
            </Stack>
        </>
    )
};

export default OnlineLocation;
