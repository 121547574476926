import { axiosInstance } from "../../config/interceptor";
import { GROUP_SUBSCRIPTION_API } from "../../utils/constants/apis.urls";

export const getGroupSubscriptions = (groupId) => {
    return axiosInstance.get(
        `${GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION.replace(
            ":group_id",
            groupId
        )}`
    );
};

export const getGroupSubscriptionPayments = ({ groupId, page }) => {
    return axiosInstance.get(
        `${GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION_PAYMENTS.replace(
            ":group_id",
            groupId
        )}?page=${page}`
    );
};

export const getGroupSubscriptionPaymentDetails = (groupId, memberSubscriptionId) => {
    return axiosInstance.get(
        `${GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION_PAYMENT_DETAILS.replace(
            ":group_id",
            groupId
        ).replace(":member_subscription_id", memberSubscriptionId)
        }`
    );
};

export const addGroupSubscription = (payload, groupId) => {
    return axiosInstance.post(
        `${GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION.replace(
            ":group_id",
            groupId
        )}`,
        payload
    );
};

export const editGroupSubscription = (payload, groupId, subscriptionId) => {
    return axiosInstance.post(
        `${GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION.replace(
            ":group_id",
            groupId
        )}/${subscriptionId}`,
        payload
    );
};

export const deleteGroupSubscription = (groupId, subscriptionId) => {
    return axiosInstance.delete(
        `${GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION.replace(
            ":group_id",
            groupId
        )}/${subscriptionId}`
    );
};

export const submitSelectedSubscription = (payload, groupId) => {
    return axiosInstance.post(
        `${GROUP_SUBSCRIPTION_API.SELECT_GROUP_SUBSCRIPTION.replace(
            ":group_id",
            groupId
        )}`,
        payload
    );
};

export const getPurchasedGroupSubscriptionDetails = (groupId, subscriptionId) => {
    return axiosInstance.get(GROUP_SUBSCRIPTION_API.GROUP_SUBSCRIPTION_PURCHASED.replace(
        ':group_id', groupId).replace(':subscription_id', subscriptionId));
}
