import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton, Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";

const DialogBox = ({ open, handleClose, title, content }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                        maxWidth: content?.props?.children?.props?.id === 'bigWidthDialog' ? { xs: '90%', sm: '70%' } : '600px',
                    }
                }}
            >
                <DialogTitle>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>{title}</Typography>
                </DialogTitle>
                <Divider sx={{ mb: 1 }} />
                <DialogContent sx={{ px: '1rem', py: '0rem' }}>
                    {content}
                </DialogContent>
            </Dialog>
        </>
    )
};

export default DialogBox;
