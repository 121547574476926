import { Box, Typography } from "@mui/material";
import React from "react";
import { CreateGroupContext } from "../..";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";

const GroupRadioOptions = () => {
    const { formData, setFormData } = React.useContext(CreateGroupContext);
    const [groupType, setGroupType] = React.useState('public');
    const handleEventTypeChange = (event) => {
        setGroupType(event.target.value);
        setFormData({ ...formData, is_private: !formData.is_private })
    };

    return (
        <>
            <Box>
                <Typography sx={{ mb: 1, fontSize: { xs: '1rem', sm: '1rem' } }}>
                    Organization type
                </Typography>
                <CustomRadioGroup value={groupType} setValue={setGroupType}
                    handleRadioChange={handleEventTypeChange} option1='public' option2='private' />
            </Box>
        </>
    )
};

export default GroupRadioOptions;
