import { Box, Typography } from "@mui/material";
import React from "react";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import { CreateEventContext } from "../CreateEventContext";

const EventRadioOptions = () => {
    const { formData, setFormData } = React.useContext(CreateEventContext);
    const [eventType, setEventType] = React.useState(formData?.is_private ? 'invite only' : 'public');
    const handleEventTypeChange = (event) => {
        setEventType(event.target.value);
        setFormData({ ...formData, is_private: !formData.is_private })
    };
    const [eventTicket, setEventTicket] = React.useState(formData?.is_paid ? 'paid' : 'free');
    const handleEventTicketChange = (event) => {
        setEventTicket(event.target.value);
        setFormData({ ...formData, is_paid: !formData.is_paid })
    };

    return (
        <>
            <Box>
                <Typography sx={{ mb: 1, fontSize: { xs: '1rem', sm: '1rem' } }}>
                    Event Type
                </Typography>
                <CustomRadioGroup value={eventType} setValue={setEventType}
                    handleRadioChange={handleEventTypeChange} option1='public' option2='invite only' />
            </Box>
            <Box>
                <Typography sx={{ mb: 1, fontSize: { xs: '1rem' } }}>
                    Event Ticket
                </Typography>
                <CustomRadioGroup value={eventTicket} setValue={setEventTicket}
                    handleRadioChange={handleEventTicketChange} option1='free' option2='paid' />
            </Box>
        </>
    )
};

export default EventRadioOptions;
