import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import { GuestRegistrationContext } from '../..';
import { cashPayment } from "../../../../../../../../services/Payment/cashPayment";
import { toast } from 'react-toastify';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import { useSelector } from 'react-redux';
import { EventDetailsContext } from '../../../../../..';

const CashPaymentIntegration = ({ totalAmountToPay, amountCurrency, sendToWhatsapp }) => {
    const [paymentConfirmation, setPaymentConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const eventData = useSelector(eventDetails);
    const { registrationData, paymentDetails, setPaymentDetails } = useContext(GuestRegistrationContext);
    const { setTabValue } = useContext(EventDetailsContext);
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedFacilities = registrationData?.facility_status.length > 0 ?
        registrationData?.facility_status.filter(facility => facility.selected).map(facility => facility.name) : [];

    const handleViewClose = () => {
        setPaymentConfirmation(false);
    }

    const handlePayment = () => {
        setLoading(true);
        const payload = {
            guest_ticket_id: paymentDetails.guest_ticket_id,
            payment_amount: totalAmountToPay,
            amount_currency: amountCurrency,
            amount_currency_symbol: paymentDetails.ticket_currency_symbol,
            current_timezone: currentTimezone,
            send_to_whatsapp: sendToWhatsapp,
            registered_by: eventData?.current_user_role === 'manager' ? eventData?.guest_id : eventData?.event_owner_id,
            facility_details: selectedFacilities.length > 0 ? JSON.stringify(selectedFacilities) : null,
        }
        cashPayment(payload).then((response) => {
            const ticketMessage = sendToWhatsapp
                ? "Payment successful, You'll receive your event ticket on your email and whatsapp!!"
                : "Payment successful, You'll receive your event ticket on your email!!";
            setLoading(false);
            setPaymentDetails({ ...paymentDetails, paymentId: response.payment_id });
            setPaymentConfirmation(false);
            toast.success(ticketMessage, {
                position: "top-right",
                theme: "dark"
            });

            setTabValue(0);  // navigate to info tab
            // if (guestId) {
            //     navigate(`/${PAGE_PATHS.GUEST_EVENT_DETAILS.replace(':guestId', guestId)}`)
            // } else {
            //     navigate(`/${PAGE_PATHS.EVENT_DETAILS.replace(':id', id)}`)
            // }
        }).catch((err) => {
            console.log("err", err)
            toast.error(err?.response?.data?.message, {
                position: "top-right",
                theme: "dark"
            });
            setLoading(false);
        })
    }

    return (
        <>
            <Button
                type="submit"
                onClick={() => setPaymentConfirmation(true)}
                sx={{
                    color: 'white',
                    backgroundColor: '#1F274A',
                    ":hover": {
                        backgroundColor: '#1F274A',
                    },
                }}
            >
                Submit
            </Button>
            <Dialog
                fullWidth
                open={paymentConfirmation}
                onClose={handleViewClose}
                PaperProps={{ sx: { width: { xs: "80%", sm: "70%", md: "50%" }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={handleViewClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontWeight: "500", fontSize: "20px", display: "inline-block" }}>
                        Confirmation
                    </Typography>
                </DialogTitle>
                <Divider dir="horizontal" />
                <DialogContent sx={{
                    p: { xs: "1rem", md: "1rem 1.5rem 0.5rem" },
                }}>
                    <Typography sx={{ fontSize: { xs: "1rem" } }} mb={1}>
                        Have you received payment? 
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={handleViewClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        onClick={handlePayment}
                        startIcon={loading ? <SaveIcon /> : ''}
                        loadingPosition={loading ? "start" : null}
                        variant="contained"
                        color="warning"
                        sx={{ boxShadow: "none" }}
                    >
                        {loading ? 'Processing..' : 'Yes'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CashPaymentIntegration;