import { Button, DialogActions, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { toast } from "react-toastify";
import { EventKeyMembersContext } from "..";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { createEventMember } from "../../../../../../../../../services/wEvents/eventDetails";
import { createKeyMemberForm } from "../../../../../../../../../utils/constants/createKeyMember";
import { manageEventMemberPayload } from "../../../../../../../../../utils/functions/managePayload";
import pluralize from "pluralize";

const CreateMemberContent = (handleClose, setItems) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();

    const eventData = useSelector(eventDetails);
    const [loader,setLoader]=useState(false);
    const { viewId, title } = useContext(EventKeyMembersContext);
    const [imageToCrop, setImageToCrop] = useState(null);
    const onImageChoose = (img) => {
        setImageToCrop(img);
    }

    const mySubmit = (data) => {
        setLoader(true);
        data.viewId = viewId
        let payload = manageEventMemberPayload(data)
        createEventMember(payload, eventData?.id).then((res) => {
            toast.success(`${pluralize.singular(title)} Created Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => [...items, res.data.data])
            setLoader(false);
            handleClose();
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            setImageToCrop(null);
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {createKeyMemberForm.data.map((item, index) => (
                        <RenderFormFields
                            key={index}
                            item={item}
                            eventData={eventData}
                            register={register}
                            errors={errors}
                            control={control}
                            file={imageToCrop}
                            setFile={setImageToCrop}
                            onImageChoose={onImageChoose}
                        />
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>


                    <LoadingButton
                    type="submit"
                    color="warning"
                    variant="contained"
                    loading={loader ? true:false}
                    startIcon={loader ?<SaveIcon />:null}
                    loadingPosition={loader ?"start":null}
                    >
                      Create
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const CreateMemberDialog = ({ handleClose, setItems }) => {
    return (
        <DialogBox open handleClose={handleClose}
            title='Add Person Details' content={CreateMemberContent(handleClose, setItems)}
        />
    )
};

export default CreateMemberDialog;
