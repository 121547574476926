import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress, DialogActions, Divider, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EventImg from '../../../../assets/Event.webp';
import CropImage from "../../../../components/CropImage";
import DialogBox from "../../../../components/DialogBox";
import { clear_event_media, eventDetails, update_event_media } from "../../../../redux/slices/eventDataSlice";
import { userStatus } from "../../../../redux/slices/userDataSlice";
import { createEventMedia, deleteEventMedia, updateEventMedia } from "../../../../services/wEvents/eventDetails";
import { BACKEND_URL } from "../../../../utils/constants/apis.urls";
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";
import WowslyLogo from '../../../../assets/WowslyMobileLogo2.png';

const DeleteEventMediaDialogContent = (eventData, dispatch, setSelectedImage, handleClose) => {
    const mediaId = eventData?.media?.slice(-1)[0]?.id;

    const deleteEventMediaApi = () => {
        deleteEventMedia({ 'eventId': eventData.id, mediaId: mediaId }).then((res) => {
            dispatch(clear_event_media()); // To update store
            setSelectedImage(null); // To remove image on UI
            handleClose('delete');
            toast.success("Event media deleted successfully!!", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log("Error ocurred =>", err);
        })
    }
    return (
        <Stack spacing={3}>
            <Typography>Are you want to delete event logo?</Typography>
            <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                <Button variant="outlined" color='warning' onClick={handleClose}>
                    Cancel
                </Button>
                <Button color='warning' variant="contained" onClick={deleteEventMediaApi}
                    sx={{
                        boxShadow: 'none'
                    }}>
                    Ok
                </Button>
            </DialogActions>
        </Stack>
    )
}

const EventMediaUpload = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(userStatus);
    const eventData = useSelector(eventDetails);
    const [loading, setLoading] = useState(null);
    const [dialog, setDialog] = useState({
        crop: false,
        delete: false,
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageToCrop, setImageToCrop] = useState(null);

    useEffect(() => {
        if (eventData?.media?.length > 0) {
            const eventImg = eventData?.event_main_photo ?? null
            setSelectedImage(eventImg)
        }
    }, [eventData]);

    const onImageChoose = (event) => {
        let i = event.target.files[0];
        setImageToCrop(i);
        handleDialogOpen('crop');
    }

    const uploadImage = (croppedImage) => {
        handleDialogClose('crop');
        setLoading(true);
        if (selectedImage) {
            const imageId = eventData.media.slice(-1)[0].id;
            updateEventMedia({ 'eventId': eventData.id, 'image': croppedImage, imageId: imageId }).then((res) => {
                dispatch(update_event_media([res.data.data]));
                setLoading(false);
                toast.success("Event image updated successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log("Error ocurred =>", err);
                setLoading(false);
                toast.error("Error occurred in event image upload", {
                    position: "top-right",
                    theme: "dark"
                });
            })
        } else {
            createEventMedia({ 'eventId': eventData.id, 'image': croppedImage, 'type': 'image' }).then((res) => {
                dispatch(update_event_media(res.data.data));
                setLoading(false);
                toast.success("Event image uploaded successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                // console.log("Error ocurred =>", Object.values(err.response.data)[0][0]);
                toast.error("Error occurred in event image upload", {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    }

    const handleDialogOpen = (operation) => {
        setDialog({ ...dialog, [operation]: true });
    }

    const handleDialogClose = (operation) => {
        setDialog({ ...dialog, [operation]: false });
    }

    return (
        <>
            {Object.keys(eventData).length > 0 ?
                <Box sx={{
                    borderRadius: { xs: 0, sm: '15px' },
                    backgroundColor: '#F0F0F0',
                    width: { xs: '100%', sm: '30rem', lg: isLoggedIn ? '30rem' : '35rem' },
                    height: { xs: '215px', sm: '24rem', lg: '23rem' }
                }}>
                    <Box sx={{ display: 'flex', height: '100% !important', position: 'relative' }}>
                        {loading ?
                            <Stack alignItems='center' sx={{ margin: 'auto' }}>
                                <CircularProgress color="warning" />
                            </Stack>
                            :
                            <>
                                {selectedImage ?
                                    <>
                                        <Box
                                            component='img'
                                            src={`${selectedImage}`}
                                            alt='Event Img.'
                                            className="event-upload-image"
                                        />
                                        {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                                            <Stack direction='row' sx={{
                                                position: 'absolute',
                                                top: '90%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                margin: 'auto',
                                                borderRadius: '50px',
                                                backgroundColor: "#FFFFFF",
                                                boxShadow: '0 0 10px 10px rgba(0, 0, 100, 0.5)',
                                            }}>
                                                <IconButton
                                                    variant="contained"
                                                    component='label'
                                                    sx={{
                                                        p: '0.8rem',
                                                        height: "2rem",
                                                        color: 'black',
                                                        borderTopLeftRadius: '50px',
                                                        borderBottomLeftRadius: '50px',
                                                        borderTopRightRadius: '0px',
                                                        borderBottomRightRadius: '0px',
                                                        ":hover": {
                                                            color: 'white',
                                                            backgroundColor: '#FF8359',
                                                            boxShadow: '0 0 10px 10px rgba(0, 0, 100, 0.5)',
                                                        }
                                                    }}
                                                >
                                                    <EditIcon />
                                                    <input
                                                        hidden
                                                        accept="image/jpeg, image/png"
                                                        type="file"
                                                        onChange={(event) => onImageChoose(event)}
                                                    />
                                                </IconButton>
                                                <Divider orientation="vertical" flexItem
                                                    sx={{ borderColor: 'black' }} />
                                                <IconButton
                                                    variant="contained"
                                                    onClick={() => handleDialogOpen('delete')}
                                                    sx={{
                                                        p: '0.8rem',
                                                        height: "2rem",
                                                        color: 'black',
                                                        borderTopLeftRadius: '0px',
                                                        borderBottomLeftRadius: '0px',
                                                        borderTopRightRadius: '50px',
                                                        borderBottomRightRadius: '50px',
                                                        ":hover": {
                                                            color: 'white',
                                                            backgroundColor: '#FF8359',
                                                            boxShadow: '0 0 10px 10px rgba(0, 0, 100, 0.5)',
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Stack> :
                                            null
                                        }
                                        {dialog.delete ?
                                            <DialogBox open={true} handleClose={() => handleDialogClose('delete')}
                                                title='Delete Event Logo'
                                                content={DeleteEventMediaDialogContent(eventData, dispatch, setSelectedImage, handleDialogClose)}
                                            />
                                            : null
                                        }
                                    </> :
                                    <Stack alignItems='center' sx={{ margin: 'auto' }}>
                                        <Box component='img' src={eventData.invitation_status ==
                                            "none" ? WowslyLogo : EventImg} width='10rem' height='8rem' />
                                        {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                                            <Button
                                                variant="contained"
                                                component='label'
                                                sx={{
                                                    mt: '1rem',
                                                    px: '2rem',
                                                    height: "2rem",
                                                    color: 'black',
                                                    backgroundColor: "#FFFFFF",
                                                    border: '0.5px solid black',
                                                    boxShadow: 'none',
                                                    borderRadius: '50px',
                                                    ":hover": {
                                                        border: '0.5px solid #FF8359',
                                                        boxShadow: 'none',
                                                        color: 'white',
                                                        backgroundColor: '#FF8359'
                                                    }
                                                }}
                                            >
                                                Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(event) => onImageChoose(event)}
                                                />
                                            </Button>
                                            : null
                                        }
                                    </Stack>
                                }
                                {dialog.crop ?
                                    <DialogBox open={true} handleClose={() => handleDialogClose('crop')}
                                        title='Crop Event Logo'
                                        content={
                                            <CropImage
                                                aspectType='landscape'
                                                imageToCrop={imageToCrop}
                                                uploadImage={uploadImage}
                                                handleDialogClose={handleDialogClose}
                                            />
                                        }
                                    />
                                    : null
                                }
                            </>
                        }
                    </Box>
                </Box>
                :
                <Skeleton
                    sx={{
                        width: '100%',
                        height: { xs: '215px', sm: '18rem' },
                        transform: 'scale(1, 1)',
                        borderRadius: { xs: 0, sm: '15px' },
                    }}
                />
            }
        </>
    );
};

export default EventMediaUpload;
