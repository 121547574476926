import { Avatar, AvatarGroup, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { generateColor } from "../../../../../../../../utils/functions/generateRandomColors";

const JoinedMembers = () => {
    const groupData = useSelector(groupDetails);
    // const handleJoinedGroupBtnClick = () => {
    //     setLoader(true);
    //     getGroupMembers({ groupId: groupData?.id, type: 'joined' }).then((res) => {
    //         setOpen(true);
    //         setLoader(false);
    //         setTotalMembers(res.data.data);
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }

    return (
        <>
            <Box sx={{ position: 'relative', mb: 5 }}>
                <Typography sx={{ mb: 1, color: '#1F274A', fontWeight: 'bold' }}>Members</Typography>
                <Stack direction='row' spacing={2} alignItems='center'
                    sx={{ position: 'absolute', mt: 0.5, left: '0.5%' }}>
                    <AvatarGroup total={groupData?.members} max={4}
                        sx={{
                            border: 'none'
                        }}>
                        {
                            [...Array(groupData?.members).keys()].map((a, i) => <Avatar key={i} sx={{
                                bgcolor: generateColor()
                            }} />)
                        }
                    </AvatarGroup>
                    <Button variant="text" color="warning" onClick={null}
                        sx={{ color: '#1F274A', fontWeight: 'bold' }}>
                        {groupData?.members} Joined
                    </Button>
                </Stack>
            </Box>
        </>
    )
};

export default JoinedMembers;
