import { Avatar, Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NotFound from '../../../../../assets/abc.webp';
import avtar from '../../../../../assets/messi2.webp';
import GroupDetailDialog from "./GroupDetailDialog";
import { BACKEND_URL } from "../../../../../utils/constants/apis.urls";
import { wrapString } from "../../../../../utils/functions/wrapString";
import { userDetails } from "../../../../../redux/slices/userDataSlice";
import { ALL_PERMISSION_ROLES } from "../../../../../utils/constants/roles";
import { PAGE_PATHS } from "../../../../../utils/constants/page-path.constants";
import { update_group_details } from "../../../../../redux/slices/groupDetailSlice";

const GroupCardContent = ({ g }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(userDetails);

    const [open, setOpen] = React.useState(false);
    const handleOpen = (c) => {
        dispatch(update_group_details(c))
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const handleGroupClick = () => {
        if (ALL_PERMISSION_ROLES.includes(g?.current_user_role)) {
            navigate(`/${PAGE_PATHS.GROUP_DETAILS.replace(":id", g?.id)}`)
        } else {
            if (g.member_uuid) {
                navigate(`/${PAGE_PATHS.MEMBER_GROUP_DETAILS.replace(":memberId", g.member_uuid)}`);
            } else {
                toast.error("Page not found", {
                    position: "top-right",
                    theme: "dark"
                })
                navigate(PAGE_PATHS.DASHBOARD);
            }
        }
    }

    return (
        <Stack spacing={1} sx={{ pt: '0.5rem', height: '100%' }}>
            {g ?
                <Stack onClick={handleGroupClick} direction='row' alignItems='center' spacing={1} sx={{ ml: '1rem' }}>
                    {g?.owner?.profile_photo_path ?
                        <Avatar src={`${g.owner.profile_photo_path}`} sx={{ width: 25, height: 25 }} />
                        :
                        <Avatar sx={{ width: 25, height: 25 }} />
                    }
                    <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                        Created By {g.owner.id === userData.id ? "You" : `${g.owner.first_name}`}
                    </Typography>
                </Stack>
                :
                <Skeleton sx={{ mx: '1rem !important', p: '0.5rem !important' }} animation="wave" />
            }
            {g ?
                <Box onClick={handleGroupClick} sx={{
                    height: '45%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // px: '6px'
                }}>
                    <Box
                        component="img"
                        sx={{
                            maxHeight: g?.group_logo_path ? '100%' : '80%',
                            width: g?.group_logo_path ? '100%' : '30%',
                            objectFit: 'cover',
                            // mixBlendMode: 'overlay'
                            // maxWidth: '100%',
                            // borderRadius: '0.5rem'
                        }}
                        src={g?.group_logo_path ? `${g?.group_logo_path}` : NotFound}
                        alt="Grp."
                    />
                </Box>
                : <Skeleton animation="wave" height='50%' sx={{ mx: '1rem !important' }} />
            }

            {g ?
                <Stack sx={{ px: '1rem' }}>
                    <Typography onClick={handleGroupClick}
                        sx={{ fontSize: { xs: '16px', sm: '20px' }, fontWeight: 600 }}>{g.title}</Typography>
                    <Typography onClick={handleGroupClick}
                        sx={{ fontSize: { xs: '12px', sm: '14px' }, mt: 0.5 }}>{g.members} Members</Typography>
                    <Typography sx={{
                        fontSize: { xs: '12px', sm: '14px' },
                        mt: 1.5,
                        height: { xs: '75px', sm: '66px' },
                        lineHeight: 1.5,
                        overflow: 'hidden !important',
                    }}>
                        <>
                            <span onClick={handleGroupClick} >
                                {wrapString(g.description, 100)}
                            </span>
                            {g.description.length > 100 ?
                                <Button onClick={() => handleOpen(g)} size="small" sx={{ textTransform: 'none', p: 0 }}>
                                    See More
                                </Button> : null
                            }
                        </>
                        <GroupDetailDialog open={open} handleClose={handleClose} />
                    </Typography>
                </Stack>
                : <Skeleton animation="wave" sx={{ flex: 1, mx: '1rem !important' }} />
            }

        </Stack>
    );
};

export default GroupCardContent;
