import { toast } from "react-toastify";

export const generateAndCopyCommonEventLink = (id, title) => {
    const currentOrigin = window.location.origin;

    const commonLink = `${currentOrigin}/e/${id}/${title.replace(/\s+|\/+/g, '-').toLowerCase()}`
    navigator.clipboard.writeText(commonLink);

    // Alert the copied text
    toast.success("Event Common Link copied to clipboard!", {
        position: "top-right",
        theme: "dark"
    });
}
