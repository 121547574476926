import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";
import { ticketCurrencies } from "../../utils/constants/ticketCurrency";
import { convertUtcToLocal } from "../../utils/functions/timeConversion";
import { parseDecimalNumber } from "../../utils/functions/getFormattedNumber";

const OnePaymentItemDetails = ({ details }) => {
    const isEvent = details.model_type === 'event';
    const amountCurrencySymbol = ticketCurrencies.find((t) => t.value === details.amount_currency)?.symbol;

    const rows = [
        {
            id: 1,
            title: "Registered Name",
            value: details.registered_name,
        },
        {
            id: 2,
            title: "Registered Email",
            value: details.registered_email,
        },
        {
            id: 3,
            title: "Registered Mobile",
            value: details.registered_mobile,
        },
        {
            id: 4,
            title: "Payment Time",
            value: convertUtcToLocal(details.payment_time).format("Do MMMM YYYY, h:mm A"),
        },
        {
            id: 5,
            title: isEvent ? "Ticket Title" : "Subscription Title",
            value: details.purchased_item_name,
        },
        ...(isEvent) ? [{
            id: 6,
            title: "Number of Tickets",
            value: details.number_of_tickets,
        }] : [],
    ]

    return (
        <>
            <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                <TableContainer sx={{
                    border: '1px solid lightgrey',
                    maxWidth: { xs: '100%', md: '65%' }, margin: '1rem auto !important'
                }}>
                    <Table stickyHeader>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow hover={true} key={row.id}>
                                    <TableCell sx={{
                                        width: '35%',
                                        textAlign: 'center',
                                        p: { xs: '0.5rem', md: '0.7rem' },
                                        borderRight: '1px solid #ccc'
                                    }}>
                                        {row.title}
                                    </TableCell>
                                    <TableCell sx={{
                                        width: '55%',
                                        textAlign: 'center',
                                        p: { xs: '0.5rem', md: '0.7rem' },
                                    }} align="center">{row.value}</TableCell>
                                </TableRow>
                            ))}

                            <TableRow hover={true}>
                                <TableCell sx={{
                                    width: '35%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    p: { xs: '0.6rem', md: '0.75rem' }
                                }}>
                                    {isEvent ?
                                        "Actual per ticket price" : "Actual subscription amount"}
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        width: '55%',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        p: { xs: '0.6rem', md: '0.75rem' }
                                    }}>
                                    {parseDecimalNumber(details.actual_price, 3)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </TableCell>
                            </TableRow>

                            <TableRow hover={true}>
                                <TableCell sx={{
                                    width: '35%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    p: { xs: '0.6rem', md: '0.75rem' }
                                }}>
                                    {isEvent ? "System charges and taxes on ticket price" : "Tax on subscription price"}
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        width: '55%',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        p: { xs: '0.6rem', md: '0.75rem' }
                                    }}>
                                    {parseDecimalNumber(details.tax_on_item_price, 3)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </TableCell>
                            </TableRow>

                            <TableRow hover={true}>
                                <TableCell sx={{
                                    width: '35%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    p: { xs: '0.6rem', md: '0.75rem' }
                                }}>
                                    Total Amount Paid
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        width: '55%',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        p: { xs: '0.6rem', md: '0.75rem' }
                                    }}>
                                    {parseDecimalNumber(details.total_amount_paid, 3)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
};

export default OnePaymentItemDetails;
