import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, DialogActions, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DialogBox from "../../../../../../../../components/DialogBox";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import DeleteGroupMember from "./DeleteGroupMember";
import EditGroupMember from "./EditGroupMember";

const MemberDetailContent = (memberDetails, handleClose) => {
    const groupData = useSelector(groupDetails);
    const [edit, setEdit] = useState(false); // For open edit form conditionally
    const [anchorEl, setAnchorEl] = React.useState(null); // For delete popover
    const isAllPermission = ALL_PERMISSION_ROLES.includes(groupData?.current_user_role);

    return (
        <>
            {edit ? <EditGroupMember memberDetails={memberDetails} setEdit={setEdit} /> :
                <Box sx={{ mb: 1 }}>
                    {isAllPermission ?
                        <Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center' sx={{ mb: { sm: '-1rem' } }}>
                            <IconButton onClick={() => setEdit(true)}>
                                <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                            </IconButton>
                            <IconButton onClick={(e) => { setAnchorEl(e.currentTarget) }}>
                                <DeleteIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                            </IconButton>
                            <DeleteGroupMember memberId={memberDetails.id} handleClose={handleClose}
                                anchorEl={anchorEl} setAnchorEl={setAnchorEl}
                            />
                        </Stack>
                        : null
                    }
                    <Stack direction='row' spacing={2} alignItems='center' sx={{ wordBreak: 'break-all' }}>
                        <Typography sx={{ flex: 1, fontWeight: 500, fontSize: '1.1rem' }}>
                            Name: {memberDetails?.name}
                        </Typography>
                    </Stack>
                    <Stack spacing={1.5} sx={{ mt: 1.5 }}>
                        {memberDetails?.email ?
                            <Typography sx={{ fontSize: '1rem' }}>
                                Email: {memberDetails?.email}
                            </Typography>
                            : null}
                        <Typography sx={{ fontSize: '1rem' }}>
                            Contact: +{memberDetails?.dialing_code} {memberDetails?.mobile}
                        </Typography>
                    </Stack>
                    <DialogActions></DialogActions>
                </Box>
            }
        </>
    )
}

const MemberDialog = ({ handleClose, memberDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Member details' content={MemberDetailContent(memberDetails, handleClose)}
        />
    )
};

export default MemberDialog;
