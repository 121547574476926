import React, { useContext, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { EventSettingsContext } from '../..'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import NoRepliesGif from "../../../../../../../../assets/no-replies.gif";
import { convertUtcToLocal } from '../../../../../../../../utils/functions/timeConversion';
import CheckInRecordsPagination from './CheckInRecordsPagination';
import Collapse from '@mui/material/Collapse';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function Row({ row, index, checkInRecords }) {
    const [openRow, setOpenRow] = useState(false);
    const categoryCheckIn = row.guest_category_count && typeof row.guest_category_count === 'object' && Object.keys(row.guest_category_count).some(key => key !== "" && row.guest_category_count[key] > 0)
        ? Object.entries(row.guest_category_count)
            .filter(([category, count]) => category !== "" && count > 0) // Filter out empty categories or counts of 0
            .map(([category, count]) => `${category}: ${count}`)
            .join(', ')
        : 'N/A';
    const otherCategoryCheckIn = row.optional_category_count && typeof row.optional_category_count === 'object' && Object.keys(row.optional_category_count).some(key => key !== "" && row.optional_category_count[key] > 0)
        ? Object.entries(row.optional_category_count)
            .filter(([category, count]) => category !== "" && count > 0) // Filter out empty categories or counts of 0
            .map(([category, count]) => `${category}: ${count}`)
            .join(', ')
        : 'N/A';


    return (
        <React.Fragment>
            <TableRow hover={true} sx={{ border: 'none' }}>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: '0rem',
                }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenRow(!openRow)}
                    >
                        {openRow ? <RemoveCircleIcon style={{ fontSize: 16 }} /> : <AddCircleIcon style={{ fontSize: 16 }} />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' }
                }}>
                    {row.name}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' },
                }}>
                    {row.email ?? 'N/A'}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' }
                }}>
                    {row.mobile ? `+${row.dialing_code} ${row.mobile}` : 'N/A'}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' },
                }}>
                    {row.check_in_count}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' }
                }}>
                    {row.invited_by_name ?? "N/A"}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' }
                }}>
                    {categoryCheckIn ? categoryCheckIn : "N/A"}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    borderRight: '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' }
                }}>
                    {otherCategoryCheckIn ? otherCategoryCheckIn : "N/A"}
                </TableCell>
                <TableCell sx={{
                    textAlign: 'center',
                    borderBottom: checkInRecords.length === index + 1 && !openRow ? 'none' : '1px solid #2b2b2b',
                    p: { xs: '0.5rem', md: '0.7rem' }
                }}>
                    {convertUtcToLocal(row.check_in_time).format("Do MMMM YYYY, h:mm A")}
                </TableCell>
            </TableRow>
            <TableRow hover={true}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: openRow && checkInRecords.length !== index + 1 ? "1px solid #2b2b2b" : "none", }} colSpan={7}>
                    <Collapse in={openRow} timeout="auto" unmountOnExit>
                        {row.facilities.length > 0 ?
                            <TableContainer sx={{
                                border: '1px solid #ccc',
                                maxWidth: '100%', margin: '1rem auto !important'
                            }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                width: '25%',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>
                                                Facility Name
                                            </TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                width: '25%',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>
                                                Check In Count
                                            </TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                width: '50%',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>
                                                Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.facilities.map((facilityRow, facilityIndex) => (
                                            <TableRow hover={true} key={facilityRow.facility_name}>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    width: '25%',
                                                    borderBottom: row.facilities.length === facilityIndex + 1 ? 'none' : '1px solid #ccc',
                                                    borderRight: '1px solid #ccc',
                                                    p: { xs: '0.5rem', md: '0.7rem' }
                                                }}>
                                                    {facilityRow.facility_name}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    width: '25%',
                                                    borderBottom: row.facilities.length === facilityIndex + 1 ? 'none' : '1px solid #ccc',
                                                    borderRight: '1px solid #ccc',
                                                    p: { xs: '0.5rem', md: '0.7rem' }
                                                }}>
                                                    {facilityRow.check_in_count}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    width: '50%',
                                                    borderBottom: row.facilities.length === facilityIndex + 1 ? 'none' : '1px solid #ccc',
                                                    p: { xs: '0.5rem', md: '0.7rem' }
                                                }}>
                                                    {facilityRow.check_in_time.map((date, index) => (
                                                        <React.Fragment key={index}>
                                                            {convertUtcToLocal(date).format("Do MMMM YYYY - h:mm A")}
                                                            {facilityRow.check_in_time.length !== index + 1 && <>,<br /></>}
                                                        </React.Fragment>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <Box style={{ textAlign: 'center', padding: '12px' }}>No Facilities Availed</Box>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const TicketCheckInsDetailsDialog = ({ activeTicketId, setViewDialog }) => {
    const { checkInRecords, setCheckInRecords, totalCheckInCount } = useContext(EventSettingsContext);
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    const handleClose = () => {
        setViewDialog(false);
        setCheckInRecords(null);
    }

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', md: '60%' },
                        maxWidth: { lg: '700px' }, m: { xs: 0 }
                    }
                }}
            >
                <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                    <IconButton onClick={() => handleClose()}
                        sx={{ position: "absolute", right: "0.2rem", top: "0.2rem" }}>
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: "1.5rem", sm: "1.5rem" } }}>
                        Ticket Check In Details
                    </Typography>
                </DialogTitle>
                {checkInRecords ?
                    <>
                        {checkInRecords.length === 0 ? (
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ mt: 5 }}>
                                <Box
                                    component="img"
                                    src={NoRepliesGif}
                                    width={isSmallDevice ? "90%" : "60% !important"}
                                    height={isSmallDevice ? "15rem" : "17rem"}
                                />
                            </Stack>
                        ) :
                            <>
                                <DialogContent dividers sx={{
                                    p: { xs: '0rem', md: '0rem' },
                                    height: { xs: '26.5rem', md: '31.5rem' }
                                }}
                                >
                                    <Typography sx={{ textAlign: 'left', my: 1, mx: 2 }}>
                                        {`Total Check In Count: ${totalCheckInCount?.total_event_check_in.total_check_in}/${totalCheckInCount?.total_event_check_in.total_purchase_ticket}`}
                                    </Typography>
                                    {totalCheckInCount?.total_facilities_check_in.facilities.map((facility, index) => (
                                        <Typography sx={{ textAlign: 'left', my: 1, mx: 2 }} key={index}>
                                            {`${facility.facility_name}: ${facility.check_in_count}${facility.facilities_taken_by_user ? '/' + facility.facilities_taken_by_user : ''}`}
                                        </Typography>
                                    ))}
                                    <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                                        <TableContainer sx={{
                                            border: '1px solid #2b2b2b',
                                            maxWidth: '100%', margin: '1rem auto !important'
                                        }}>

                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow hover={true} sx={{ borderBottom: '1px solid #2b2b2b' }}>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: '0rem'
                                                        }} />
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.7rem' }
                                                        }}>Name</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.7rem' }
                                                        }}>Email</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.7rem' }
                                                        }}>Contact</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.7rem' }
                                                        }}>Guests</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.5rem' }
                                                        }}>Invited by</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.5rem' }
                                                        }}>Category Guest Check-In</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            borderRight: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.5rem' }
                                                        }}>Other Guest Check-In</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #2b2b2b',
                                                            p: { xs: '0.5rem', md: '0.5rem' }
                                                        }}>Time</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {checkInRecords.map((row, index) => (
                                                        <Row
                                                            key={row.id}
                                                            row={row}
                                                            index={index}
                                                            checkInRecords={checkInRecords}
                                                        />
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CheckInRecordsPagination ticketId={activeTicketId} />
                                </DialogActions>
                            </>
                        }
                    </>
                    : <Skeleton sx={{
                        mx: '0.5rem !important',
                        p: '0rem !important',
                        transform: 'scale(1,0.9)'
                    }}
                        height='34.5rem'
                        animation="wave"
                    />
                }
            </Dialog>
        </>
    )
}

export default TicketCheckInsDetailsDialog