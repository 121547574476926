import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { userStatus } from "../../../../redux/slices/userDataSlice";
import EventBasicDetails from "./EventBasicDetails";
import EventMediaUpload from "./EventMediaUpload";

const EventDetailCards = () => {
    const isLoggedIn = useSelector(userStatus);

    return (
        <Grid container alignItems="center" justifyContent={{ sm: 'space-between', lg: 'space-around', }}
            sx={{ mt: { xs: 0, sm: 3 }, px: { xs: 0, sm: 2 } }}>
            <Grid item xs={12} sm={4.5} lg={isLoggedIn ? 4 : 5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <EventMediaUpload />
            </Grid>

            <Grid item xs={12} sm={7} lg={6.5} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start', lg: 'center' }, mt: { xs: 0, sm: 0 } }}>
                <EventBasicDetails />
            </Grid>
        </Grid>
    )
};

export default EventDetailCards;
