import "../../index.scss";
import "./mobilelogin.scss";
import { Box, Stack, Typography } from "@mui/material";
import MobileLoginForm from "./components/MobileLoginForm";
import mobileLogo from '../../../../assets/WowslyLogo.png';

function MobileLogin() {
    return (
        <>
            <Stack
                sx={{
                    position: "relative",
                    backgroundColor: "white",
                    borderRadius: "25px",
                    width: {
                        xs: '90%',
                        sm: '70%',
                        md: '80%',
                        lg: '75%'
                    },
                    p: '1rem',
                    mx: 'auto',
                    mt: "-2rem",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.25)',
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: {
                            xs: "-6.5rem",
                            sm: "-8rem",
                            md: "-8rem",
                            lg: "-9rem"
                        },
                        textAlign: "center",
                        color: "white"
                    }}
                >
                    <Box component='img'
                        sx={{
                            width: '3rem',
                            height: '3rem'
                        }}
                        alt="Wowsly Logo"
                        src={mobileLogo}
                    />
                    <Typography variant="h3">Enter Mobile Number</Typography>
                    <Typography variant="h5" sx={{ mt: 1 }}>
                        You will receive an OTP at this number
                    </Typography>
                </Box>

                <Box>
                    <MobileLoginForm />
                </Box>
            </Stack>
        </>
    );
}

export default MobileLogin;
