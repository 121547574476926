import _ from "lodash"

const getSmsChargeRate = (totalGuests) => {
    if (_.inRange(totalGuests, 0, 1001)) {
        return 0.195
    } else if (_.inRange(totalGuests, 1001, 5001)) {
        return 0.175
    } else if (_.inRange(totalGuests, 5001, 10001)) {
        return 0.165
    } else if (_.inRange(totalGuests, 10001, 30001)) {
        return 0.155
    } else if (totalGuests > 30000) {
        return 0.145
    }
}

export const getMessageSendCharge = (type, ...args) => {
    switch (type) {
        case 'sms':
            return getSmsChargeRate(args[0]?.totalGuests)
        case 'whatsapp':
            return 0.79
        case 'email':
            return 0.9
        default:
            return 0
    }
}

export default getMessageSendCharge