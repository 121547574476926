import React, { useContext } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { GroupSettingsContext } from "../..";
import { deleteGroupSubscription } from "../../../../../../../../services/wGroups/groupSubscription";
import { toast } from "react-toastify";

const DeleteSubscription = ({ subscription, setAction }) => {
    const groupData = useSelector(groupDetails);
    const { setSubscriptions } = useContext(GroupSettingsContext);
    const handleDeleteClose = () => {
        setAction((action) => ({ ...action, 'delete': false }))
    }
    const handleDelete = () => {
        deleteGroupSubscription(groupData?.id, subscription.id).then((res) => {
            toast.success("Subscription deleted", {
                position: "top-right",
                theme: "dark"
            });
            setSubscriptions((subscriptions) => {
                console.log(subscription);
                let newSubscriptions = subscriptions.filter((i) => i.id !== subscription?.id)
                return newSubscriptions;
            })
            handleDeleteClose();
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleDeleteClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleDeleteClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        Delete subscription
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure you want to delete this subscription?</Typography>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={() => handleDeleteClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" color='warning' variant="contained"
                        sx={{ boxShadow: 'none' }} onClick={handleDelete}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default DeleteSubscription;
