import { BANK_API, USERS_LINK_BANK_ACCOUNT } from "../utils/constants/apis.urls";
import { axiosInstance } from "../config/interceptor";

export const getBanks = (userId, { verified }) => {
    if (verified) {
        return axiosInstance.get(`${BANK_API.replace(':user_id', userId)}?verified=true`);
    }
    return axiosInstance.get(`${BANK_API.replace(':user_id', userId)}`);
};

export const createBank = (payload, userId) => {
    return axiosInstance.post(`${BANK_API.replace(':user_id', userId)}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const editBank = (payload, userId, bankId) => {
    return axiosInstance.post(`${BANK_API.replace(':user_id', userId)}/${bankId}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const deleteBank = (userId, bankId) => {
    return axiosInstance.delete(`${BANK_API.replace(':user_id', userId)}/${bankId}`);
};

export const createLinkBankAccount = (bankId,payload) => {
    return axiosInstance.post(`${USERS_LINK_BANK_ACCOUNT.replace(':bank_id', bankId)}`,payload);
};