import EventNoteIcon from '@mui/icons-material/EventNote';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import { Avatar, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import { green, purple } from '@mui/material/colors';
import React, { useState } from "react";
import { getOnePaymentDetails } from '../../services/Payment/payments';
import { ticketCurrencies } from '../../utils/constants/ticketCurrency';
import { parseDecimalNumber } from '../../utils/functions/getFormattedNumber';
import { convertUtcToLocal } from '../../utils/functions/timeConversion';
import OnePaymentItemDetails from './OnePaymentItemDetails';

const PaymentItem = ({ item, isLast }) => {
    const isSmallDevices = useMediaQuery("(max-width:600px)");
    const [details, setDetails] = useState(null);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    let time = new Date(item?.payment_time * 1000);
    time = convertUtcToLocal(time).format("Do MMMM YYYY, h:mm A")

    const handleClick = () => {
        setOpen(!open);
        if (!open) {
            setLoader(true);
            getOnePaymentDetails(item?.id, item?.event_title ? 'event' : 'group').then((res) => {
                setLoader(false);
                setDetails(res.data.data);
            }).catch((err) => {
                console.log(err);
                setLoader(false);
            })
        }
    };

    return (
        <>
            <ListItem onClick={item ? handleClick : null}
                sx={{
                    px: { xs: 1, md: 1.5 },
                    backgroundColor: open ? '#F1F1F1' : 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}
            >
                {!isSmallDevices ?
                    <ListItemAvatar>
                        {item ? <Avatar sx={{ bgcolor: item.event_title ? green[500] : purple[300] }}>
                            {item.event_title ? <EventNoteIcon /> : <GroupsIcon />}
                        </Avatar> :
                            <Skeleton animation="wave" variant="circular" width='2.5rem' height='2.5rem' />}
                    </ListItemAvatar>
                    : null}
                <ListItemText
                    primary={
                        <Stack direction='row'>
                            {item ?
                                <Typography>
                                    <b style={{ color: 'red' }}>{parseDecimalNumber(item.amount_to_pay, 3)}</b>&nbsp;
                                    <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === item.currency)?.symbol }} /> Paid for {item.event_title ? 'tickets of event ' : 'subscription of group '} <b>{item.event_title || item.group_title}</b>
                                </Typography>
                                : <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '100%', sm: '80%' }, transform: 'scale(1,1)' }} />
                            }
                        </Stack>
                    }
                    secondary={item ? `${time}` :
                        <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '75%', sm: '65%' }, mt: 1 }} />
                    }
                />
                {item ? (open ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {loader ?
                        <Skeleton height='10rem' variant='wave' sx={{
                            m: 2, transform: 'scale(1,1)',
                        }} />
                        :
                        <OnePaymentItemDetails details={details} />
                    }
                </List>
            </Collapse>
            {!isLast ? <Divider variant="inset" component="li" sx={{ ml: 0 }} /> : null}
        </>
    )
};

export default PaymentItem;
