import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import React, {useState,useContext } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from "@mui/material";
import { EventSettingsContext } from "../..";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { hideEventTicket } from "../../../../../../../../services/wEvents/eventDetails";

const HideTicketDialog = ({ ticket, setAction }) => {
    const eventData = useSelector(eventDetails);
    const { setTickets } = useContext(EventSettingsContext)
    const [loader, setLoader] = useState(false);
    const handleHideClose = () => {
        
        setAction((action) => ({ ...action, 'hide': false }))
    }

    const toggleTicketHide = () => {
        setLoader(true);
        hideEventTicket(eventData?.id, ticket.id).then((res) => {
            console.log(res.data);
            toast.success(`Event ticket ${res.data.data?.is_hidden ? 'hidden' : 'unhidden'} successfully`, {
                position: "top-right",
                theme: "dark"
            });
            setTickets((tickets) => {
                return tickets.map((ticket) => {
                    if (ticket.id === res.data.data?.id) {
                        return res.data.data; // Replace the ticket with the updated ticket values
                    }
                    return ticket; // Keep other tickets unchanged
                });
            });
            setLoader(false);
            handleHideClose();
        }).catch((err) => {
            setLoader(false);
            console.log(err);
        })
    }

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleHideClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleHideClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        {ticket.is_hidden ? 'Unhide' : 'Hide'} ticket
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure you want to {ticket.is_hidden ? 'unhide' : 'hide'} this ticket?</Typography>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={() => handleHideClose()}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                        onClick={toggleTicketHide}
                    >
                        Ok
                    </LoadingButton>
               
                </DialogActions>
            </Dialog>
        </>
    )
};

export default HideTicketDialog;
