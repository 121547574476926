import React, { useCallback, useState } from "react";
import ImageViewer from 'react-simple-image-viewer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Stack } from "@mui/material";

const PreviewImage = ({ selectedImage, hideOptions }) => {
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback(() => {
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    return (
        <>
            <Stack id='preview-image-box'
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Box
                    component='img'
                    src={selectedImage}
                    alt='Event Img.'
                    id='uploaded-image'
                    sx={{
                        objectFit: 'cover',
                        borderRadius: '10px',
                        // opacity: { xs: 0.5, md: 1 },
                        width: '100%', height: '100%'
                    }}
                    onClick={openImageViewer}
                />

                {/* <Stack id='image-options' direction='row' spacing={2} 
                    sx={{
                        p: '8px 3px 0px 0px',
                        position: 'absolute',
                        display: { md: 'none' },
                    }}>
                    <IconButton sx={{ p: 1, border: '1px solid white' }} >
                        <VisibilityIcon sx={{
                            color: 'white',
                            width: '1.2rem', height: '1.2rem'
                        }} />
                    </IconButton>
                </Stack> */}
                {isViewerOpen &&
                    <ImageViewer
                        src={[selectedImage]}
                        currentIndex={0}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                        backgroundStyle={{ zIndex: 1200 }}
                    />
                }
            </Stack>
        </>
    )
};

export default PreviewImage;
