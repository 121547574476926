import React, { useContext } from "react";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { GroupMembersContext } from "../../../../GroupMembersContext";

const CheckInvitedMembersDialog = ({ handleClose }) => {
    const { members, checkedMembers } = useContext(GroupMembersContext);
    const invited_members = checkedMembers.length > 0 ?
        members.filter(item => checkedMembers.includes(item.id)) : members;

    return (
        <>
            <Dialog
                open={true}
                onClose={() => handleClose('reviewMembers')}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '50%' },
                        m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '70%' },
                    }
                }}
            >
                <DialogTitle>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>Review Invited Guests</Typography>
                </DialogTitle>
                <DialogContent sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {(invited_members ?? Array.from(new Array(3))).map((g, index) => (
                            <ListItem key={index}
                                sx={{
                                    ":hover": {
                                        transition: '0.5s',
                                        backgroundColor: '#F1F1F1'
                                    }
                                }}>
                                {g ?
                                    <ListItemAvatar>
                                        <Avatar sx={{ backgroundColor: '#FFEBE4' }} />
                                    </ListItemAvatar> :
                                    <Skeleton variant="circular" width={40} height={40} />
                                }
                                <ListItemText primary={g ? g.name : <Skeleton width='90%' height='1.75rem' sx={{ ml: 2 }} />}
                                    secondary={g ? `+ ${g?.dialing_code} ${g?.mobile}` : <Skeleton width='60%' sx={{ ml: 2 }} />} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='outlined'
                        onClick={() => handleClose('reviewMembers')} sx={{ mr: 1, mt: 1 }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default CheckInvitedMembersDialog;
