import { Avatar, Box, Button, DialogActions, Divider, FormControl, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoRepliesGif from '../../../../../../../../assets/no-replies.gif';
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { downloadGroupFormReplies, getGroupFormReplies } from "../../../../../../../../services/wGroups/groupForm";
import { toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from "@mui/lab";
import { convertUtcToLocal } from "../../../../../../../../utils/functions/timeConversion";
import moment from "moment";

const RFormReplies = () => {
    const groupData = useSelector(groupDetails);
    const [replies, setReplies] = useState([]);
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const isSmallDevice = useMediaQuery("(max-width:600px)");

    const handleItemClick = (e, item) => {
        setDetails(item)
        setShowDetails(true)
    }
    useEffect(() => {
        getGroupFormReplies(groupData?.id).then((result) => {
            setReplies(result.data);
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        });
    }, []);

    const handleDownload = () => {
        setLoading(true);
        downloadGroupFormReplies(groupData?.id).then((result) => {
            const fileBlob = new Blob([result.data], { type: 'text/csv' });
            const downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            const fileUrl = URL.createObjectURL(fileBlob);

            // Create a link to download the file
            downloadLink.href = fileUrl;
            downloadLink.download = `${groupData.title}-Replies-${moment().format('D-M-YYYY HH-mm')}.csv`;
            // Add the link to the DOM and click it
            downloadLink.click();
            setLoading(false);

            // Clean up the URL object
            URL.revokeObjectURL(fileUrl);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        });
    }

    return (
        <>
            {showDetails ?
                <>
                    <Box sx={{
                        height: { xs: '22rem', md: '27rem' }, overflow: 'auto',
                        px: '1rem'
                    }}>
                        <Stack spacing={3} sx={{ p: 1 }}>
                            {details.Replies?.map((d, i) => (
                                <FormControl key={i}>
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Q. &nbsp;&nbsp; {d.question}</Typography>
                                    <Typography>A. &nbsp;&nbsp; {d.answer ?? '---'}</Typography>
                                </FormControl>
                            ))}
                        </Stack>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <DialogActions sx={{ py: 0 }}>
                        <Button type="submit" color='warning' variant="contained"
                            onClick={() => setShowDetails(false)} sx={{ boxShadow: 'none' }}>
                            Done
                        </Button>
                    </DialogActions>
                </> : <>
                    {replies?.length > 0 ?
                        <List sx={{
                            width: '100%',
                            px: '1rem',
                            bgcolor: 'background.paper', pt: 0, pb: 0
                        }}>
                            <Box sx={{ textAlign: 'center', my: 1 }}>
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<DownloadIcon />}
                                    variant="outlined"
                                    color='warning'
                                    sx={{
                                        boxShadow: 'none'
                                    }}
                                    onClick={handleDownload}
                                >
                                    Download Replies
                                </LoadingButton>
                            </Box>
                            {replies?.map((r, index) => (
                                <ListItem key={index} onClick={(e) => handleItemClick(e, r)}
                                    sx={{
                                        ":hover": {
                                            cursor: 'pointer',
                                            backgroundColor: '#F1F1F1',
                                            transition: '0.5s'
                                        }
                                    }}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ backgroundColor: '#FFEBE4' }} />
                                    </ListItemAvatar>
                                    <ListItemText primary={`${r.Replies.find((re) => re.question === 'Name').answer}`}
                                        secondary={r ? `${convertUtcToLocal(r.timestamp).format("Do MMMM YYYY, h:mm A")}` :
                                            <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '70%', sm: '65%' }, mt: 1 }} />}
                                    />
                                </ListItem>
                            ))}
                        </List> :
                        <Stack justifyContent='center' alignItems='center' spacing={2} sx={{ mt: 3 }}>
                            <Box component='img'
                                src={NoRepliesGif}
                                width={isSmallDevice ? '90%' : '50% !important'}
                                height={isSmallDevice ? '15rem' : '17rem'}
                            />
                            <Typography>No form replies yet!!</Typography>
                        </Stack>
                    }
                </>
            }
        </>
    )
};

export default RFormReplies;
