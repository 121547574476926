import React, { useContext } from "react";
import { Pagination, Stack } from "@mui/material";
import { EventGuestsContext } from "../../EventGuestContext";
import { getEventGuests } from "../../../../../../../../services/wEvents/eventGuests";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { useSelector } from "react-redux";

const GuestPagination = () => {
    const eventData = useSelector(eventDetails)
    const { tabValue,searchedGuest, totalGuests, per_page_guests, setGuests } = useContext(EventGuestsContext)
    const handleChange = (event, value) => {
        getEventGuests({ eventId: eventData?.id, page: value, type: tabValue === 0 ? 'invited' : 'registered', searchedGuest: searchedGuest }).then((res) => {
            setGuests(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <Stack sx={{ py: { xs: 2, sm: 3 }, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination count={Math.ceil(totalGuests.current / per_page_guests.current)}
                    shape="rounded" variant="outlined" color="warning"
                    onChange={handleChange} showFirstButton showLastButton />
            </Stack>
        </>
    )
};

export default GuestPagination;
