import React from "react";
import { Box, TextField } from "@mui/material";

const EditOnlineLocation = ({ eventDetailsObj, register, errors }) => {
    return (
        <>
            <Box sx={{ zIndex: 0 }}>
                <TextField
                    fullWidth
                    color="warning"
                    label="Link of event"
                    defaultValue={(eventDetailsObj && eventDetailsObj[`event_link`]) ? eventDetailsObj[`event_link`] : null}
                    error={errors.eventLink ? true : false}
                    helperText={
                        errors.eventLink ? errors.eventLink.message : null
                    }
                    {...register(`eventLink`)}
                />
            </Box>
        </>
    )
};

export default EditOnlineLocation;
