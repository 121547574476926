import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import UploadCsvDialog from "./UploadcsvDialog";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const UploadGuestCSV = () => {
    let [open, setOpen] = useState(false);
    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    return (
        <>
            <Tooltip title="Upload Guest List" arrow>
                <IconButton sx={{ p: 0 }} size='small' color='warning' onClick={handleOpen}>
                    <CloudUploadIcon color='warning' sx={{
                        width: '2rem', height: '1.5rem'
                    }} />
                </IconButton>
            </Tooltip>
            {open ? <UploadCsvDialog handleClose={handleClose} /> : null}
        </>
    )
};

export default UploadGuestCSV;
