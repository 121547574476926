import { Box } from "@mui/material";
import React from "react";
import AboutGroupSection from "./components/AboutGroupSection";
import GroupGallery from "./components/GroupGallery";
import GroupKeyPersons from "./components/GroupKeyPersons";
import JoinedMembers from "./components/JoinedMemberSection";

const GroupInfo = () => {
    return (
        <>
            <Box sx={{ pb: '1rem' }}>
                <AboutGroupSection />

                <GroupKeyPersons />

                <GroupGallery />

                <JoinedMembers />
            </Box>
        </>
    )
};

export default GroupInfo;