import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import mycsv from '../../../../../../../../../../assets/csvfile/Guest List Format Wowsly.csv';
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { groupDetails } from "../../../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupMembers, uploadGroupMemberCsv } from "../../../../../../../../../../services/wGroups/groupMembers";
import '../../../../../../../../group-details.scss';
import { GroupMembersContext } from "../../../../GroupMembersContext";

const UploadCsvContent = (handleClose) => {
    const theme = useTheme();
    const groupData = useSelector(groupDetails)
    const { tabValue, totalMembers, setMembers } = useContext(GroupMembersContext);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null)
    const {
        handleSubmit,
        setValue
    } = useForm();

    const handleFileChange = (e) => {
        if (e.type !== "text/csv") {
            toast.error('Only CSV file allowed', {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        if (e) {
            setFile(e)
            setValue('member_list', e)
        }
    }

    const mySubmit = (data) => {
        setLoading(true);
        if (!data.member_list) {
            setLoading(false);
            toast.error('Something went wrong!!', {
                position: "top-right",
                theme: "dark"
            });
        } else {
            const formData = new FormData();
            formData.append('member_list', data.member_list)
            uploadGroupMemberCsv(formData, groupData?.id).then((res) => {
                getGroupMembers({ groupId: groupData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'joined' }).then((res2) => {
                    totalMembers.current = res2.data.meta.total
                    setMembers(res2.data.data)
                    setLoading(false);
                    toast.success(res.data.data.message, {
                        position: "top-right",
                        theme: "dark"
                    });
                    handleClose();
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error(err?.response?.data?.error ?? 'Something went wrong in uploading csv', {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <Stack spacing={3}>
                    <Box>
                        <Typography sx={{ mt: 1, fontSize: '1rem', color: '#6F738B' }}>
                            Get started by downloading our sample members CSV file.
                            Ensure you keep the column names and order intact.
                            Simply add your members list data to the file and upload it here to complete the process.
                        </Typography>
                        <Button
                            fullWidth
                            href={mycsv}
                            download="Members List.csv"
                            color='warning'
                            variant="outlined"
                            sx={{ color: '#FF8359', my: 2, p: 2 }}
                        >
                            Download Samples Members List
                        </Button>
                    </Box>

                    <FileUploader
                        name="member_list"
                        handleChange={handleFileChange}
                        types={["csv"]}
                        classes="group-upload-csv-drag-drop-area"
                        required={file ? false : true}
                        maxSize={5}
                        children={
                            <Stack
                                spacing={1}
                                alignItems='center'
                                justifyContent='center'
                                sx={{
                                    height: '100%',
                                    borderRadius: '0.5rem',
                                    border: `3.5px dashed ${theme.palette.primaryColor}`,
                                    ":hover": {
                                        cursor: 'pointer'
                                    }
                                }}>
                                <DriveFolderUploadIcon sx={{
                                    fontSize: "2rem",
                                    color: theme.palette.primaryColor
                                }} />
                                <Stack spacing={1} direction='row' justifyContent='center' alignItems='center'>
                                    {file ? <CheckCircleIcon color='success' /> : null}
                                    <Typography sx={{
                                        color: '#6F738B',
                                        fontSize: '0.9rem'
                                    }}>
                                        {file ? "Uploaded Successfully" : "Drop or Upload Members List CSV File"}
                                    </Typography>
                                </Stack>
                            </Stack>
                        }
                        onSizeError={() => {
                            toast.error('Maximum upload size for file is 5MB', {
                                position: "top-right",
                                theme: "dark"
                            });
                        }}
                        dropMessageStyle={{ backgroundColor: 'red' }}
                    />

                    <Typography textAlign='center'>
                        {file ? `File name: ${file.name}` : "No files selected"}
                    </Typography>

                    <Typography sx={{ mt: 1, fontSize: '1rem', color: '#6F738B' }}>
                        Please ensure that the CSV file contains a mobile number for each user,
                        rows without a mobile number and country code will not be processed.
                        Additionally, any rows without a name will be substituted with the name 'Member'.
                    </Typography>
                    <Divider />
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loading ? true : false}
                        loadingPosition={loading ? "start" : null}
                        startIcon={loading ? <SaveIcon /> : null}
                    >
                        {loading ? 'Uploading..' : 'Submit'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const UploadCsvDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Upload Members List' content={UploadCsvContent(handleClose)}
            />
        </>
    )
};

export default UploadCsvDialog;
