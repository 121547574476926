import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Collapse, DialogActions, DialogContent, Stack, Switch, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { GroupSettingsContext } from ".";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import { groupDetails, update_group_details } from "../../../../../../redux/slices/groupDetailSlice";
import { updateGroupDetails } from "../../../../../../services/wGroups/group";
import { getGroupForms } from '../../../../../../services/wGroups/groupForm';
import { manageEditGroupPayload } from "../../../../../../utils/functions/managePayload";
import PaidGroupOptions from "./PaidGroupOptions";

const GroupSettingsForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { handleSubmit, register } = useForm();
    const groupDetailsObj = useSelector(groupDetails);
    const { setSlideType, handleNextClick, setRegistrationForm } = useContext(GroupSettingsContext)
    const [loader, setLoader] = useState(false);
    const handleRegistrationClick = () => {
        getGroupForms({ groupId: groupDetailsObj?.id }).then((res) => {
            console.log(res.data);
            setRegistrationForm(res.data?.form?.[0]);
            setSlideType('editGroupRegistration');
            handleNextClick();
        }).catch((err) => {
            console.log(err);
        })
    }

    const [groupType, setGroupType] = useState(groupDetailsObj?.is_paid ? 'paid' : 'free');
    const handleGroupTypeRadioChange = (event) => {
        setGroupType(event.target.value);
    };

    const handleDocumentVerificationClick = () => {
        setSlideType('verification');
        handleNextClick();
    }

    const mySubmit = (data) => {
        setLoader(true);
        data.is_paid = +(groupType === 'paid');
        data.has_registration = 1;
        const payload = manageEditGroupPayload(data);
        updateGroupDetails(payload, groupDetailsObj?.id).then((res) => {
            dispatch(update_group_details(res.data.data));
            toast.success("Group settings updated", {
                position: "top-right",
                theme: "dark"
            });
            setLoader(false);
            handleClose('settings');
        }).catch((err) => {
            setLoader(false);
            console.log("Error ocurred =>", err);
        })
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={true} sx={{
                    p: { xs: '1rem', md: '1rem 1.25rem' },
                    height: { xs: '26rem', md: '31rem' },
                    overflowY: 'auto'
                }}>
                    <Stack spacing={1.5}>
                        <Box>
                            <Typography sx={{ mb: 1 }}>Group type</Typography>
                            <CustomRadioGroup
                                value={groupType}
                                handleRadioChange={handleGroupTypeRadioChange}
                                option1='paid'
                                option2='free'
                            />
                        </Box>

                        <Collapse in={groupType === 'paid'} unmountOnExit={true}>
                            <PaidGroupOptions
                                register={register}
                            />
                        </Collapse>

                        <Stack direction='row' alignItems='center' justifyContent='space-between'
                            onClick={handleRegistrationClick}
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                }
                            }}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Registration form
                            </Typography>
                            <Box sx={{ pr: 0.5 }}><ArrowForwardIosIcon sx={{ color: '#1F274A' }} /></Box>
                        </Stack>

                        <Stack direction='row' alignItems='center' justifyContent='space-between'
                            onClick={handleDocumentVerificationClick}
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                }
                            }}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Document verification
                            </Typography>
                            <Box sx={{ pr: 0.5 }}><ArrowForwardIosIcon sx={{ color: '#1F274A' }} /></Box>
                        </Stack>

                    </Stack>
                </DialogContent>

                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                <LoadingButton 
                  type="submit"
                  color='warning'
                  variant="contained" 
                  loading={loader ? true:false}
                  startIcon={loader?<SaveIcon/>:null}
                   loadingPosition={loader?"start" :null}
                >
                     Save Changes
                </LoadingButton>               
                 </DialogActions>
            </form>
        </>
    )
};

export default GroupSettingsForm;
