import React from 'react'
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import onboard1 from '../../../../assets/Onboard-1.webp'
import onboard2 from '../../../../assets/Onboard-2.webp'
import onboard3 from '../../../../assets/Onboard-3.webp'
import { useNavigate } from 'react-router-dom';
import { PAGE_PATHS } from '../../../../utils/constants/page-path.constants'

const steps = [
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
    image: onboard1
  },
  {
    label: "Create an ad group",
    description:
      `Try out different ad text to see what brings in the most customers,
      and learn how to enhance your ads using features like ad extensions.
      If you run into any problems with your ads, find out how to tell if
      they're running and how to resolve approval issues.`,
    image: onboard2
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    image: onboard3
  }
];

function Onboarding() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  let navigate = useNavigate()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: { xs: '30px', sm: '30px', md: '40px' },
      }}>
        <Box>
          <Link
            onClick={() => navigate(`${PAGE_PATHS.MOBILE_LOGIN}`)}
            component="button"
            underline="none"
            sx={{
              float: "right",
              pr: { xs: '0rem', sm: '1rem', md: '3rem', lg: '4rem', xl: '4.5rem' },
              color: '#FF8359',
              fontSize: '1.5rem'
            }}
            size="small"
          >
            Skip
          </Link>
        </Box>

        <Box
          sx={{
            mt: { xs: '1rem', sm: '2rem', md: '4rem', lg: '5rem', xl: '5rem' },
            display: "flex",
            textAlign: 'justify',
            alignItems: 'center',
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row"
            }
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: "17rem", sm: "15rem", md: "20rem", lg: "25rem" },
              width: { xs: "19rem", sm: "18rem", md: "25rem", lg: "30rem" },
              mx: { xs: 0, sm: 0, md: 5, lg: 7, xl: 10 },
            }}
            alt="Img"
            src={steps[activeStep].image}
          />
          <Box sx={{ display: "flex", flexDirection: "column", mt: { xs: '2rem', sm: '2rem' } }}>
            <Typography variant="h1">h1. Heading</Typography>
            <Box sx={{ mt: { xs: '1rem', sm: '2rem', md: '3rem', lg: '4rem' } }}>{steps[activeStep].description}</Box>
          </Box>
        </Box>

        <Box sx={{ flex: 1 }}>
          <MobileStepper
            variant="dots"
            steps={3}
            activeStep={activeStep}
            sx={{
              color: '#FF8359',
              px: { xs: '1rem', sm: '2rem', md: '4rem', lg: '5rem', xl: '6rem' },
              pb: '1rem'
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === 2}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default Onboarding