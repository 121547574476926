import "./App.scss";
import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";
import routes from "./config/routes.";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AppRoutes() {
    const react_routes = useRoutes(routes);
    return react_routes;
}

const App = () => {
    return (
        <Router>
            <AppRoutes />
            <ToastContainer
                className="toast-position"
                position="top-right"
                autoClose={5000}
                newestOnTop
                closeOnClick
                hideProgressBar
                draggable={true}
                pauseOnHover={true}
                rtl={false}
            />
        </Router>
    );
}

export default App;
