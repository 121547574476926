import { Pagination, Stack } from "@mui/material";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupMembers } from "../../../../../../../../services/wGroups/groupMembers";
import { GroupMembersContext } from "../../GroupMembersContext";

const MemberPagination = () => {
    const groupData = useSelector(groupDetails);
    const { tabValue, totalMembers, per_page_members, setMembers } = useContext(GroupMembersContext);
    const handleChange = (event, value) => {
        getGroupMembers({ groupId: groupData?.id, page: value, type: tabValue === 0 ? 'invited' : 'joined' }).then((res) => {
            console.log(res.data.data);
            setMembers(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <Stack sx={{ py: { xs: 2, sm: 3 }, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination count={Math.ceil(totalMembers.current / per_page_members.current)} shape="rounded" variant="outlined" color="warning"
                    onChange={handleChange} showFirstButton showLastButton />
            </Stack>
        </>
    )
};

export default MemberPagination;
