import { Box, List, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import { EventSettingsContext } from "../../..";
import NoRepliesGif from "../../../../../../../../../assets/no-replies.gif";
import TicketPaymentItem from "./TicketPaymentItem";

const ReceivedRecords = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { paymentRecords } = useContext(EventSettingsContext);

    return (
        <>
            {paymentRecords?.receivedData?.length === 0 ? (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 3 }}
                >
                    <Box
                        component="img"
                        src={NoRepliesGif}
                        width={isSmallDevice ? "90%" : "55% !important"}
                        height={isSmallDevice ? "15rem" : "17rem"}
                    />
                    <Typography>No one purchased any tickets!!</Typography>
                </Stack>
            ) : (
                <List
                    sx={{
                        p: 0,
                        width: "100%",
                        overflow: "auto",
                        bgcolor: "background.paper"
                    }}
                >
                    {(paymentRecords?.receivedData ?? Array.from(new Array(5))).map((r, index) => (
                        <TicketPaymentItem item={r} key={index} />
                    ))}
                </List>
            )}
        </>
    )
};

export default ReceivedRecords;
