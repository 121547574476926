import EventGuests from "../../pages/EventDetails/components/Menubar/components/EventGuests"
import EventRegistration from "../../pages/EventDetails/components/Menubar/components/EventRegistration"
import SubEvents from "../../pages/EventDetails/components/Menubar/components/SubEvents"
import SubstituteGuests from "../../pages/EventDetails/components/Menubar/components/SubstituteGuests"

export const mapEventTabs = (title, views) => {
    const tabsMapping = {
        'Info': <SubEvents views={views} />,
        'Guests': <EventGuests views={views} />,
        'Registration': <EventRegistration views={views} />,
        'Register a Guest': <EventRegistration views={views} />,
        'Substitute': <SubstituteGuests views={views} />
    }
    return tabsMapping[title];
}