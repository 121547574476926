import { Box, DialogActions, DialogContent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import { EventSettingsContext } from '../..'
import NoRepliesGif from "../../../../../../../../assets/no-replies.gif";
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { convertUtcToLocal } from '../../../../../../../../utils/functions/timeConversion';
import CheckInRecordsPagination from './CheckInRecordsPagination';
import { downloadEventCheckIns } from '../../../../../../../../services/wEvents/eventGuests';
import { useSelector } from 'react-redux';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import moment from 'moment';
import { toast } from 'react-toastify';

const CheckInRecordsWithoutTickets = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { checkInRecords } = useContext(EventSettingsContext);
    const eventData = useSelector(eventDetails);
    const [loading, setLoading] = useState(false);

    const handleCheckInsDownload = () => {
        setLoading(true);
        downloadEventCheckIns(eventData?.id).then((result) => {
            console.log(result);
            const fileBlob = new Blob([result.data], { type: 'text/csv' });
            const downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            const fileUrl = URL.createObjectURL(fileBlob);

            // Create a link to download the file
            downloadLink.href = fileUrl;
            downloadLink.download = `${eventData.title}-Check In Records-${moment().format('D-M-YYYY HH-mm')}.csv`;
            // Add the link to the DOM and click it
            downloadLink.click();
            setLoading(false);

            // Clean up the URL object
            URL.revokeObjectURL(fileUrl);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        });
    }
    return (
        <>
            {
                checkInRecords.length === 0 ? (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 5 }}>
                    <Box
                        component="img"
                        src={NoRepliesGif}
                        width={isSmallDevice ? "90%" : "60% !important"}
                        height={isSmallDevice ? "15rem" : "17rem"}
                    />
                </Stack>
            ) :
                <>
                    <DialogContent dividers sx={{
                        p: { xs: '0rem', md: '0rem' },
                        height: { xs: '26.5rem', md: '31.5rem' }
                    }}
                    >
                        <Box sx={{ textAlign: 'center', my: 1 }}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<DownloadIcon />}
                                variant="outlined"
                                color='warning'
                                sx={{
                                    boxShadow: 'none'
                                }}
                                onClick={handleCheckInsDownload}
                            >
                                Download Records
                            </LoadingButton>
                        </Box>
                        <Typography sx={{ textAlign: 'center', my: 1 }}>
                            {/* Total Check In Count: {totalCheckInCount} */}
                        </Typography>
                        <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                            <TableContainer sx={{
                                border: '1px solid lightgrey',
                                maxWidth: '100%', margin: '1rem auto !important'
                            }}>

                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow hover={true}>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>Name</TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>Email</TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>Contact</TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.7rem' }
                                            }}>Guests</TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                borderRight: '1px solid #ccc',
                                                p: { xs: '0.5rem', md: '0.5rem' }
                                            }}>Invited by</TableCell>
                                            <TableCell align="center" sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                p: { xs: '0.5rem', md: '0.5rem' }
                                            }}>Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {checkInRecords.map((row) => (
                                            <TableRow hover={true} key={row.id}>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    p: { xs: '0.5rem', md: '0.7rem' },
                                                    borderRight: '1px solid #ccc'
                                                }}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    p: { xs: '0.5rem', md: '0.7rem' },
                                                    borderRight: '1px solid #ccc'
                                                }}>
                                                    {row.email ?? 'N/A'}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    p: { xs: '0.5rem', md: '0.7rem' },
                                                    borderRight: '1px solid #ccc'
                                                }}>
                                                    {row.mobile ? `+${row.dialing_code} ${row.mobile}` : 'N/A'}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    p: { xs: '0.5rem', md: '0.7rem' },
                                                    borderRight: '1px solid #ccc'
                                                }}>
                                                    {row.check_in_count}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    p: { xs: '0.5rem', md: '0.7rem' },
                                                    borderRight: '1px solid #ccc'
                                                }}>
                                                    {row.invited_by_name ?? "N/A"}
                                                </TableCell>
                                                <TableCell sx={{
                                                    textAlign: 'center',
                                                    p: { xs: '0.5rem', md: '0.7rem' }
                                                }}>
                                                    {convertUtcToLocal(row.check_in_time).format("Do MMMM YYYY, h:mm A")}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <CheckInRecordsPagination />
                    </DialogActions>
                </>
            }
        </>
    )
}

export default CheckInRecordsWithoutTickets