import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import InvitationsList from "./InvitationsList";
import OneInvitationDetails from "./OneInvitationDetails";

const HistoryDialogContent = (title, invitationType) => {
    const useStyles = makeStyles({
        root: {
            "&.Mui-selected": {
                color: "#FF8359 !important",
                opacity: "1 !important",
                fontWeight: "bold !important"
            },
        },
    });
    const tabType = useRef(null);
    const tabStyles = useStyles();
    const [details, setDetails] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    return (
        <>
            {showDetails ?
                <OneInvitationDetails
                    invitationType={invitationType}
                    details={details} setDetails={setDetails}
                    setShowDetails={setShowDetails} tabStyles={tabStyles} />
                :
                <InvitationsList
                    invitationType={invitationType}
                    title={title} setDetails={setDetails}
                    tabType={tabType} setShowDetails={setShowDetails} tabStyles={tabStyles} />
            }
        </>
    )
}

const HistoryDialog = ({ title, invitationType, handleClose }) => {
    return (
        <>
            <Dialog
                scroll="paper"
                open={true}
                onClose={handleClose}
                sx={{
                    pb: { xs: '1.8rem', sm: '0rem' }
                }}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '35rem' },
                    }
                }}
            >
                <DialogTitle sx={{ p: 1.5 }}>
                    <IconButton onClick={handleClose}
                        sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.5rem' } }}>{title}</Typography>
                </DialogTitle>
                <DialogContent dividers sx={{ p: '0rem' }}>
                    {HistoryDialogContent(title, invitationType)}
                </DialogContent>
            </Dialog>
        </>
    )
};

export default HistoryDialog;
