import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Divider, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import EditGroupDialog from "./components/EditGroup";
import GroupSettingsDialog from "./components/GroupSettings";
import { groupDetails } from "../../../../redux/slices/groupDetailSlice";
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";

const GroupBasicDetails = () => {
    const groupData = useSelector(groupDetails);
    let [dialog, setDialog] = useState({
        edit: false,
        settings: false
    });
    const handleOpen = (operation) => {
        setDialog({ ...dialog, [operation]: true });
    }
    const handleClose = (operation) => {
        setDialog({ ...dialog, [operation]: false });
    }

    return (
        <>
            <Box sx={{
                width: { xs: '95%', sm: '38rem' },
                height: { sm: 'max-content' },
                border: { xs: '1px solid #E5E4E9' },
                borderRadius: { xs: '15px' },
                m: { xs: '1rem', sm: 0 }
            }}>
                <Box sx={{ p: '1rem' }}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Box sx={{ width: '100%' }}>
                            {Object.keys(groupData).length > 0
                                ? <Typography sx={{ fontSize: '2rem', fontWeight: 500 }}>{groupData?.title}</Typography>
                                : <Skeleton height='2rem' width='90%' />}
                            {Object.keys(groupData).length > 0
                                ? <Typography sx={{ fontSize: '0.8rem', color: '#6F738B', fontWeight: 400 }}>Since {groupData?.since}</Typography>
                                : <Skeleton height='1.5rem' width='60%' />}
                        </Box>

                        {ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) ?
                            <>
                                {Object.keys(groupData).length > 0 ?
                                    <Stack direction='row' spacing={1}>
                                        <IconButton onClick={() => handleOpen('edit')} sx={{ ":hover": { backgroundColor: 'white' } }}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleOpen('settings')} sx={{ ":hover": { backgroundColor: 'white' } }}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </Stack>
                                    : <Skeleton width='6rem' />
                                }
                            </> : null}
                    </Stack>

                    <Divider sx={{ my: 2 }} orientation='horizontal' />

                    <Stack spacing='2rem' sx={{ pl: { sm: '1rem' } }}>
                        {/* <Box>
                            {Object.keys(groupData).length > 0 ?
                                <Typography>{groupData?.description}</Typography>
                                : <Skeleton width='100%' height='4rem' sx={{ transform: 'scale(1,1)' }} />
                            }
                        </Box> */}
                        <Stack direction='row' spacing='1.5rem' alignItems='center'>
                            {Object.keys(groupData).length > 0 ?
                                <IconButton color="warning" sx={{
                                    border: '1px solid #FF8359', borderRadius: '4px',
                                    backgroundColor: 'rgba(255, 131, 89, 0.16)',
                                    ":hover": { cursor: 'default', backgroundColor: 'rgba(255, 131, 89, 0.16)' }
                                }}>
                                    <LocationOnIcon />
                                </IconButton>
                                : <Skeleton variant="circular" width='2.5rem' height='2.5rem' />
                            }
                            <Box sx={{ flexGrow: 1 }}>
                                {Object.keys(groupData).length > 0
                                    ? <Typography sx={{ fontSize: '1rem', fontWeight: 400, color: '#1F274A' }}>{groupData?.city}</Typography>
                                    : <Skeleton width='80%' height='1.5rem' />
                                }
                                {Object.keys(groupData).length > 0
                                    ? <Typography sx={{ fontSize: '0.8rem', color: '#6F738B' }}>{groupData?.state}, {groupData?.country}</Typography>
                                    : <Skeleton width='50%' height='1rem' />
                                }
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Box>

            {dialog.edit ? <EditGroupDialog handleClose={handleClose} /> : null}
            {dialog.settings ? <GroupSettingsDialog handleClose={handleClose} groupDetailsObj={groupData} /> : null}

        </>
    )
};

export default GroupBasicDetails;
