import dayjs from "dayjs";
import moment from "moment";
import { ticketCurrencies } from "../constants/ticketCurrency";

export const getEventEmailTemplate = (isReminder, eventData, userData) => {
    const event_name = eventData?.title;
    const event_address = eventData?.address;
    const event_link = eventData?.event_link;
    const event_date =
        eventData?.end_date === null || (eventData?.start_date === eventData?.end_date)
            ? moment(eventData?.start_date).format("ddd, Do MMM YYYY")
            : `${moment(eventData?.start_date).format(
                  "dddd, Do MMM"
              )} - ${moment(eventData?.end_date).format("dddd, Do MMM")}`;
    const event_time = eventData?.end_time === null ? moment(eventData?.start_time, "HH:mm:ss").format(
        "h:mm A"
    ) : `${moment(eventData?.start_time, "HH:mm:ss").format(
        "h:mm A"
    )} - ${moment(eventData?.end_time, "HH:mm:ss").format("h:mm A")}`;

    if (isReminder) {
        return `
            <p>Dear <strong>{{Guest Name}}</strong>,</p>
            <p>
            We hope this email finds you well. We wanted to send you a friendly reminder about our upcoming event ${event_name}, which is just around the corner. 
                We highly value your presence and would love for you to join us for this exciting occasion.
            </p>
            
            <p>Here are the key details once again:</p>
            <p>Event Name: ${event_name}</p>
            ${
                eventData.schedule_announcement_description
                    ? `<p>Schedule announcement: ${eventData.schedule_announcement_description}</p>`
                    : `
                        <p>Date: ${event_date}</p>
                        <p>Time: ${event_time}</p>
                    `
            }
            <p>${
                eventData.is_online
                    ? `Event link: <a href='${event_link}'>${event_link}</a>`
                    : `Event location: ${event_address}`
            } </p>

            <p>
                To confirm your attendance, kindly RSVP by ${dayjs(
                    new Date()
                ).format("dddd, D MMMM YYYY")}. 
                We kindly request you to respond as soon as possible to secure your spot, as seating is limited.
            </p><br/>

            <p>Warm regards,</p>
            <p>${
                eventData?.group_id ? eventData.group_name : userData.full_name
            }</p>
            `;
    } else {
        return `
            <p>Join Us for an Unforgettable Event!</p>
            <p>Dear <strong>{{Guest Name}}</strong>,</p>

            <p>We hope this message finds you in great spirits. 
            We are thrilled to invite you to a ${event_name}. 
            We would be honoured to have you join us for this exciting occasion.
            </p>

            <p>For Event Details please click on this link: <strong>{{Event Weblink}}</strong></p>

            ${
                eventData.schedule_announcement_description
                    ? `<p>Schedule announcement: ${eventData.schedule_announcement_description}</p>`
                    : `
                        <p>Date: ${event_date}</p>
                        <p>Time: ${event_time}</p>
                    `
            }
            <p>${
                eventData.is_online
                    ? `Event link: <a href='${event_link}'>${event_link}</a>`
                    : `Event location: ${event_address}`
            } </p>
            <p>Agenda: [Event Overview]</p>

            <p>Reasons to Attend:</p>
            <ul>
                <li>Reason 1</li>
                <li>Reason 2</li>
                <li>Reason 3</li>
            </ul>
            <p>We believe this event will provide an excellent opportunity for [Event Purpose].</p>

            <p>We have carefully curated the agenda to ensure a memorable experience for all attendees.</p>
            <p>To secure your place at the event, please RSVP by ${dayjs(
                new Date()
            ).format("dddd, D MMMM YYYY")} on this number: [RSVP Contact]</p>
            <p>Should you have any questions or require further information, 
                please don't hesitate to reach out to us. We sincerely hope you can join us for this remarkable event and look forward to seeing you there.
            </p><br/>

            <p>Warm regards,</p>
            ${eventData?.group_id ? eventData.group_name : userData.full_name}
            `;
    }
};

export const getGroupEmailTemplate = (isReminder, groupData, userData) => {
    const group_name = groupData?.title;
    const inviter_name = userData.full_name;
    if (isReminder) {
        return `<p>Dear <strong>{{Member Name}}</strong>,</p>
            <p>
                We hope this email finds you well. We wanted to send you a friendly reminder about the invitation we extended to join our organization, ${group_name}. 
                We understand that life gets busy, and we want to ensure you don't miss out on this exciting opportunity to be a part of our dynamic team.
            </p>
            <p>
                To discuss this opportunity further or to express your interest, please feel free to contact us at +${userData.dialing_code} ${userData.mobile}. 
                We will be more than happy to provide you with additional information, answer any questions you may have, and guide you through the application process.
            </p>
            <p>
                Please remember that the deadline to express your interest is approaching soon. 
                We would be thrilled to receive your application and have the opportunity to discuss how you can become an integral part of our team.
            </p>
            <p>
                Thank you for your time and consideration. We sincerely hope that you will join us at ${group_name} and embark on a rewarding journey with us.
            </p>

            <p>Warm regards,</p>
            <p>${inviter_name}</p>`;
    } else {
        return `<p>Dear <strong>{{Member Name}}</strong>,</p>
            <p>
                We hope this email finds you well. We are reaching out to you with an exciting opportunity to become a valued member of our esteemed organization, ${group_name}.
            </p>
            <p>
                We invite you to consider joining us and becoming an integral part of our organization.
            </p>
            <p>
                For more group details please click on this link: <strong>{{Group Weblink}}</strong>.
            </p>
            <p>
                To discuss this opportunity further or to express your interest, please feel free to contact us at +${userData.dialing_code} ${userData.mobile}.
                We will be more than happy to provide you with additional information, answer any questions you may have, and guide you through the application process.
            </p>
            <p>
                We appreciate your time and consideration and we look forward to the possibility of welcoming you on board.
            </p>
            <p>Warm regards,</p>
            <p>${inviter_name}</p>`;
    }
};

export const getTicketEmailTemplate = ( selfCheckin,eventData, userData, ticketData, ticketPrice, ticketCurrency) => {
    const event_name = eventData?.title;
    const event_address = eventData?.address;
    const event_link = eventData?.event_link;
    const ticketSymbol = (ticketCurrencies.find((t) => t.value === ticketCurrency)?.symbol
);
    const event_date =
        eventData?.end_date === null || (eventData?.start_date === eventData?.end_date)
            ? moment(eventData?.start_date).format("ddd, Do MMM YYYY")
            : `${moment(eventData?.start_date).format(
                  "dddd, Do MMM"
              )} - ${moment(eventData?.end_date).format("dddd, Do MMM")}`;
    const event_time = eventData?.end_time === null ? moment(eventData?.start_time, "HH:mm:ss").format(
        "h:mm A"
    ) : `${moment(eventData?.start_time, "HH:mm:ss").format(
        "h:mm A"
    )} - ${moment(eventData?.end_time, "HH:mm:ss").format("h:mm A")}`;
    if (ticketData?.custom_email_message) {
        return ticketData.custom_email_message;
    }
    if (selfCheckin === 0) {
        return `
         <p>Dear <strong>{{Guest Name}}</strong>,</p>

    ${eventData?.is_paid ? 

    `<p>Thank you for purchasing a ticket to <strong>${event_name}</strong> We are thrilled to have you join us for this exciting event.</p>` :
    `<p>Thank you for registering for <strong>${event_name}</strong>! We are thrilled to have you join us for this exciting event.</p>`}
    
    <p>Below, you will find the details of your ticket:</p>
    
    ${eventData?.event_link ? 
    `<p>For Event Details please click on this link: <a href="${event_link}">${event_link}</a></p>` : ''}
    
    ${eventData?.start_date && eventData?.end_date ? 
    `<p>Event Date: ${event_date}</p>
     <p>Event Time: ${event_time}</p>` : 
    eventData?.schedule_announcement_description ? 
    `<p>Schedule announcement: ${eventData.schedule_announcement_description}</p>` : ''}
    
    ${eventData?.address ? `<p>Event Venue: ${event_address}</p>` : ''}
    
    <p>Ticket Name: {{Ticket Name}}</p>
    <p>Number of Tickets: {{Num of Tickets}}</p>
    
    ${ticketPrice ? 
    `<p>Ticket Price: ${ticketPrice}
        <span style="font-family: DejaVu Sans; sans-serif;">${ticketSymbol}</span></p>` : ''}    
    <br />
    <p>
        Please make sure to keep this email safe, as it contains important information regarding your ticket. On the day
        of the event,
        ${selfCheckin ? 
        `you can scan the QR code from your mobile device by visiting the event link.` :
        `please have your ticket ready for validation. You can either print the ticket or display the QR code on your mobile device.`}
        If you have any questions or need further assistance, please do not hesitate to contact us at
        <strong>${userData.email}</strong>.
        </br>
        We look forward to seeing you at <strong>${eventData.title}</strong>!
    </p>

    <p>Best regards,</p>
    <p>${eventData?.group_name || userData.full_name}</p>
    <p>${userData.email}</p>
            `;
    } else {
        return `
               <p>Dear <strong>{{Guest Name}}</strong> ,</p>
    <p>
        Thank you for registering for the ${eventData?.title}! Please find your ticket/pass attached to this email.
    </p>

    <p>Event Details:</p>
    ${eventData?.start_date && eventData?.end_date ? 
    `<p>Date: ${event_date}</p>
     <p>Time: ${event_time}</p>` : 
    eventData?.schedule_announcement_description ? 
    `<p>Schedule announcement: ${eventData.schedule_announcement_description}</p>` : ''}
    
    ${eventData?.address ? `<p>Venue: ${eventData.address}</p>` : ''}
    
    <p>Ticket Name: {{ Ticket Name }}</p>
    <p>Number of Tickets Purchased: {{ Num of Tickets }}</p>
    
    ${ ticketPrice ? 
    `<p>Amount Paid: ${ticketPrice}
        <span style="font-family: DejaVu Sans; sans-serif;">${ticketSymbol}</span></p>` : ''}
    <br />
    <p>
        To complete the check-in process, please follow these steps:<br />
        1. Access the event link: ${eventData?.event_link}<br />
        2. Click on the "Scan QR Code" button for check-in.<br />
        3. After scanning, click the "Check-in" button.<br />
    </p>

    <p>
        Upon successful check-in, you will see a green tick on the screen. Please be prepared to show this success
        screen at the entry gate if necessary.
    </p>

    <p>
        If you have any questions or need further assistance, please do not hesitate to contact us at
        ${userData.email}.
        We look forward to seeing you at the ${eventData?.title}!
    </p>

    <p>Best regards,</p>
    <p>${eventData?.group_name || userData.full_name}</p>
    <p>${userData.email}</p>            `;
    }

}