import { USER_KYC_API } from "../utils/constants/apis.urls";
import { axiosInstance } from "../config/interceptor";

export const getKycs = (userId) => {
    return axiosInstance.get(`${USER_KYC_API.replace(':user_id', userId)}`);
};

export const createKyc = (payload, userId) => {
    return axiosInstance.post(`${USER_KYC_API.replace(':user_id', userId)}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const editKyc = (payload, userId, kycId) => {
    return axiosInstance.post(`${USER_KYC_API.replace(':user_id', userId)}/${kycId}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};