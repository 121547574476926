import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { parseDecimalNumber } from "../../../../../../../../../utils/functions/getFormattedNumber";
import getMessageSendCharge from "../../../../../../../../../utils/functions/getMessageSendCharge";
import { EventGuestsContext } from "../../../EventGuestContext";
import InvitationPayment from "./InvitationPayment";

const PurchaseOrder = () => {
    const theme = useTheme();
    const { guests, checkedGuests, inviteMsg, totalGuests } = useContext(EventGuestsContext);
    const invited_guests = checkedGuests.length > 0 ? checkedGuests.length : totalGuests.current;
    const guests_for_email = checkedGuests.length > 0 ?
        guests.filter(item => checkedGuests.includes(item.id) && item.email).length // Filter objects with specified IDs and non-null email values
        : guests.filter(item => item.email).length;

    const data = {
        sms: {
            cost: getMessageSendCharge('sms', { totalGuests: checkedGuests }),
            msg_quantity: inviteMsg?.total_sms,
            guests_quantity: invited_guests
        },
        email: {
            cost: getMessageSendCharge('email'),
            msg_quantity: 1,
            guests_quantity: guests_for_email
        },
        whatsapp: {
            cost: getMessageSendCharge('whatsapp'),
            msg_quantity: 1,
            guests_quantity: invited_guests
        },
    }
    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const priceRow = (desc, unit) => {
        if (desc.includes('sms')) {
            return inviteMsg?.total_sms * invited_guests * unit;
        } else if (desc.includes('email')) {
            return guests_for_email * unit;
        }
        return invited_guests * unit;
    }
    const createRow = (desc, unit, msg_qty, guests_qty) => {
        const price = priceRow(desc, unit);
        return { desc, unit, price, msg_qty, guests_qty };
    }
    const subTotalOfMessageSend = (items) => {
        return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
    }

    const mapKeyToText = {
        sms: "SMS",
        whatsapp: "WhatsApp",
        email: "Email"
    }

    const [rows, setRows] = useState([]);
    const subTotal = subTotalOfMessageSend(rows);
    const gstCharge = (GST_PERCENT / 100) * subTotal;
    const extraCharges = 0;
    let invoiceTotal = gstCharge + extraCharges + subTotal;

    useEffect(() => {
        let inviteFields = inviteMsg ? Object.keys(inviteMsg).filter((e) => typeof (inviteMsg[e]) == 'boolean' && inviteMsg[e]) : null
        if (inviteFields) {
            let newRows = [];
            inviteFields.forEach((i) => {
                newRows.push(createRow(i, data[i].cost, data[i].msg_quantity, data[i].guests_quantity))
            })
            setRows(newRows)
        }
    }, [inviteMsg]);

    return (
        <>
            <Stack spacing={2} sx={{ px: { xs: 0, sm: 1, lg: 2 }, pb: 2 }}>
                <Box>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        You have chosen {invited_guests} guest(s).
                    </Typography>
                    <Typography sx={{ mt: 1, color: '#6F738B', fontSize: '0.9rem' }}>
                        The displayed estimate represents the exact amount you will be charged, which is calculated based on the number of guests you have selected.
                    </Typography>
                </Box>
                <TableContainer sx={{ border: '1px solid lightgrey', width: { md: '90%' }, margin: '1rem auto !important' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow hover={true}>
                                <TableCell>Type</TableCell>
                                <TableCell align="center">Per Message Cost</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Guests Count</TableCell>
                                <TableCell align="center">Sum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow hover={true} key={index}>
                                    <TableCell>
                                        {mapKeyToText[row.desc]}
                                    </TableCell>
                                    <TableCell align="center">{row.unit} &#8377;</TableCell>
                                    <TableCell align="center">
                                        <Typography>{row.msg_qty}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.guests_qty}
                                    </TableCell>
                                    <TableCell align="right">
                                        {parseDecimalNumber(row.price, 2)} &#8377;
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow>
                                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>Sub Total</TableCell>
                                <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold' }}>{parseDecimalNumber(subTotal, 2)} &#8377;</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>System Charges</TableCell>
                                <TableCell colSpan={3} align="right">{parseDecimalNumber(extraCharges, 2)} &#8377;</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>GST({GST_PERCENT} %)</TableCell>
                                <TableCell colSpan={3} align="right">{parseDecimalNumber(gstCharge, 2)} &#8377;</TableCell>
                            </TableRow>
                            <TableRow hover={true}>
                                <TableCell colSpan={2} sx={{ color: '#1F274A', fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Final Amount
                                </TableCell>
                                <TableCell align="right" colSpan={3}
                                    sx={{ color: '#FF8359', fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    {parseDecimalNumber(invoiceTotal, 2)} &#8377;
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {invoiceTotal > 0 ?
                    <Stack spacing={2}>
                        <Box sx={{ textAlign: 'center' }}>
                            <InvitationPayment
                                amountCurrency='rupees'
                                totalAmountToPay={parseDecimalNumber(invoiceTotal, 2) < 1 ? 1 : parseDecimalNumber(invoiceTotal, 2)}
                                gst_charges={parseDecimalNumber(gstCharge, 2)}
                                extra_charges={parseDecimalNumber(extraCharges, 2)}
                                total_guests={invited_guests}
                                guests_for_email={guests_for_email}
                            />
                        </Box>
                        <Typography sx={{
                            mt: 1, textAlign: 'center',
                            color: '#6F738B', fontSize: '0.8rem'
                        }}>
                            <span style={{
                                color: theme.palette.primaryColor
                            }}>Note:</span> Minimum 1 Rs. payment will be taken as per razorpay standards!!
                        </Typography>
                    </Stack>
                    : null
                }

            </Stack>
        </>
    )
};

export default PurchaseOrder;