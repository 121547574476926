import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useDebounce } from 'use-debounce';
import { getEvents } from "../../../../../services/wEvents/event";

const SearchEvent = ({ eventsCount, perPageEvents, setEvents, searchedEvent, setSearchedEvent }) => {
    const [value] = useDebounce(searchedEvent, 1000);
    useEffect(() => {
        getEvents({ page: 1, searchedKeyword: value }).then((res) => {
            eventsCount.current = res.data.meta.total
            perPageEvents.current = res.data.meta.per_page
            setEvents(res?.data.data);
        }).catch((err) => {
            console.log('Error: ', err);
            toast.error("Something went wrong in fetching events", {
                position: "top-right",
                theme: "dark"
            });
        })
    }, [value]);

    const handleSearch = (e) => {
        setEvents(null);
        setSearchedEvent(e.target.value);
    }

    return (
        <>
            <TextField
                size='small'
                color="warning"
                variant="outlined"
                autoComplete="off"
                onChange={handleSearch}
                label="Search Events"
                InputProps={{
                    className: "MuiAutocomplete-inputRoot",
                    type: "search",
                }}
            />
        </>
    )
};

export default SearchEvent;
