import { AUTH_API } from "../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const sendOtp = (payload) => {
    return axiosInstance.post(AUTH_API.LOGIN_AND_SEND_OTP, payload)
};

export const verifyUser = (payload) => {
    // return axios.post(AUTH_API.VERIFY_OTP, payload, {
    return axiosInstance.post(AUTH_API.VERIFY_OTP, payload);
};

export const getUserDetails = ({ userId }) => {
    return axiosInstance.get(`https://jsonplaceholder.typicode.com/users/${userId}`)
    // return axiosInstance.get(AUTH_API.USER_DETAILS + `${userId}`)
};

export const updateUserDetails = (payload, { userId }) => {
    return axiosInstance.post(`${AUTH_API.USER_DETAILS}/${userId}`, payload,
        // return axios.put(AUTH_API.USER_DETAILS + `${userId}`, payload, {
        {
            headers: { "content-type": "multipart/form-data" }
        }
    );
};
