import { Box, Tab, Tabs } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import MuiTabPanel from '../../../components/muiTabPanel';
import AllEvents from './AllEvents';
import AllGroups from './AllGroups';

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DashboardTabs = () => {
    const location = useLocation();
    const isSmallDevice = useMediaQuery("(max-width:900px)");
    const [params, setParams] = useSearchParams();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (params.get('type') === 'event') {
            setValue(0)
        } else if (params.get('type') === 'group') {
            setValue(1)
        }
    }, [location]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{
                width: '100%', overflow: 'auto',
                position: 'sticky',
                top: '0rem',
                // top: { xs: '3.5rem', sm: '4rem' },
                backdropFilter: 'blur(1rem)',
                zIndex: 100
            }}>
                <Tabs
                    value={value}
                    variant={isSmallDevice ? 'fullWidth' : null}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    centered={isSmallDevice ? true : false}
                    sx={{
                        '.Mui-selected': {
                            fontWeight: "bold !important",
                            color: '#FF8359 !important'
                        }
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#FF8359"
                        }
                    }}
                >
                    <Tab  {...tabProps(0)}
                        onClick={() => setParams({ type: 'event' })}
                        label={<span style={{ textTransform: 'none' }}>All Events</span>}
                        sx={{ color: '#FF8359', mr: { md: 8 }, fontWeight: 'bold', minWidth: { xs: '50%', sm: 0 } }} />
                    <Tab {...tabProps(1)}
                        onClick={() => setParams({ type: 'group' })}
                        label={<span style={{ textTransform: 'none' }}>All Organizations</span>}
                        sx={{ color: '#FF8359', fontWeight: 'bold', minWidth: { xs: '50%', sm: 0 } }} />
                </Tabs>
            </Box >
            <MuiTabPanel value={value} index={0}>
                <AllEvents />
            </MuiTabPanel>
            <MuiTabPanel value={value} index={1}>
                <AllGroups />
            </MuiTabPanel>
        </>
    );
};

export default DashboardTabs;
