import dayjs from "dayjs";
import moment from "moment";
import utc from 'dayjs/plugin/utc';

export const convertUtcToLocal = (utcDateTimeString, format) => {
    // Create a Moment object from the UTC datetime string
    const utcMoment = moment.utc(utcDateTimeString, format ?? 'YYYY-MM-DD HH:mm:ss');

    // Convert to the user's local time
    const localMoment = utcMoment.local();

    return localMoment;
}

export const convertLocalToUtc = (local_time) => {
    // Require for converting local time to UTC time
    dayjs.extend(utc);

    const utc_time = local_time.utc().format('YYYY-MM-DD HH:mm:ss');

    return utc_time;
}