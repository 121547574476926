import React, { useContext, useEffect } from "react";
import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MuiTabPanel from "../../../../../../../../components/muiTabPanel";
import { GroupSettingsContext } from "../..";
import SubscriptionList from "./SubscriptionList";
import PurchasedSubscriptionData from "./PurchasedSubscriptionData";

const SubscriptionTabs = () => {
    const { activeSlide, handleNextClick } = useContext(GroupSettingsContext);
    const useStyles = makeStyles({
        root: {
            "&.Mui-selected": {
                color: "#FF8359 !important",
                opacity: "1 !important",
                fontWeight: "bold !important"
            },
        },
    });
    const tabStyles = useStyles();

    const [value, setValue] = React.useState(0);
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        return () => {
            setValue(0); //Set 1st tab as default on unmounting
        };
    }, [activeSlide]);

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#FF8359"
                    }
                }}
                sx={{
                    backgroundColor: '#FFEBE4',
                    maxWidth: { sm: '100%' }
                }}
            >
                <Tab className={tabStyles.root} label='Subscription' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
                <Tab className={tabStyles.root} label='Purchased'
                    sx={{
                        width: '50%',
                        opacity: '0.6',
                        color: '#FF8359',
                        textTransform: 'none'
                    }} />
            </Tabs>
            <MuiTabPanel value={value} index={0}>
                {/* Form has been already created and will have name  */}
                <Box sx={{ mx: { xs: 1, md: 2 } }}>
                    <Stack spacing={1} sx={{ height: '100%' }}>
                        <Button color='warning' variant="text"
                            onClick={() => handleNextClick()} sx={{ width: 'fit-content' }}>
                            + Create subscription
                        </Button>
                        <SubscriptionList />
                    </Stack>
                </Box>
            </MuiTabPanel>
            <Box
                role="tabpanel"
                hidden={value !== 1}
                id={`simple-tabpanel-${1}`}
                aria-labelledby={`simple-tab-${1}`}
                sx={{ mt: '0px !important' }}
            >
                {value === 1 && (
                    <PurchasedSubscriptionData />
                )}
            </Box>
        </>
    )
};

export default SubscriptionTabs;
