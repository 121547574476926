import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './daterangepicker.scss';

const EventDatesDialog = ({ handleClose, eventDetailsObj }) => {
    const selectionRange = {
        startDate: new Date(eventDetailsObj?.start_date),
        endDate: new Date(eventDetailsObj?.end_date ?? eventDetailsObj?.start_date),
    }

    return (
        <Dialog
            fullWidth
            open={true}
            onClose={(e, r) => handleClose(e, r, 'calender')}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title">
                <IconButton onClick={(e, r) => handleClose(e, r, 'calender')} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                    <CloseIcon fontSize='medium' />
                </IconButton>
                <Typography sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}>
                    Event dates
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={() => { }}
                    rangeColors={["#FF8359"]}
                    showSelectionPreview={false}
                    showMonthAndYearPickers={false}
                    direction="horizontal"
                    showDateDisplay={true}
                />
            </DialogContent>
        </Dialog>
    )
};

export default EventDatesDialog;
