import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventTickets } from '../../../../../../../../services/wEvents/eventDetails';
import { EventGuestsContext } from "../../EventGuestContext";
import InviteConfirmationDialog from "../GuestInvitation/InviteConfirmationDialog";
import SetInvitationDialog from "../GuestInvitation/SetInvitationDialog";

const GuestFooter = () => {
    const [loader, setLoader] = useState(false);
    const eventData = useSelector(eventDetails);
    const { isCheckboxVisible, checkedGuests, setVisible, setChecked, tabValue,
        setScheduledDate, setGuestInviteActiveStep, setPayment } = useContext(EventGuestsContext);

    const handleCancel = () => {
        setChecked([]);
        setVisible(false)
    }

    let [dialogOpen, setDialogOpen] = useState({
        confirmation: false,
        invitation: false
    });

    const handleConfirmationClose = () => {
        setDialogOpen({ ...dialogOpen, confirmation: false });
    }
    const handleConfirmationClick = () => {
        setDialogOpen({ confirmation: false, invitation: true });
        document.body.classList.add("no-scroll");
    }

    const handleDialogOpen = () => {
        // To show warning based on tickets and form existence at time of invite
        setLoader(true);
        getEventTickets({ eventId: eventData?.id, includeHiddenTickets: 0 }).then((res) => {
            setLoader(false);
            if ((eventData?.is_paid && res.data.data.length === 0) || (eventData?.has_registration && !eventData?.has_registration_form)) {
                setDialogOpen({ ...dialogOpen, confirmation: true });
            } else {
                document.body.classList.add("no-scroll");
                setDialogOpen({ ...dialogOpen, invitation: true });
            }
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error("Something went wrong in fetching tickets", {
                position: "top-right",
                theme: "dark"
            });
        });
    }

    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        document.body.classList.remove("no-scroll");
        setScheduledDate(null);
        setGuestInviteActiveStep(0);
        setPayment(false);
        setDialogOpen({ ...dialogOpen, invitation: false });
    }

    const footerBtnStyle = {
        m: 0,
        width: '100%',
        display: { sm: 'none' }
    };

    const CancelEditOptionComponent = () => {
        return (isCheckboxVisible ?
            <Button sx={footerBtnStyle} onClick={handleCancel}
                variant='outlined' color='primary'>
                Cancel
            </Button>
            : null
        )
    }

    return (
        <>
            {(tabValue === 1) ? // Show only cancel option for Registered guests tab
                <CancelEditOptionComponent />
                :
                <>
                    <Stack direction='row' spacing={1} sx={{ width: '100%', mt: 2 }}>
                        <CancelEditOptionComponent />
                        {
                            <LoadingButton
                                onClick={handleDialogOpen}
                                loading={loader ? true : false}
                                startIcon={loader ? <SaveIcon /> : null}
                                sx={{
                                    ...footerBtnStyle, color: loader ? '#00000042' : 'white',
                                    backgroundColor: loader ? 'white' : '#1F274A !important',
                                    border: loader ? '1px solid lightgrey' : 'none'
                                }}>
                                {checkedGuests.length > 0 ? `Invite(${checkedGuests.length})` : 'Invite'}
                            </LoadingButton>
                        }
                    </Stack >
                    {dialogOpen.invitation ? <SetInvitationDialog handleClose={handleDialogClose} /> : null}
                    {
                        dialogOpen.confirmation ?
                            <InviteConfirmationDialog
                                tag={eventData?.is_paid ? 'paid' : 'free'}
                                handleConfirmationClick={handleConfirmationClick}
                                handleConfirmationClose={handleConfirmationClose}
                            /> : null
                    }
                </>
            }
        </>
    )
};

export default GuestFooter;
