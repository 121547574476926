import { PAGE_PATHS } from "../utils/constants/page-path.constants";
import Main from "../pages/Main";
import DefaultRoute from "../routes/defaultRoute";
import PrivateRoutes from "../routes/privateRoute";
import PublicRoutes from "../routes/publicRoute";

import AuthLayout from "../Layout/AuthLayout";
import MobileLogin from "../pages/User/components/MobileLogin";
import VerifyOtp from "../pages/User/components/VerifyOtp";
import UserDetails from "../pages/User/components/UserDetails";
import UserAccount from "../pages/User/components/UserAccount";

import DashboardLayout from "../Layout/Dashboard/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import Pricing from "../pages/Pricing";
import Payment from "../pages/Payment";
import CreateEvent from "../pages/Dashboard/components/CreateEvent";
import EventDetails from "../pages/EventDetails";
import BankDetails from "../pages/BankDetails";
import CreateGroup from "../pages/Dashboard/components/CreateGroup";
import GroupDetails from "../pages/GroupDetails";

const routes = [
    {
        path: "/" + PAGE_PATHS.ONBOARDING,
        element: <Main />
    },
    // {
    //     path: "",
    //     element: <PublicRoutes />,
    //     children: [
    {
        path: "/",
        element: <AuthLayout />,
        children: [
            {
                path: PAGE_PATHS.MOBILE_LOGIN,
                element: <MobileLogin />
            },
            {
                path: PAGE_PATHS.OTP_INPUT,
                element: <VerifyOtp />
            },
            {
                path: PAGE_PATHS.USER_PROFILE,
                element: <UserDetails />
            },
            {
                path: "",
                element: <DefaultRoute />
            }
        ]
    },
    //     ]
    // },
    {
        path: "",
        element: <PrivateRoutes />,
        children: [
            {
                path: "/",
                element: <DashboardLayout />,
                children: [
                    {
                        path: PAGE_PATHS.DASHBOARD,
                        element: <Dashboard />
                    },
                    {
                        path: PAGE_PATHS.EVENT_DETAILS,
                        element: <EventDetails />
                    },
                    {
                        path: PAGE_PATHS.GUEST_EVENT_DETAILS,
                        element: <EventDetails />
                    },
                    {
                        path: PAGE_PATHS.PUBLIC_EVENT_DETAILS,
                        element: <EventDetails />
                    },
                    {
                        path: PAGE_PATHS.USER_ACCOUNT,
                        element: <UserAccount />
                    },
                    {
                        path: PAGE_PATHS.CREATE_EVENT,
                        element: <CreateEvent />
                    },
                    {
                        path: PAGE_PATHS.CREATE_GROUP,
                        element: <CreateGroup />
                    },
                    {
                        path: PAGE_PATHS.GROUP_DETAILS,
                        element: <GroupDetails />
                    },
                    {
                        path: PAGE_PATHS.MEMBER_GROUP_DETAILS,
                        element: <GroupDetails />
                    },
                    {
                        path: PAGE_PATHS.PRICING,
                        element: <Pricing />
                    },
                    {
                        path: PAGE_PATHS.PAYMENTS,
                        element: <Payment />
                    },
                    {
                        path: PAGE_PATHS.BANK_DETAILS,
                        element: <BankDetails />
                    },
                    {
                        path: "*",
                        element: <DefaultRoute />
                    },
                ]
            }
        ]
    },
    {
        path: "*",
        element: <DefaultRoute />
    }
];

export default routes;
