import { ThumbUpAltOutlined } from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Avatar, Box, IconButton, Skeleton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NotFound from '../../../../../assets/abc.webp';
import avtar from '../../../../../assets/messi2.webp';
import { clear_event_details, update_event_details } from "../../../../../redux/slices/eventDataSlice";
import { userDetails } from "../../../../../redux/slices/userDataSlice";
import { BACKEND_URL } from "../../../../../utils/constants/apis.urls";
import { PAGE_PATHS } from "../../../../../utils/constants/page-path.constants";
import { ALL_PERMISSION_ROLES } from "../../../../../utils/constants/roles";
import { generateColor } from "../../../../../utils/functions/generateRandomColors";
import RsvpDialog from "./RsvpDialog";
import { wrapString } from "../../../../../utils/functions/wrapString";
import WowslyLogo from '../../../../../assets/WowslyMobileLogo2.png';

const EventCardFooter = memo(({ e, handleEventClick }) => {
    const bgColor = generateColor();

    return (
        <Stack
            onClick={handleEventClick}
            spacing={1}
            justifyContent='center'
            sx={{
                textAlign: 'center',
                backgroundColor: bgColor,
                borderRadius: '0.5rem',
                color: 'white',
                p: '0.35rem 0.75rem',
                ":hover": {
                    cursor: 'pointer',
                }
            }}>
            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '1rem' } }}>
                {e.start_date_display.split(' ')[0]}
            </Typography>
            <Typography sx={{ fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 600 }}>
                {e.start_date_display.split(' ')[1]}
            </Typography>
        </Stack>
    )
})

const CardContent = ({ e, setEvents }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userData = useSelector(userDetails);
    const isMediumDevices = useMediaQuery("(min-width:600px) and (max-width:1200px)");

    const [open, setOpen] = useState(false);

    const handleOpen = (c) => {
        dispatch(update_event_details(c))
        setOpen(true);
    }
    const handleClose = () => {
        dispatch(clear_event_details())
        setOpen(false);
    }
    const handleEventClick = useCallback(() => { //To memorize this function every time component re-renders
        if (ALL_PERMISSION_ROLES.includes(e.current_user_role)) {
            navigate(`/${PAGE_PATHS.EVENT_DETAILS.replace(":id", e.id)}`)
        } else {
            if (e.guest_uuid) {
                navigate(`/${PAGE_PATHS.GUEST_EVENT_DETAILS.replace(":guestId", e.guest_uuid)}`);
            } else {
                toast.error("Page not found", {
                    position: "top-right",
                    theme: "dark"
                })
                navigate(PAGE_PATHS.DASHBOARD);
            }
        }
    }, [e])

    return (
        <>
            <Stack spacing={1} sx={{ pt: '0.5rem', height: '100%' }}>
                {e ?
                    <Stack
                        onClick={handleEventClick}
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                            mx: '0.7rem',
                            ":hover": {
                                cursor: 'pointer',
                            }
                        }}
                    >
                        <Stack direction='row' alignItems='center' spacing={1}>
                            {e.owner?.profile_photo_path ?
                                <Avatar
                                    src={`${e.owner?.profile_photo_path}`}
                                    alt={avtar}
                                    sx={{
                                        width: 25,
                                        height: 25
                                    }}
                                />
                                :
                                <Avatar sx={{ width: 25, height: 25 }} />
                            }
                            <Tooltip title=
                                {`${e.group_id ? (`${e.group_name} Organization`) :
                                    (e.owner?.id === userData?.id ? 'You' : e.owner.first_name)}`}
                            >
                                <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                                    Created By {e.group_id ? wrapString(`${e.group_name} Organization`, isMediumDevices ? 18 : 25) :
                                        (e.owner?.id === userData?.id ? 'You' : wrapString(e.owner.first_name, 10))}
                                </Typography>
                            </Tooltip>
                        </Stack>
                    </Stack>
                    : <Skeleton sx={{ mx: '1rem !important', p: '0.5rem !important' }} animation="wave" />
                }
                {e ?
                    <Box
                        onClick={handleEventClick}
                        sx={{
                            height: { xs: '58%', sm: '50%' },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ":hover": {
                                cursor: 'pointer',
                            }
                        }}>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: e.event_main_photo ? '100%' : '80%',
                                width: e.event_main_photo ? '100%' : '30%',
                                mx: '0.35rem',
                                objectFit: 'cover',
                                borderRadius: '0.5rem',
                                ":hover": {
                                    cursor: 'pointer',
                                },
                                // maxWidth: '100%',
                                // mixBlendMode: 'overlay'
                            }}
                            src={e.event_main_photo ? `${e.event_main_photo}` : WowslyLogo}
                            alt="Event Logo"
                        />
                    </Box>
                    : <Skeleton animation="wave" height='50%' sx={{ mx: '1rem !important' }} />
                }

                {e ?
                    <Stack direction="row" spacing={1}
                        sx={{
                            alignItems: 'center',
                            px: '0.7rem', pt: { sm: '3px' },
                            mt: { xs: '6px !important', sm: '8px' }
                        }}>
                        {
                            e.start_date_display ?
                                <EventCardFooter e={e} handleEventClick={handleEventClick} /> : null
                        }
                        <Stack
                            onClick={handleEventClick}
                            flex={1}
                            spacing={0.5}
                            justifyContent='center'
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                }
                            }}>
                            <Typography className="dashboard-event-title">
                                {e.title}
                            </Typography>
                            <Typography sx={{
                                color: '#6F738B',
                                fontSize: { xs: '0.9rem', sm: '0.9rem' }
                            }}>
                                {
                                    !e.is_online ? (
                                        e.city?.length >= 25 ? (
                                            <>
                                                {e.city?.slice(0, 25)}...
                                            </>
                                        ) :
                                            e.city
                                    ) : "Online Event"
                                }
                            </Typography>
                        </Stack>
                        {
                            ((e.owner?.id !== userData?.id) && e.is_rsvp_required) ?
                                <Box>
                                    <IconButton onClick={() => handleOpen(e)}>
                                        {
                                            e.invitation_status ?
                                                (e.invitation_status === 'accepted' ?
                                                    (<ThumbUpIcon sx={{ color: '#FF8359', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }} />) :
                                                    (e.invitation_status === 'declined' ?
                                                        (<ThumbDownIcon fontSize='large' sx={{ color: '#FF8359', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }} />) :
                                                        (e.invitation_status === 'maybe' ?
                                                            (<RemoveCircleOutlinedIcon fontSize='large' sx={{ color: '#FF8359', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }} />) :
                                                            (<ThumbUpAltOutlined fontSize='large' sx={{ color: '#FF8359', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }} />)
                                                        )
                                                    )
                                                ) :
                                                null
                                        }
                                    </IconButton>
                                    <RsvpDialog open={open} handleClose={handleClose} setEvents={setEvents} />
                                </Box>
                                : null
                        }
                    </Stack>
                    : <Skeleton animation="wave" sx={{ flex: 1, mx: '1rem !important' }} />
                }

            </Stack>
        </>
    );
};

export default CardContent;
