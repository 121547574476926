import { Button, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { GroupMembersContext } from "../../GroupMembersContext";
// import SetInviteMessageDialog from "../GuestInvitation/SetInvitationDialog";

const MembersFooter = () => {
    const { isCheckboxVisible, checkedMembers, setVisible, setChecked } = useContext(GroupMembersContext);

    const handleCancel = () => {
        setChecked([]);
        setVisible(false)
    }

    let [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        document.body.classList.add("no-scroll");
        setDialogOpen(true);
    }
    const handleDialogClose = () => {
        document.body.classList.remove("no-scroll");
        setDialogOpen(false);
    }

    const footerBtnStyle = {
        m: 0,
        width: '100%',
        display: { sm: 'none' }
    };

    return (
        <>
            <Stack direction='row' spacing={1} sx={{ width: '100%', mt: 2 }}>
                {
                    isCheckboxVisible ?
                        <Button sx={footerBtnStyle} onClick={handleCancel}
                            variant='outlined' color='primary'>
                            Cancel
                        </Button>
                        : null
                }
                {
                    <Button
                        onClick={handleDialogOpen}
                        sx={{ ...footerBtnStyle, color: 'white', backgroundColor: '#1F274A !important' }}>
                        {checkedMembers.length > 0 ? `Invite(${checkedMembers.length})` : 'Invite'}
                    </Button>
                }
            </Stack>
            {/* {dialogOpen ? <SetInviteMessageDialog handleClose={handleDialogClose} /> : null} */}
        </>
    )
};

export default MembersFooter;
