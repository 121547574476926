import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button, Collapse, DialogActions, DialogContent, IconButton, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import CustomRadioGroup from "../../../../../../../../../../components/CustomRadioGroup";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FacilityForm = ({ edit, setAdd, handleEditClose, handleAddEventFacility, handleEditEventFacility, facility }) => {
    const handleClose = () => {
        setAdd(false);
    }

    const [totalScanQuantity, setScanQuantity] = useState(edit ? (facility.scan_quantity ?? 1) : 1);
    const [scanQuantityType, setScanQuantityType] = useState(edit ? ((facility.scan_quantity === null || !facility.hasOwnProperty('scan_quantity')) ? 'Multiple times' : 'Quantity') : 'Quantity');
    
    const schema = yup.object({
        amount: yup.number().required(),
    })
    const { control, register, watch, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            facilityName: edit ? facility.name : null,
            isIncluded: edit ? (facility.is_included ? true : false) : false,
            isFree: edit ? (facility.is_free ? true : false) : true,
            currency: edit ? (!facility.is_free ? facility.currency : ticketCurrencies[0].value) : ticketCurrencies[0].value,
            amount: edit ? (!facility.is_free ? facility.price : 0) : 0
        },
        resolver: yupResolver(schema)
    })

    const handleScanQuantityTypeRadioChange = (event) => {
        setScanQuantityType(event.target.value);
    };

    const handleMinusScanCount = () => {
        if (totalScanQuantity > 1) {
            setScanQuantity((prev) => prev - 1);
        }
    }
    const handlePlusScanCount = () => {
        setScanQuantity((prev) => prev + 1);
    }

    const handleFacilityDetails = () => {
        if (watch("isIncluded")) {
            setValue('isFree', true);            
            setValue('currency', 'rupees');
            setValue('amount', 0);            
        }
    };

    const addFacility = () => {
        handleFacilityDetails();
        let data = {
            name: watch('facilityName'),
            is_included: watch("isIncluded"),
            is_free: watch("isFree"),
            ...(scanQuantityType === "Quantity" && { scan_quantity: totalScanQuantity }),
            ...(!watch("isFree") && {
                currency: watch('currency'),
                price: watch('amount')
            })
        };
        if (edit) {
            handleEditEventFacility(facility.name, data);
            handleEditClose();
        } else {
            handleAddEventFacility(data);
        }
    }

    return (
        <Box>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Facility Name"
                        {...register('facilityName', { required: true })}
                    >
                    </TextField>
                    <Box>
                        <Typography sx={{ mb: 1.5, fontSize: { xs: '1rem', sm: '1rem' } }}>
                            Scan Quantity Type:
                        </Typography>
                        <CustomRadioGroup
                            value={scanQuantityType}
                            handleRadioChange={handleScanQuantityTypeRadioChange}
                            option1='Quantity' option2='Multiple times'
                            gridCols={{
                                xs: 12,
                                sm: 5.5,
                                lg: 4.5,
                            }}
                        />
                    </Box>

                    <Collapse in={scanQuantityType === 'Quantity'}>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Typography>
                                Total Quantity:
                            </Typography>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <IconButton onClick={handleMinusScanCount}>
                                    <RemoveCircleOutlineIcon color='warning' />
                                </IconButton>
                                <TextField
                                    color="warning"
                                    sx={{ width: '5rem' }}
                                    inputProps={{
                                        type: 'number',
                                        min: 1
                                    }}
                                    value={totalScanQuantity}
                                    onChange={(e) => {
                                        setScanQuantity(Number(e.target.value))
                                    }}
                                />
                                <IconButton onClick={handlePlusScanCount}>
                                    <AddCircleOutlineIcon color='warning' />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Collapse>

                    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                        <Typography sx={{ fontSize: { xs: '1rem' } }}>
                            Included
                        </Typography>
                        <Controller
                            name="isIncluded"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Switch
                                    color='warning'
                                    checked={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Stack>

                    {
                        !watch("isIncluded") ?
                            <>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                        Free
                                    </Typography>
                                    <Controller
                                        name="isFree"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Switch
                                                color='warning'
                                                checked={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Collapse in={!watch("isFree")}>
                                    <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Select value={value} onChange={(event) => {
                                                    // Call the original onChange function
                                                    onChange(event);
                                                }}>
                                                    {ticketCurrencies.map((option, index) => (
                                                        <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        <TextField
                                            fullWidth
                                            color="warning"
                                            label={'Price'}
                                            error={errors.amount ? true : false}
                                            helperText={
                                                errors?.amount ? "This is required field" : null
                                            }
                                            {...register("amount", { required: true })}
                                        />
                                    </Stack>
                                </Collapse>
                            </> : null
                    }
                </Stack>
            </DialogContent>
            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                <Button onClick={!edit ? handleClose : () => {
                    setScanQuantityType(edit ? ((facility.scan_quantity === null || !facility.hasOwnProperty('scan_quantity')) ? 'Multiple times' : 'Quantity') : 'Quantity');
                    setScanQuantity(edit ? (facility.scan_quantity ?? 1) : 1);
                    reset();
                }} color='warning' variant="outlined"
                    sx={{ boxShadow: 'none' }}>
                    {edit ? "Reset" : "Cancel"}
                </Button>
                <Button id='addFacility' onClick={addFacility} color='warning' variant="contained"
                    sx={{ boxShadow: 'none' }}
                    disabled={(watch('facilityName') && (!watch("isFree") ? (watch('amount') !== "" && watch('amount') >= 0) : true)) ? false : true}
                >
                    { edit ? "Save" : "Add" }
                </Button>
            </DialogActions>
        </Box>
    )
};

export default FacilityForm;
