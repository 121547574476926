import _ from "lodash"

export const getExtraChargesCosting = (actualModelPrice, gst_percent) => {
    const system_charges = 0.01 * (actualModelPrice);
    const wowsly_gst_on_system_charges = 0.18 * (system_charges);
    const total_system_charge = system_charges + wowsly_gst_on_system_charges;
    const payment_platform_fees = 0.02 * (actualModelPrice + total_system_charge +
        (gst_percent ? ((gst_percent / 100) * actualModelPrice) : 0));

    const charges = {
        systemFee: actualModelPrice ? _.round((total_system_charge), 2) : 0,
        razorPayFee: _.round(payment_platform_fees, 2),
        wowslyGst: _.round(wowsly_gst_on_system_charges, 2)
    }

    return charges;
}