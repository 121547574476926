import axios from "axios";
import ls from 'local-storage'
export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const token = ls.get('Token')
        const contentType = config.headers['content-type']
        config.headers = {
            ...config.headers,
            "Content-Type": !contentType && "application/json",
            "Authorization": `${token ? "Bearer " + token : null}`
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);