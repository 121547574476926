import { useSelector } from "react-redux";
import React, { useContext, useState } from "react";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import GuestBox from "./GuestBox";
import GuestPagination from "./GuestPagination";
import { EventGuestsContext } from "../../EventGuestContext";
import noGuests from '../../../../../../../../assets/NoGuests.webp'
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import AddMembersToEventConfirmationDialog from "./AddMembersToEventConfirmationDialog";

const GuestList = () => {
    const eventData = useSelector(eventDetails);
    const { guests } = useContext(EventGuestsContext);
    const [dialog, setDialog] = useState(false);
    const handleClick = () => {
        setDialog(true);
    }
    const handleClose = () => {
        setDialog(false);
    }
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    return (
        <>
            {guests?.length === 0 ?
                <Box sx={{ textAlign: 'center' }}>
                    {eventData?.group_id ?
                        <Button color='warning' variant="contained"
                            onClick={handleClick} sx={{ mt: 2 }}>Add all group members</Button>
                        : null
                    }
                    {dialog ? <AddMembersToEventConfirmationDialog handleClose={handleClose} /> : null}
                    <br />
                    <Box
                        component='img'
                        src={noGuests}
                        width={isSmallDevice ? '100%' : '30rem'}
                        height='25rem'
                    />
                </Box> :
                <>
                    <Grid
                        container
                        spacing={{ xs: 0, md: 3 }}
                        sx={{ px: '0.5rem', height: 'auto', maxHeight: '20rem', overflow: 'auto' }}
                    >
                        {(guests ?? Array.from(new Array(6))).map((g, index) => (
                            <GuestBox key={index} guestDetails={g} />
                        ))}
                    </Grid>
                    {guests ?
                        <GuestPagination /> :
                        null
                    }
                </>
            }
        </>
    )
};

export default GuestList;
