import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoginDialog from "../../../../../../components/LoginDialog";
import { eventDetails } from "../../../../../../redux/slices/eventDataSlice";
import { userStatus } from "../../../../../../redux/slices/userDataSlice";
import { getEventForms } from "../../../../../../services/wEvents/eventDetails";
import { getGuestDetails } from "../../../../../../services/wEvents/eventGuests";
import GuestRegistrationSection from "./components/GuestRegistrationSection";
import GuestTicketPayment from "./components/GuestTicketPayment";
import SelectEventTicket from "./components/SelectEventTicket";
import SuccessfulRegistration from "./components/SuccessfulRegistration";

export const GuestRegistrationContext = React.createContext({});

const EventRegistration = () => {
    const { commonEventTitle } = useParams();
    const isLoggedIn = useSelector(userStatus);
    const eventData = useSelector(eventDetails);

    const [activeSlide, setSlide] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [rForm, setRForm] = useState(null);
    const [tickets, setTickets] = useState(null);
    const [ticketsCount, setTicketCount] = useState({});
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [registrationData, setRegistrationData] = useState(null);
    const [paymentDone, setPaymentDone] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const handleSlideChange = (slideNum) => {
        setSlide(slideNum);
    };
    // console.log("payment details", paymentDetails);
    const mapSlideToComponent = {
        0: !formSubmitted ? <GuestRegistrationSection /> : <SuccessfulRegistration />, //Don't show form if already submitted
        1: (!eventData?.is_paid && tickets?.length === 0) ? <SuccessfulRegistration /> : <SelectEventTicket />,
        2: (paymentDetails?.total_amount_to_pay > 0) ? <GuestTicketPayment /> : <SuccessfulRegistration />,
    }

    const [loginDialog, setLoginDialog] = useState(false);
    const [guestData, setGuestData] = useState(null);
    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    useEffect(() => { // Dependency given of dialog state for updating register form details
        if (isLoggedIn || commonEventTitle) {
            getEventForms({ eventId: eventData?.id, commonEvent: commonEventTitle ? true : false }).then((res) => {
                console.log(res.data);
                setRForm(res.data.form[0]);
                setFormSubmitted(res.data.is_filled);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [loginDialog]);

    useEffect(() => { //Separate use-effect for stop re-rendering of dialog state without login
        if (!isLoggedIn && !commonEventTitle) {
            getGuestDetails({ eventId: eventData?.id, uuid: eventData?.guest_uuid }).then((result) => {
                setLoginDialog(true);
                setGuestData(result.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);


    return (
        <>
            {eventData?.has_registration ?
                <GuestRegistrationContext.Provider value={{
                    activeSlide, setSlide, handleSlideChange, ticketsCount, setTicketCount,
                    selectedTicket, setSelectedTicket, registrationData, setRegistrationData,
                    paymentDone, setPaymentDone, paymentDetails, setPaymentDetails,
                    rForm, setRForm, tickets, setTickets, setFormSubmitted
                }}>
                    <Box sx={{
                        m: '1rem auto !important',
                        p: { xs: '0.5rem', sm: '0.5rem 1.25rem' },
                        width: { xs: '90%', sm: '80%' },
                        textAlign: 'left',
                        borderRadius: '5px',
                        boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
                    }}>
                        {mapSlideToComponent[activeSlide]}
                    </Box>
                    {loginDialog ? <LoginDialog open={true}
                        handleClose={() => handleDialogClose('login')} guestData={guestData} /> : null}
                </GuestRegistrationContext.Provider>
                : null
            }
        </>
    )
};

export default EventRegistration;
