export const PAGE_PATHS = {

    //auth routes
    MOBILE_LOGIN: "mobile-login",
    OTP_INPUT: "verify-otp",
    USER_PROFILE: "user-details/:id",
    USER_ACCOUNT: "profile",

    ONBOARDING: "onboarding",

    DASHBOARD: "dashboard",
    PRICING: "pricing",
    PAYMENTS: "payments",
    BANK_DETAILS: "bank-details",

    CREATE_EVENT: "create-event",
    EVENT_DETAILS: "event-details/:id",
    GUEST_EVENT_DETAILS: "e/:guestId",
    PUBLIC_EVENT_DETAILS: "e/:commonEventId/:commonEventTitle",

    CREATE_GROUP: "create-group",
    GROUP_DETAILS: "group-details/:id",
    MEMBER_GROUP_DETAILS: "g/:memberId"
};
