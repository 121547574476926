import React from "react";
import { TextField, Toolbar } from "@mui/material";
import DialogBox from "../../../../../../../../components/DialogBox";

const AboutGroupContent = (desc) => {
    return (
        <>
            <TextField
                fullWidth
                multiline
                color="warning"
                value={desc}
                InputProps={{
                    readOnly: true
                }}
                sx={{ mt: 2 }}
            />
            <Toolbar />
        </>
    )
}


const AboutGroupDialog = ({ open, handleClose, desc }) => {
    return (
        <DialogBox open={open} handleClose={handleClose}
            title='Group Description' content={AboutGroupContent(desc)}
        />
    )
};

export default AboutGroupDialog;