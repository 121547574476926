import { createSlice } from "@reduxjs/toolkit";
import ls from 'local-storage'

export const userDataSlice = createSlice({
    name: 'User',
    initialState: {
        isLoggedIn: ls('Token') ? true : false,
        userData: ls('UserData') ? ls('UserData') : null
    },
    reducers: {
        change_login_status: (state, action) => {
            state.isLoggedIn = action.payload
        },
        change_user_details: (state, action) => {
            state.userData = action.payload
        },
    }
})

export const { change_login_status, change_user_details } = userDataSlice.actions

export const userStatus = (state) => state.User.isLoggedIn
export const userDetails = (state) => state.User.userData

export default userDataSlice.reducer