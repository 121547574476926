import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Button, Popover, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { deleteGroupMember, getGroupMembers } from "../../../../../../../../services/wGroups/groupMembers";
import { GroupMembersContext } from "../../GroupMembersContext";

const DeleteGroupMember = ({ memberId, anchorEl, setAnchorEl, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const groupData = useSelector(groupDetails);
    const { tabValue, totalMembers, setMembers } = useContext(GroupMembersContext)
    const openPopover = Boolean(anchorEl);
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleMemberDelete = (guest_id) => {
        setLoading(true);
        deleteGroupMember(groupData?.id, guest_id).then(() => {
            getGroupMembers({ groupId: groupData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'joined' }).then((res) => {
                setMembers(res.data.data);
                totalMembers.current -= 1;
                setLoading(false);
                handleClosePopover();
                handleClose();
                toast.success("Group member removed!!", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            })
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <>
            <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ fontWeight: 'bold', p: '8px 8px' }}>Delete Member</Typography>
                <Typography sx={{ px: 1 }}>Are you sure want to remove this member?</Typography>
                <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ my: 1, p: 1 }}>
                    <Button size='small' color='warning' variant='outlined' onClick={handleClosePopover}>Cancel</Button>
                    <LoadingButton
                        size="small"
                        color="warning"
                        variant="contained"
                        onClick={() => handleMemberDelete(memberId)}
                        loading={loading ? true : false}
                        startIcon={loading ? <SaveIcon /> : null}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Deleting...' : 'Yes'}
                    </LoadingButton>
                </Stack>
            </Popover>
        </>
    )
};

export default DeleteGroupMember;
