export const gstOptions = [
    {
        label: '0 %',
        value: 0,
    },
    {
        label: '5 %',
        value: 5,
    },
    {
        label: '8 %',
        value: 8,
    },
    {
        label: '18 %',
        value: 18,
    },
]