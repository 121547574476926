import { Avatar, Box, Checkbox, Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { memo, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { stringAvatar } from "../../../../../../../../utils/functions/muiAvatar";
import { GroupMembersContext } from "../../GroupMembersContext";
import MemberDialog from "./MemberDialog";

const MemberAvatar = memo(({ memberDetails }) => {
    return (
        <Avatar {...stringAvatar(memberDetails?.name?.trim())} />
    )
})

const MemberBox = ({ memberDetails }) => {
    const { memberId } = useParams();
    let [open, setOpen] = useState(false);
    const { tabValue } = useContext(GroupMembersContext);

    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    const { isCheckboxVisible, checkedMembers, handleToggle } = useContext(GroupMembersContext)

    return (
        <>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            p: 1,
                            ":hover": {
                                cursor: "pointer",
                                backgroundColor: "#F1F1F1"
                            }
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}
                            sx={{ flex: 1 }}
                            onClick={memberDetails ? handleOpen : null}
                        >
                            {memberDetails ?
                                <MemberAvatar memberDetails={memberDetails} />
                                : <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            }
                            {memberDetails ?
                                <Typography>{memberDetails?.name} {memberDetails.uuid === memberId ? "(You)" : null}</Typography>
                                : <Skeleton animation="wave" variant="rectangular" width='80%' height='2rem' />
                            }
                        </Stack>
                        {
                            (memberDetails?.is_subscription_expired && tabValue === 1) ?
                                <Chip
                                    size='small'
                                    color='error'
                                    label="Expired"
                                    sx={{ width: 'fit-content', fontSize: '0.65rem' }} />
                                : null
                        }
                        <Checkbox size="medium" color="warning"
                            onChange={handleToggle(memberDetails?.id)}
                            checked={checkedMembers.indexOf(memberDetails?.id) !== -1}
                            sx={{ display: isCheckboxVisible ? 'inline-flex' : 'none' }}
                        />
                    </Stack>
                </Box>
                {open ? //To stop multiple renders as num. of items in list
                    <MemberDialog memberDetails={memberDetails} handleClose={() => handleClose()} />
                    : null
                }
            </Grid>
        </>
    )
};

export default MemberBox;
