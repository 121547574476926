import "../../index.scss";
import "./verifyotp.scss";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Box, Typography, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OtpVerifyForm from "./components/OtpVerifyForm";
import mobileLogo from '../../../../assets/WowslyLogo.png';
import { PAGE_PATHS } from "../../../../utils/constants/page-path.constants";

function VerifyOtp() {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!state || !state.CountryCode || !state.MobileNumber) {
            toast.error("Please provide mobile number!!", {
                position: "top-right",
                theme: "dark"
            });
            navigate(`/${PAGE_PATHS.MOBILE_LOGIN}`);
        }
    }, []);

    return (
        <>
            <Stack
                sx={{
                    position: "relative",
                    backgroundColor: "white",
                    borderRadius: "25px",
                    width: {
                        xs: '80%',
                        sm: '60%',
                        md: '80%',
                        lg: '60%'
                    },
                    p: '1.25rem 1rem',
                    mt: "-2rem",
                    mx: 'auto',
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.25)',
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: {
                            xs: "-6.5rem",
                            sm: "-8rem",
                            md: "-75%",
                            lg: "-80%"
                        },
                        textAlign: "center",
                        color: "white"
                    }}
                >
                    <Box component='img'
                        sx={{
                            width: '3rem',
                            height: '3rem'
                        }}
                        alt="Wowsly Logo"
                        src={mobileLogo}
                    />
                    <Typography variant="h3">Enter OTP</Typography>
                    <Typography variant="h5" sx={{ mt: 1 }}>
                        Enter the OTP you received on +{state?.CountryCode}{" "}
                        {state?.MobileNumber}
                    </Typography>
                </Box>

                <Stack sx={{
                    alignItems: "center"
                }}>
                    <OtpVerifyForm userData={state} />
                </Stack>
            </Stack>
        </>
    );
}

export default VerifyOtp;
