import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { BACKEND_URL } from "../../../../../../../../utils/constants/apis.urls";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import { wrapString } from "../../../../../../../../utils/functions/wrapString";
import CopyProgramDialog from "./components/CopyProgramDialog";
import DeleteProgramDialog from "./components/DeleteProgramDialog";
import EditProgramDialog from "./components/EditProgramDialog";
import ProgramDetailDialog from "./components/ProgramDetailDialog";

const SubEventCard = ({ setItems, eventProgramDetails }) => {
    const eventData = useSelector(eventDetails);
    const isProgramMedia = eventProgramDetails?.media?.length > 0;
    let [dialogStates, setDialogStates] = useState({
        details: false,
        edit: false,
        delete: false,
        copy: false
    });
    const handleOpen = (operation) => {
        setDialogStates({ ...dialogStates, [operation]: true });
    }
    const handleClose = (operation) => {
        setDialogStates({ ...dialogStates, [operation]: false });
    }

    return (
        <>
            <Box sx={{
                width: '20rem',
                height: ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ? '12rem' : '8rem',
                borderRadius: '1rem',
                m: '8px 1.5rem 8px 8px',
                boxShadow: '0px 0px 9px #ccc',
                userSelect: "none",
                display: "inline-block",
                transition: 'transform 0.3s',
                ":hover": {
                    transform: 'scale(1.04)',
                }
            }}>
                <Stack direction='row' alignItems='center' spacing={3} sx={{
                    p: 2, ':hover': {
                        cursor: 'pointer',
                    }
                }}
                    onClick={() => handleOpen('details')}
                >
                    {isProgramMedia ? (
                        <Avatar src={`${eventProgramDetails?.media.slice(0)[0].path}`}
                            alt='Event logo'
                            variant="rounded"
                            sx={{ width: '4.5rem', height: '4.5rem', backgroundColor: '#F0F0F0' }} />
                    )
                        : null
                    }
                    <Stack spacing={0.5}>
                        <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>
                            {wrapString(eventProgramDetails?.title, isProgramMedia ? 20 : 30)}
                        </Typography>
                        <Typography sx={{ color: '#6F738B', fontSize: '13px' }}>
                            {eventProgramDetails?.is_online ? wrapString(eventProgramDetails?.live_url, isProgramMedia ? 25 : 40) :
                                `${wrapString(eventProgramDetails?.venue?.name, isProgramMedia ? 20 : 40)}`}
                        </Typography>
                        {
                            (eventProgramDetails?.end_datetime) ?
                                <Typography sx={{ color: '#6F738B', fontSize: '13px' }}>
                                    {
                                        moment(eventProgramDetails?.start_datetime).format('DD MMM YYYY') === moment(eventProgramDetails?.end_datetime).format('DD MMM YYYY') ?
                                            moment(eventProgramDetails?.start_datetime).format('DD MMM YYYY') :
                                            moment(eventProgramDetails?.start_datetime).format('DD MMM YYYY') + '-' + moment(eventProgramDetails?.end_datetime).format('DD MMM YYYY')
                                    }
                                </Typography>
                                :
                                <Typography sx={{ color: '#6F738B', fontSize: '13px' }}>
                                    {moment(eventProgramDetails?.start_datetime).format('dddd, Do MMM YYYY')}
                                </Typography>
                        }
                        {
                            eventProgramDetails?.end_datetime ?
                                <Typography sx={{ color: '#6F738B', fontSize: '13px' }}>
                                    {moment(eventProgramDetails?.start_datetime).format('h:mm A')} - {moment(eventProgramDetails?.end_datetime).format('h:mm A')}
                                </Typography>
                                :
                                <Typography sx={{ color: '#6F738B', fontSize: '13px' }}>
                                    {moment(eventProgramDetails?.start_datetime).format('h:mm A')}
                                </Typography>
                        }
                    </Stack>
                </Stack>
                {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                    <>
                        <Divider sx={{ my: 1 }} />
                        <Stack direction='row' justifyContent='center' alignItems='center' spacing={1} sx={{ height: '3rem' }}>
                            <IconButton onClick={() => handleOpen('edit')}><EditIcon sx={{ color: '#1F274A' }} /></IconButton>
                            <IconButton onClick={() => handleOpen('delete')}><DeleteIcon sx={{ color: '#1F274A' }} /></IconButton>
                            <IconButton onClick={() => handleOpen('copy')}><ContentCopyIcon sx={{ color: '#1F274A' }} /></IconButton>
                        </Stack>
                    </>
                    : null
                }
            </Box>

            <EditProgramDialog setItems={setItems} eventProgramDetails={eventProgramDetails}
                open={dialogStates.edit} handleClose={() => handleClose('edit')} />
            <DeleteProgramDialog setItems={setItems} eventProgramDetails={eventProgramDetails}
                open={dialogStates.delete} handleClose={() => handleClose('delete')} />
            <CopyProgramDialog setItems={setItems} eventProgramDetails={eventProgramDetails}
                open={dialogStates.copy} handleClose={() => handleClose('copy')} />
            <ProgramDetailDialog eventProgramDetails={eventProgramDetails}
                open={dialogStates.details} handleClose={() => handleClose('details')} />
        </>
    )
};

export default SubEventCard;
