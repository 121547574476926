export const editEventMemberForm = {
    "method": "PUT",
    "title": "Key member edit form",
    "description": "Event/group key member edit form",
    "data": [
        {
            "type": "header",
            "subtype": "h1",
            "label": "Edit member",
            "access": false
        },
        {
            "type": "image",
            "required": false,
            "label": "Upload Image",
            "description": "Multiple images Upload",
            "placeholder": "Upload Image",
            "className": "form-control",
            "name": "photo",
            "access": false,
        },
        {
            "type": "text",
            "required": true,
            "label": "Person Name",
            "placeholder": "Person Name",
            "className": "form-control",
            "name": "title",
            "access": false,
            "subtype": "text",
            "value": ""
        },
        {
            "type": "select",
            "required": true,
            "label": "Type",
            "description": "type",
            "placeholder": "type",
            "className": "form-control",
            "name": "type",
            "access": false,
            "multiple": false,
            "values": [
                {
                    "label": "Family",
                    "value": "family",
                    "selected": true
                },
                {
                    "label": "Organizer",
                    "value": "organizer",
                    "selected": false
                },
                {
                    "label": "Speaker",
                    "value": "speaker",
                    "selected": false
                },
                {
                    "label": "Sponsor",
                    "value": "sponsor",
                    "selected": false
                },
                {
                    "label": "Team",
                    "value": "team",
                    "selected": false
                },
                {
                    "label": "Commitee",
                    "value": "commitee",
                    "selected": false
                },
                {
                    "label": "Member",
                    "value": "member",
                    "selected": false
                }
            ]
        },
        {
            "type": "text",
            "required": false,
            "label": "Designation",
            "placeholder": "Designation",
            "className": "form-control",
            "name": "designation",
            "access": false,
            "subtype": "text",
            "value": ""
        },
        {
            "type": "text",
            "required": false,
            "label": "Role/Relation",
            "placeholder": "Role/Relation",
            "className": "form-control",
            "name": "relation",
            "access": false,
            "subtype": "text",
            "value": ""
        },
        {
            "type": "textarea",
            "required": false,
            "label": "About Person",
            "description": "About Person",
            "placeholder": "About Person",
            "className": "form-control",
            "name": "description",
            "access": false,
            "subtype": "textarea",
            "maxlength": 250,
            "rows": 5,
            "value": ""
        },
        {
            "type": "text",
            "required": false,
            "label": "Contact Number",
            "placeholder": "Contact Number",
            "className": "form-control",
            "name": "contacts[personal]",
            "access": false,
            "subtype": "text",
            "value": ""
        },
        {
            "type": "socials",
            "required": false,
            "label": "Other Contact",
            "description": "You can only add maximum 3 contact details in the field down below",
            "placeholder": "Other Contact",
            "className": "form-control",
            "name": "socials",
            "access": false,
            "multiple": true,
            "values": [
                {
                    "label": "Email",
                    "value": "email",
                    "selected": false,
                    "icon": "images/social-icons/ic_mail.png"
                },
                {
                    "label": "Twitter",
                    "value": "twitter",
                    "selected": false,
                    "icon": "images/social-icons/ic_twii.png"
                },
                {
                    "label": "Linkdin",
                    "value": "linkdin",
                    "selected": false,
                    "icon": "images/social-icons/ic_face.png"
                },
                {
                    "label": "Facebook",
                    "value": "facebook",
                    "selected": false,
                    "icon": "images/social-icons/ic_face.png"
                },
                {
                    "label": "Instagram",
                    "value": "instagram",
                    "selected": false,
                    "icon": "images/social-icons/ic_instaa.png"
                },
            ]
        },
        {
            "type": "switch",
            "required": true,
            "label": "Show default user photo if no image?",
            "description": "Show default user photo if no image?",
            "placeholder": "Use limit",
            "className": "form-control",
            "name": "show_default_user_avatar"
        },
        {
            "type": "button",
            "subtype": "submit",
            "label": "Submit",
            "className": "fa fa-btn fa-floppy-o btn-primary btn",
            "name": "button",
            "access": false,
            "style": "primary"
        }
    ]
}