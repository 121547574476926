import { Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useContext } from "react";
import { GroupMembersContext } from "../../GroupMembersContext";
import GroupMemberInvitation from "../GroupMemberInvitation";
import AddMember from "./components/AddDeleteOptions/AddMember";
import BulkDeleteGroupMembers from "./components/AddDeleteOptions/BulkDeleteGroupMembers";
import UploadMembersCSV from "./components/AddDeleteOptions/UploadMembersCSV";
import InvitationOptions from "./components/InvitationOptions";
import { useSelector } from 'react-redux';
import { groupDetails } from '../../../../../../../../redux/slices/groupDetailSlice';
import { ALL_PERMISSION_ROLES } from '../../../../../../../../utils/constants/roles';

const GroupMemberHeader = () => {
    const groupData = useSelector(groupDetails);
    const { totalMembers } = useContext(GroupMembersContext)
    const isBigDevices = useMediaQuery("(min-width:600px)"); //Tablets or bigger

    return <>
        <Stack sx={{ m: 1 }} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography>Group Members ({totalMembers.current ?? 0})</Typography>

            {ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) ?
                <Stack direction='row' spacing={2} alignItems='center'>
                    {(isBigDevices || totalMembers.current === 0) ? <AddMember /> : null}
                    <UploadMembersCSV />
                    {totalMembers.current > 0 ?
                        <>
                            <BulkDeleteGroupMembers />
                            {/* As we are showing options of cancel and invite in header only in big devices */}
                            {isBigDevices ? <GroupMemberInvitation /> : null}
                            <InvitationOptions />
                        </> : null
                    }
                </Stack>
                : null
            }
        </Stack>
    </>
};

export default GroupMemberHeader;
