import { Box, Stack, Tab, Tabs, TextField, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventGuests } from "../../../../../../../../services/wEvents/eventGuests";
import { EventGuestsContext } from "../../EventGuestContext";
import EventGuestInvite from "../GuestInvitation";
import AddGuest from "./components/AddDeleteOptions/AddGuest";
import BulkDeleteEventGuests from "./components/AddDeleteOptions/BulkDeleteEventGuests";
import UploadGuestCSV from "./components/AddDeleteOptions/UploadGuestCSV";
import InvitationOptions from "./components/InvitationOptions";

const GuestHeader = ({ handleTabChange }) => {
    const { searchedGuest, setSearchedGuest, tabValue, guests, 
        setGuests, totalGuests, per_page_guests } = useContext(EventGuestsContext);
    const eventData = useSelector(eventDetails);
    const [value] = useDebounce(searchedGuest, 1000);
    const isBigDevices = useMediaQuery("(min-width:600px)"); //Tablets or bigger
    const tabletOrSmallerDevices = useMediaQuery("(max-width:900px)");
    const guestTabs = ['Invited', 'Registered'];

    useEffect(() => {
        getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered', searchedGuest: value }).then((res) => {
            totalGuests.current = res.data.meta.total;
            per_page_guests.current = res.data.meta.per_page;
            setGuests(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [value]);

    const handleSearch = (e) => {
        setSearchedGuest(e.target.value);
    }

    return <>
        <Stack sx={{ m: 1 }} direction='row' justifyContent='space-between' alignItems='center'>
            {guests ?
                <TextField
                    size={tabletOrSmallerDevices ? 'small' : 'default'}
                    color="warning"
                    variant="outlined"
                    autoComplete="off"
                    onChange={handleSearch}
                    sx={{ width: { xs: '70%', sm: '25%' }, mr: 2 }}
                    label={tabletOrSmallerDevices ? 'Search' : ((searchedGuest || totalGuests.current === null) ?
                        'Search guests' : `Search from ${totalGuests.current} guests`)}
                    InputProps={{
                        className: "MuiAutocomplete-inputRoot",
                        type: "search",
                    }}
                />
                : <Box sx={{
                    width: { xs: '70%', sm: '25%' },
                    display: { sm: 'none' }
                }} /> // To display header options in right side of screen in small devices
            }
            {
                isBigDevices ?
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor=""
                        sx={{
                            border: '2px solid #FF8359',
                            borderRadius: '0.7rem',
                            minHeight: { xs: '1rem', md: '2rem', lg: '2.5rem' },
                            minWidth: '30%',
                            '.Mui-selected': {
                                fontWeight: "bold !important",
                                color: '#FFFFFF !important',
                                backgroundColor: '#FF8359',
                                opacity: '1 !important'
                            },
                        }}>
                        {guestTabs.map((tab, index) => (
                            <Tab key={index} label={tab} sx={{
                                color: '#FF8359',
                                minHeight: { xs: '1rem', md: '2rem', lg: '2.5rem' },
                                textTransform: 'none',
                                width: '50%'
                            }} />
                        )
                        )}
                    </Tabs> :
                    null
            }
            <Stack direction='row' spacing={2} alignItems='center'>
                {
                    (eventData?.current_user_role !== null && eventData?.current_user_role === 'manager') ?
                        null :
                        <>
                            {(isBigDevices || totalGuests.current === 0) ? <AddGuest /> : null}
                            <UploadGuestCSV />
                            {totalGuests.current > 0 ?
                                <>
                                    <BulkDeleteEventGuests />
                                    {/* As we are showing options of cancel and invite in header only in big devices */}
                                    {isBigDevices ? <EventGuestInvite /> : null}
                                    <InvitationOptions />
                                </> : null
                            }
                        </>
                }
            </Stack>
        </Stack>
    </>
};

export default GuestHeader;
