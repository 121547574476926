import { toast } from "react-toastify";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from "react-hook-form";
import {
    Stack, DialogActions, TextField, DialogContent, DialogTitle, Dialog, IconButton,
    Typography, FormControl, RadioGroup, FormControlLabel, Radio, Switch, FormHelperText, Skeleton, Box
}
    from "@mui/material";
import { manageRsvpAnswerPayload } from "../../../../../../../../utils/functions/managePayload";
import { submitEventRsvpAnswers } from "../../../../../../../../services/wEvents/eventDetails";
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';

const DoRsvpDialog = ({ oneGuestRsvp, setOneGuestRsvp, rsvpGiven, setTotalGuests, handleClose }) => {
    const eventData = useSelector(eventDetails);
    const [loader, setLoader] = useState(false);
    const {
        handleSubmit, register, control, formState: { errors }
    } = useForm();

    const mySubmit = (data) => {
        setLoader(true);
        const payload = manageRsvpAnswerPayload(data);
        submitEventRsvpAnswers(eventData?.id, payload).then((res) => {
            setOneGuestRsvp(res.data.data);
            setLoader(false);
            if (rsvpGiven.current) {
                toast.success("Event RSVP updated", {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                toast.success("Event RSVP submitted", {
                    position: "top-right",
                    theme: "dark"
                });
                rsvpGiven.current = 1;
                setTotalGuests((prev) => prev + 1);
            }
            handleClose();
        }).catch((err) => {
            console.log(err);
            setLoader(false);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            })
            handleClose();
        })
    };

    const renderItemView = (item) => {
        switch (item.type) {
            case 'text':
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            error={errors[item.id] ? true : false}
                            helperText={
                                errors[item.id] ? 'This is required field' : null
                            }
                            defaultValue={item.answer ?? null}
                            {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                        />
                    </>
                )
            case 'textarea':
                return (
                    <>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                            color="warning"
                            label={item.question}
                            error={errors[item.id] ? true : false}
                            helperText={
                                errors[item.id] ? 'This is required field' : null
                            }
                            defaultValue={item.answer ?? null}
                            {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                        />
                    </>
                );
            case 'switch':
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                {item.question}
                            </Typography>
                            <Switch
                                color='warning'
                                defaultChecked={item.answer ? JSON.parse(item.answer) : null}
                                {...register(`${item.id}`)}
                            />
                        </Stack>
                    </>
                )
            case 'radio':
                return (
                    <>
                        <FormControl error={(errors[item.id]) ? true : false}>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                            <Controller
                                rules={{ required: true }}
                                name={`${item.id}`}
                                control={control}
                                defaultValue={item.answer ?? null}
                                render={({ field }) => (
                                    <RadioGroup row {...field}>
                                        {item.options.map((option, i) => (
                                            <FormControlLabel key={i} value={option}
                                                label={option} control={<Radio color="warning" />} />
                                        ))}
                                    </RadioGroup>
                                )}
                            />
                            {errors[item.id] ? <FormHelperText>Please choose one option</FormHelperText> : null}
                        </FormControl>
                    </>
                )
            default:
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                        />
                    </>
                );
        }
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{ sx: { width: { xs: '90%', md: '60%' }, m: { xs: 0 } } }}
        >
            <DialogTitle>
                <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                    <CloseIcon fontSize='medium' />
                </IconButton>
                <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>RSVP</Typography>
            </DialogTitle>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={true} sx={{ p: { xs: '1rem', md: '1rem 1.5rem' } }}>
                    <Stack spacing={2}>
                        {Object.keys(oneGuestRsvp).length > 0 ?
                            Object.values(oneGuestRsvp)?.map((item, i) => (
                                <React.Fragment key={i}>
                                    {renderItemView(item)}
                                </React.Fragment>
                            ))
                            : Array.from(new Array(3))?.map((a, i) => (
                                <Box key={i}>
                                    <Skeleton width='95%' height='2rem' />
                                    <Skeleton width='65%' height='1.2rem' />
                                </Box>
                            ))
                        }
                    </Stack>
                </DialogContent>

                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {loader ? 'Submitting...' : 'Submit'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    )
};

export default DoRsvpDialog;
