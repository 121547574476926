import React from "react";
import { Box, Button, Stack } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function ImageUpload({ register, selectedImage, setSelectedImage }) {
    const { onChange, ...params } = register("pic");

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: "8rem",
                    minHeight: "8rem",
                    mx: 'auto',
                    mt: { xs: "-5rem", sm: "-4rem" },
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.25)'
                }}
            >
                <Stack
                    sx={{
                        m: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "6rem",
                        height: "6rem"
                    }}
                >
                    {selectedImage ? (
                        <img
                            src={URL.createObjectURL(selectedImage)}
                            className="profile-img-preview"
                        />
                    ) : (
                        <AccountCircleIcon sx={{ fontSize: "4rem" }} />
                    )}
                </Stack>
                <Button
                    variant="contained"
                    component="label"
                    sx={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        borderTopLeftRadius: "1rem",
                        borderBottomRightRadius: "1rem",
                        minWidth: "0.2rem !important",
                        height: "2rem",
                        backgroundColor: "#FF8359"
                    }}
                >
                    +
                    <input
                        hidden
                        {...params}
                        accept="image/png, image/jpg, image/jpeg"
                        type="file"
                        onChange={(event) => {
                            setSelectedImage(event.target.files[0]);
                            onChange(event);
                        }}
                    />
                </Button>
            </Box>
        </>
    );
}

export default ImageUpload;
