import React, { useState } from "react";
import { ListItemText, ListItemAvatar, Avatar, ListItem, List, DialogActions, Button, Divider, Box, Stack, useMediaQuery, Typography } from "@mui/material";
import DialogBox from "../../../../../../../../components/DialogBox";
import OneGuestRsvpView from "./OneGuestRsvpView";
import { downloadRsvp } from "../../../../../../../../services/wEvents/eventDetails";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from "@mui/lab";
import NoRepliesGif from "../../../../../../../../assets/no-replies.gif";

const SetupGoingListContent = (guests, handleClose) => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const [loading, setLoading] = useState(false);
    const [activeGuest, setActiveGuest] = useState(null);
    const eventData = useSelector(eventDetails);

    const handleDownload = () => {
        setLoading(true);
        downloadRsvp({ eventId: eventData?.id }).then((res) => {
            console.log(res.data);
            const fileBlob = new Blob([res.data], { type: 'text/csv' });
            const downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            const fileUrl = URL.createObjectURL(fileBlob);

            // Create a link to download the file
            downloadLink.href = fileUrl;
            downloadLink.download = "RSVPResponse.csv";
            // Add the link to the DOM and click it
            downloadLink.click();
            setLoading(false);

            // Clean up the URL object
            URL.revokeObjectURL(fileUrl);
        })
    }

    return (
        <>
            {activeGuest ?
                <>
                    <OneGuestRsvpView activeGuest={activeGuest} setActiveGuest={setActiveGuest} />
                </> :
                <>
                    <Stack sx={{ mt: 1 }} spacing={2}>
                        {guests.length > 0 ?
                            <>
                                <Box sx={{ textAlign: 'center' }}>
                                    <LoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<DownloadIcon />}
                                        variant="outlined"
                                        color='warning'
                                        sx={{
                                            boxShadow: 'none', width: '50%'
                                        }}
                                        onClick={handleDownload}
                                    >
                                        Download RSVP
                                    </LoadingButton>
                                </Box>
                                <List sx={{ width: '100%', bgcolor: 'background.paper', pt: 0 }}>
                                    {guests?.map((guest, index) => (
                                        <ListItem key={index} onClick={() => setActiveGuest(guest)}
                                            sx={{
                                                ":hover": {
                                                    cursor: 'pointer',
                                                    backgroundColor: '#F1F1F1',
                                                    transition: '0.5s'
                                                }
                                            }}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ backgroundColor: '#FFEBE4' }}>
                                                    {/* <CalendarMonthIcon color='warning' /> */}
                                                </Avatar>
                                            </ListItemAvatar>
                                            {
                                                guest.user_name === null ?
                                                    <ListItemText primary={`+${guest.dialing_code} ${guest.mobile}`} /> :
                                                    <ListItemText primary={guest.user_name} secondary={`+${guest.dialing_code} ${guest.mobile}`} />
                                            }
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                            : <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Box
                                    component="img"
                                    src={NoRepliesGif}
                                    width={isSmallDevice ? "90%" : "55% !important"}
                                    height={isSmallDevice ? "15rem" : "17rem"}
                                />
                                <Typography>No RSVPs</Typography>
                            </Stack>
                        }
                    </Stack>
                </>
            }
            <Divider sx={{ pt: 2 }} />

            <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                {activeGuest ?
                    <Button color='warning' variant="outlined" sx={{
                        boxShadow: 'none'
                    }} onClick={() => setActiveGuest(null)}>
                        Done
                    </Button>
                    :
                    <Button color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }} onClick={handleClose}>
                        Done
                    </Button>
                }
            </DialogActions>
        </>
    )
};

const GoingListDialog = ({ guests, handleClose }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Guests coming to event' content={SetupGoingListContent(guests, handleClose)}
        />
    )
};
export default GoingListDialog;
