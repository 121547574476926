export const eventProgramForm = {
  "title": "Create program form",
  "description": "Event program add form",
  "slug": "event-program",
  "method": "POST",
  "data": [
    {
      "type": "header",
      "subtype": "h1",
      "label": "Create Program",
      "access": false
    },
    // {
    //   "type": "images",
    //   "required": false,
    //   "label": "Program image",
    //   "description": "Multiple images Upload",
    //   "placeholder": "Add resources",
    //   "className": "form-control",
    //   "name": "file",
    //   "access": false,
    //   // "max_limit": "3",
    //   // "multiple": true
    // },
    {
      "type": "image",
      "required": false,
      "label": "Program image",
      "placeholder": "Upload Image",
      "className": "form-control",
      "name": "file",
      "access": false,
    },
    {
      "type": "text",
      "required": true,
      "label": "Name",
      "placeholder": "Name",
      "className": "form-control",
      "name": "title",
      "access": false,
      "subtype": "text",
      "value": ""
    },
    {
      "type": "datetime",
      "required": true,
      "label": "Start Date & Time",
      "placeholder": "dd/mm/yyyy",
      "className": "datetime form-control",
      "name": "start_datetime",
      "access": false,
      "value": ""
    },
    {
      "type": "datetime",
      "required": false,
      "label": "End Date & Time",
      "placeholder": "dd/mm/yyyy",
      "className": "datetime form-control",
      "name": "end_datetime",
      "access": false,
      "value": ""
    },
    {
      "name": "timezone",
      "type": "timezone",
      "label": "Program timezone",
      "required": true,
      "className": "timezone",
      "placeholder": "Asia/Kolkata",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "online-location",
      "required": true,
      "label": "Program link",
      "name": "live_url",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": true,
      "label": "Event Area",
      "name": "venue[name]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": true,
      "label": "Venue address",
      "name": "venue[address]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": true,
      "label": "City",
      "name": "venue[city]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": true,
      "label": "Zip",
      "name": "venue[zip]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": true,
      "label": "State",
      "name": "venue[state]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": true,
      "label": "Country",
      "name": "venue[country]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": false,
      "label": "Landmark",
      "name": "venue[landmark]",
      "value": ""
    },
    {
      "type": "text",
      "subtype": "physical-location",
      "required": false,
      "label": "Google map link",
      "name": "venue[google_map_link]",
      "value": ""
    },
    {
      "type": "textarea",
      "required": false,
      "label": "About program",
      "description": "About group",
      "placeholder": "About group",
      "className": "form-control",
      "name": "description",
      "access": false,
      "subtype": "textarea",
      "maxlength": 250,
      "rows": 5,
      "value": ""
    },
    {
      "type": "textarea",
      "required": false,
      "label": "Instructions",
      "description": "Instructions",
      "placeholder": "Instructions",
      "className": "form-control",
      "name": "instructions",
      "access": false,
      "subtype": "textarea",
      "maxlength": 250,
      "rows": 2,
      "value": ""
    },
    {
      "type": "text",
      "required": false,
      "label": "Dress code",
      "placeholder": "Dress code",
      "className": "form-control",
      "name": "dress_code",
      "access": false,
      "subtype": "text",
      "value": ""
    },
    {
      "type": "text",
      "required": false,
      "label": "Theme color",
      "placeholder": "Theme color",
      "className": "form-control",
      "name": "theme_color",
      "access": false,
      "subtype": "text",
      "value": ""
    },
    {
      "type": "button",
      "subtype": "submit",
      "label": "Submit",
      "className": "fa fa-btn fa-floppy-o btn-primary btn",
      "name": "submit",
      "access": false,
      "style": "primary"
    }
  ]
}