import CallIcon from '@mui/icons-material/Call';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CopyMemberDialog from './components/CopyMemberDialog';
import EditMemberDialog from "./components/EditMemberDialog";
import MemberDetailDialog from './components/MemberDetailDialog';
import DeleteMemberDialog from "./components/DeleteMemberDialog";
import { wrapString } from "../../../../../../../../utils/functions/wrapString";
import { BACKEND_URL } from "../../../../../../../../utils/constants/apis.urls";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";

const KeyMemberCard = ({ setItems, memberDetails }) => {
    const eventData = useSelector(eventDetails);
    let [dialogStates, setDialogStates] = useState({
        details: false,
        edit: false,
        delete: false,
        copy: false,
    });
    const handleOpen = (operation) => {
        setDialogStates({ ...dialogStates, [operation]: true });
    }
    const handleClose = (operation) => {
        setDialogStates({ ...dialogStates, [operation]: false });
    }

    return (
        <>
            <Box sx={{
                m: '8px 1.5rem 8px 8px',
                borderRadius: '0.5rem',
                boxShadow: '0px 0px 10px #ccc',
                width: '11rem',
                height: ALL_PERMISSION_ROLES.includes(eventData?.current_user_role)
                    ? ((memberDetails?.photo || memberDetails?.show_default_user_avatar) ? '17rem' : '9.5rem')
                    : ((memberDetails?.photo || memberDetails?.show_default_user_avatar) ? '14rem' : '6.5rem'),
                userSelect: "none",
                display: "inline-block",
                transition: 'transform 0.3s',
                ":hover": {
                    transform: 'scale(1.02)'
                }
            }}>
                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', p: 0.5 }}>
                    {(memberDetails?.photo) ?
                        <Box component='img'
                            src={`${memberDetails?.photo}`}
                            alt='Member Pic'
                            sx={{
                                width: '100%', height: '120px',
                                objectFit: 'cover',
                                borderRadius: '8px', backgroundColor: '#F0F0F0',
                                ':hover': {
                                    cursor: 'grab',
                                }
                            }}
                            onClick={() => handleOpen('details')}
                        /> :
                        <>
                            {memberDetails?.show_default_user_avatar ?
                                <Box sx={{ width: '100%', height: '120px', borderRadius: '8px', backgroundColor: '#F0F0F0' }}>
                                    <Avatar
                                        sx={{
                                            width: '3rem',
                                            height: '3rem',
                                            position: 'absolute',
                                            top: '45%',
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                            ':hover': {
                                                cursor: 'grab',
                                            }
                                        }}
                                        onClick={() => handleOpen('details')}
                                    />
                                </Box>
                                : null
                            }
                        </>
                    }
                    {memberDetails?.contacts ? <>
                        <IconButton sx={{
                            p: 0,
                            position: 'absolute',
                            left: '50%',
                            bottom: '-10%',
                            transform: 'translate(-50%, 0%)'
                        }}>
                            {memberDetails?.photo || memberDetails?.show_default_user_avatar ?
                                <Avatar sx={{ backgroundColor: '#FF8359', width: '2rem', height: '2rem' }}>
                                    <CallIcon fontSize='small' />
                                </Avatar>
                                : null
                            }
                        </IconButton>
                    </> : null}
                </Box>
                <Stack justifyContent='center' alignItems='center' spacing={0.5}
                    sx={{
                        height: '4.5rem',
                        pt: memberDetails?.contacts ?
                            (memberDetails?.photo || memberDetails?.show_default_user_avatar ? 3 : 0) : 1,
                        ':hover': {
                            cursor: 'grab',
                        }
                    }}
                    onClick={() => handleOpen('details')}
                >
                    <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{wrapString(memberDetails?.title, 13)}</Typography>
                    <Typography sx={{ color: '#6F738B' }}>{_.capitalize(memberDetails?.type)}</Typography>
                </Stack>
                {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                    <>
                        <Divider sx={{ my: 1 }} />
                        <Stack direction='row' justifyContent='center' sx={{ pb: 1 }} spacing={1}>
                            <IconButton onClick={() => handleOpen('edit')}><EditIcon sx={{ color: '#1F274A' }} /></IconButton>
                            <IconButton onClick={() => handleOpen('delete')}><DeleteIcon sx={{ color: '#1F274A' }} /></IconButton>
                            <IconButton onClick={() => handleOpen('copy')}><ContentCopyIcon sx={{ color: '#1F274A' }} /></IconButton>
                        </Stack>
                    </> : null
                }
            </Box>

            {dialogStates.edit ? <EditMemberDialog setItems={setItems} memberDetails={memberDetails}
                handleClose={() => handleClose('edit')} /> : null}
            {dialogStates.delete ? <DeleteMemberDialog setItems={setItems} memberDetails={memberDetails}
                handleClose={() => handleClose('delete')} /> : null}
            {dialogStates.copy ? <CopyMemberDialog setItems={setItems} memberDetails={memberDetails}
                handleClose={() => handleClose('copy')} /> : null}
            {dialogStates.details ? <MemberDetailDialog memberDetails={memberDetails}
                handleClose={() => handleClose('details')} /> : null}
        </>
    )
};

export default KeyMemberCard;
