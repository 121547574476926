import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Badge, IconButton } from '@mui/material';
import React, { useContext, useState } from "react";
import { GroupMembersContext } from "../../../../GroupMembersContext";
import BulkDeleteGroupMembersDialog from "./BulkDeleteGroupMembersDialog";

const BulkDeleteGroupMembers = () => {
    const { isCheckboxVisible, setVisible, checkedMembers } = useContext(GroupMembersContext);

    let [open, setOpen] = useState(false);
    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    return (
        <>
            {isCheckboxVisible ?
                (<>
                    <IconButton disabled={checkedMembers?.length === 0}
                        sx={{ p: '0rem 0.4rem 0rem 0rem', opacity: checkedMembers?.length === 0 ? 0.5 : 1 }}
                        onClick={handleOpen}
                    >
                        <Badge color="error" badgeContent={checkedMembers?.length > 0 ? checkedMembers?.length : null}>
                            <DeleteIcon color='warning' sx={{
                                width: '2rem', height: '1.5rem'
                            }} />
                        </Badge>
                    </IconButton>
                    {open ? <BulkDeleteGroupMembersDialog handleClose={handleClose} /> : null}
                </>
                ) :
                <IconButton sx={{ p: 0 }} onClick={() => setVisible(true)}>
                    <EditIcon color='warning' sx={{
                        width: '2rem', height: '1.5rem'
                    }} />
                </IconButton>
            }
        </>
    )
};

export default BulkDeleteGroupMembers;
