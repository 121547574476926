import { axiosInstance } from "../../config/interceptor";
import { GROUP_DOCS_API } from "../../utils/constants/apis.urls";

export const getGroupDocuments = (groupId) => {
    return axiosInstance.get(`${GROUP_DOCS_API.replace(':group_id', groupId)}`);
};

export const addGroupDocument = (payload, groupId) => {
    return axiosInstance.post(`${GROUP_DOCS_API.replace(':group_id', groupId)}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const editGroupDocument = (payload, groupId, documentId) => {
    return axiosInstance.post(`${GROUP_DOCS_API.replace(':group_id', groupId)}/${documentId}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};