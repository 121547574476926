import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { groupDetails } from '../../../../../../../../../../redux/slices/groupDetailSlice';
import { deleteMembersInvitation } from '../../../../../../../../../../services/wGroups/groupMembers';

const DeleteMemberInvitationDialog = ({ setData, invitationId, isInvitation, handleDialogClose }) => {
    const groupData = useSelector(groupDetails);
    const [loading, setLoading] = useState(false);
    const handleDelete = () => {
        setLoading(true);
        deleteMembersInvitation({ groupId: groupData?.id, invitationId: invitationId }).then((res) => {
            toast.success(`${isInvitation ? 'Invitation' : 'Reminder'} deleted successfully`, {
                position: "top-right",
                theme: "dark"
            });
            setData((prevData) => {
                let newData = { ...prevData };
                if (isInvitation) {
                    newData.invitations = prevData.invitations.filter((d) => d.id !== invitationId);
                } else {
                    newData.reminders = prevData.reminders.filter((d) => d.id !== invitationId);
                }
                return newData;
            });
            setLoading(false);
            handleDialogClose();
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <>
            <Dialog
                fullWidth
                open
                onClose={handleDialogClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%', lg: '30%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleDialogClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        Delete {isInvitation ? 'Invitation' : 'Reminder'}
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure you want to delete this {isInvitation ? 'Invitation' : 'Reminder'}?</Typography>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={() => handleDialogClose()}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        loading={loading}
                        variant="contained"
                        onClick={handleDelete}
                        startIcon={loading ? <SaveIcon /> : ''}
                        loadingPosition={loading ? "start" : null}
                    >
                        Ok
                    </LoadingButton>
                </DialogActions>
            </Dialog>

        </>
    )
};

export default DeleteMemberInvitationDialog;
