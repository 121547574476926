import { Box, Grid, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import groupGif from '../../../../assets/group-pic.gif';
import GradientBtn from "../../../../components/GradientBtn";
import { userDetails } from "../../../../redux/slices/userDataSlice";
import { getGroups } from "../../../../services/wGroups/group";
import { PAGE_PATHS } from "../../../../utils/constants/page-path.constants";
import GroupCard from "./components/GroupCard";
import SearchGroup from "./components/SearchGroup";
import GroupsPagination from "./components/GroupsPagination";

const AllGroups = () => {
    const userData = useSelector(userDetails);
    const [groups, setGroups] = useState(null);
    const currentUserGroupCount = useRef(4); // Kept here because don't want to show create button for greater than 3 groups made by user
    console.log(currentUserGroupCount)
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const groupsCount = useRef(0);
    const perPageGroups = useRef(0);

    useEffect(() => {
        //Fetch all groups
        getGroups({ page: 1 }).then((res) => {
            setGroups(res?.data.data);
            currentUserGroupCount.current = res?.data.data.filter((g) => g.owner.id === userData.id).length
            groupsCount.current = res.data.meta.total
            perPageGroups.current = res.data.meta.per_page
        }).catch((err) => {
            console.log('error@@@@@@@@', err);
            toast.error("Something went wrong in fetching groups", {
                position: "top-right",
                theme: "dark"
            });
        })
    }, []);

    return (
        <>
            {groups?.length !== 0 ?
                <Grid container sx={{ px: { xs: 3, sm: 3, md: 2, lg: 1, xl: 3 }, mb: { xs: 2.5, sm: 2, lg: 3 }, mt: 2 }}>
                    <Grid item xs={12} sm={5} md={4}>
                        <SearchGroup />
                    </Grid>
                    <Grid item xs={0} sm={3} md={4}></Grid>
                    {(currentUserGroupCount.current < 3) ?
                        <Grid item xs={0} sm={4} md={4} sx={{ textAlign: 'right' }}>
                            <GradientBtn label='Create Organization' redirectPath={`${PAGE_PATHS.CREATE_GROUP}`} />
                        </Grid>
                        : null
                    }
                </Grid>
                : null
            }

            {
                groups?.length === 0 ?
                    <Stack justifyContent='center' alignItems='center' spacing={2}>
                        <Box component='img'
                            src={groupGif}
                            width={isSmallDevice ? '90%' : '27rem'}
                            height={isSmallDevice ? '15rem' : '20rem'}
                        />
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Welcome {userData?.full_name}!</Typography>
                        <Typography sx={{
                            width: { xs: "95%", lg: '90%' },
                            px: { md: 3 },
                            pb: { xs: 8, sm: 0 },
                            textAlign: 'center'
                        }}>
                            With Wowsly, you can easily create an organization of your Association, NGO, Apartment or Business groups.
                            Invite members to join, and manage all of your organization's events in one place. Our user-friendly platform makes it easy to stay organized and keep everyone up-to-date on upcoming events.
                            Let's create a organization that brings everyone together!
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <GradientBtn label='Create Organization' redirectPath={`${PAGE_PATHS.CREATE_GROUP}`} />
                        </Box>
                    </Stack> :
                    <>
                        <Grid container rowSpacing={{ xs: 4, md: 5, lg: 6 }} columnSpacing={2} pb={3}>
                            {(groups ?? Array.from(new Array(3))).map((g, i) => (
                                <GroupCard key={i} groupObj={g} />
                            ))}
                        </Grid>
                        {(groups) ?
                            <>
                                {groupsCount.current > perPageGroups.current ?
                                    <GroupsPagination
                                        groupsCount={groupsCount.current}
                                        perPageGroups={perPageGroups.current}
                                        setGroups={setGroups}
                                    /> : null
                                }
                            </>
                            :
                            <Stack sx={{ alignItems: 'center' }}>
                                <Skeleton width='50%' animation="wave"
                                    sx={{ mx: '1rem !important', p: '0.5rem !important' }}
                                />
                            </Stack>
                        }
                    </>
            }
        </>

    );
};

export default AllGroups;