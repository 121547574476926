import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    TextField,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const TokenDialog = ({
    open,
    onClose,
    token,
    handleCopyToken,
    showTick,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Typography
                    style={{ fontSize: "1rem", marginRight: "100px" }}
                >
                    Generated API Token
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <TextField
                    value={token}
                    InputProps={{
                        readOnly: true,
                        classes: {
                            input: "generated-token",
                        },
                    }}
                    sx={{ ml: 2, width: "45%" }}
                />
                {showTick ? (
                    <CheckCircleRoundedIcon style={{ color: "green" }} />
                ) : (
                    <IconButton onClick={handleCopyToken}>
                        <ContentCopyIcon />
                    </IconButton>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TokenDialog;
