import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
    Stack,
    Step,
    Button,
    Stepper,
    StepLabel,
    Box,
    Divider,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { EventGuestsContext } from "../../EventGuestContext";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiStepIcon-root.Mui-active": { color: "#FF8359" },
        "& .MuiStepIcon-root.Mui-completed": { color: "#28a745" }
        // "& .Mui-disabled .MuiStepIcon-root": { color: "#FF8359" }
    }
}));

const WebInvitationStepper = ({ steps, activeStep, getStepContent, handleNext, handleBack }) => {
    const c = useStyles();
    const { paymentDone } = useContext(EventGuestsContext)
    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Stepper activeStep={activeStep} alternativeLabel className={c.root}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Divider sx={{ my: 1.5 }} />
                <Box sx={{ height: { md: '55vh' }, overflow: 'auto' }}>
                    {getStepContent(activeStep)}
                </Box>
            </Box>
            <Stack direction='row' justifyContent='space-between' sx={{ my: 0.5 }}>
                <Button
                    color='warning'
                    onClick={handleBack}
                    disabled={activeStep === 0 || (activeStep === 1 && paymentDone)}
                    sx={{ fontSize: '0.9rem', pl: 0, ":disabled": { fontSize: '0.9rem' } }}
                >
                    <ArrowBackIosIcon sx={{ fontSize: '1rem' }} />&nbsp;Back
                </Button>
                <Button
                    color='warning'
                    onClick={handleNext}
                    disabled={activeStep === steps.length - 1}
                    sx={{ fontSize: '0.9rem', pr: 0, ":disabled": { fontSize: '0.9rem' } }}
                >
                    {activeStep === steps.length - 1
                        ? ""
                        : <>Next&nbsp;<ArrowForwardIosIcon sx={{ fontSize: '1rem' }} /></>}
                </Button>
            </Stack>
        </>
    )
};

export default WebInvitationStepper;
