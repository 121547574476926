import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, IconButton, InputAdornment, MenuItem, Select, Skeleton, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { getEventTickets, substituteGuestTickets } from "../../../../../../../../../../services/wEvents/eventDetails";
import { getEventGuestTicketCount, getSubstituteEventGuests, substituteEventGuest, substituteUser } from "../../../../../../../../../../services/wEvents/eventGuests";
import { codes as countryCodes } from '../../../../../../../../../../utils/constants/countries.codes';
import { manageEventGuestPayload, manageSubstituteTicketPayload, manageSubstituteUserPayload } from "../../../../../../../../../../utils/functions/managePayload";
import { SubstituteEventGuestsContext } from "../../../../SubstituteEventGuestsContext";

const AddSubstituteGuestContent = (handleClose) => {
    const { totalGuests, setGuests, maxValue, setMaxValue } = useContext(SubstituteEventGuestsContext);
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset, watch
    } = useForm();

    const [ticketsCount, setTicketCount] = useState(1);
    const eventData = useSelector(eventDetails);
    const [tickets, setTickets] = useState(null);
    const eventTicketType = eventData?.is_paid ? 'paid' : 'free';
    const [inviteLoader, setInviteLoader] = useState(false);

    const handleMinusTicketCount = () => {
        if (ticketsCount > 1) {
            setTicketCount((prev) => prev - 1);
        }
    }

    const handlePlusTicketCount = () => {
        if (ticketsCount < maxValue) {
            setTicketCount((prev) => prev + 1);
        }
    }

    useEffect(() => {
        getEventTickets({ eventId: eventData?.id }).then((res) => {
            setTickets(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        getEventGuestTicketCount(eventData?.id, eventData?.guest_id).then((res) => {
            setMaxValue(res.data.tickets_available);
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            })
        })
    }, [])

    const mySubmit = (data) => {
        setInviteLoader(true);
        let payload = manageEventGuestPayload(data);
        payload["guest_id"] = eventData?.guest_id;
        payload["added_by_substitute"] = 1;
        substituteEventGuest(payload, eventData?.id).then((res) => {
            const substituteUserPayload = manageSubstituteUserPayload(res.data?.data.id, eventData?.guest_id, ticketsCount);
            substituteUser(eventData?.id, substituteUserPayload).then((res2) => {
                console.log(res2.data);
                const substituteTicketPayload = manageSubstituteTicketPayload(
                    data, ticketsCount, res.data.data.uuid, eventData?.guest_id,
                    res.data?.data.id, eventData?.guest_id
                );
                substituteGuestTickets(eventData?.id, substituteTicketPayload).then((res3) => {
                    console.log(res3.data);
                    getSubstituteEventGuests({ eventId: eventData?.id, guestId: eventData?.guest_id }).then((res4) => {
                        setInviteLoader(false);
                        console.log(res4.data)
                        totalGuests.current = res4.data.data.length;
                        setGuests(res4.data.data);
                        if (res3.data?.message) {
                            toast.success(res3.data.message, {
                                position: "top-right",
                                theme: "dark"
                            });
                        } else {
                            toast.success("Invitation sent successfully to substitute guest", {
                                position: "top-right",
                                theme: "dark"
                            });
                        }
                        handleClose();
                    }).catch((err) => {
                        console.log(err);
                        handleClose();
                    })
                }).catch((err) => {
                    console.log(err);
                    toast.error("Something went wrong", {
                        position: "top-right",
                        theme: "dark"
                    });
                    handleClose();
                })
            }).catch((err) => {
                console.log(err);
                toast.error("Something went wrong", {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose();
            })
        }).catch((err) => {
            console.log(err);
            toast.error(err.response?.data, {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Enter Name"
                        name="guest_name"
                        inputProps={{
                            type: 'text',
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {watch('guest_name') ? watch('guest_name').length : 0}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        error={_.get(errors, 'guest_name') ? true : false}
                        helperText={
                            _.get(errors, 'guest_name') ? 'This is required field' : null
                        }
                        {...register('guest_name', { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Email ID"
                        name="email"
                        inputProps={{
                            type: 'email',
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {watch('email') ? watch('email').length : 0}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        error={_.get(errors, 'email') ? true : false}
                        helperText={
                            _.get(errors, 'email') ? 'This is required field' : null
                        }
                        {...register('email', { required: false })}
                    />

                    <Stack direction='row' spacing={1}>
                        <Select
                            color="warning"
                            defaultValue='91'
                            error={_.get(errors, 'dialing_code') ? true : false}
                            {...register('dialing_code', { required: true })}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                        width: "7rem",
                                        marginTop: "0.5rem",
                                        border: "1px solid #E4E4E4",
                                        p: 1.5,
                                        pt: 0.75,
                                        boxShadow: "0",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        scrollbarWidth: "0px",
                                        "&& .Mui-selected": {
                                            backgroundColor: "lightblue",
                                            border: "1px solid #E4E4E4",
                                            "&:hover": {
                                                backgroundColor: "#FAFBFD"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {countryCodes.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    <Box>+{code.dial_code}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            label='Phone Number'
                            inputProps={{
                                type: 'tel',
                                pattern: '[0-9]*'
                            }}
                            error={_.get(errors, 'mobile') ? true : false}
                            helperText={
                                _.get(errors, 'mobile') ? 'This is required field' : null
                            }
                            {...register('mobile', { required: true })}
                            sx={{ flex: 1 }}
                        />
                    </Stack>

                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                        {maxValue !== null ?
                            <>
                                {
                                    maxValue > 0 ?
                                        (eventTicketType === 'free' && tickets?.length === 0) ? 
                                            null :
                                            <>
                                                <Typography>
                                                    Number of guests:&nbsp;
                                                </Typography>
                                                <Stack direction='row' spacing={1} alignItems='center'>
                                                    <IconButton onClick={handleMinusTicketCount}>
                                                        <RemoveCircleOutlineIcon color='warning' />
                                                    </IconButton>
                                                    <TextField
                                                        color="warning"
                                                        sx={{ width: '4rem' }}
                                                        inputProps={{
                                                            type: 'number',
                                                            min: 0,
                                                            max: maxValue
                                                        }}
                                                        value={ticketsCount}
                                                        onChange={(e) => {
                                                            if (e.target.value < maxValue + 1) {
                                                                setTicketCount(Number(e.target.value))
                                                            }
                                                        }}
                                                    />
                                                    <IconButton onClick={handlePlusTicketCount}>
                                                        <AddCircleOutlineIcon color='warning' />
                                                    </IconButton>
                                                </Stack>
                                            </> :
                                        <Typography color='red' textAlign='center' sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }}>
                                            No tickets available!!
                                        </Typography>
                                }
                            </>
                            :
                            <Skeleton animation="wave" sx={{ flex: 1,height: '5rem', mx: '1rem !important' }} />
                        }
                    </Stack>
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        disabled={maxValue ? false : true}
                        loading={inviteLoader ? true : false}
                        startIcon={inviteLoader ? <SaveIcon /> : null}
                        loadingPosition={inviteLoader ? "start" : null}
                    >
                        Invite
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const AddSubstituteGuestDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Invite Guest' content={AddSubstituteGuestContent(handleClose)}
            />
        </>
    )
};

export default AddSubstituteGuestDialog;
