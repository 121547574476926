import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";

const InviteConfirmationDialog = ({ tag, handleConfirmationClick, handleConfirmationClose }) => {
    return (
        <>
            <Dialog
                open={true}
                onClose={handleConfirmationClose}
                sx={{
                    pb: { xs: '1.8rem', sm: '0rem' }
                }}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '50%' },
                    }
                }}
                disableEnforceFocus
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Typography sx={{ fontSize: '1.5rem' }}>
                        Warning
                    </Typography>
                </DialogTitle>
                <Divider sx={{ mb: 1 }} />
                <DialogContent sx={{ px: '1rem', py: '0rem' }}>
                    <Typography>
                        {tag === 'paid' ?
                            "Are you sure you wants to send invitation as you marked event as paid or Registration form required but you didn't create any ticket or registration form?" :
                            "Are you sure you wants to send invitation as you marked event as free but you didn't create registration form?"
                        }
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={handleConfirmationClose}>
                        Cancel
                    </Button>
                    <Button type="submit" color='warning' variant="contained"
                        sx={{ boxShadow: 'none' }} onClick={handleConfirmationClick}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default InviteConfirmationDialog;
