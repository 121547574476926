import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import GroupDocForm from "./GroupDocForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Chip, DialogActions, DialogContent, Skeleton, Stack, Typography } from "@mui/material";
import { GroupSettingsContext } from "../..";
import { BACKEND_URL } from "../../../../../../../../utils/constants/apis.urls";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { manageDocumentVerifyPayload } from '../../../../../../../../utils/functions/managePayload'
import { addGroupDocument, editGroupDocument, getGroupDocuments } from "../../../../../../../../services/wGroups/groupDocs";
import ResetDocConfirmationDialog from "./ResetDocConfirmationDialog";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";

const GroupDocVerification = () => {
    const { handlePrevClick } = useContext(GroupSettingsContext)
    const groupData = useSelector(groupDetails);
    const [docData, setDocData] = useState(null);
    const FILE_SIZE = 2 * 1000 * 1000; //2MB Size
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [openConfirmation, setConfirmation] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleOpen = () => {
        setConfirmation(true);
    };
    const handleClose = () => {
        setConfirmation(false);
    };

    useEffect(() => {
        getGroupDocuments(groupData?.id).then(
            (res) => {
                setDocData(res.data.data.length > 0 ? res.data.data[0] : {})
                res.data.data[0]?.front_photo_path && setFrontImage(`${res.data.data[0]?.front_photo_path}`)
                res.data.data[0]?.back_photo_path && setBackImage(`${res.data.data[0]?.back_photo_path}`)
            })
            .catch((err) => console.log(err));
    }, []);

    const frontPhotoValidation = docData?.front_photo_path ?
        yup.mixed() :
        yup.mixed()
            .test(
                "required",
                "Document photo is required",
                (value) => {
                    return value.length > 0
                }
            )
            .test("fileSize", "File Size should be less than 2MB", (value) => {
                return value.length && value[0].size <= FILE_SIZE;
            })
    const backPhotoValidation = docData?.back_photo_path ?
        yup.mixed() :
        yup.mixed().optional()
            .test("fileSize", "File Size should be less than 2MB", (value) => {
                if (value.length > 0) {
                    return value.length && value[0].size <= FILE_SIZE;
                } else {
                    return true;
                }
            })

    const schema = yup.object({
        document_front_photo: frontPhotoValidation,
        document_back_photo: backPhotoValidation
    });

    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const mySubmit = (data) => {
        setLoader(true);
        if (Object.keys(docData).length > 0) {
            const payload = manageDocumentVerifyPayload(data, true);
            editGroupDocument(payload, groupData?.id, docData?.id).then((res) => {
                setLoader(false);
                setDocData(res.data.data);
                handlePrevClick();
                toast.success("Documents updated", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                setLoader(false);
                console.log(err);
                toast.error(Object.values(err.response.data)[0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        } else {
            const payload = manageDocumentVerifyPayload(data);
            addGroupDocument(payload, groupData?.id).then((res) => {
                setLoader(false);
                setDocData(res.data.data);
                handlePrevClick();
                toast.success("Documents uploaded", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                setLoader(false);
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    };

    const handleCancel = () => {
        reset();
        handleOpen();
    }

    const onConfirmReset = () => {
        setFrontImage(null);
        setBackImage(null);
        handleClose();
    }

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={docData?.is_verified ? false : true} sx={{
                    p: { xs: '1rem', md: '0.5rem 1.5rem' },
                    height: { xs: '26rem', md: '31rem' },
                }}>
                    {docData ?
                        <Stack spacing={2} >
                            {docData?.is_verified ?
                                <Box sx={{ textAlign: 'center' }}>
                                    <Chip size="small" color="success"
                                        label="Verified"
                                        icon={<VerifiedIcon />}
                                        sx={{ width: 'fit-content' }} />
                                </Box>
                                :
                                <>
                                    <Typography>
                                        Upload any government authorised document photo
                                    </Typography>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Chip size="small" color={docData?.is_verified ? "success" : "error"}
                                            label={docData?.is_verified ? "Verified" : "Not verified"}
                                            icon={docData?.is_verified ? <VerifiedIcon /> : <DangerousIcon />}
                                            sx={{ width: 'fit-content' }} />
                                    </Box>
                                </>
                            }
                            <GroupDocForm docData={docData}
                                register={register} errors={errors}
                                frontImage={frontImage} setFrontImage={setFrontImage}
                                backImage={backImage} setBackImage={setBackImage}
                            />
                        </Stack> :
                        <Skeleton sx={{ height: '10rem', transform: 'scale(1,1)' }} />
                    }
                </DialogContent>
                <DialogActions sx={{ my: 0.5 }}>
                    {!docData?.is_verified ?
                        <>
                            <Button color='warning' variant="outlined" sx={{
                                boxShadow: 'none'
                            }} onClick={handleCancel}>
                                Reset
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="warning"
                                variant="contained"
                                loading={loader ? true : false}
                                startIcon={loader ? <SaveIcon/>: null}
                                loadingPosition={loader ?"start" : null}

                            >
                                {loader ? 'Saving' : 'Save'}
                            </LoadingButton>
                        </>
                        : <Button color='warning' variant="outlined" sx={{
                            boxShadow: 'none'
                        }} onClick={handlePrevClick}>
                            Done
                        </Button>
                    }
                </DialogActions>
            </form>
            {openConfirmation ?
                <ResetDocConfirmationDialog handleClose={handleClose} onConfirmReset={onConfirmReset} /> : null}
        </>
    )
};

export default GroupDocVerification;
