import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment/moment";
import React from "react";
import { BACKEND_URL } from "../../../../../../../../../utils/constants/apis.urls";

const ProgramDetailContent = (eventProgramDetails) => {
    const isSmallDevices = useMediaQuery("(max-width:600px)");
    return (
        <>
            <Box sx={{ mb: 3, mt: { xs: 0, sm: 2 } }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems='center'
                    sx={{ wordBreak: 'break-all' }}>
                    {eventProgramDetails?.media?.length > 0 ? (
                        <Avatar src={`${eventProgramDetails?.media.slice(-1)[0].path}`}
                            alt='Event logo'
                            variant="rounded"
                            sx={{
                                width: { xs: '100%', sm: '5rem' },
                                height: { xs: '10rem', sm: '5rem' },
                                backgroundColor: '#F0F0F0'
                            }} />
                    ) : (
                        null
                        // <>
                        //     <Avatar
                        //         variant="rounded"
                        //         sx={{ width: '5rem', height: '5rem', backgroundColor: '#F0F0F0' }}
                        //     >
                        //         <InsertPhotoIcon sx={{ width: '100%', height: '100%' }} />
                        //     </Avatar>
                        // </>
                    )}
                    <Box>
                        <Typography sx={{
                            fontWeight: 'bold', lineHeight: '1.25rem',
                            fontSize: '1.5rem'
                        }}>
                            {eventProgramDetails?.title}
                        </Typography>
                    </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />

                <Stack spacing={1}>
                    {eventProgramDetails?.end_datetime ?
                        <>
                            <Typography>
                                <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Date: </strong>
                                {
                                    moment(eventProgramDetails?.start_datetime).format('DD MMM YYYY') === moment(eventProgramDetails?.end_datetime).format('DD MMM YYYY') ?
                                        moment(eventProgramDetails?.start_datetime).format('dddd, Do MMM YYYY') :
                                        moment(eventProgramDetails?.start_datetime).format('dddd, Do MMM YYYY') + "-" + moment(eventProgramDetails?.end_datetime).format('dddd, Do MMM YYYY')
                                }
                            </Typography>
                            <Typography>
                                <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Time: </strong>
                                {moment(eventProgramDetails?.start_datetime).format('h:mm A')} - {moment(eventProgramDetails?.end_datetime).format('h:mm A')}
                            </Typography>
                        </>
                        :
                        <>
                            <Typography>
                                <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Date: </strong>
                                {moment(eventProgramDetails?.start_datetime).format('dddd, Do MMM YYYY')}
                            </Typography>
                            <Typography>
                                <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Time: </strong>
                                {moment(eventProgramDetails?.start_datetime).format('h:mm A')}
                            </Typography>
                        </>
                    }
                    {eventProgramDetails?.is_online ?
                        <>
                            {eventProgramDetails?.live_url ?
                                <Box>
                                    <Typography sx={{ fontSize: '1rem', display: 'inline' }}>
                                        <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Event Link: </strong>
                                    </Typography>
                                    <Link underline="none" target="_blank" href={eventProgramDetails?.live_url}
                                        sx={{ fontFamily: 'Poppins', display: 'inline' }}>
                                        {eventProgramDetails?.live_url}
                                    </Link>
                                </Box>
                                : null
                            }
                        </>
                        :
                        <>
                            <Typography sx={{ fontSize: '1rem' }}>
                                <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Event Area:</strong> {eventProgramDetails?.venue?.name}
                            </Typography>
                            <Typography sx={{ fontSize: '1rem' }}>
                                <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Venue:</strong> {eventProgramDetails?.venue?.address}, {eventProgramDetails?.venue?.landmark}, {eventProgramDetails?.venue?.city}-{eventProgramDetails?.venue?.zip}, {eventProgramDetails?.venue?.state}, {eventProgramDetails?.venue?.country}
                            </Typography>
                            {eventProgramDetails?.venue?.google_map_link ?
                                <Link underline="none" target="_blank"
                                    href={eventProgramDetails?.venue?.google_map_link}
                                    sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center' }}>
                                    Location link <LaunchIcon />
                                </Link>
                                : null
                            }
                        </>
                    }
                    {eventProgramDetails?.dress_code ?
                        <Typography sx={{ fontSize: '1rem' }}>
                            <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Dress code:</strong> {eventProgramDetails?.dress_code}
                        </Typography>
                        : null
                    }
                    {eventProgramDetails?.description ?
                        <Typography sx={{ fontSize: '1rem' }}>
                            <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Description:</strong> {eventProgramDetails?.description}
                        </Typography>
                        : null
                    }
                    {eventProgramDetails?.instructions ?
                        <Typography sx={{ fontSize: '1rem' }}>
                            <strong style={{ fontSize: isSmallDevices ? '1rem' : '1.1rem' }}>Instructions:</strong> {eventProgramDetails.instructions}
                        </Typography>
                        : null
                    }
                </Stack>

                <DialogActions></DialogActions>
            </Box>
        </>
    )
};

const ProgramDetailDialog = ({ open, handleClose, eventProgramDetails }) => {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            sx={{
                "& .MuiDialog-container": {
                    xs: {
                        justifyContent: "flex-end",
                        alignItems: "flex-end"
                    }, sm: {
                        justifyContent: "center",
                        alignItems: "center"
                    }
                },
            }}
            PaperProps={{
                sx: {
                    width: { xs: '100%' },
                    borderTopLeftRadius: { xs: '1rem', sm: '0rem' },
                    borderTopRightRadius: { xs: '1rem', sm: '0rem' },
                    m: { xs: 0 }, pb: '1rem'
                }
            }}
        >
            <DialogTitle>
                <IconButton onClick={handleClose}
                    sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                    <CloseIcon fontSize='medium' />
                </IconButton>
                <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>Details</Typography>
            </DialogTitle>
            <Divider sx={{ mb: 1 }} />
            <DialogContent sx={{ px: '1rem', py: '0rem' }}>
                {ProgramDetailContent(eventProgramDetails)}
            </DialogContent>
        </Dialog>
    )
};


export default ProgramDetailDialog;
