import FormControl from "@mui/material/FormControl";
import React, { useContext, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import OnlineLocation from "./OnlineLocation";
import PhysicalLocation from "./PhysicalLocation";
import { CreateEventContext } from "../CreateEventContext";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";

const GetLocation = () => {
    let { formData, setFormData } = useContext(CreateEventContext);
    const [value, setValue] = useState(
        formData.is_online ? "online" : "offline"
    );
    if(formData.current_tab_value === 'date'){
        formData.schedule_announcement_description = null;
    }
    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setFormData({ ...formData, is_online: !formData.is_online });
    };

    // const toggleSwitch = (e) => {
    //     let field = e.target.name
    //     setFormData({ ...formData, [field]: !formData[field] })
    // }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: { xs: "4rem", sm: "5rem" }
                }}
            >
                <Box
                    sx={{
                        borderRadius: "10px",
                        boxShadow: "0px 0px 25px #ccc",
                        width: { xs: "95%", sm: "65%", lg: "45%" },
                        mt: { xs: 1, sm: 4 }
                    }}
                >
                    <Stack sx={{ m: { xs: 1, sm: 2 } }} spacing={1}>
                        <Typography
                            sx={{
                                mt: 1,
                                ml: { xs: 1, sm: 0 },
                                fontSize: { xs: "1rem", sm: "1.5rem" }
                            }}
                        >
                            Location
                        </Typography>

                        <FormControl>
                            <CustomRadioGroup
                                value={value}
                                setValue={setValue}
                                handleRadioChange={handleRadioChange}
                                option1="offline"
                                option2="online"
                            />
                        </FormControl>

                        {value === "offline" ? (
                            <PhysicalLocation />
                        ) : value === "online" ? (
                            <OnlineLocation />
                        ) : <></>
                        }

                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default GetLocation;
