import React, { useEffect, useState } from "react";
import _ from "lodash";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Button, DialogActions, TextField, Box } from "@mui/material";
import UploadImage from "../../components/UploadImage";
import { createBank, editBank } from "../../services/bankDetails";
import { userDetails } from "../../redux/slices/userDataSlice";
import { manageBankPayload } from "../../utils/functions/managePayload";

const BankDetailsForm = ({ banks, setBanks, bankForm, setBankForm }) => {
    const userData = useSelector(userDetails);
    const [loader, setLoader] = useState(false);
    const FILE_SIZE = 2 * 1000 * 1000; //2MB Size
    const [selectedImage, setSelectedImage] = useState(bankForm?.type === 'edit' ?
        `${bankForm?.details.bank_cheque_photo_path}` : null);

    const imageValidation = (bankForm?.type === 'edit' && typeof (selectedImage) === 'string') ?
        yup.mixed().optional() :
        yup.mixed()
            .test(
                "required",
                "Cheque photo is required",
                (value) => {
                    return value.length > 0
                }
            )
            .test("fileSize", "File Size should be less than 2MB", (value) => {
                return value.length && value[0].size <= FILE_SIZE;
            })

    const schema = yup.object({
        bank_cheque_photo: imageValidation,
        account_number: yup.string().required("This field is required"),
        confirm_account_number: yup
            .string()
            .oneOf([yup.ref('account_number'), null], 'Account number must match')
            .required("This field is required"),
        ifsc_code: yup.string().required("This field is required"),
        account_holder_name: yup.string().required("This field is required"),
        account_holder_email: yup.string().email('Entered email is invalid').required("This field is required"),
        bank_name: yup.string().required("This field is required")
    });

    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const formFields = [
        { name: 'account_number', label: 'Account number' },
        { name: 'confirm_account_number', label: 'Confirm account number' },
        { name: 'ifsc_code', label: 'IFSC code' },
        { name: 'account_holder_name', label: 'Holder name' },
        { name: 'account_holder_email', label: 'Holder email' },
        { name: 'bank_name', label: 'Bank name' },
    ]

    const handleCancel = () => {
        if (banks.length !== 0) {
            setBankForm({ ...bankForm, details: null, visible: false });
        } else {
            reset();
            setSelectedImage(null);
        }
    }

    const mySubmit = (data) => {
        setLoader(true);
        if (bankForm.type === 'add') {
            const payload = manageBankPayload(data);
            createBank(payload, userData?.id).then((res) => {
                console.log('----------->', res.data);
                setBanks([...banks, res.data.data]);
                setBankForm({ ...bankForm, details: res.data.data, visible: false });
                toast.success("New bank added!!", {
                    position: "top-right",
                    theme: "dark"
                });
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
                toast.error((Object.values(err.response.data)[0][0]), {
                    position: "top-right",
                    theme: "dark"
                });
            })
        } else if (bankForm.type === 'edit') {
            const payload = manageBankPayload(data, true);
            console.log(Object.fromEntries(payload));
            editBank(payload, userData?.id, bankForm.details.id).then((res) => {
                console.log('----------->', res.data);
                setBanks(() => {
                    let ele = banks.find(b => b.id === bankForm.details.id);
                    banks[banks.indexOf(ele)] = res.data.data;
                    return [...banks];
                });
                setBankForm({ ...bankForm, details: res.data.data, visible: false });
                toast.success("Bank details updated!!", {
                    position: "top-right",
                    theme: "dark"
                });
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    };

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ width: '100%' }}
            >
                <Stack spacing={2}>
                    {formFields.map((f, i) => (
                        <TextField
                            key={i}
                            fullWidth
                            color="warning"
                            label={f.label}
                            defaultValue={bankForm?.type === 'edit' ?
                                (f.name === 'confirm_account_number' ? _.get(bankForm?.details, 'account_number') :
                                    _.get(bankForm?.details, f.name)) : (f.name === 'account_holder_email' ? userData?.email : null)}
                            error={_.get(errors, f.name) ? true : false}
                            helperText={
                                _.get(errors, f.name) ? _.get(errors, f.name).message : null
                            }
                            {...register(f.name)}
                        />
                    ))}
                </Stack>

                <Box
                    sx={{
                        backgroundColor: selectedImage ? '#171717' : 'white',
                        borderRadius: "10px",
                        width: { xs: "75%", sm: "50%" },
                        height: "11rem",
                        border: errors.bank_cheque_photo ? '1px solid red' : '1px solid lightgrey',
                        margin: '2rem auto 0.5rem !important'
                    }}
                >
                    <UploadImage fieldName='bank_cheque_photo' label='Upload bank cheque'
                        register={register} errors={errors}
                        selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                </Box>

                <DialogActions sx={{ pr: 0, mt: 0.5 }}>
                    <Button color='warning' variant="outlined" sx={{
                        boxShadow: 'none'
                    }} onClick={handleCancel}>
                        {banks.length === 0 ? "Reset" : "Cancel"}
                    </Button>
                    <LoadingButton type="submit" color='warning' variant="contained"  loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                           {loader ? 'Saving...' : 'Save'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
};

export default BankDetailsForm;
