import { Grid, Paper } from "@mui/material";
import React from "react";
import GroupCardContent from "./GroupCardContent";

const GroupCard = ({ groupObj }) => {
    return (
        <>
            <Grid item xs={12} sm={6} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={5} sx={{
                    height: { xs: '21rem', sm: '21.5rem' },
                    width: { xs: '90%', sm: '20rem', lg: '22rem' },
                    borderRadius: '1rem',
                    transition: 'transform 0.3s', /* Animation */
                    ":hover": {
                        cursor: 'pointer',
                        boxShadow: '0px 5px 10px 4px rgba(0, 0, 150, 0.3)',
                        // transform: 'translateY(-2px)',
                        transform: 'scale(1.04)'
                    }
                }}>
                    <GroupCardContent g={groupObj} />
                </Paper>
            </Grid>
        </>
    )
};

export default GroupCard;
