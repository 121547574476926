import { Stack, Switch, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CreateEventContext } from "../CreateEventContext";

const EventSwitches = () => {
    const { formData, setFormData } = React.useContext(CreateEventContext);
    const toggleSwitch = (e) => {
        let field = e.target.name
        setFormData({ ...formData, [field]: !formData[field] })
    }
    const switchLists = [
        { fieldName: 'has_registration', desc: 'Do you required Event Registration Form?' },
        // { fieldName: 'is_qrcode', desc: 'Will you like to add QR code for your guest verification' },
        // { fieldName: 'allow_create_feed', desc: 'Allow creating live feed for event' }
    ]
    // Commented these 2 because as of now not needed

    useEffect(() => {
        if (formData.is_paid) {
            setFormData({ ...formData, has_registration: true })
        }
    }, [formData.is_paid])

    return (
        <>
            {switchLists.map((item, index) => (
                <Stack key={index} direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                        {item.desc}
                    </Typography>
                    <Switch name={item.fieldName} color='warning'
                        checked={formData[item.fieldName]}
                        disabled={formData?.is_paid ? true : false}
                        onChange={(e) => toggleSwitch(e)}
                    />
                </Stack>
            ))}
        </>
    )
};

export default EventSwitches;
