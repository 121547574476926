import '../event.scss'
import { toast } from 'react-toastify';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import MuiToggleButton from "@mui/material/ToggleButton";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Stack, Typography, ToggleButtonGroup, IconButton, Avatar, Dialog, DialogContent, DialogTitle, CircularProgress } from "@mui/material";
import { BACKEND_URL } from '../../../../../utils/constants/apis.urls';
import { userDetails } from '../../../../../redux/slices/userDataSlice';
import { eventDetails } from "../../../../../redux/slices/eventDataSlice";
import { submitQuickEventRsvp } from '../../../../../services/wEvents/eventDetails';

const RsvpDialog = ({ open, handleClose, setEvents }) => {
    const userData = useSelector(userDetails);
    const eventData = useSelector(eventDetails);
    const mapStatus = {
        'Yes': 'accepted',
        'No': 'declined',
        'Maybe': 'maybe'
    }

    const alignment = Object.keys(mapStatus).find(key => mapStatus[key] === eventData.invitation_status) ?? null;
    const [loading, setLoading] = useState(false);

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected": {
            backgroundColor: '#FF8359 !important'
        },

        "&.MuiButtonBase-root&.Mui-selected": {
            color: "white !important",
        },

        "&.MuiButtonBase-root:hover": {
            color: "white !important",
            backgroundColor: '#FF8359 !important'
        }
    });

    const handleChange = (newAlignment) => {
        setLoading(true);
        const payload = {
            rsvp: newAlignment,
            status: mapStatus[newAlignment]
        }
        submitQuickEventRsvp(eventData?.id, payload).then((result) => {
            console.log(result.data);
            setEvents((events) => {
                const currentEve = events.find(e => e.id === eventData?.id);
                events[events.indexOf(currentEve)] = result.data.event;
                return [...events];
            });
            setLoading(false);
            handleClose();
            toast.success("Quick RSVP submitted", {
                position: "top-right",
                theme: "dark"
            })
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                sx={{
                    "& .MuiDialog-container": {
                        xs: {
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                        }, sm: {
                            justifyContent: "center",
                            alignItems: "center"
                        }
                    }
                }}
                PaperProps={{
                    sx: {
                        width: { xs: '100%' }, borderRadius: { xs: 0, sm: '0.25rem' },
                        m: { xs: 0 }, pb: '1rem'
                    }
                }}
            >
                <DialogTitle sx={{ pr: 1, pt: 1 }}>
                    <IconButton onClick={() => handleClose()} sx={{ float: 'right' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton><br /><br />
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Avatar src={`${eventData?.event_main_photo}`} sx={{ width: '4rem', height: '4rem' }} />
                        <Box>
                            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                {eventData.title}
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#6F738B', mt: 1 }}>
                                {/* {eventData.city}, {eventData.state}, {eventData.country} */}
                                {eventData.city}
                            </Typography>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ maxHeight: '15rem', my: { xs: 1.5, sm: 3 } }}>
                    <Typography sx={{ textAlign: 'justify' }}>
                        {eventData.welcome_note}
                    </Typography>
                </DialogContent>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}
                    sx={{ minHeight: '5rem', px: { xs: '0.5rem', sm: '1rem' }, mt: '1.5rem', mb: { xs: '1rem', sm: '2.5rem' } }}>
                    {!loading ?
                        <ToggleButtonGroup
                            color='standard'
                            value={alignment}
                            exclusive
                        >
                            <Box>
                                <ToggleButton selected={eventData.invitation_status === 'accepted'}
                                    disabled={eventData?.owner?.id === userData?.id ? true : false}
                                    value='Yes' onClick={() => handleChange('Yes')}
                                    sx={{ backgroundColor: alignment === 'Yes' && '#FF8359', p: 3 }}>
                                    <ThumbUpOffAltIcon fontSize='large' sx={{ width: '3rem', }} />
                                </ToggleButton>
                                <Typography sx={{ fontSize: { xs: '12px', sm: '14px' }, textAlign: 'center', mt: 1 }}>
                                    Attending
                                </Typography>
                            </Box>
                            <Box>
                                <ToggleButton selected={eventData.invitation_status === 'declined'}
                                    disabled={eventData?.owner?.id === userData?.id ? true : false}
                                    value='No' sx={{ backgroundColor: alignment === 'No' && '#FF8359', p: 3 }}
                                    onClick={() => handleChange('No')}>
                                    <ThumbDownOffAltIcon fontSize='large' sx={{ width: '3rem' }} />
                                </ToggleButton>
                                <Typography sx={{ fontSize: { xs: '12px', sm: '14px' }, textAlign: 'center', mt: 1 }}>
                                    Not Attending
                                </Typography>
                            </Box>
                            <Box>
                                <ToggleButton selected={eventData.invitation_status === 'maybe'}
                                    disabled={eventData?.owner?.id === userData?.id ? true : false}
                                    value='Maybe' sx={{ backgroundColor: alignment === 'Maybe' && '#FF8359', p: 3 }}
                                    onClick={() => handleChange('Maybe')}>
                                    <RemoveCircleOutlineIcon fontSize='large' sx={{ width: '3rem' }} />
                                </ToggleButton>
                                <Typography sx={{ fontSize: { xs: '12px', sm: '14px' }, textAlign: 'center', mt: 1 }}>
                                    Maybe
                                </Typography>
                            </Box>
                        </ToggleButtonGroup>
                        :
                        <CircularProgress color="warning" />
                    }
                </Stack>
            </Dialog>
        </>
    )
}

export default RsvpDialog;
