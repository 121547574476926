import React from "react";
import CardContent from "./CardContent";
import { Paper, Grid } from "@mui/material";

const EventCard = ({ eventObj, setEvents }) => {
    return (
        <>
            <Grid item xs={12} sm={6} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Paper elevation={6}
                    sx={{
                        height: '17rem',
                        width: { xs: '90%', sm: '20rem', lg: '22rem' },
                        borderRadius: '1rem',
                        transition: 'transform 0.3s', /* Animation */
                        ":hover": {
                            boxShadow: '0px 5px 15px 4px rgba(0, 0, 150, 0.3)',
                            // transform: 'translateY(-2px)',
                            transform: 'scale(1.05)'
                        }
                    }}>
                    <CardContent e={eventObj} setEvents={setEvents} />
                </Paper>
            </Grid>
        </>
    )
};

export default EventCard;
