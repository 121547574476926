import { Pagination, Stack } from "@mui/material";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { EventSettingsContext } from "../..";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventPaidPayments, getEventReceivedPayments } from "../../../../../../../../services/wEvents/eventDetails";

const ItemPagination = ({ tabType }) => {
    const eventDetailsObj = useSelector(eventDetails);
    const { setPaymentRecords, totalPaidRecords, totalReceivedRecords, per_page_records } = useContext(EventSettingsContext);
    const totalItems = Math.ceil((tabType === 'Paid' ? totalPaidRecords : totalReceivedRecords).current / per_page_records.current);
    const handleChange = (event, value) => {
        if (tabType === 'Paid') {
            setPaymentRecords((prevData) => {
                return { ...prevData, paidData: null } // To show skeleton on changing page
            });
            getEventPaidPayments({ eventId: eventDetailsObj?.id, page: value }).then((res) => {
                setPaymentRecords((prevData) => {
                    return { ...prevData, paidData: res.data.data }
                });
            }).catch((err) => {
                console.log(err);
            })
        } else if (tabType === 'Received') {
            setPaymentRecords((prevData) => {
                return { ...prevData, receivedData: null } // To show skeleton on changing page
            });
            getEventReceivedPayments({ eventId: eventDetailsObj?.id, page: value }).then((res) => {
                setPaymentRecords((prevData) => {
                    return { ...prevData, receivedData: res.data.data }
                })
            }).catch((err) => {
                console.log(err);
            })
        }
    };

    return (
        <>
            <Stack sx={{ py: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination
                    color="warning"
                    variant="outlined"
                    count={totalItems}
                    onChange={handleChange}
                />
            </Stack>
        </>
    );
};

export default ItemPagination;
