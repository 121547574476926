import SaveIcon from '@mui/icons-material/Save';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from '@mui/lab';
import { Chip, useTheme } from "@mui/material";
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MemberRegistrationContext } from '../..';
import logo from '../../../../../../../../assets/WowslyLogo.png';
import { groupDetails } from '../../../../../../../../redux/slices/groupDetailSlice';
import { userDetails } from '../../../../../../../../redux/slices/userDataSlice';
import { createOrder } from '../../../../../../../../services/Payment/paymentGateway';
import { loadScript } from '../../../../../../../../utils/functions/loadScript';
import { razorPayModalConfig } from '../../../../../../../../utils/constants/razorPayModalConfig';

const SubscriptionPaymentIntegration = ({ totalAmountToPay, amountCurrencyForRazorPay }) => {
    const theme = useTheme();
    const { memberId } = useParams();
    const userData = useSelector(userDetails);
    const groupData = useSelector(groupDetails);
    const { paymentDone, setPaymentDone, paymentDetails, setPaymentDetails,
        setSlide, setFormSubmitted } = useContext(MemberRegistrationContext);

    const [loading, setLoading] = useState(false);
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handlePayment = async () => {
        setLoading(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            alert('Failed to load Razorpay payment gateway. Please try again later.');
            return;
        }

        const payload = {
            payment_amount: totalAmountToPay * 100, // For rupees send in paisa
            amount_currency: amountCurrencyForRazorPay,
            receipt: 'Wowsly_Subscriptions'
        }
        createOrder(payload).then((result) => {
            setLoading(false);
            console.log(result);
            const order_id = result.data?.id;
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                amount: paymentDetails.amount_currency === 'rupees' ? totalAmountToPay * 100 : totalAmountToPay, // Amount in paise
                currency: amountCurrencyForRazorPay,
                name: 'Wowsly',
                description: 'Payment for group subscription',
                notes: {
                    'task': 'group_subscription_payment',
                    'group_id': groupData?.id,
                    'user_id': userData?.id,
                    'invited_member_uuid': memberId,
                    'member_subscription_id': paymentDetails.member_subscription_id,
                    'member_subscription_name': paymentDetails.member_name,
                    'member_subscription_email': paymentDetails.member_email,
                    'current_timezone': currentTimezone,
                    'amount_currency_symbol': paymentDetails.subscription_currency_symbol
                },
                image: logo,
                order_id: order_id,
                handler: function (response) {
                    // Callback function triggered on successful payment
                    console.log("Done", response);
                    if ('razorpay_payment_id' in response
                        && 'razorpay_order_id' in response
                        && 'razorpay_signature' in response
                    ) {
                        setLoading(false);
                        setPaymentDone(true);
                        setFormSubmitted(true);
                        setSlide(0);
                        setPaymentDetails({ ...paymentDetails, paymentId: response.razorpay_payment_id });
                        toast.success("Payment successful and joined group, Subscription receipt sent to registered member email.", {
                            position: "top-right",
                            theme: "dark"
                        });
                    } else {
                        toast.error("Payment failed due to some issues, please try again later!!", {
                            position: "top-right",
                            theme: "dark"
                        });
                        return;
                    }
                },
                prefill: {
                    name: paymentDetails.member_name,
                    email: paymentDetails.member_email,
                    contact: `+${paymentDetails.member_dial_code}${paymentDetails.member_number}`,
                },
                theme: {
                    color: theme.palette.primaryColor,
                },
                config: razorPayModalConfig.paymentMethodsConfiguration,
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
            razorpay.on("payment.failed", function (response) {
                console.log(response);
                setLoading(false);
                toast.error(response.error.description, {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            });
        });
    };

    return (
        <>
            {paymentDone ?
                <Chip
                    label="Paid" color='success' icon={<VerifiedIcon />}
                />
                :
                <LoadingButton
                    type="submit"
                    loading={loading}
                    onClick={handlePayment}
                    startIcon={loading ? <SaveIcon /> : ''}
                    loadingPosition={loading ? "start" : null}
                    sx={{
                        color: 'white',
                        // borderRadius: '1.1rem',
                        backgroundColor: loading ? 'lightgray' : '#1F274A',
                        ":hover": {
                            backgroundColor: '#1F274A',
                        },
                    }}
                >
                    {loading ? 'Processing..' : 'Buy Subscription'}
                </LoadingButton>
            }
        </>
    )
}

export default SubscriptionPaymentIntegration