import React from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
    Button,
    Box,
    Typography,
    Paper,
    MobileStepper,
    useTheme
} from "@mui/material";
import { Navigate } from "react-router-dom";

const MobileDotStepper = ({ steps, activeStep, getStepContent, handleNext, handleBack }) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Paper
                    square
                    elevation={0}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: 50,
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>{steps[activeStep]}</Typography>
                </Paper>
            </Box>
            <Box
                sx={{
                    width: "100%",
                }}
            >
                {getStepContent(activeStep)}
            </Box>
            <MobileStepper
                variant="dots"
                steps={steps.length}
                activeStep={activeStep}
                sx={{
                    backgroundColor: "#F2F2F2",
                    '.MuiMobileStepper-dotActive': {
                        backgroundColor: '#FF8359 !important'
                    }
                }}
                nextButton={
                    activeStep === steps.length ? (
                        <>
                            <Navigate to='/dashboard' />
                            {/* <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                pt: 2
                                            }}
                                        >
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            <Button onClick={handleReset}>
                                                Reset
                                            </Button>
                                        </Box> */}
                        </>
                    ) : (
                        <>
                            <Button
                                size="small"
                                color='warning'
                                onClick={handleNext}
                            // disabled={
                            //     activeStep === maxSteps - 1
                            // }
                            >
                                {
                                    activeStep === steps.length - 1 ? "Create" : "Next"
                                }
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        </>
                    )
                }
                backButton={
                    <Button
                        size="small"
                        color='warning'
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </>
    );
};

export default MobileDotStepper;
