import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoginDialog from "../../../../../../components/LoginDialog";
import { groupDetails } from "../../../../../../redux/slices/groupDetailSlice";
import { userStatus } from "../../../../../../redux/slices/userDataSlice";
import { getGroupForms } from "../../../../../../services/wGroups/groupForm";
import { getMemberDetails } from "../../../../../../services/wGroups/groupMembers";
import MemberRegistrationSection from "./components/MemberRegistrationSection";
import MemberSubscriptionPayment from "./components/MemberSubscriptionPayment";
import SelectSubscription from "./components/SelectGroupSubscription";
import SuccessfulRegistration from "./components/SuccessfulRegistration";

export const MemberRegistrationContext = React.createContext({});

const GroupRegistration = ({ subscriptionRenew }) => {
    const { memberId } = useParams();
    const isLoggedIn = useSelector(userStatus);
    const groupData = useSelector(groupDetails);

    const [activeSlide, setSlide] = useState(subscriptionRenew ? 1 : 0); // For subscription renewal
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [rForm, setRForm] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [registrationData, setRegistrationData] = useState(null);
    const [paymentDone, setPaymentDone] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const handleSlideChange = (slideNum) => {
        setSlide(slideNum);
    };

    const mapSlideToComponent = {
        0: !formSubmitted ? <MemberRegistrationSection /> : <SuccessfulRegistration />, //Don't show form if already submitted
        1: groupData?.is_paid ? <SelectSubscription /> : <SuccessfulRegistration />,
        2: <MemberSubscriptionPayment />
    }

    const [loginDialog, setLoginDialog] = useState(false);
    const [memberData, setMemberData] = useState(null);
    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    useEffect(() => { // Dependency given of dialog state for updating register form details, it'll be called on member weblink side if login
        if (isLoggedIn) {
            getGroupForms({ groupId: groupData?.id, member_uuid: memberId }).then((res) => {
                setRForm(res.data.form[0]);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [loginDialog]);

    useEffect(() => { //Separate use-effect for stop re-rendering of dialog state without login
        getMemberDetails({ groupId: groupData?.id, uuid: groupData?.member_uuid }).then((result) => {
            if (!isLoggedIn) {
                setLoginDialog(true);
            }
            setMemberData(result.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <>
            <MemberRegistrationContext.Provider value={{
                activeSlide, setSlide, handleSlideChange,
                subscriptions, setSubscriptions,
                selectedSubscription, setSelectedSubscription,
                registrationData, setRegistrationData,
                paymentDone, setPaymentDone,
                paymentDetails, setPaymentDetails,
                rForm, setRForm, setFormSubmitted,
                memberData, subscriptionRenew
            }}>
                <Box sx={{
                    m: '1rem auto !important',
                    p: { xs: '0.5rem', sm: '0.5rem 1.25rem' },
                    width: { xs: '90%', sm: '80%' },
                    textAlign: 'left',
                    borderRadius: '5px',
                    boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
                }}>
                    {mapSlideToComponent[activeSlide]}
                </Box>
                {loginDialog ? <LoginDialog open={true}
                    handleClose={() => handleDialogClose('login')} guestData={memberData} /> : null}
            </MemberRegistrationContext.Provider>
        </>
    )
};

export default GroupRegistration;
