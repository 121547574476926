import GroupInfo from "../../pages/GroupDetails/components/GroupMenubar/components/GroupInfo"
import GroupEvents from "../../pages/GroupDetails/components/GroupMenubar/components/GroupEvents"
import GroupMembers from "../../pages/GroupDetails/components/GroupMenubar/components/GroupMembers"
import GroupRegistration from "../../pages/GroupDetails/components/GroupMenubar/components/GroupRegistration"

export const mapGroupTabs = {
    'Info': <GroupInfo />,
    'Events': <GroupEvents />,
    'Members': <GroupMembers />,
    'Registration': <GroupRegistration />,
    'Subscription': <GroupRegistration subscriptionRenew />
}