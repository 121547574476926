import { Divider, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoginDialog from "../../../../../../components/LoginDialog";
import { groupDetails } from "../../../../../../redux/slices/groupDetailSlice";
import { userStatus } from "../../../../../../redux/slices/userDataSlice";
import { getGroupMembers, getMemberDetails } from "../../../../../../services/wGroups/groupMembers";
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import { GroupMembersContext } from "./GroupMembersContext";
import GroupMembersTabs from "./GroupMembersTabs";
import GroupMemberHeader from "./components/GroupMemberHeader";
import MemberList from "./components/MemberList";
import MembersFooter from "./components/MembersFooter";

const GroupMembers = () => {
    const groupData = useSelector(groupDetails);
    const isLoggedIn = useSelector(userStatus);
    let totalMembers = useRef(null);
    let per_page_members = useRef(5);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(groupData?.current_user_role);

    const [members, setMembers] = useState(null);
    const [checkedMembers, setChecked] = useState([]);
    const [isCheckboxVisible, setVisible] = useState(false);
    const [inviteMsg, setInviteMsg] = useState(null);
    const [scheduledDate, setScheduledDate] = useState(null);
    const [memberInviteActiveStep, setMemberInviteActiveStep] = useState(0);

    const handleToggle = (value) => () => {
        const currentIndex = checkedMembers.indexOf(value);
        const newChecked = [...checkedMembers];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const isSmallDevice = useMediaQuery("(max-width:600px)"); // Smaller than tablets
    const [loginDialog, setLoginDialog] = useState(false);
    const [memberData, setMemberData] = useState(null);
    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    const [tabValue, setTabValue] = useState(isAllPermission ? 0 : null); // Because we are showing only joined members list for group member web link.
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (!isLoggedIn) {
            getMemberDetails({ groupId: groupData?.id, uuid: groupData?.member_uuid }).then((result) => {
                setLoginDialog(true);
                setMemberData(result.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            setMembers(null); // For showing skeleton till data is fetched
            getGroupMembers({ groupId: groupData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'joined' }).then((res) => {
                totalMembers.current = res.data.meta.total
                per_page_members.current = res.data.meta.per_page
                setMembers(res.data.data)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [loginDialog, tabValue]);

    return (
        <>
            <GroupMembersContext.Provider value={{
                tabValue, members, setMembers, totalMembers, per_page_members, inviteMsg, setInviteMsg,
                isCheckboxVisible, setVisible, checkedMembers, setChecked,
                memberInviteActiveStep, setMemberInviteActiveStep,
                scheduledDate, setScheduledDate, handleToggle
            }}>
                <Stack sx={{ position: 'relative' }}>
                    <GroupMemberHeader />
                    <Divider sx={{ my: 1 }} />
                    {
                        isAllPermission ? // As we are showing invited and joined tab to group owner
                            <GroupMembersTabs tabValue={tabValue} handleTabChange={handleTabChange} />
                            : <MemberList />
                    }
                    {/* As we are showing options of cancel and invite in footer only in small devices */}
                    {
                        totalMembers.current > 0 ?
                            (isSmallDevice ? <MembersFooter /> : null)
                            : null
                    }
                </Stack>

                {
                    loginDialog ? <LoginDialog open={true}
                        handleClose={() => handleDialogClose('login')} guestData={memberData} /> : null
                }
            </GroupMembersContext.Provider>
        </>
    );
};

export default GroupMembers;
