import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { userStatus } from "../redux/slices/userDataSlice";
import { PAGE_PATHS } from "../utils/constants/page-path.constants";

const PrivateRoutes = () => {
    const isLoggedIn = useSelector(userStatus);
    const location = useLocation();
    const openUrlParams = ['e', 'g'];
    if (isLoggedIn || openUrlParams.includes(location.pathname.split('/')[1])) {
        return <Outlet />
    }
    return <Navigate to={`/${PAGE_PATHS.MOBILE_LOGIN}`} />;
};

export default PrivateRoutes;
