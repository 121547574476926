import React from 'react'
import logo from '../../../../assets/WowslyLogo.png'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Splash() {
  return (
    <div style={{position:'fixed', width:'100%', height:'100%', backgroundColor: 'orange', backgroundImage:'linear-gradient(#FF9966, #FF5E62)', display: 'flex', justifyContent:'space-between', alignItems: 'center', flexDirection: 'column' }}>
      {/* <img src={logo} width='250rem' height='250rem' alt="LOGO"/> */}
      <Box sx={{p: '4rem'}}>
        <Box
          component="img"
          sx={{
            height: {xs: '10rem', sm: '15rem', md: '18rem', lg: '18rem'},
            width: {xs: '10rem', sm: '15rem', md: '18rem', lg: '18rem'},
            // maxHeight: { xs: 200, md: 350 },
            // maxWidth: { xs: 200, md: 350 },
          }}
          alt="The house from the offer."
          src={logo}
        />
        <Typography sx={{ 
          color: 'white', textAlign:'center', fontFamily: 'SF Compact Rounded',
          fontSize: {xs: '2.5rem', sm: '3.5rem', md: '4rem', lg: '4.5rem'}
        }}>
          Wowsly
        </Typography>
      </Box>
      <Typography variant='h6' sx={{color:'white', paddingBottom: '4rem', fontSize: {xs: '1rem', sm: '1rem', md: '1.5rem', lg: '1.5rem'}}}>Your digital event partner</Typography>
    </div>
  )
}

export default Splash