import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/userDataSlice'
import eventReducer from '../slices/eventDataSlice'
import groupReducer from '../slices/groupDetailSlice'
import sidebarReducer from '../slices/sidebarDataSlice'

export default configureStore({
    reducer: {
        User: userReducer,
        Event: eventReducer,
        Group: groupReducer,
        Sidebar: sidebarReducer
    }
})