import { Box, Button, DialogActions, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { groupDetails } from "../../../../../../../../../../redux/slices/groupDetailSlice";
import { createGroupMember, getGroupMembers } from "../../../../../../../../../../services/wGroups/groupMembers";
import { codes as countryCodes } from '../../../../../../../../../../utils/constants/countries.codes';
import { manageEventGuestPayload } from "../../../../../../../../../../utils/functions/managePayload";
import { GroupMembersContext } from "../../../../GroupMembersContext";

const AddMemberContent = (handleClose) => {
    const { tabValue, totalMembers, setMembers } = useContext(GroupMembersContext)
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch, reset
    } = useForm();

    const groupData = useSelector(groupDetails)

    const mySubmit = (data) => {
        let payload = manageEventGuestPayload(data)
        createGroupMember(payload, groupData?.id).then((res) => {
            getGroupMembers({ groupId: groupData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'joined' }).then((res2) => {
                totalMembers.current = res2.data.meta.total
                setMembers(res2.data.data)
                toast.success("Member added successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose();
            }).catch((err) => {
                console.log(err);
            })
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong while adding member", {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Enter Name"
                        name="member_name"
                        inputProps={{
                            type: 'text',
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {watch('member_name') ? watch('member_name').length : 0}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        error={_.get(errors, 'member_name') ? true : false}
                        helperText={
                            _.get(errors, 'member_name') ? 'This is required field' : null
                        }
                        {...register('member_name', { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Email ID"
                        name="email"
                        inputProps={{
                            type: 'email',
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {watch('email') ? watch('email').length : 0}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        error={_.get(errors, 'email') ? true : false}
                        helperText={
                            _.get(errors, 'email') ? 'This is required field' : null
                        }
                        {...register('email', { required: false })}
                    />

                    <Stack direction='row' spacing={1}>
                        <Select
                            color="warning"
                            defaultValue='91'
                            error={_.get(errors, 'dialing_code') ? true : false}
                            {...register('dialing_code', { required: true })}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                        width: "7rem",
                                        marginTop: "0.5rem",
                                        border: "1px solid #E4E4E4",
                                        p: 1.5,
                                        pt: 0.75,
                                        boxShadow: "0",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        scrollbarWidth: "0px",
                                        "&& .Mui-selected": {
                                            backgroundColor: "lightblue",
                                            border: "1px solid #E4E4E4",
                                            "&:hover": {
                                                backgroundColor: "#FAFBFD"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {countryCodes.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    <Box>+{code.dial_code}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            label='Phone Number'
                            inputProps={{
                                type: 'tel',
                                pattern: '[0-9]*'
                            }}
                            error={_.get(errors, 'mobile') ? true : false}
                            helperText={
                                _.get(errors, 'mobile') ? 'This is required field' : null
                            }
                            {...register('mobile', { required: true })}
                            sx={{ flex: 1 }}
                        />
                    </Stack>
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const AddMemberDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Add Member' content={AddMemberContent(handleClose)}
            />
        </>
    )
};

export default AddMemberDialog;
