import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FullScreenLoader from "../../../../components/Loader";
import { createGroup } from "../../../../services/wGroups/group";
import { PAGE_PATHS } from "../../../../utils/constants/page-path.constants";
import { manageCreateGroupPayload } from "../../../../utils/functions/managePayload";
import MobileDotStepper from "../CreateEvent/components/MobileDotStepper";
import WebLinearStepper from "../CreateEvent/components/WebLinearStepper";
import GroupCategory from "./components/GroupCategory";
import GroupInfo from "./components/GroupInfo";

export const CreateGroupContext = React.createContext({});

const CreateGroup = () => {
    const navigate = useNavigate();
    const defaultYear = new Date();
    const [loader, setLoader] = useState(false);
    const isMobileScreen = useMediaQuery("(max-width:600px)");

    const steps = [
        "Choose group category",
        "Set group details",
    ];
    const [formData, setFormData] = useState({
        category_id: null,
        since: defaultYear,
        title: '',
        description: '',
        city: '',
        state: '',
        country: '',
        is_private: false,
    })
    const [fieldErrors, setFieldErrors] = useState({})

    const [activeStep, setActiveStep] = React.useState(0);
    const value = { formData, setFormData, fieldErrors, setFieldErrors };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    let error = false

    const handleNext = () => {
        if (activeStep === 0) {
            if (!formData.category || !formData.category_id) {
                toast.error("Please select category of group", {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        else if (activeStep === 1) {
            let fields = ['title', 'description', 'since', 'city', 'state', 'country']
            fields.forEach((item) => {
                if (!formData[item]) {
                    error = true
                    fieldErrors[item] = true
                }
            })
            if (error) {
                setFieldErrors({ ...fieldErrors })
            } else {
                setLoader(true);
                formData.has_registration = 1;
                let payload = manageCreateGroupPayload(formData)
                createGroup(payload).then((res) => {
                    setLoader(false);
                    toast.success('Group created successfully', {
                        position: "top-right",
                        theme: "dark"
                    });
                    navigate(`/${PAGE_PATHS.GROUP_DETAILS.replace(':id', res.data.data.id)}`);
                }).catch((err) => {
                    setLoader(false);
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                })
            }
        }
    }

    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return <GroupCategory />;
            case 1:
                return <GroupInfo />;
            default:
                return <></>;
        }
    };

    return (
        <CreateGroupContext.Provider value={value}>
            <Box sx={{ width: "100%" }}>
                {isMobileScreen ? (
                    <>
                        <MobileDotStepper
                            steps={steps}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            getStepContent={getStepContent}
                        />
                    </>
                ) : (
                    <>
                        <WebLinearStepper
                            steps={steps}
                            activeStep={activeStep} //edit to activeStep here
                            handleBack={handleBack}
                            handleNext={handleNext}
                            getStepContent={getStepContent}
                        />
                    </>
                )}
            </Box>
            {loader ? <FullScreenLoader /> : null}
        </CreateGroupContext.Provider>
    )
};

export default CreateGroup;
