import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import { GroupMembersContext } from "../../GroupMembersContext";
import MobileInvitationStepper from "./MobileInvitationStepper";
import ScheduleMemberInvite from "./Steps/SendMessage/ScheduleMemberInvite";
import MemberInvitationMessage from "./Steps/SetMessage/MemberInvitationMessage";
import WebInvitationStepper from "./WebInvitationStepper";

const SetMemberInvitationDialog = ({ isReminder, handleClose }) => {
    const { memberInviteActiveStep, setMemberInviteActiveStep } = useContext(GroupMembersContext);
    const isMobileScreen = useMediaQuery("(max-width:600px)");

    const steps = [
        isReminder ? 'Set Reminder Message' : 'Set Invitation Message',
        isReminder ? 'Send Reminders' : 'Send Invitations'
    ];

    const handleBack = () => {
        setMemberInviteActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleNext = () => {
        if (memberInviteActiveStep === 0) {
            document.getElementById("saveMemberInviteBtn").click();
        }
    }
    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return <MemberInvitationMessage isReminder={isReminder} />;
            case 1:
                return <ScheduleMemberInvite handleClose={handleClose} isReminder={isReminder} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                sx={{
                    pb: { xs: '1.8rem', sm: '0rem' }
                }}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '70%' },
                    }
                }}
                disableEnforceFocus // For enable tinyMCE dialogs and dropdowns
                disableEscapeKeyDown // For stop closing dialog on escape key 
            >
                <DialogTitle>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>
                        {isReminder ? 'Reminder' : 'Invitation'}
                    </Typography>
                </DialogTitle>
                <Divider sx={{ mb: 1 }} />
                <DialogContent sx={{ px: '1rem', py: '0rem' }}>
                    {isMobileScreen ? (
                        <>
                            <MobileInvitationStepper
                                steps={steps}
                                activeStep={memberInviteActiveStep}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                getStepContent={getStepContent}
                            />
                        </>
                    ) : (
                        <>
                            <WebInvitationStepper
                                steps={steps}
                                activeStep={memberInviteActiveStep} //edit to activeStep here
                                handleBack={handleBack}
                                handleNext={handleNext}
                                getStepContent={getStepContent}
                            />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
};

export default SetMemberInvitationDialog;
