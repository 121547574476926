import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { EventGuestsContext } from "../../EventGuestContext";
import MobileInvitationStepper from "./MobileInvitationStepper";
import InvitationMessage from "./Steps/InvitationMessage";
import PurchaseOrder from "./Steps/PurchaseOrder";
import ScheduleInvite from "./Steps/ScheduleInvite";
import WebInvitationStepper from "./WebInvitationStepper";

const SetInvitationDialog = ({ isReminder, handleClose }) => {
    const { paymentDone, guestInviteActiveStep, setGuestInviteActiveStep } = useContext(EventGuestsContext)
    const isMobileScreen = useMediaQuery("(max-width:600px)");

    const steps = [
        isReminder ? 'Set Reminder Message' : 'Set Invitation Message',
        'Make Payment',
        isReminder ? 'Send Reminders' : 'Send Invitations',
    ];

    const handleBack = () => {
        setGuestInviteActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleNext = () => {
        if (guestInviteActiveStep === 0) {
            document.getElementById("saveGuestInviteBtn").click();
        } else if (guestInviteActiveStep === 1) {
            if (paymentDone) {
                setGuestInviteActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                toast.error("Please make payment first!!", {
                    position: "top-right",
                    theme: "dark"
                });
            }
        }
    }
    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return <InvitationMessage isReminder={isReminder} />;
            case 1:
                return <PurchaseOrder />;
            case 2:
                return <ScheduleInvite handleClose={handleClose} isReminder={isReminder} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                sx={{
                    pb: { xs: '1.8rem', sm: '0rem' }
                }}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '70%' },
                    }
                }}
                disableEnforceFocus
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>
                        {isReminder ? 'Reminder' : 'Send Invitation Process'}
                    </Typography>
                </DialogTitle>
                <Divider sx={{ mb: 1 }} />
                <DialogContent sx={{ px: '1rem', py: '0rem' }}>
                    {isMobileScreen ? (
                        <>
                            <MobileInvitationStepper
                                steps={steps}
                                activeStep={guestInviteActiveStep}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                getStepContent={getStepContent}
                            />
                        </>
                    ) : (
                        <>
                            <WebInvitationStepper
                                steps={steps}
                                activeStep={guestInviteActiveStep} //edit to activeStep here
                                handleBack={handleBack}
                                handleNext={handleNext}
                                getStepContent={getStepContent}
                            />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
};

export default SetInvitationDialog;
