import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SuccessGif from '../../../../../../../../assets/success-register.gif';
import { update_group_details } from "../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupDetails } from "../../../../../../../../services/wGroups/group";

const SuccessfulRegistration = () => {
    const dispatch = useDispatch();
    const { memberId } = useParams();
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        getGroupDetails({ memberId: memberId }).then((res) => {
            if (res.data.data.is_member_joined) {
                setTimeout(() => {
                    dispatch(update_group_details(res.data.data))
                }, 3000);
            }
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            })
        })
    }, []);

    return (
        <Stack spacing={3} justifyContent='center' alignItems='center' sx={{ p: 2 }}>
            <Box component='img'
                src={SuccessGif}
                width={isSmallDevice ? '90%' : '22rem'}
                height={isSmallDevice ? '15rem' : '22rem'}
            />
            <Typography sx={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>You have successfully registered for group!!</Typography>
        </Stack>
    )
};

export default SuccessfulRegistration;
