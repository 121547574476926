import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from 'moment';
import React, { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GIF from '../../../../../../../../../../assets/groupSendMsg1.gif';
import { groupDetails } from "../../../../../../../../../../redux/slices/groupDetailSlice";
import { saveGroupMemberInvitation } from "../../../../../../../../../../services/wGroups/groupMembers";
import { manageInviteMessagePayload } from "../../../../../../../../../../utils/functions/managePayload";
import { GroupMembersContext } from "../../../../GroupMembersContext";
import SendLaterConfirmation from "./SendLaterConfirmation";
import SendNowConfirmation from "./SendNowConfirmation";

const ScheduleMemberInvite = ({ isReminder, handleClose }) => {
    const groupData = useSelector(groupDetails);
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { inviteMsg, totalMembers, isCheckboxVisible, members, checkedMembers,
        setChecked, setVisible, scheduledDate, setScheduledDate } = useContext(GroupMembersContext)
    const membersToInvite = isCheckboxVisible ? checkedMembers.length : totalMembers.current
    const members_for_email = checkedMembers.length > 0 ?
        members.filter(item => checkedMembers.includes(item.id) && item.email).length // Filter objects with specified IDs and non-null email values
        : members.filter(item => item.email).length;

    const isPickerVisible = useRef(false);
    const [loading, setLoading] = useState(false);
    const [isDateError, setDateError] = useState(false);
    const [confirmationBox, setConfirmationBox] = useState(false);

    const handleCloseConfirmation = () => {
        isPickerVisible.current = false;
        setLoading(false);
        setDateError(false);
        setScheduledDate(null);
        setConfirmationBox(false);
    }
    const handleSendNow = (e) => {
        setConfirmationBox(true);
    }
    const handleSendLater = () => {
        isPickerVisible.current = true;
        setConfirmationBox(true);
    }
    const sendInvite = (inviteType) => {
        //Call schedule or send invitation API
        const model_type = 'group';
        let inviteFields = Object.entries(inviteMsg).filter((e) => e[1] === true);

        if (inviteType === 'sendLater') {
            if (!scheduledDate) {
                setDateError(true)
                return;
            }
            else if (scheduledDate <= moment().add(1, 'minutes')) { // Kept 1 min gap to save and process scheduling task
                setDateError("Schedule time should be greater than current time")
                return;
            }
        }

        setLoading(true); // Start loader as everything is ok and api call is about to happen
        inviteMsg.scheduled_date = inviteType === 'sendLater' ? scheduledDate : null;
        inviteMsg.members_for_email = members_for_email;
        let payload = manageInviteMessagePayload(inviteFields, checkedMembers, inviteMsg, model_type);
        payload.set('invite_type', isReminder ? 'reminder' : 'invitation');

        saveGroupMemberInvitation(payload, groupData?.id).then((result) => {
            console.log(result.data, '------');
            setLoading(false);
            handleClose();
            // setInviteMsg(null);
            if (isCheckboxVisible) {
                setChecked([]);
                setVisible(false);
            }
            toast.success(`${isReminder ? 'Reminder' : 'Invitation'} ${inviteType === 'sendNow' ? "Sent" : "Scheduled"}`, {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        });

    }

    return (
        <>
            <Stack sx={{ py: { xs: 2 }, textAlign: 'center' }} spacing={2} alignItems='center' justifyContent='center'>
                <Box component='img'
                    src={GIF}
                    alt={'Loading'}
                    width={isSmallDevice ? '100%' : '18rem'}
                    height={isSmallDevice ? '10rem' : '15rem'}
                />
                <Typography>You have successfully uploaded member(s). Now send the {isReminder ? 'Reminder' : 'invitation'} to ask your members to join this group !!!</Typography>
                {confirmationBox ?
                    <Box sx={{
                        width: { xs: '95%', sm: '90%', md: '80%', lg: '60%' },
                        borderRadius: '5px',
                        boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
                    }}>
                        {
                            isSmallDevice ? <IconButton size='small' onClick={handleCloseConfirmation} sx={{ float: 'right' }}>
                                <CloseIcon fontSize='medium' />
                            </IconButton> : null
                        }
                        {isPickerVisible.current ?
                            <SendLaterConfirmation
                                loading={loading}
                                sendInvite={sendInvite}
                                handleCloseConfirmation={handleCloseConfirmation}
                                isDateError={isDateError}
                                setDateError={setDateError}
                            /> :
                            <SendNowConfirmation
                                loading={loading}
                                sendInvite={sendInvite}
                                membersToInvite={membersToInvite}
                                handleCloseConfirmation={handleCloseConfirmation}
                            />
                        }
                    </Box> :
                    <Stack direction='row' spacing={3} alignItems='center' justifyContent='center'>
                        <Button variant="contained"
                            onClick={handleSendNow}
                            sx={{ backgroundColor: '#1F274A', ":hover": { backgroundColor: '#1F274A' } }}>
                            Send now
                        </Button>
                        <Button variant="outlined"
                            onClick={handleSendLater}>
                            Send Later
                        </Button>
                    </Stack>
                }
            </Stack>
        </>
    )
};

export default ScheduleMemberInvite;
