import React, { useEffect, useState } from "react";
import { IconButton, Menu, Tooltip } from "@mui/material";
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import CreateEventViewDialog from './CreateEventViewDialog';
import CreateDragAndDropDialog from './CreateDragAndDropDialog';

const CreateEditViewDialog = ({ views }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState({
        addView: false,
        dragView: false
    });
    const handleDialogOpen = (operation) => {
        document.body.classList.add("no-scroll");
        setDialogOpen({...dialogOpen, [operation]: true});
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleDialogClose = (event, reason, operation, closeMenu) => {
        if (reason && reason === "backdropClick")
            return;
        if (closeMenu) {
            handleClose();
        }
        document.body.classList.remove("no-scroll");
        setDialogOpen({...dialogOpen, [operation]: false});
    }

    useEffect(() => {
        window.addEventListener('scroll', handleClose); //To hide menu on scroll
    }, [anchorEl]);
    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Tooltip title="Options">
                    <IconButton
                        className='menubar-addTabBtn'
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{
                            color: 'white',
                            borderRadius: 0,
                            backgroundColor: '#1F274A',
                            ":hover": {
                                color: 'white',
                                backgroundColor: '#1F274A'
                            }
                        }}>
                        <EditIcon sx={{ fontSize: '2rem' }} />
                    </IconButton> 
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    MenuListProps={{
                        sx: {
                            py: '0.8rem'
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <CreateEventViewDialog title='Add View'
                        dialogOpen={dialogOpen} handleDialogOpen={handleDialogOpen} handleDialogClose={handleDialogClose} />
                    <CreateDragAndDropDialog title='Manage Views' views={views}
                        dialogOpen={dialogOpen} handleDialogOpen={handleDialogOpen} handleDialogClose={handleDialogClose} />
                </Menu>
            </Box>
        </>
    )
};

export default CreateEditViewDialog;
