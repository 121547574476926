import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import FullScreenLoader from '../../../../components/Loader';
import { createEvent } from "../../../../services/wEvents/event";
import { PAGE_PATHS } from "../../../../utils/constants/page-path.constants";
import { manageCreateEventPayload } from "../../../../utils/functions/managePayload";
import EventDetails from "./components/AboutEvent";
import { CreateEventContext } from "./components/CreateEventContext";
import GetLocation from "./components/GetLocation";
import MobileDotStepper from "./components/MobileDotStepper";
import WebLinearStepper from "./components/WebLinearStepper";
import WhatCelebrating from "./components/WhatCelebrating";
import "./create_event.scss";

const CreateEvent = () => {
    const { state } = useLocation();
    const groupId = state?.groupId;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const initialVenueDetails = {
        venueName: '',
        streetLine: '',
        landmark: '',
        city: '',
        zipCode: '',
        state: '',
        country: '',
        googleMapLink: ''
    }
    const [formData, setFormData] = useState({
        category: null,
        category_id: null,
        is_online: false,
        multiple_venues: false,
        eventVenue: initialVenueDetails,
        eventLink: null,
        title: '',
        start_date: null,
        end_date: null,
        timezone: 'Asia/Kolkata',
        is_private: false,
        is_paid: false,
        has_registration: false,
        is_qrcode: false,
        allow_create_feed: false,
        current_tab_value:'date'
    });
    const [fieldErrors, setFieldErrors] = useState({
        title: false, start_date: false, end_date: false
    })
    const [activeStep, setActiveStep] = useState(0);

    let error = false
    const isMobileScreen = useMediaQuery("(max-width:900px)");

    const steps = [
        "What you celebrating",
        "About Event",
        "Set Location",
    ];
    const value = { formData, setFormData, fieldErrors, setFieldErrors };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        if (activeStep === 0) {
            if (!formData.category || !formData.category_id) {
                toast.error("Please select category of event", {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        else if (activeStep === 1) {
            if (!formData['title']) {
                error = true
                fieldErrors['title'] = true
            }
            if ((!formData['start_date'] && !formData['schedule_announcement_description'])) {
                error = true
                fieldErrors['start_date'] = true
            }
            if (error) {
                setFieldErrors({ ...fieldErrors })
            } else if (formData.end_date && (formData.start_date >= formData.end_date)) {
                toast.error('Start date must be less than end date', {
                    position: "top-right",
                    theme: "dark"
                });
            } else if (formData.is_paid && !formData.has_registration) {
                toast.error('Paid events must have registration forms', {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                setFormData({ ...formData });
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        else if (activeStep === 2) {
            // if (formData.is_online) {
            //     if (!formData.eventLink) {
            //         error = true
            //         toast.error("Please provide link for online event", {
            //             position: "top-right",
            //             theme: "dark"
            //         });
            //     }
            // } else {
            //     for (var key in formData.eventVenue) {
            //         if (!formData.eventVenue[key] && (!['streetLine2', 'googleMapLink'].includes(key))) {
            //             console.log("inside");
            //             error = true
            //             fieldErrors[key] = true
            //         }
            //     }
            // }
            // if (error) {
            //     setFieldErrors({ ...fieldErrors })
            // } else {
            if (formData.is_online) {
                formData.eventVenue = initialVenueDetails;
                formData.multiple_venues = false;
                if (formData.eventLink) {
                    const spaces = formData.eventLink.split(' ').length;
                    if (spaces > 1) {
                        toast.error("Online platform link should not contain spaces", {
                            position: "top-right",
                            theme: "dark"
                        });
                        return;
                    }
                }
            } else {
                if (formData.multiple_venues) {
                    formData.eventVenue = initialVenueDetails;
                } else {
                    const spaces = formData.eventVenue.googleMapLink.split(' ').length;
                    // For valiadte if it's google map link or not!!
                    // const isValidInput = validateLocationLink(formData.eventVenue.googleMapLink);
                    if (spaces > 1) {
                        toast.error("Google map link should not contain spaces", {
                            position: "top-right",
                            theme: "dark"
                        });
                        return;
                    }
                }
                formData.eventLink = null;
            }
            setLoader(true);
            let payload = manageCreateEventPayload(formData, groupId);

            createEvent(JSON.stringify(payload)).then((res) => {
                setLoader(false);
                toast.success('Event created successfully', {
                    position: "top-right",
                    theme: "dark"
                });
                navigate(`/${PAGE_PATHS.EVENT_DETAILS.replace(':id', res.data.data.id)}`);
            }).catch((err) => {
                setLoader(false);
                console.log("$$$$$$$$", err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    }


    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return <WhatCelebrating />;
            case 1:
                return <EventDetails />;
            case 2:
                return <GetLocation />;
            default:
                return <WhatCelebrating />;
        }
    };

    return (
        <CreateEventContext.Provider value={value}>
            <Box sx={{ width: "100%" }}>
                {isMobileScreen ? (
                    <MobileDotStepper
                        steps={steps}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        getStepContent={getStepContent}
                    />
                ) : (
                    <WebLinearStepper
                        steps={steps}
                        activeStep={activeStep} //edit to activeStep here
                        handleBack={handleBack}
                        handleNext={handleNext}
                        getStepContent={getStepContent}
                    />
                )}
            </Box>
            {/* For showing loader after last step */}
            {loader ? <FullScreenLoader /> : null}
        </CreateEventContext.Provider>
    );
};

export default CreateEvent;
