import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import ImageViewer from 'react-simple-image-viewer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { CreateGroupContext } from "../..";

const GroupImage = () => {
    const { formData, setFormData } = useContext(CreateGroupContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback(() => {
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };
    useEffect(() => {
        setFormData({ ...formData, group_logo: selectedImage })
    }, [selectedImage]);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: selectedImage ? '#171717' : 'white',
                    borderRadius: "10px",
                    width: { xs: "75%", sm: "50%" },
                    height: "11rem",
                    border: '1px solid lightgrey',
                    margin: '2rem auto 0.5rem !important'
                }}
            >
                <Stack id='image-box'
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {selectedImage ? (
                        <>
                            <Box
                                component='img'
                                src={URL.createObjectURL(selectedImage)}
                                alt='Group Img.'
                                id='uploaded-image'
                                sx={{
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    opacity: { xs: 0.5, md: 1 },
                                    width: '100%', height: '100%'
                                }}
                            />

                            <Stack id='image-options' direction='row' spacing={2}
                                sx={{
                                    p: '8px 3px 0px 0px',
                                    position: 'absolute',
                                    display: { md: 'none' },
                                }}>
                                <IconButton sx={{ p: 1, border: '1px solid white' }} component="label">
                                    <input
                                        hidden
                                        accept="image/png, image/jpg, image/jpeg"
                                        type="file"
                                        onChange={(event) => {
                                            setSelectedImage(event.target.files[0]);
                                        }}
                                    />
                                    <EditIcon sx={{
                                        color: 'white',
                                        width: '1.3rem', height: '1.2rem'
                                    }} />
                                </IconButton>
                                <IconButton sx={{ p: 1, border: '1px solid white' }} onClick={openImageViewer}>
                                    <VisibilityIcon sx={{
                                        color: 'white',
                                        width: '1.3rem', height: '1.2rem'
                                    }} />
                                </IconButton>
                            </Stack>
                            {isViewerOpen &&
                                <ImageViewer
                                    src={typeof (selectedImage) === 'object' ? [URL.createObjectURL(selectedImage)] : [selectedImage]}
                                    currentIndex={0}
                                    disableScroll={true}
                                    closeOnClickOutside={true}
                                    onClose={closeImageViewer}
                                    backgroundStyle={{ zIndex: 1200 }}
                                />
                            }
                        </>
                    ) : (
                        <Stack alignItems='center' justifyContent='center' sx={{ margin: 'auto !important' }}>
                            <Typography sx={{ color: 'grey', fontFamily: 'Poppins', textAlign: 'center' }}>
                                Upload {formData?.category} logo
                            </Typography>
                            <IconButton
                                color="warning"
                                component="label"
                                size="large"
                                sx={{
                                    height: "2.5rem",
                                }}
                            >
                                <input
                                    hidden
                                    accept="image/png, image/jpg, image/jpeg"
                                    type="file"
                                    onChange={(event) => {
                                        setSelectedImage(event.target.files[0]);
                                    }}
                                />
                                <DriveFolderUploadIcon size='large' sx={{ fontSize: '2rem' }} />
                            </IconButton>
                        </Stack>
                    )
                    }
                </Stack>
            </Box>
        </>
    )
};

export default GroupImage;
