import { Pagination } from "@mui/material";
import React from "react";

const InvitedUserPagination = ({ activePage, setActivePage, totalUsers, per_page_records, fetchOneInvitationDetails }) => {
    return (
        <Pagination
            size="small"
            color="warning"
            page={activePage}
            count={totalUsers ? Math.ceil(totalUsers / per_page_records) : 0}
            onChange={(event, value) => {
                setActivePage(value);
                fetchOneInvitationDetails(value);
            }}
        />
    )
};

export default InvitedUserPagination;
