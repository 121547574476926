import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton, Dialog, DialogTitle } from "@mui/material";
import CreateSubscription from "./CreateSubscription";

const EditSubscription = ({ subscription, setAction }) => {
    const handleEditClose = () => {
        setAction((action) => ({ ...action, 'edit': false }))
    }
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleEditClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleEditClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        Edit subscription
                    </Typography>
                </DialogTitle>
                <CreateSubscription edit={true} subscription={subscription}
                    handleEditClose={handleEditClose} />
            </Dialog>
        </>
    )
};

export default EditSubscription;
