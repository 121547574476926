import React from "react";
import { Stack, Button } from "@mui/material";
import BankCard from "./BankCard";

const BanksList = ({ banks, setBanks, setBankForm }) => {
    const handleAddBankBtn = () => setBankForm((bankForm) => ({ ...bankForm, type: 'add', visible: true }));
    return (
        <>
            <Stack spacing={2} sx={{ width: '100%' }}>
                {banks.map((b, i) => (
                    <BankCard key={i} bank={b} setBanks={setBanks} setBankForm={setBankForm} />
                ))}

                <Button sx={{ width: 'fit-content !important' }} variant='text' color='warning' onClick={handleAddBankBtn}>
                    Add bank
                </Button>
            </Stack>
        </>
    )
};

export default BanksList;
