import { axiosInstance } from "../../config/interceptor";
import {
    GROUP_MEMBERS_API,
    USERS_INVITE_DETAILS,
    USERS_INVITE_MESSAGE
} from "../../utils/constants/apis.urls";

export const getGroupMembers = ({ groupId, page, type }) => {
    return axiosInstance.get(
        `${GROUP_MEMBERS_API.GROUP_MEMBERS.replace(
            ":group_id",
            groupId
        )}?page=${page}&type=${type}`
    );
};

//Using this for fetching guest details using uuid so it can fill data directly while login by guest in weblink
export const getMemberDetails = ({ groupId, uuid }) => {
    return axiosInstance.get(
        `${GROUP_MEMBERS_API.GROUP_MEMBERS_DETAILS.replace(
            ":group_id",
            groupId
        ).replace(":member_uuid", uuid)}`
    );
};

export const createGroupMember = (payload, groupId) => {
    return axiosInstance.post(
        GROUP_MEMBERS_API.GROUP_MEMBERS.replace(":group_id", groupId),
        payload
    );
};

export const editGroupMember = (payload, groupId, memberId) => {
    return axiosInstance.post(
        `${GROUP_MEMBERS_API.GROUP_MEMBERS.replace(
            ":group_id",
            groupId
        )}/${memberId}`,
        payload
    );
};

export const deleteGroupMember = (groupId, memberId) => {
    return axiosInstance.delete(
        `${GROUP_MEMBERS_API.GROUP_MEMBERS.replace(
            ":group_id",
            groupId
        )}/${memberId}`
    );
};

export const multipleDeleteGroupMembers = (payload, groupId) => {
    return axiosInstance.post(
        GROUP_MEMBERS_API.GROUP_MEMBERS_DELETE.replace(":group_id", groupId),
        payload
    );
};

export const joinGroup = (payload, groupId) => {
    return axiosInstance.post(
        GROUP_MEMBERS_API.GROUP_MEMBER_JOIN_GROUP.replace(":group_id", groupId),
        payload
    );
};

export const uploadGroupMemberCsv = (payload, groupId) => {
    return axiosInstance.post(
        GROUP_MEMBERS_API.GROUP_MEMBERS_IMPORT.replace(":group_id", groupId),
        payload,
        {
            headers: { "content-type": "multipart/form-data" }
        }
    );
};

export const saveGroupMemberInvitation = (payload, groupId) => {
    return axiosInstance.post(
        USERS_INVITE_MESSAGE.replace(":model_id", groupId),
        payload,
        {
            headers: { "content-type": "multipart/form-data" }
        }
    );
};

export const getMembersInvitation = ({ groupId, listType, inviteType }) => {
    return axiosInstance.get(
        `${USERS_INVITE_MESSAGE.replace(
            ":model_id",
            groupId
        )}?model_type=group&invite_type=${inviteType}&list_type=${listType}`
    );
};

export const getMemberInvitationDetails = ({ groupId, invitationId, page }) => {
    return axiosInstance.get(
        `${USERS_INVITE_DETAILS.replace(":model_id", groupId).replace(
            ":invite_id",
            invitationId
        )}?page=${page}`
    );
};

export const deleteMembersInvitation = ({ groupId, invitationId }) => {
    return axiosInstance.delete(USERS_INVITE_DETAILS.replace(':model_id', groupId).replace(':invite_id', invitationId));
}
