import AboutEventSection from "../../pages/EventDetails/components/Menubar/components/SubEvents/components/AboutEventSection";
import AlbumsSection from "../../pages/EventDetails/components/Menubar/components/SubEvents/components/AlbumsSection";
import EventProgramsSection from "../../pages/EventDetails/components/Menubar/components/SubEvents/components/EventProgramsSection";
import KeyMembersSection from "../../pages/EventDetails/components/Menubar/components/SubEvents/components/KeyMembersSection";
import RsvpListSection from "../../pages/EventDetails/components/Menubar/components/SubEvents/components/RsvpListSection";

export const mapEventViews = (viewId, viewTitle, viewType, addDivider) => {
    const viewsMapping = {
        'about': <AboutEventSection viewId={viewId} title={viewTitle} addDivider={addDivider} />,
        'eventprogram': <EventProgramsSection viewId={viewId} title={viewTitle} addDivider={addDivider} />,
        'eventmember': <KeyMembersSection viewId={viewId} title={viewTitle} addDivider={addDivider} />,
        'eventalbum': <AlbumsSection viewId={viewId} title={viewTitle} addDivider={addDivider} />,
        'eventbanner': <></>,
        'eventrsvp': <RsvpListSection viewId={viewId} title={viewTitle} addDivider={addDivider} />
    }
    return viewsMapping[viewType];
}