import React from "react";
import { useForm } from "react-hook-form";
import { questionTypes } from "../../../../../../../../utils/constants/questionTypes";
import { Box, Button, Chip, DialogActions, DialogContent, ListItem, MenuItem, Stack, TextField } from "@mui/material";

const AddFormQuestionsDialog = ({ setAdd, handleAddFormQuestion }) => {
    const handleClose = () => {
        setAdd(false);
    }
    const { register, watch, getValues, resetField } = useForm();

    const addQuestion = () => {
        const data = getValues()
        handleAddFormQuestion(data, [...new Set(chipData)])
    }

    const [chipData, setChipData] = React.useState([]);

    const handleAddOption = () => {
        const option = watch('options');
        option && setChipData((chipData) => [...chipData, option]);
        resetField('options');
    }
    const handleDeleteOption = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <Box>
            <DialogContent>
                <Stack spacing={3}>
                    <TextField
                        select
                        fullWidth
                        color="warning"
                        label="Question Type"
                        defaultValue={'text'}
                        {...register('questionType', { required: true })}
                    >
                        {questionTypes.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        fullWidth
                        color="warning"
                        label="Type your question"
                        inputProps={{
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    document.getElementById('addQuestion').click();
                                }
                            },
                        }}
                        {...register("question", { required: true })}
                    />

                    {watch('questionType') === 'radio' ?
                        <Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={{ xs: 1, sm: 2 }}>
                                <TextField
                                    fullWidth
                                    color="warning"
                                    label="Add option"
                                    inputProps={{
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                document.getElementById('addOption').click();
                                            }
                                        },
                                    }}
                                    {...register("options")}
                                />
                                <Button id="addOption" color="warning" sx={{ fontSize: '2rem', p: 0, minWidth: '1rem' }}
                                    onClick={handleAddOption}>
                                    +
                                </Button>
                            </Stack>

                            {chipData.length > 0 ?
                                <Stack direction='row' flexWrap='wrap' sx={{ mt: 1, p: 0 }}>
                                    {chipData.map((c, i) => (
                                        <ListItem key={i} sx={{ p: 0.5, width: 'auto' }}>
                                            <Chip
                                                color="warning"
                                                label={c}
                                                onDelete={handleDeleteOption(c)}
                                            />
                                        </ListItem>
                                    ))}
                                </Stack> : null
                            }
                        </Stack> : null}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                <Button onClick={handleClose} color='warning' variant="outlined"
                    sx={{ boxShadow: 'none' }}>
                    Cancel
                </Button>
                <Button id='addQuestion' onClick={addQuestion} color='warning' variant="contained"
                    sx={{ boxShadow: 'none' }}
                    disabled={watch('question') ?
                        (watch('questionType') === 'radio' ? (chipData.length > 1 ? false : true) : false) :
                        true}>
                    Add
                </Button>
            </DialogActions>
        </Box>
    )
};

export default AddFormQuestionsDialog;
