import _ from "lodash";
import React, { useContext } from "react";
import { InputAdornment, TextField } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CreateGroupContext } from "../..";

const GroupDatePicker = ({ changeYear }) => {
    const { formData } = useContext(CreateGroupContext);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <MobileDatePicker
                    views={['year']}
                    label="Since"
                    value={_.get(formData, 'since')}
                    onChange={(year) => changeYear('since', year)}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><CalendarMonthIcon /></InputAdornment>
                    }}
                />
            </LocalizationProvider>
        </>
    )
};

export default GroupDatePicker;
