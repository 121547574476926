import { Badge, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import _ from "lodash";
import React from "react";

const CustomRadioGroup = ({ value, handleRadioChange, option1, option2, disabled, option2Props, gridCols = { xs: 5.5, sm: 4.5, lg: 4 } }) => {
    const radioBoxStyle = {
        display: "flex",
        alignItems: "center",
        height: "3.5rem",
        pl: { xs: "15px", sm: "18px" },
        borderRadius: "4px",
        textTransform: "none",
        color: "black",
        fontSize: "1rem",
        fontWeight: 500,
        ":hover": { cursor: "pointer", backgroundColor: "rgba(255, 131, 89, 0.16)" }
    };

    return (<>
        <RadioGroup
            row
            name="position"
            value={value}
            onChange={(e) => handleRadioChange(e)}
        >
            <Grid
                container
                gap={{ xs: "1rem", sm: "1.5rem" }}
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-start" }}
            >
                <Grid
                    item {...gridCols}
                    sx={{
                        ...radioBoxStyle,
                        border: value === option1 ? "2px solid #FF8359" : "2px solid lightgrey",
                        backgroundColor: value === option1 ? "rgba(255, 131, 89, 0.16)" : null
                    }}
                >
                    <FormControlLabel
                        value={option1}
                        control={<Radio />}
                        sx={{ width: "100%" }}
                        label={<span style={{ width: "100%" }}>{_.capitalize(option1)}</span>}
                    />
                </Grid>
                <Grid
                    item {...gridCols}
                    sx={{
                        ...radioBoxStyle,
                        border: value === option2 ? "2px solid #FF8359" : "2px solid lightgrey",
                        backgroundColor: value === option2 ? "rgba(255, 131, 89, 0.16)" : null
                    }}
                >
                    {option2Props?.badge ? (
                        <Badge badgeContent={option2Props?.badgeContent || ''} color="primary" sx={{ width: "100%" }}>
                            <FormControlLabel
                                value={option2}
                                control={<Radio />}
                                sx={{ width: "100%" }}
                                label={<span style={{ width: "100%" }}>{_.capitalize(option2)}</span>}
                                disabled={disabled}
                            />
                            {option2Props?.extraItem || null}
                        </Badge>
                    ) : (
                        <>
                            <FormControlLabel
                                value={option2}
                                control={<Radio />}
                                sx={{ width: "100%" }}
                                label={<span style={{ width: "100%" }}>{_.capitalize(option2)}</span>}
                                disabled={disabled}
                            />
                            {option2Props?.extraItem || null}
                        </>
                    )}
                </Grid>
            </Grid>
        </RadioGroup>
    </>);
};

export default CustomRadioGroup;
