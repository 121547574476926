import React from "react";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../redux/slices/groupDetailSlice";
import { Box, Avatar, Stack, Typography, IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { BACKEND_URL } from "../../../../../utils/constants/apis.urls";
import PreviewImage from "../../../../../components/PreviewImage";

const GroupDetailDialog = ({ open, handleClose }) => {
    const groupData = useSelector(groupDetails)

    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                sx={{
                    "& .MuiDialog-container": {
                        xs: {
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                        }, sm: {
                            justifyContent: "center",
                            alignItems: "center"
                        }
                    }
                }}
                PaperProps={{ sx: { width: { xs: '100%' }, m: { xs: 0 }, pb: '1rem' } }}
            >
                <DialogTitle>
                    <IconButton onClick={() => handleClose()} sx={{ float: 'right', p: 0 }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton><br /><br />
                    <Stack direction='row' spacing={2} alignItems='center'>
                        {groupData?.group_logo_path ?
                            <Box
                                sx={{
                                    borderRadius: "5px",
                                    width: { xs: "20%" },
                                    height: "6rem",
                                    ":hover": {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                <PreviewImage selectedImage={`${groupData?.group_logo_path}`} />
                            </Box>
                            : <Avatar sx={{ width: '4rem', height: '4rem' }} />
                        }
                        <Box>
                            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                {groupData?.title}
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#6F738B', mt: 1 }}>
                                {groupData?.members} Members
                            </Typography>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ maxHeight: '15rem', my: 2 }}>
                    <Typography variant="h5" sx={{ textAlign: 'justify' }}>
                        {groupData?.description}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    )

};

export default GroupDetailDialog;
