import { Box, Tab, Tabs } from '@mui/material';
import React from 'react'
import MuiTabPanel from '../../../../../../components/muiTabPanel';
import GuestList from './components/GuestList'

const EventGuestTabs = ({ tabValue, handleTabChange }) => {
    const guestTabs = ['Invited', 'Registered'];
    return (
        <>
            <Box display='flex' justifyContent='center'>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor=""
                    sx={{
                        border: '2px solid #FF8359',
                        borderRadius: '10px',
                        minHeight: '30px',
                        minWidth: '60%',
                        '.Mui-selected': {
                            fontWeight: "bold !important",
                            color: '#FFFFFF !important',
                            backgroundColor: '#FF8359',
                            opacity: '1 !important'
                        },
                    }}
                >
                    {guestTabs.map((tab, index) => (
                        <Tab key={index} label={tab} sx={{
                            color: '#FF8359',
                            minHeight: '30px',
                            textTransform: 'none',
                            width: { xs: '50%', md: 'auto' },
                        }} />
                    )
                    )}
                </Tabs>
            </Box>

            {guestTabs.map((tab, index) => (
                <MuiTabPanel key={index} value={tabValue} index={index}>
                    <GuestList />
                </MuiTabPanel>
            ))}

        </>
    )
}

export default EventGuestTabs;