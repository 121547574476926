import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { sendOtp } from "../../../../../services/Authentication/user";
import { codes as countryCodes } from "../../../../../utils/constants/countries.codes";
import { PAGE_PATHS } from "../../../../../utils/constants/page-path.constants";

const MobileLoginForm = () => {
    // const phoneRegExp = /^\+[0-9]{1,4}[- ]?[0-9]{8,15}$/;
    const schema = yup
        .object({
            CountryCode: yup.string().required("This is a required field")
                .matches(/[0-9]{1,4}/, "Phone number is not valid"),
            MobileNumber: yup
                .string()
                .required("This is a required field")
                .test("is-valid-number", "Phone number is not valid", (value, { parent }) => {
                    // Check if the CountryCode is "+91" for Indian numbers
                    if (parent.CountryCode === "91") {
                        return /^[0-9]{10}$/.test(value); // Indian numbers must have 10 digits
                    } else {
                        return /^[0-9]{8,13}$/.test(value); // Other numbers can have 8 to 13 digits
                    }
                })
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema)
    });

    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    const [loadingSms, setLoadingSms] = useState(false);
    const [isWhatsApp, setWhatsapp] = useState(true);

    const onSubmit = (data) => {
        data.otp_method = isWhatsApp ? 'whatsapp' : 'sms';
        if (isWhatsApp) {
            setLoadingWhatsapp(true);
        } else {
            setLoadingSms(true);
        }
        sendOtp({
            dialing_code: data.CountryCode,
            mobile: data.MobileNumber,
            otp_method: data.otp_method
        }).then((res) => {
            console.log('------------>', res);
            isWhatsApp ? setLoadingWhatsapp(false) : setLoadingSms(false);
            toast.info("OTP has been sent!", {
                position: "top-right",
                theme: "colored"
            });
            navigate(`/${PAGE_PATHS.OTP_INPUT}`, { state: data });
        }).catch((err) => {
            console.log("$$$$$$$$", err);
            reset();
            isWhatsApp ? setLoadingWhatsapp(false) : setLoadingSms(false);
            toast.error(err?.response?.data?.message, {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    let navigate = useNavigate();

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Stack spacing={{ xs: "1rem" }} onKeyDown={handleKeyDown}>
                    <Stack direction="row" spacing={1} mx={1}>
                        <Box>
                            <Select
                                color="warning"
                                defaultValue="91"
                                error={errors.CountryCode ? true : false}
                                {...register("CountryCode")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: "300px",
                                            width: "7rem",
                                            marginTop: "0.5rem",
                                            border: "1px solid #E4E4E4",
                                            p: 1.5,
                                            pt: 0.75,
                                            boxShadow: "0",
                                            borderRadius: "4px",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 2,
                                            scrollbarWidth: "0px",
                                            "&& .Mui-selected": {
                                                backgroundColor: "#FFEBE4",
                                                border: "1px solid #E4E4E4",
                                                "&:hover": {
                                                    backgroundColor: "#FAFBFD"
                                                }
                                            }
                                        }
                                    }
                                }}
                            >
                                {countryCodes.map((code, index) => (
                                    <MenuItem key={index} value={code.dial_code}>
                                        <Box>+{code.dial_code}</Box>
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.CountryCode && (
                                <Typography
                                    variant="h6"
                                    sx={{ mt: "5px", mr: "10px", color: "red" }}
                                >
                                    {errors.CountryCode?.message}
                                </Typography>
                            )}
                        </Box>

                        <TextField
                            required
                            color="warning"
                            label="Mobile Number"
                            inputProps={{
                                type: "number",
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                // style: {paddingLeft: {md: "20rem" } }
                            }}
                            error={errors.MobileNumber ? true : false}
                            helperText={
                                errors.MobileNumber
                                    ? <span style={{}}>{errors.MobileNumber.message}</span>
                                    : ""
                            }
                            {...register("MobileNumber")}
                        />
                    </Stack>

                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                        <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>Not using whatsapp?</Typography>
                        <LoadingButton
                            type="submit"
                            color="warning"
                            loading={loadingSms}
                            onClick={() => setWhatsapp(false)}
                            startIcon={loadingSms ? <SaveIcon /> : ''}
                            loadingPosition={loadingSms ? "start" : null}
                        >
                            {loadingSms ? 'Sending..' : 'Send SMS'}
                        </LoadingButton>
                    </Stack>

                    <Box className="btn-position">
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            loading={loadingWhatsapp}
                            onClick={() => setWhatsapp(true)}
                            startIcon={loadingWhatsapp ? <SaveIcon /> : ''}
                            loadingPosition={loadingWhatsapp ? "start" : null}
                            sx={{ bgcolor: "#1F274A", color: "white" }}
                        >
                            {loadingWhatsapp ? 'Sending..' : 'Get OTP on WhatsApp'}
                        </LoadingButton>
                    </Box>
                </Stack>
            </form>
        </>
    );
};

export default MobileLoginForm;
