import { useTheme } from '@emotion/react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, FormControl, FormHelperText, InputLabel, Link, MenuItem, Select, Skeleton, Stack, Typography } from "@mui/material";
import _ from 'lodash';
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { EventSettingsContext } from ".";
import { EventDetailsContext } from '../../../..';
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import { eventDetails } from "../../../../../../redux/slices/eventDataSlice";
import { userDetails } from "../../../../../../redux/slices/userDataSlice";
import { getBanks } from "../../../../../../services/bankDetails";
import { getEventTickets } from "../../../../../../services/wEvents/eventDetails";
import { PAGE_PATHS } from '../../../../../../utils/constants/page-path.constants';

const TicketTypeOptions = ({ register, eventTicketType, handleTicketTypeRadioChange }) => {
    const theme = useTheme();
    const userData = useSelector(userDetails);
    const eventDetailsObj = useSelector(eventDetails);
    const { refs } = useContext(EventDetailsContext);
    const { banks, setBanks, setSlideType, handleNextClick, setTickets } = useContext(EventSettingsContext)

    const handleTicketClick = () => {
        // fetch hidden and unhidden tickets
        getEventTickets({ eventId: eventDetailsObj?.id }).then((res) => {
            setTickets(res.data.data)
            setSlideType('ticket')
            handleNextClick();
        }).catch((err) => {
            console.log(err);
        })
    }

    const [bankCollapse, setBankCollapse] = useState(false);
    const [notVerifiedBanks, setNotVerifiedBanks] = useState(0);
    const handleChooseBankClick = () => {
        getBanks(userData?.id, { verified: true }).then((res) => {
            const verifiedBanks = res.data.verified_banks;
            const allBanks = res.data.all_banks;
            setBanks(verifiedBanks);
            if (verifiedBanks.length === 0 && allBanks.length > 0) {
                setNotVerifiedBanks(_.difference(allBanks, verifiedBanks).length);
            }
        }).catch((err) => {
            console.log(err);
        })
        setBankCollapse(!bankCollapse);
    }

    return (
        <Box>
            <Typography sx={{ mb: 1 }}>Ticket type</Typography>
            <CustomRadioGroup value={eventTicketType} handleRadioChange={handleTicketTypeRadioChange} option1='free' option2='paid' />

            <Stack
                ref={refs?.ticketsBtnRef}
                direction='row' alignItems='center' justifyContent='space-between'
                onClick={handleTicketClick}
                spacing={1.5}
                sx={{
                    ":hover": {
                        cursor: 'pointer',
                    },
                    pt: 1
                }}>
                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                    Tickets
                </Typography>
                <Box sx={{ pr: 0.5 }}><ArrowForwardIosIcon sx={{ color: '#1F274A' }} /></Box>
            </Stack>

            <Collapse in={eventTicketType === 'paid'} unmountOnExit={true}>
                <Stack spacing={1.5} sx={{ pt: 2 }}>

                    <Stack
                        direction='row'
                        alignItems='center' justifyContent='space-between'
                        onClick={handleChooseBankClick}
                        sx={{
                            ":hover": {
                                cursor: 'pointer',
                            }
                        }}>
                        <Typography sx={{ fontSize: { xs: '1rem' } }}>
                            Choose bank
                        </Typography>
                        <Box sx={{ pr: 0.5 }}>
                            {bankCollapse ?
                                <ExpandMoreIcon sx={{ color: '#1F274A' }} /> :
                                <ArrowForwardIosIcon sx={{ color: '#1F274A' }} />
                            }
                        </Box>
                    </Stack>

                    <Collapse in={bankCollapse} unmountOnExit={true}>
                        {banks ?
                            <>
                                {banks.length > 0 ?
                                    <Stack sx={{ pt: 1 }} spacing={1}>
                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-helper-label">Bank</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                label="Bank"
                                                defaultValue={eventDetailsObj?.linked_bank ?? ''}
                                                renderValue={(value) => {
                                                    return typeof (value) === 'object' ? value.bank_name : eventDetailsObj?.linked_bank;
                                                }}
                                                {...register('bank')}
                                            >
                                                {banks.map((option, index) => (
                                                    <MenuItem key={index} value={option} >
                                                        <Stack spacing={0.5}>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: '600', fontSize: '15px', display: 'inline' }}>Bank name: </Typography>
                                                                <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{option.bank_name}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline' }}>Ifsc code:</Typography>
                                                                <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{option.ifsc_code}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline' }}>Account number:</Typography>
                                                                <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{option.account_number}</Typography>
                                                            </Box>
                                                        </Stack>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>Select bank in which you want payment</FormHelperText>
                                        </FormControl>
                                    </Stack>
                                    :
                                    <>
                                        {notVerifiedBanks ?
                                            <Typography sx={{
                                                fontSize: '0.9rem',
                                                ml: 1, mt: 1,
                                                color: 'rgba(0, 0, 0, 0.6)'
                                            }}>
                                                Please get your banks verified.
                                            </Typography>
                                            :
                                            <Box sx={{
                                                pl: 1,
                                                pt: 1
                                            }}>
                                                <Link underline="none" target="_blank"
                                                    href={`/${PAGE_PATHS.BANK_DETAILS}`}
                                                    sx={{
                                                        fontSize: '0.95rem',
                                                        fontFamily: 'Poppins',
                                                        color: theme.palette.primaryColor
                                                    }}>
                                                    Add bank via bank details section.
                                                </Link>
                                            </Box>
                                        }
                                    </>
                                }
                            </> :
                            <Skeleton animation="wave" sx={{ flex: 1, ml: 1, height: '2rem' }} />
                        }
                    </Collapse>
                </Stack>
            </Collapse>
        </Box>
    )
};

export default TicketTypeOptions;
