import React, { useContext, useState } from "react";
import DialogBox from "../../../../../../../../components/DialogBox";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { addGroupMembersAsEventGuests, getEventGuests } from "../../../../../../../../services/wEvents/eventGuests";
import { EventGuestsContext } from "../../EventGuestContext";
import { toast } from "react-toastify";

const ConfirmationDialogContent = (handleClose) => {
    const eventData = useSelector(eventDetails);
    const [loading, setLoading] = useState(false);
    const { tabValue, totalGuests, setGuests, setSearchedGuest } = useContext(EventGuestsContext);

    const handleBulkAddEventGuests = () => {
        setLoading(true);
        setSearchedGuest(null);
        const payload = {
            'groupId': eventData?.group_id
        };
        addGroupMembersAsEventGuests(payload, eventData?.id).then((res) => {
            getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered' }).then((res2) => {
                totalGuests.current = res2.data.meta.total
                setGuests(res2.data.data)
                setLoading(false);
                toast.success(res.data.message, {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose();
            }).catch((err) => {
                console.log(err);
            })
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            });
            setLoading(false);
            handleClose();
        })
        toast.success("Request submitted please check after some time", {
            position: "top-right",
            theme: "dark"
        });
        handleClose();
        setLoading(false);
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography>Are you sure you want to add all group members to this event?</Typography>
                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        onClick={handleBulkAddEventGuests}
                        loading={loading ? true : false}
                        startIcon={loading ? <SaveIcon /> : null}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Adding...' : 'Yes'}
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </>
    )
}
const AddMembersToEventConfirmationDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Add guests' content={ConfirmationDialogContent(handleClose)}
            />
        </>
    )
};

export default AddMembersToEventConfirmationDialog;
