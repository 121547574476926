import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/WowslyMobileLogo2.png';
import { DrawerTabs } from '../utils/constants/drawer.tabs';
import { sidebarTabs } from '../utils/constants/sidebar.tabs';

const DashboardDrawer = ({ handleMobileDrawerToggle, handleWebDrawerToggle }) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const isSmallDevices = useMediaQuery("(max-width:900px)");
    const [selectedIndex, setSelectedIndex] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        setSelectedIndex(sidebarTabs.indexOf(pathname))
    }, [pathname]);

    const handleClick = (item) => {
        navigate(`/${item.url}`)
    }

    return (
        <>
            <Box sx={{ mt: '0.75rem' }}>
                <IconButton
                    size='small'
                    onClick={isSmallDevices ? handleMobileDrawerToggle : handleWebDrawerToggle}
                    sx={{
                        float: 'right',
                        mr: 1,
                        boxShadow: '0 0 10px 1px rgb(115 103 240 / 70%)'
                        // background: 'linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7))',
                    }}
                >
                    {
                        theme.direction === 'ltr' ?
                            <MenuOpenIcon sx={{ color: 'white' }} /> :
                            <></>
                    }
                </IconButton>
            </Box>
            <Box onClick={isSmallDevices ? handleMobileDrawerToggle : null}
                sx={{ textAlign: 'center', flex: 1 }}>
                <Toolbar sx={{ justifyContent: 'center', width: '100%' }}>
                    <Box component='img'
                        sx={{
                            width: '7rem',
                            height: '7rem',
                        }}
                        alt="Wowsly Logo"
                        src={logo}
                    />
                </Toolbar>
                <List sx={{
                    mt: 2,
                    mx: 1,
                    '&& .Mui-selected': {
                        borderRadius: '4px',
                        p: '0.3rem 0rem',
                        overflow: 'auto',
                        background: 'linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7))',
                        boxShadow: '0 0 10px 1px rgb(115 103 240 / 70%)'
                    }
                }}>
                    {DrawerTabs.map((item, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                        >
                            <ListItemButton
                                sx={{ textAlign: 'left' }}
                                onClick={() => handleClick(item)}
                                selected={selectedIndex === -1 ? index === 0 : selectedIndex === index}
                            >
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='center'
                                    sx={{
                                        p: (selectedIndex === index || (selectedIndex === -1 && index === 0)) ? 1 : 0,
                                        ml: (selectedIndex === index || (selectedIndex === -1 && index === 0)) ? 1 : 0
                                    }}>
                                    {item.icon}<ListItemText sx={{ ml: 1.5 }} primary={item.name} />
                                </Stack>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );
};

export default DashboardDrawer;
