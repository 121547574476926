import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { eventDetails, update_event_details } from "../../../../redux/slices/eventDataSlice";
import { reorderEventModelViews } from "../../../../services/wEvents/eventDetails";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DragAndDropViewContent = (handleClose, initialViews) => {
    const {
        handleSubmit,
    } = useForm();
    const eventData = useSelector(eventDetails);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [views, setViews] = useState(initialViews);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the droppable area
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const updatedViews = [...views];
        const [draggedView] = updatedViews.splice(startIndex, 1);
        updatedViews.splice(endIndex, 0, draggedView);

        setViews(updatedViews);
    }

    const mySubmit = () => {
        setLoading(true);

        reorderEventModelViews(views, eventData?.id).then((res) => {
            let updatedEventData = JSON.parse(JSON.stringify(eventData));
            updatedEventData.tabs[0].views = [...res.data.data];

            dispatch(update_event_details(updatedEventData));
            toast.success("Views reordered successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setLoading(false);
            handleClose();
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(Object.values(err.response?.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        })
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="sections">
                        {(provided) => (
                            <Stack spacing={2} ref={provided.innerRef} {...provided.droppableProps} sx={{ pb: '1rem' }}>
                                {views.map((v, i) => (
                                    <Draggable key={i} draggableId={i.toString()} index={i}>
                                        {(provided) => (
                                            <Box ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} sx={{ border: '2px solid lightgrey', borderRadius: '0.2rem', p: 1 }}>
                                                <Typography>{v?.title}</Typography>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Stack>
                        )}
                    </Droppable>
                </DragDropContext>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <LoadingButton
                        type="submit"
                        color='warning'
                        variant="contained"
                        loading={loading ? true : false}
                        loadingPosition={loading ? "start" : null}
                        startIcon={loading ? <SaveIcon /> : null}
                        sx={{
                            boxShadow: 'none'
                        }}>
                        Reorder
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const CreateDragAndDropDialog = ({ views, title, dialogOpen, handleDialogOpen, handleDialogClose }) => {
    const content = DragAndDropViewContent((e, r) => handleDialogClose(e, r, 'dragView', true), views);
    return (
        <>
            <Box sx={{
                ":hover": { backgroundColor: '#FFEBE4', cursor: 'pointer' },
                px: '1.5rem', width: '100%', textAlign: 'center'
            }}>
                <Typography sx={{ lineHeight: '2rem' }} onClick={() => handleDialogOpen('dragView')}>{title}</Typography>
            </Box>
            {
                dialogOpen.dragView ?
                    <Dialog
                        open={true}
                        onClose={(e, r) => handleDialogClose(e, r, 'dragView')}
                        PaperProps={{
                            sx: {
                                width: { xs: '90%', sm: '100%' }, m: { xs: 0 },
                                maxWidth: { xs: '90%', sm: '60%', md: '40%' },
                            }
                        }}
                    >
                        <DialogTitle>
                            <IconButton onClick={(e, r) => handleDialogClose(e, r, 'dragView')} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                                <CloseIcon fontSize='medium' />
                            </IconButton>
                            <Typography sx={{ fontSize: '1.5rem' }}>Manage Views</Typography>
                        </DialogTitle>
                        <Divider sx={{ mb: 1 }} />
                        <DialogContent sx={{ px: '1rem', py: '0rem' }}>
                            {content}
                        </DialogContent>
                    </Dialog> :
                    null
            }
        </>
    )
};

export default CreateDragAndDropDialog;
