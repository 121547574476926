import React, { useEffect, useRef, useState } from "react";
import { Box, Button, DialogActions, Divider, Stack } from "@mui/material";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

const TO_RADIANS = Math.PI / 180

function useDebounceEffect(
    fn,
    waitTime,
    deps,
) {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps)
        }, waitTime)

        return () => {
            clearTimeout(t)
        }
    }, deps)
}

async function canvasPreview(
    image,
    canvas,
    crop,
    scale = 1,
    rotate = 0,
) {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}

const aspectTypesMapping = {
    landscape: 16 / 9,
    square: 1,
    free: undefined
}

const CropImage = ({
    aspectType,
    imageToCrop,
    handleDialogClose,
    uploadImage,
    allowChangeImage = false,
    defaultPercentageCrop = null,
    updatePercentageCrop = (_) => { },
    changeImage = () => { }
}) => {
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [percentageCrop, setPercentageCrop] = useState();
    const [aspect, setAspect] = useState(aspectType in aspectTypesMapping ? aspectTypesMapping[aspectType] : 1);
    const [imgSrc, setImgSrc] = useState('')

    useEffect(() => {
        setCrop(undefined) // Makes crop preview update between images.
        if (typeof imageToCrop === 'string') {
            setImgSrc(imageToCrop);
            return;
        }
        const reader = new FileReader()
        reader.addEventListener('load', () =>
            setImgSrc(reader.result?.toString() || ''),
        )
        reader.readAsDataURL(imageToCrop)
    }, []);

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop
                )
            }
        },
        100,
        [completedCrop],
    )

    const submitCrop = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }

        if (!allowChangeImage) {
            // toBlob(callback, type, quality)
            previewCanvasRef.current.toBlob(async (blob) => {
                const blobUrl = URL.createObjectURL(blob); // Replace with your Blob URL
                // Fetch the Blob data
                const response = await fetch(blobUrl);
                const blobData = await response.blob();

                // Create a new File object
                const convertedFile = new File([blobData], imageToCrop.name, { type: imageToCrop.type });
                uploadImage(convertedFile);
            },
                imageToCrop.type,
                0.5  // A Number between 0 and 1 indicating the image quality to be used
            );
        } else {
            updatePercentageCrop(percentageCrop);
        }
    }

    function onImageLoad(e) {
        if (defaultPercentageCrop) {
            setCrop(defaultPercentageCrop);
            return;
        }
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: 75,
                    },
                    aspect, width, height,
                ),
                width, height,
            ))
        }
    }

    return (
        <Stack spacing={3} alignItems='center' justifyContent='center'>
            <Stack sx={{ maxWidth: 'fit-content !important', width: '100%' }}>
                {!!imgSrc &&
                    <ReactCrop crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c, p) => {
                            setCompletedCrop(c);
                            setPercentageCrop(p);
                        }}
                        style={{ maxWidth: 'fit-content !important' }}
                        aspect={aspect}
                    >
                        <Box component='img'
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                }
            </Stack>

            {allowChangeImage ? (
                <>
                    <Divider flexItem dir='horizontal' />
                    <Button variant='outlined' color='warning' onClick={() => changeImage()}>
                        Change Image
                    </Button>
                </>
            ) : null}

            {!!completedCrop && (
                <>
                    <Box>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                display: 'none',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </Box>
                </>
            )}

            <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                <Button variant="outlined" color='warning' onClick={() => handleDialogClose('crop')}>
                    Cancel
                </Button>
                <Button color='warning' variant="contained"
                    sx={{
                        boxShadow: 'none'
                    }}
                    onClick={submitCrop}
                >
                    Ok
                </Button>
            </DialogActions>
        </Stack>
    )
};

export default CropImage;
