import { Avatar, Box, DialogActions, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import DialogBox from "../../../../../../../../../components/DialogBox";
import { BACKEND_URL } from "../../../../../../../../../utils/constants/apis.urls";

const MemberDetailContent = (memberDetails) => {
    return (
        <>
            <Box sx={{ mb: 3, mt: 2 }}>
                <Stack direction='row' spacing={2} alignItems='flex-start' sx={{ wordBreak: 'break-all' }}>
                    {memberDetails?.photo ? (
                        <Avatar src={`${memberDetails?.photo}`}
                            alt='Event logo'
                            variant="rounded"
                            sx={{ width: '6rem', height: '6rem', backgroundColor: '#F0F0F0' }} />
                    ) :
                        null
                    }
                    <Stack spacing={1}>
                        <Typography sx={{ fontWeight: 'bold', lineHeight: '1.8rem', fontSize: '1.5rem' }}>
                            {memberDetails?.title}
                        </Typography>
                        <Typography sx={{ fontSize: '1rem', color: '#6F738B' }}>
                            Type: {_.capitalize(memberDetails?.type)}
                        </Typography>
                    </Stack>
                </Stack>
                <Divider sx={{ my: 2 }} />

                <Stack spacing={1.5}>
                    {memberDetails?.relation ?
                        <Typography sx={{ fontSize: '1rem' }}>
                            <strong>Relation:</strong> {memberDetails?.relation}
                        </Typography>
                        : null
                    }
                    {memberDetails?.designation ?
                        <Typography sx={{ fontSize: '1rem' }}>
                            <strong>Designation:</strong> {memberDetails?.designation}
                        </Typography>
                        : null
                    }
                    {memberDetails?.description ?
                        <Typography sx={{ fontSize: '1rem' }}>
                            <strong>About:</strong> {memberDetails?.description}
                        </Typography>
                        : null
                    }
                    <Box>
                        {memberDetails?.contacts ?
                            <>
                                <Typography><strong>Contact Details</strong></Typography>
                                {Object.entries(memberDetails?.contacts).map((c, i) => (
                                    <Typography key={i} sx={{ fontSize: '1rem', color: '#6F738B' }}>
                                        {_.capitalize(c[0])}: {c[1]}
                                    </Typography>
                                ))}
                            </>
                            : null
                        }
                    </Box>

                    <Box>
                        {memberDetails?.socials ?
                            <>
                                <Typography><strong>Social Details</strong></Typography>
                                {Object.entries(memberDetails?.socials).map((s, i) => (
                                    <Typography key={i} sx={{ fontSize: '1rem', color: '#6F738B' }}>
                                        {_.capitalize(s[0])}: {s[1]}
                                    </Typography>
                                ))}
                            </>
                            : null
                        }
                    </Box>
                </Stack>

                <DialogActions></DialogActions>
            </Box>
        </>
    )
};

const MemberDetailDialog = ({ handleClose, memberDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Person details' content={MemberDetailContent(memberDetails)}
        />
    )
};

export default MemberDetailDialog;