import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useContext } from "react";
import { EventGuestsContext } from "../../../EventGuestContext";

const SendLaterConfirmation = ({ loading, sendInvite, handleCloseConfirmation, isDateError, setDateError }) => {
    const isBigDevices = useMediaQuery("(min-width:600px)");
    const { scheduledDate, setScheduledDate } = useContext(EventGuestsContext);
    return (
        <>
            <Box sx={{ px: 2, py: 1 }}>
                <Typography sx={{ textAlign: 'left', py: '0.75rem' }}>
                    Choose a specific date and time for scheduling the invitation you wish to send.
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                        disablePast={true}
                        renderInput={(props) => (
                            <TextField {...props}
                                fullWidth
                                color="warning"
                                error={isDateError ? true : false}
                                helperText={isDateError ?? null}
                                inputProps={{ ...props.inputProps }}
                            />
                        )}
                        label="Schedule Invite"
                        value={scheduledDate}
                        onChange={(newValue) => {
                            newValue && setDateError(false);
                            setScheduledDate(newValue);
                        }}
                    />
                </LocalizationProvider><br />
                <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ my: 1, p: 1 }}>
                    {isBigDevices ? <Button size='small' color='warning' variant='outlined'
                        onClick={handleCloseConfirmation}>
                        Cancel
                    </Button> : null}
                    <LoadingButton
                        type="submit"
                        size='small'
                        color="warning"
                        variant='contained'
                        loading={loading}
                        onClick={() => sendInvite('sendLater')}
                        startIcon={loading ? <SaveIcon /> : ''}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Scheduling...' : "Schedule"}
                    </LoadingButton>
                </Stack>
            </Box>
        </>
    )
};

export default SendLaterConfirmation;
