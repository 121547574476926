import React, { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddSubstituteGuestDialog from "./AddSubstituteGuestDialog";

const AddSubstituteGuest = ({ title }) => {
    let [open, setOpen] = useState(false);
    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    return (
        <>
            <Tooltip title="Invite guest" arrow>
                {title ? <Box sx={{
                    ":hover": { backgroundColor: 'lightcyan', cursor: 'pointer' },
                    px: '1.5rem', width: '100%', textAlign: 'center'
                }}>
                    <Typography onClick={handleOpen} sx={{
                        lineHeight: '2rem'
                    }}>{title}</Typography>
                </Box>
                    :
                    <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                        <AddCircleOutlineIcon color='warning'
                            sx={{
                                width: '2rem', height: '1.5rem'
                            }} /></IconButton>
                }
            </Tooltip>
            {open ? <AddSubstituteGuestDialog handleClose={handleClose} /> : null}
        </>
    )
};

export default AddSubstituteGuest;