import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Stack, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography, useMediaQuery } from "@mui/material";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { eventDetails } from '../../../../../../../../../redux/slices/eventDataSlice';
import { getEventReceivedPaymentDetails } from '../../../../../../../../../services/wEvents/eventDetails';
import { ticketCurrencies } from "../../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../../utils/functions/getFormattedNumber";
import { stringAvatar } from "../../../../../../../../../utils/functions/muiAvatar";
import { convertUtcToLocal } from "../../../../../../../../../utils/functions/timeConversion";
import OneTicketPaymentDetails from './OneTicketPaymentDetails';

const ItemAvatar = memo(({ name }) => {
    return (
        <Avatar {...stringAvatar(name?.trim(), { singleLetter: true })} />
    )
});

const TicketPaymentItem = ({ item }) => {
    const isSmallDevice = useMediaQuery("(max-width:480px)");
    const eventDetailsObj = useSelector(eventDetails);
    const [details, setDetails] = useState(null);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        if (!open) {
            setLoader(true);
            getEventReceivedPaymentDetails(eventDetailsObj?.id, item.id).then((res) => {
                setLoader(false);
                setDetails(res.data.data);
            }).catch((err) => {
                console.log(err);
                setLoader(false);
            })
        }
    };

    return (
        <React.Fragment>
            <ListItem onClick={item ? handleClick : null}
                sx={{
                    px: { xs: 1, md: 1.5 },
                    py: 0.5,
                    backgroundColor: open ? '#F1F1F1' : 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}
            >
                <ListItemAvatar>
                    {item ? <ItemAvatar name={item.invited_guest_name} /> :
                        <Skeleton animation="wave" variant="circular" width='2.5rem' height='2.5rem' />}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Stack direction='row'>
                            {isSmallDevice ?
                                (
                                    item ?
                                        <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                            {parseDecimalNumber(item.amount_to_pay, 3)}&nbsp;
                                            <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === item.currency)?.symbol }} />&nbsp;
                                        </Typography>
                                        : null
                                )
                                : null
                            }
                            {item ?
                                <Typography>
                                    Received from {item.invited_guest_name}
                                </Typography>
                                : <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '100%', sm: '80%' }, transform: 'scale(1,1)' }} />
                            }
                        </Stack>
                    }
                    secondary={item ? `${convertUtcToLocal(item.payment_time).format("Do MMMM YYYY, h:mm A")}` :
                        <Skeleton animation="wave" height='1.5rem' sx={{ width: { xs: '75%', sm: '65%' }, mt: 1 }} />
                    }
                />
                {!isSmallDevice ?
                    (
                        item ?
                            <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                {parseDecimalNumber(item.amount_to_pay, 3)}&nbsp;
                                <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === item.currency)?.symbol }} />&nbsp;
                            </Typography>
                            : <Skeleton animation="wave" variant="rounded" width='2.5rem' height='2.5rem' />
                    )
                    : null
                }
                {item ? (open ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {loader ?
                        <Skeleton height='10rem' variant='wave' sx={{
                            m: 2, transform: 'scale(1,1)',
                        }} />
                        :
                        <OneTicketPaymentDetails details={details} />
                    }
                </List>
            </Collapse>
            <Divider variant="inset" component="li" sx={{ ml: 0 }} />
        </React.Fragment >
    )
};

export default TicketPaymentItem;
