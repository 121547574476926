import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination, Skeleton,
    Stack,
    Typography,
    useMediaQuery, CircularProgress
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NoRepliesGif from "../../../../../../../../assets/no-replies.gif";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import {
    downloadEventFormReplies,
    getEventFormReplies
} from "../../../../../../../../services/wEvents/eventDetails";
import { convertUtcToLocal } from "../../../../../../../../utils/functions/timeConversion";
import moment from "moment";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import FormDateTimePicker from "./FormDateTimePicker";

const RFormReplies = () => {
    const eventData = useSelector(eventDetails);
    const [replies, setReplies] = useState(null);
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(false); // New state for page loading
    const [showDetails, setShowDetails] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [displayedPage, setDisplayedPage] = useState(1); // New state to track displayed page
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [isDialogOpen, setDialogOpen] = useState(false);
    // to download form replies between start date and end date
    const [dateTime, setDateTime] = useState({
        start_date: null,
        end_date: null
    });

    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const listContainerRef = useRef(null);
    const isMediumSmallDevices = useMediaQuery("(max-width:700px)");

    const handleItemClick = (e, item) => {
        setDetails(item);
        setShowDetails(true);
    };

    const fetchReplies = async (page, isInitial = false) => {
        if (isInitial) {
            setInitialLoading(true);
        } else {
            setPageLoading(true);
        }
        
        try {
            const result = await getEventFormReplies(eventData?.id, currentTimeZone, page);
            const responseData = result.data;
            
            setReplies(responseData.data);
            setTotalCount(responseData.total);
            setDisplayedPage(responseData.current_page);
            
            if (!isInitial && listContainerRef.current) {
                listContainerRef.current.scrollTop = 0;
            }
        } catch (err) {
            console.log(err);
            // Revert to previous page on error
            setCurrentPage(displayedPage);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        } finally {
            if (isInitial) {
                setInitialLoading(false);
            } else {
                setPageLoading(false);
            }
        }
    };

    useEffect(() => {
        if (eventData?.id) {
            fetchReplies(currentPage, true);
        }
    }, [eventData?.id, currentTimeZone]);

    const handlePageChange = (event, page) => {
        if (page !== currentPage) {
            setCurrentPage(page); // Update UI immediately
            fetchReplies(page, false);
        }
    };

    const totalPages = Math.ceil(totalCount / 20);

    const handleDialogClose = () => {
        setDialogOpen(false);
        setDateTime({ ...dateTime, start_date: null, end_date: null });
    };

    const handleDownload = () => {
        if (dateTime.end_date && dateTime.start_date >= dateTime.end_date) {
            toast.error("Start date must be less than end date", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        setLoading(true);
        let payload = {};
        payload["current_time_zone"] = currentTimeZone;
        payload["start_date"] = dateTime.start_date
            ? dayjs(dateTime.start_date).format("YYYY-MM-DD")
            : null;
        payload["start_time"] = dateTime.start_date
            ? dayjs(dateTime.start_date).format("HH:mm:ss")
            : null;
        payload["end_date"] = dateTime.end_date
            ? dayjs(dateTime.end_date).format("YYYY-MM-DD")
            : null;
        payload["end_time"] = dateTime.end_date
            ? dayjs(dateTime.end_date).format("HH:mm:ss")
            : null;
        downloadEventFormReplies(eventData?.id, payload)
            .then((result) => {
                console.log(result);
                const fileBlob = new Blob([result.data], { type: "text/csv" });
                const downloadLink = document.createElement("a");
                document.body.appendChild(downloadLink);
                const fileUrl = URL.createObjectURL(fileBlob);

                // Create a link to download the file
                downloadLink.href = fileUrl;
                downloadLink.download = `${
                    eventData.title
                }-Replies-${moment().format("D-M-YYYY HH-mm")}.csv`;
                // Add the link to the DOM and click it
                downloadLink.click();
                setLoading(false);
                handleDialogClose();

                // Clean up the URL object
                URL.revokeObjectURL(fileUrl);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error("Something went wrong", {
                    position: "top-right",
                    theme: "dark"
                });
                handleDialogClose();
            });
    };

    // Skeleton loader for list items
    const ListItemSkeleton = () => (
        <ListItem>
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton width="60%" />}
                secondary={<Skeleton width="40%" />}
            />
        </ListItem>
    );
        

    const changeDate = (field) => (newValue) => {
        setDateTime({ ...dateTime, [field]: dayjs(newValue).toDate() });
    };

    return (
        <>
            {showDetails ? (
                <>
                    <Box sx={{ height: { xs: '22rem', md: '27rem' }, overflow: 'auto', px: '1rem' }}>
                        <Stack spacing={3} sx={{ p: 1 }}>
                            {details.form_replies?.map((d, i) => (
                                <FormControl key={i}>
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Q. &nbsp;&nbsp; {d.question}</Typography>
                                    {d.type === 'checkbox' && d.answer !== null ? (
                                        <Typography>A. &nbsp;&nbsp; {(JSON.parse(d.answer).join(', ')) ?? '---'}</Typography>
                                    ) : d.type === 'file' && d.answer !== null ? (
                                        <Typography>A. &nbsp;&nbsp;
                                            <Box component="img" src={d.answer} alt="uploaded file" sx={{ width: '100%', maxWidth: 200, mt: 1 }} />
                                        </Typography>
                                    ) : (
                                        <Typography>A. &nbsp;&nbsp; {d.answer ?? '---'}</Typography>
                                    )}
                                </FormControl>
                            ))}
                        </Stack>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <DialogActions sx={{ py: 0 }}>
                        <Button type="submit" color='warning' variant="contained" onClick={() => setShowDetails(false)} sx={{ boxShadow: 'none' }}>
                            Done
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    {initialLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                            <CircularProgress color="warning" />
                        </Box>
                    ) : replies.length > 0 ? (
                        <>
                            <Box sx={{ textAlign: 'center', my: 1 }}>
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<DownloadIcon />}
                                    variant="outlined"
                                    color='warning'
                                    sx={{ boxShadow: 'none' }}
                                    onClick={handleDownload}
                                >
                                    Download Replies
                                </LoadingButton>
                            </Box>
                            <List 
                                ref={listContainerRef} 
                                sx={{ 
                                    width: '100%', 
                                    px: '1rem', 
                                    bgcolor: 'background.paper', 
                                    pt: 0, 
                                    pb: 0, 
                                    // maxHeight: "300px", 
                                    maxHeight: {
                                        lg:"370px",
                                        xs: '300px', // Mobile
                                        md: '360px'  // Desktop
                                    },
                                    overflow: 'auto',
                                    transition: 'all 0.3s ease' 
                                }}
                            >
                                {pageLoading ? (
                                    // Show skeletons while loading new page
                                    Array(10).fill(0).map((_, index) => (
                                        <ListItemSkeleton key={index} />
                                    ))
                                ) : (
                                    replies.map((r, index) => (
                                        <ListItem 
                                            key={index} 
                                            onClick={(e) => handleItemClick(e, r)} 
                                            sx={{
                                                ":hover": { 
                                                    cursor: 'pointer', 
                                                    backgroundColor: '#F1F1F1', 
                                                    transition: '0.5s' 
                                                }
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar sx={{ backgroundColor: '#FFEBE4' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${r.form_replies.find((re) => re.question === 'Name')?.answer || 'N/A'}`}
                                                secondary={convertUtcToLocal(r.timestamp).format("Do MMMM YYYY, h:mm A")}
                                            />
                                        </ListItem>
                                    ))
                                )}
                            </List>
                            <Box sx={{ display: 'flex', 
                                    justifyContent: 'center',
                                    py: 2,
                                    bgcolor: 'background.paper',
                                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                                    position: 'sticky',
                                    bottom: 0,
                                    zIndex: 1}}>
                                <Pagination
                                    color="warning"
                                    variant="outlined"
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    disabled={pageLoading}
                                />
                            </Box>
                        </>
                    ) : (
                        <Stack justifyContent='center' alignItems='center' spacing={2} sx={{ mt: 3 }}>
                            <Box component='img'
                                src={NoRepliesGif}
                                width={isSmallDevice ? '90%' : '50% !important'}
                                height={isSmallDevice ? '15rem' : '17rem'}
                            />
                            <Typography>No form replies yet!!</Typography>
                        </Stack>
                    )}
                </>
            )}
        </>
    );
};

export default RFormReplies;