import { EVENT_API, GROUP_ALBUM_API, GROUP_KEY_PERSONS } from "../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const getGroupKeyPersons = ({ groupId }) => {
    return axiosInstance.get(GROUP_KEY_PERSONS.replace(':group_id', groupId));
}

export const createGroupKeyPerson = (payload, groupId) => {
    return axiosInstance.post(GROUP_KEY_PERSONS.replace(':group_id', groupId), payload,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    );
}

export const editGroupKeyPerson = (payload, groupId, keyPersonId) => {
    return axiosInstance.post(`${GROUP_KEY_PERSONS.replace(':group_id', groupId)}/${keyPersonId}`, payload,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    );
}

export const deleteGroupKeyPerson = (groupId, keyPersonId) => {
    return axiosInstance.delete(`${GROUP_KEY_PERSONS.replace(':group_id', groupId)}/${keyPersonId}`);
}

export const getGroupAlbums = ({ groupId }) => {
    return axiosInstance.get(GROUP_ALBUM_API.GROUP_ALBUM.replace(':group_id', groupId));
}

export const createGroupAlbum = (payload, groupId) => {
    return axiosInstance.post(GROUP_ALBUM_API.GROUP_ALBUM.replace(':group_id', groupId), payload);
}

export const editGroupAlbum = (payload, groupId, groupAlbumId) => {
    return axiosInstance.post(`${GROUP_ALBUM_API.GROUP_ALBUM.replace(':group_id', groupId)}/${groupAlbumId}`,
        payload);
}

export const deleteGroupAlbum = (groupId, groupAlbumId) => {
    return axiosInstance.delete(`${GROUP_ALBUM_API.GROUP_ALBUM.replace(':group_id', groupId)}/${groupAlbumId}`);
}

export const getGroupAlbumImages = (albumId) => {
    return axiosInstance.get(GROUP_ALBUM_API.GROUP_ALBUM_MEDIA.replace(':album_id', albumId));
}

export const createGroupAlbumMedia = (payload, albumId) => {
    return axiosInstance.post(GROUP_ALBUM_API.GROUP_ALBUM_MEDIA.replace(':album_id', albumId), payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}

export const deleteGroupAlbumMedia = (albumId, albumMediaId) => {
    return axiosInstance.delete(`${GROUP_ALBUM_API.GROUP_ALBUM_MEDIA.replace(':album_id', albumId)}/${albumMediaId}`)
}

export const deleteGroupEvent = (eventId) => {
    return axiosInstance.delete(`${EVENT_API.EVENTS}/${eventId}`);
}