import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { toast } from 'react-toastify';

const DeleteFacilityDialog = ({ setAction, facility, handleDeleteEventFacility }) => {
    const handleDeleteClose = () => {
        setAction((action) => ({ ...action, 'delete': false }))
    }
    const handleDelete = () => {
        handleDeleteEventFacility(facility.name)
        toast.success("Event facility deleted successfully", {
            position: "top-right",
            theme: "dark"
        });
        handleDeleteClose();
    }
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleDeleteClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleDeleteClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        Delete facility
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure you want to delete this event facility?</Typography>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={() => handleDeleteClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" color='warning' variant="contained"
                        sx={{ boxShadow: 'none' }} onClick={handleDelete}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteFacilityDialog;