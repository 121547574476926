import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { DialogActions, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../components/DialogBox";
import { eventDetails, update_event_details } from "../../../../redux/slices/eventDataSlice";
import { getEventDetails } from "../../../../services/wEvents/event";
import { createEventModelView } from "../../../../services/wEvents/eventDetails";
import { modelViews } from "../../../../utils/constants/events.modelviews";
import { Box } from '@mui/system';

const CreateEventViewContent = (handleDialogClose) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset, control
    } = useForm();
    const eventData = useSelector(eventDetails);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const mySubmit = (data) => {
        setLoading(true);
        const viewDetails = eventData.tabs[0].views.find((tv) => tv.type === data.type);
        data.view_id = viewDetails.view_id
        data.model_tab_id = viewDetails.model_tab_id
        createEventModelView(data, eventData?.id).then((res) => {
            let updatedEventData = JSON.parse(JSON.stringify(eventData));
            updatedEventData.tabs[0].views.push({ ...res.data.data });
            dispatch(update_event_details(updatedEventData));
                toast.success("View added successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
                setLoading(false);
                handleDialogClose();
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(Object.values(err.response?.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
            handleDialogClose();
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleDialogClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="View Title"
                        name="title"
                        inputProps={{
                            type: 'text',
                        }}
                        error={_.get(errors, 'title') ? true : false}
                        helperText={
                            _.get(errors, 'title') ? 'This is required field' : null
                        }
                        {...register('title', { required: true })}
                    />

                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Controller
                            name="type"
                            control={control}
                            defaultValue={''} // For getting no warnings
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel id="demo-simple-select-helper-label" color="warning">View Type</InputLabel>
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        label="View Type"
                                        color="warning"
                                    >
                                        {modelViews.map((mv, index) => (
                                            <MenuItem key={index} value={mv.value}>
                                                {mv.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            )}
                        />
                    </FormControl>
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <LoadingButton
                        type="submit"
                        color='warning'
                        variant="contained"
                        loading={loading ? true : false}
                        loadingPosition={loading ? "start" : null}
                        startIcon={loading ? <SaveIcon /> : null}
                        sx={{
                            boxShadow: 'none'
                        }}>
                        Create
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const CreateEventViewDialog = ({ title, dialogOpen, handleDialogOpen, handleDialogClose }) => {
    return (
        <>
            <Box sx={{
                ":hover": { backgroundColor: '#FFEBE4', cursor: 'pointer' },
                px: '1.5rem', width: '100%', textAlign: 'center'
            }}>
                <Typography sx={{ lineHeight: '2rem' }} onClick={() => handleDialogOpen('addView')}>{title}</Typography>
            </Box>
            {
                dialogOpen.addView ?
                    <DialogBox open={true} handleClose={(e, r) => handleDialogClose(e, r, 'addView')}
                        title={title} content={CreateEventViewContent((e, r) => handleDialogClose(e, r, 'addView', true))}
                    /> : null
            }
        </>
    )
};

export default CreateEventViewDialog;
