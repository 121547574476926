import { Navigate } from "react-router-dom"
import { useSelector } from 'react-redux';
import { userStatus } from "../redux/slices/userDataSlice";
import { PAGE_PATHS } from '../utils/constants/page-path.constants';

const DefaultRoute = () => {
  const isLoggedIn = useSelector(userStatus);
  return (
    <>
      {isLoggedIn ? <Navigate to={`/${PAGE_PATHS.DASHBOARD}`} /> : <Navigate to={`/${PAGE_PATHS.MOBILE_LOGIN}`} />}
    </>

  );
}

export default DefaultRoute