import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import DialogBox from "../../../../../../../../components/DialogBox";
import pluralize from "pluralize";

const SubstituteGuestDetailContent = (guestDetails) => {
    return (
        <>
            {
                <Box sx={{ mb: 1 }}>
                    <Stack direction='row' spacing={2} alignItems='center' sx={{ wordBreak: 'break-all' }}>
                        <Typography sx={{ flex: 1, fontWeight: 500, fontSize: '1.1rem' }}>
                            Name: {guestDetails?.name}
                        </Typography>
                    </Stack>
                    <Stack spacing={1.5} sx={{ mt: 1.5 }}>
                        {guestDetails?.email ?
                            <Typography sx={{ fontSize: '1rem' }}>
                                Email: {guestDetails?.email}
                            </Typography>
                            : null
                        }
                        {guestDetails?.dialing_code && guestDetails?.mobile ? (
                            <Typography sx={{ fontSize: '1rem' }}>
                                Contact: +{guestDetails?.dialing_code} {guestDetails?.mobile}
                            </Typography>
                        ) : null
                        }
                        {guestDetails?.tickets_distributed ? (
                            <Typography sx={{ fontSize: '1rem' }}>
                                Tickets Distributed: {guestDetails.tickets_distributed} {pluralize('ticket', guestDetails.tickets_distributed)}
                            </Typography>
                        ) : null
                        }
                    </Stack>
                </Box>
            }
        </>
    )
}

const SubstituteGuestDialog = ({ handleClose, guestDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Substitute Guest details' content={SubstituteGuestDetailContent(guestDetails)}
        />
    )
};

export default SubstituteGuestDialog;
