import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, FormControlLabel, Grid, IconButton, Radio, Skeleton, Stack, Typography } from "@mui/material";
import _ from 'lodash';
import React from "react";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";

const SubscriptionSelectionCard = ({ s, handleSubscriptionViewClick }) => {
    const ticketCurrencySymbol = ticketCurrencies.find((tc) => tc.value === s?.currency)?.symbol;

    return (
        <Grid item xs={12} lg={5.5}>
            {s ?
                <Box sx={{
                    py: 2, pl: 2, border: '2px solid lightgrey',
                    borderRadius: '5px',
                    ":hover": {
                        backgroundColor: 'rgba(255, 131, 89, 0.16)'
                    }
                }}>
                    <FormControlLabel disableTypography sx={{ width: '100%' }}
                        value={s.id}
                        label={
                            <Stack spacing={3} direction='row' justifyContent='space-between'
                                sx={{ width: '100%' }}>
                                <Stack spacing={1} justifyContent='center' sx={{ ml: 1 }}>
                                    <Typography sx={{ fontWeight: '500', fontSize: '1.1rem' }}>
                                        {s.title}
                                    </Typography>
                                    <Typography sx={{ color: '#6F738B' }}>
                                        {s.validity_type !== 'lifetime' ? s.validity_time : null} {_.capitalize(s.validity_type)}
                                    </Typography>
                                    <Typography sx={{ color: '#6F738B' }}>
                                        Price: {Number(s.purchase_price)} <span dangerouslySetInnerHTML={{ __html: ticketCurrencySymbol }} />
                                    </Typography>
                                </Stack>
                                <IconButton onClick={() => handleSubscriptionViewClick(s)} sx={{
                                    ":hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}>
                                    <VisibilityIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                </IconButton>
                            </Stack>
                        }
                        control={<Radio color="warning" />} />
                </Box>
                :
                <Box sx={{
                    py: 0.5,
                    borderRadius: '5px',
                }}>
                    <Skeleton sx={{
                        transform: 'scale(1,1)', height: '5rem',
                    }} />
                </Box>
            }
        </Grid>
    )
};

export default SubscriptionSelectionCard;
