import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h1: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    h2: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '0.8rem',
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
    },
    h5: {
      fontSize: '0.7rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
    },
    h6: {
      fontSize: '0.4rem',
      '@media (min-width:600px)': {
        fontSize: '0.6rem',
      },
    },
    tooltip_text: {
      fontSize: '0.7rem'
    },
    fontFamily: 'Poppins'
  },
  components: {
    // Name of the component ⚛️
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize !important'
        },
      },
    }
  },
  palette: {
    primaryColor: '#FF8359',
  },
});

export default theme