import { List } from "@mui/material";
import React, { useContext } from "react";
import { GroupSettingsContext } from "../..";
import SubscriptionCard from "./SubscriptionCard";

const SubscriptionList = () => {
    const { subscriptions } = useContext(GroupSettingsContext);
    return (
        <>
            <List sx={{
                width: '100%', bgcolor: 'background.paper', borderRadius: '0.5rem',
                boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.1)', pt: 0, pb: 0
            }}>
                {subscriptions?.map((s, i) => (
                    <SubscriptionCard key={i} subscription={s} />
                ))}
            </List>
        </>
    )
};

export default SubscriptionList;
