import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import React from "react";
import CustomTooltip from "../../../../../../../../components/CustomTooltip";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import UploadImage from "../../../../../../../../components/UploadImage";
import { BACKEND_URL } from "../../../../../../../../utils/constants/apis.urls";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import ViewFacilityCard from "../../../../../../../../components/ViewFacilityCard";
import { TICKET_TYPES } from "../../../../../../../../utils/constants/eventTickets";

const TicketDetailsDialog = ({ ticket, setModal }) => {
    const handleClose = () => {
        setModal(false);
    };
    const ticketSymbol = ticketCurrencies.find((t) => t.value === ticket.currency)?.symbol;
    const eventDetailsObj = useSelector(eventDetails);
    const includedFacilities = ticket?.facilities?.filter(facility => facility.is_included) || [];
    const notIncludedFacilities = ticket?.facilities?.filter(facility => !facility.is_included) || [];

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleClose}
                PaperProps={{ sx: { width: { xs: "80%", sm: "70%", md: "50%" }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                    <IconButton
                        onClick={() => handleClose()}
                        sx={{ position: "absolute", right: "0.2rem", top: "0.2rem" }}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: "1.5rem", sm: "1.5rem" } }}>
                        Ticket details
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack spacing={1} sx={{ width: "100%" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                Ticket Name:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    ml: 1,
                                    display: "inline-block"
                                }}
                            >
                                {ticket.title}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Ticket Type:</Typography>
                            <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline-block' }}>{ticket.ticket_type}</Typography>
                        </Box>

                        {ticket.show_available_tickets ?
                            <Box>
                                <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Tickets Available:</Typography>
                                <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline-block' }}>{ticket.quantity - ticket.sold_out}</Typography>
                            </Box> : null
                        }
                        {ticket.description ?
                            <Box>
                                <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                    Ticket Description:
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#6F738B",
                                        fontSize: ".9rem",
                                        ml: 1,
                                        display: "inline-block"
                                    }}
                                >
                                    {ticket.description}
                                </Typography>
                            </Box>
                            : null
                        }
                            <Box>
                                <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                    Maximum Ticket You Can Purchase:
                                </Typography>
                                <Typography
                                    sx={{
                                        ml: 1,
                                        color: "#6F738B",
                                        display: "inline-block"
                                    }}
                                >
                                    {ticket.max_ticket_limit ?? "--"}
                                </Typography>
                            </Box>

                        {ticket.ticket_photo ? (
                            <Box sx={{
                                width: { xs: "60%", sm: "50%", lg: "40%" },
                                marginInline: "auto !important"
                            }}>
                                <Typography sx={{ textAlign: "center" }} mb={1}>
                                    Ticket Photo
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: ticket.ticket_photo ? "#171717" : "white",
                                        borderRadius: "10px",
                                        height: "9rem",
                                        position: "relative",
                                        border: "1px solid lightgrey"
                                    }}
                                >
                                    <UploadImage
                                        fieldName="ticket_photo"
                                        label="Ticket Photo"
                                        register={() => ({
                                            onChange: () => {
                                            }
                                        })}
                                        errors={{}}
                                        selectedImage={`${ticket.ticket_photo}`}
                                        setSelectedImage={() => {
                                        }}
                                        hideOptions
                                        allowCrop
                                        cropDimensions={JSON.parse(ticket.crop_dimensions)}
                                    />
                                </Box>
                            </Box>
                        ) : null
                        }

                        <Divider />
                        {(ticket?.ticket_type === TICKET_TYPES.QR_CODE) ?
                            <>
                                {
                                    includedFacilities.length > 0 ?
                                        <Stack spacing={1} sx={{ width: "100%" }}>
                                            <Box>
                                                <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                                    Included Facilities:
                                                </Typography>
                                            </Box>
                                            <Box sx={{
                                                width: '100%',
                                                border: '1px solid #888',
                                                borderRadius: '0.5rem',
                                            }}>
                                                {
                                                    includedFacilities?.map((item, i) => (
                                                        <>
                                                            <ViewFacilityCard key={i} facility={item} />
                                                            {includedFacilities.length - 1 !== i ? <Divider /> : null}
                                                        </>
                                                    ))
                                                }
                                            </Box>
                                        </Stack> : null
                                }
                                {
                                    notIncludedFacilities.length > 0 ?
                                        <Stack spacing={1} sx={{ width: "100%", marginTop: 1 }}>
                                            <Box>
                                                <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                                    Additional Facilities:
                                                </Typography>
                                            </Box>
                                            <Box sx={{
                                                width: '100%',
                                                border: '1px solid #888',
                                                borderRadius: '0.5rem',
                                            }}>
                                                {
                                                    notIncludedFacilities?.map((item, i) => (
                                                        <>
                                                            <ViewFacilityCard key={i} facility={item} />
                                                            {notIncludedFacilities.length - 1 !== i ? <Divider /> : null}
                                                        </>
                                                    ))
                                                }
                                            </Box>
                                        </Stack> : null
                                }
                            </> :
                            null
                        }
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Stack direction="row" spacing={1}>
                        {
                            eventDetailsObj.is_paid ?
                                <>
                                    <Typography
                                        sx={{
                                            color: "#1F274A",
                                            fontWeight: "bold",
                                            fontSize: "1.2rem",
                                            display: "inline-block"
                                        }}
                                    >
                                        Price:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#1F274A",
                                            fontSize: "1.2rem",
                                            ml: 1,
                                            fontWeight: "bold",
                                            display: "inline-block"
                                        }}
                                    >
                                        {parseDecimalNumber(ticket.purchase_price, 2)} <span
                                            dangerouslySetInnerHTML={{ __html: ticketSymbol }} />
                                    </Typography>
                                </> :
                                <></>
                        }
                        

                        {/* If no extra charges and gst-percent is 0 we'll not show tooltip */}
                        {!(!ticket.extra_charges_from_buyer && ticket.gst_percent === 0) ?
                            <CustomTooltip
                                content={
                                    <Stack spacing={1} sx={{ p: 0.5 }}>
                                        <Typography sx={{ fontSize: "0.75rem" }}>
                                            Actual Amount = {ticket.extra_charges_from_buyer ?
                                            parseDecimalNumber(ticket.amount, 2) :
                                            parseDecimalNumber(ticket.amount, 2)
                                        } <span dangerouslySetInnerHTML={{ __html: ticketSymbol }} />
                                        </Typography>
                                        <Typography sx={{ fontSize: "0.75rem" }}>
                                            System Charges & Taxes = {
                                            ticket.extra_charges_from_buyer ?
                                                parseDecimalNumber(ticket.gst_on_ticket_price + ticket.wowsly_gst + ticket.system_charges + ticket.razor_pay_fee, 2) :
                                                parseDecimalNumber(ticket.gst_on_ticket_price, 2)
                                        } <span dangerouslySetInnerHTML={{ __html: ticketSymbol }} /></Typography>
                                        <Divider sx={{ backgroundColor: "white !important" }} />
                                        <Typography sx={{ fontSize: "0.75rem" }}>
                                            Amount to Pay
                                            = {parseDecimalNumber(ticket.purchase_price, 2)} <span
                                                dangerouslySetInnerHTML={{ __html: ticketSymbol }} />
                                        </Typography>
                                    </Stack>
                                }
                            />
                            : null
                        }
                    </Stack>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default TicketDetailsDialog;
