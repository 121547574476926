import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import React, { useContext, useState } from "react";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import { EventGuestsContext } from "../../../../EventGuestContext";
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { manageBulkDeleteEventGuestsPayload } from "../../../../../../../../../../utils/functions/managePayload";
import { multipleDeleteEventGuests, getEventGuests } from "../../../../../../../../../../services/wEvents/eventGuests";

const DeleteEventGuestsContent = (handleClose) => {
    const eventData = useSelector(eventDetails)
    const { tabValue, totalGuests, setVisible,setSearchedGuest, checkedGuests, setChecked, setGuests } = useContext(EventGuestsContext);
    const [loading, setLoading] = useState(false);

    const handleBulkDeleteEventGuests = () => {
        setLoading(true);
        setSearchedGuest(null);
        const payload = manageBulkDeleteEventGuestsPayload(checkedGuests);
        multipleDeleteEventGuests(payload, eventData?.id).then((res) => {
            getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered' }).then((res2) => {
                totalGuests.current = res2.data.meta.total
                setGuests(res2.data.data);
                setChecked([]); // Make checked guests list empty
                setVisible(false); // Make checkbox invisible
                setLoading(false);
                toast.success(res.data.data.message, {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            })
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography>Are you sure you want to delete {checkedGuests.length} guests?</Typography>
                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        onClick={handleBulkDeleteEventGuests}
                        loading={loading ? true : false}
                        startIcon={loading ? <SaveIcon /> : null}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Deleting...' : 'Yes'}
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </>
    )
}

const BulkDeleteEventGuestsDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Delete Guests' content={DeleteEventGuestsContent(handleClose)}
            />
        </>
    )
};

export default BulkDeleteEventGuestsDialog;
