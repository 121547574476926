import { Button, DialogActions, Stack } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { toast } from "react-toastify";
import { EventProgramContext } from "..";
import CropImage from "../../../../../../../../../components/CropImage";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { createEventProgram } from "../../../../../../../../../services/wEvents/eventDetails";
import { editProgramForm } from "../../../../../../../../../utils/constants/editEventProgram";
import { manageEventProgramPayload } from "../../../../../../../../../utils/functions/managePayload";
import { programFieldsToHide } from "../../../../../../../../../utils/constants/eventProgramHideFields";

const CopyProgramContent = (handleClose, setItems, eventProgramDetails) => {
    const {
        handleSubmit,
        register,
        formState: { errors }, watch,
        control, reset, setValue
    } = useForm();

    const eventData = useSelector(eventDetails);
    const [loader, setLoader] = useState(false);
    const { viewId, title } = useContext(EventProgramContext);
    const [cropDialog, setCropDialog] = useState(false);
    const handleDialogOpen = () => {
        setCropDialog(true);
    }
    const handleDialogClose = () => {
        setCropDialog(false);
        setImageToCrop(null);
    }

    const [imageToCrop, setImageToCrop] = useState(null);
    const onImageChoose = (img) => {
        setImageToCrop(img);
        handleDialogOpen('crop');
    }
    const setFormImage = (croppedImage) => {
        handleDialogClose('crop');
        setImageToCrop(croppedImage);
        setValue('file', croppedImage);
    }

    const mySubmit = (data) => {
        setLoader(true);
        if (data.end_datetime && data.start_datetime >= data.end_datetime) {
            toast.error('Start date must be less than end date', {
                position: "top-right",
                theme: "dark"
            });
            setLoader(false);
            return;
        }
        data.viewId = viewId
        let payload = manageEventProgramPayload(data, eventData);
        createEventProgram(payload, eventData?.id).then((res) => {
            toast.success(`${pluralize.singular(title)} Copied Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => [...items, res.data.data])
            handleClose();
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            setImageToCrop(null);
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {editProgramForm.data.map((item, index) => (
                        <React.Fragment key={index}>
                            {
                                (eventData?.multiple_venues || !programFieldsToHide.includes(item.label)) //To hide fields based on multiple venues of event
                                    ?
                                    <RenderFormFields
                                        key={index}
                                        item={item}
                                        watch={watch}
                                        eventData={eventData}
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        editForm={true}
                                        copyTitle={true}
                                        cardDetails={eventProgramDetails}
                                        file={imageToCrop}
                                        setFile={setImageToCrop}
                                        onImageChoose={onImageChoose}
                                    />
                                    : null
                            }
                        </React.Fragment>
                    ))}
                </Stack>

                {cropDialog ?
                    <DialogBox open={true} handleClose={() => handleDialogClose('crop')}
                        title='Crop Event Logo'
                        content={
                            <CropImage
                                aspectType='1'
                                imageToCrop={imageToCrop}
                                uploadImage={setFormImage}
                                handleDialogClose={handleDialogClose}
                            />
                        }
                    />
                    : null
                }

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true:false}
                        startIcon={loader?<SaveIcon/>:null}
                        loadingPosition={loader?"start" :null}
                    
                    >
                       Save Changes
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const CopyProgramDialog = ({ open, handleClose, setItems, eventProgramDetails }) => {
    return (
        <DialogBox open={open} handleClose={handleClose}
            title='Copy Itinerary' content={CopyProgramContent(handleClose, setItems, eventProgramDetails)}
        />
    )
};

export default CopyProgramDialog;
