export const createGroupAlbumForm = {
    "title": "Album Create",
    "description": "Group album add form",
    "method": "POST",
    "data": [
        {
            "type": "header",
            "subtype": "h1",
            "label": "Add album",
            "access": false
        },
        {
            "type": "text",
            "required": true,
            "label": "Album Name",
            "placeholder": "Album Name",
            "className": "form-control",
            "name": "title",
            "access": false,
            "subtype": "text"
        },
        {
            "type": "text",
            "subtype": "album-link",
            "required": true,
            "label": "Album Link",
            "placeholder": "Album Link",
            "className": "form-control",
            "name": "album_link",
            "access": false
        },
        {
            "type": "switch",
            "required": true,
            "label": "Can members upload photos in it?",
            "description": "Can members upload photos in it?",
            "placeholder": "Use limit",
            "className": "form-control",
            "selected": false,
            "name": "allow_guest_upload"
        },
        {
            "type": "switch",
            "required": true,
            "label": "Can members download photos from it?",
            "description": "Can members download photos from it?",
            "placeholder": "Use limit",
            "className": "form-control",
            "selected": true,
            "name": "allow_download"
        },
        {
            "type": "switch",
            "required": true,
            "label": "Is this a private album (Only Admin/Moderator can see it) ?",
            "description": "Is this a private album (Only Admin/Moderator can see it) ?",
            "placeholder": "Use limit",
            "className": "form-control",
            "selected": false,
            "name": "is_private"
        },
    ]
}