import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import _ from 'lodash';
import React, { useRef, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FullScreenLoader from '../../../../../../components/Loader';
import GroupSettingsForm from "./GroupSettingsForm";
import GroupDocVerification from './components/GroupDocVerification';
import GroupRegistrationForms from './components/GroupRegistrationForms';
import CreateGroupRegistrationForm from './components/GroupRegistrationForms/CreateRegistrationForm';
import EditGroupRegistrationForm from './components/GroupRegistrationForms/EditGroupRegistrationForm';
import GroupSubscription from './components/GroupSubscription';
import CreateSubscription from './components/GroupSubscription/CreateSubscription';
import SubscriptionPayments from './components/GroupSubscriptionPayments';

export const GroupSettingsContext = React.createContext({});

const GroupSettingsDialog = ({ handleClose }) => {
    let totalSubscriptionPaymentRecords = useRef(null);
    let per_page_records = useRef(5);
    const [loader, setLoader] = useState(false);
    const [slideType, setSlideType] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionsPayments, setSubscriptionsPayments] = useState([]);
    const [registrationForm, setRegistrationForm] = useState([]);
    const [banks, setBanks] = useState(null);

    const [activeSlide, setSlide] = useState(0);
    const handlePrevClick = () => {
        setSlide(activeSlide !== 0 ? activeSlide - 1 : 0);
    };
    const handleNextClick = () => {
        setSlide(activeSlide !== slides.length - 1 ? activeSlide + 1 : slides.length - 1);
    };

    const mapSlideType = {
        subscription: {
            title: 'Subscription',
            component: <GroupSubscription />,
            subTitle: 'Create Subscription',
            subComponent: <CreateSubscription />
        },
        subscriptionPayments: {
            title: 'Subscription Payments Record',
            component: <SubscriptionPayments />
        },
        verification: {
            title: 'Document verification',
            component: <GroupDocVerification />
        },
        editGroupRegistration: {
            title: 'Registration Form',
            component: <GroupRegistrationForms />,
            subTitle: 'Edit Registration Form',
            subComponent: <EditGroupRegistrationForm />
        }
    }

    const slides = [
        {
            id: 1,
            title: 'Group Settings',
            content: <GroupSettingsForm handleClose={handleClose} />,
        },
        {
            id: 2,
            title: slideType ? _.get(mapSlideType, slideType).title ?? null : null,
            content: slideType ? _.get(mapSlideType, slideType).component ?? null : null,
        },
        {
            id: 3,
            title: slideType ? _.get(mapSlideType, slideType).subTitle ?? null : null,
            content: slideType ? _.get(mapSlideType, slideType).subComponent ?? null : null,
        }
    ];

    return (
        <>
            <GroupSettingsContext.Provider value={{
                activeSlide, setSlide, setSlideType,
                subscriptions, setSubscriptions,
                subscriptionsPayments, setSubscriptionsPayments,
                totalSubscriptionPaymentRecords, per_page_records,
                banks, setBanks,
                registrationForm, setRegistrationForm,
                handlePrevClick, handleNextClick, loader, setLoader
            }}>
                <Dialog
                    fullWidth
                    open={true}
                    onClose={() => handleClose('settings')}
                    BackdropComponent={loader ? FullScreenLoader : null}
                    PaperProps={{
                        sx: {
                            width: { xs: '90%', md: '60%' },
                            maxWidth: { lg: '700px' }, m: { xs: 0 }
                        }
                    }}
                    disableEnforceFocus
                >
                    <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                        <IconButton onClick={() => handleClose('settings')}
                            sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                            <CloseIcon fontSize='medium' />
                        </IconButton>
                        <Stack direction='row' alignItems='center' sx={{ mt: 1 }}>
                            {(activeSlide !== 0) ?
                                <>
                                    <ArrowBackIosIcon onClick={handlePrevClick} sx={{
                                        ":hover": {
                                            cursor: 'pointer'
                                        }
                                    }} />&nbsp;
                                </> :
                                null
                            }
                            <Typography sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}>
                                {slides[activeSlide].title}
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    <Carousel autoPlay={false} selectedItem={activeSlide} swipeable={false}
                        showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
                        {slides.map(slide => (
                            <Box key={slide.id} sx={{ textAlign: 'left', height: '100%' }}>
                                {slide.content}
                            </Box>
                        ))}
                    </Carousel>
                </Dialog>
            </GroupSettingsContext.Provider>
        </>
    )
};

export default GroupSettingsDialog;
