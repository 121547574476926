import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import React, { useContext } from "react";
import { Button, DialogActions, Stack } from "@mui/material";
import { GroupMembersContext } from "../../GroupMembersContext";
import RenderFormFields from "../../../../../../../../components/RenderFormFields";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { editGroupMember } from "../../../../../../../../services/wGroups/groupMembers";
import { createGuestForm } from "../../../../../../../../utils/constants/createEventGuest";
import { manageEventGuestPayload } from "../../../../../../../../utils/functions/managePayload";

const EditGroupMember = ({ memberDetails, setEdit }) => {
    const { setMembers } = useContext(GroupMembersContext)
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();

    const groupData = useSelector(groupDetails)

    const mySubmit = (data) => {
        let payload = manageEventGuestPayload(data, true)
        editGroupMember(payload, groupData?.id, memberDetails.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success("Group member updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setMembers((members) => {
                let ele = members.find(i => i.id === res.data?.data?.id);
                members[members.indexOf(ele)] = res.data.data;
                return [...members];
            })
            setEdit(false);
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {createGuestForm.data.map((item, index) => (
                        <RenderFormFields editForm={true} cardDetails={memberDetails} key={index}
                            item={item} eventData={groupData} register={register}
                            errors={errors} control={control} />
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => {
                        reset();
                        setEdit(false);
                    }}>
                        Cancel
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Save changes
                    </Button>
                </DialogActions>
            </form>
        </>
    )
};

export default EditGroupMember;
