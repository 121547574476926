import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import SetInvitationDialog from '../../../GuestInvitation/SetInvitationDialog';
import { EventGuestsContext } from "../../../../EventGuestContext";

const SetupReminder = ({ title }) => {
    const { setInviteMsg, setScheduledDate, setGuestInviteActiveStep } = useContext(EventGuestsContext);
    let [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        document.body.classList.add("no-scroll");
        setDialogOpen(true);
    }
    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        document.body.classList.remove("no-scroll");
        // setInviteMsg(null);
        setScheduledDate(null);
        setGuestInviteActiveStep(0);
        setDialogOpen(false);
    }

    return (
        <>
            <Box sx={{
                ":hover": { backgroundColor: '#FFEBE4', cursor: 'pointer' },
                px: '1.5rem', width: '100%', textAlign: 'center'
            }}>
                <Typography sx={{ lineHeight: '2rem' }} onClick={handleDialogOpen}>{title}</Typography>
            </Box>
            {
                dialogOpen ? <SetInvitationDialog isReminder handleClose={handleDialogClose} /> : null
            }
        </>
    )
};

export default SetupReminder;
