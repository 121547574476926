import { useTheme } from "@emotion/react";
import { Divider, Link, Stack, Switch, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CreateEventContext } from "../CreateEventContext";

const PhysicalLocation = () => {
    const theme = useTheme();
    const { formData, setFormData } = React.useContext(CreateEventContext);

    const initialVenueDetails = {
        venueName: '',
        streetLine: '',
        landmark: '',
        city: '',
        zipCode: '',
        state: '',
        country: '',
        googleMapLink: ''
    }

    const handleVenue = (e) => {
        setFormData({
            ...formData, eventVenue: {
                ...formData.eventVenue,
                [e.target.name]: e.target.value
            }
        })
    }
    const toggleSwitch = (e) => {
        let field = e.target.name
        setFormData({ ...formData, [field]: !formData[field] })
    }

    return (
        <>
            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                    Event happening on multiple venues
                </Typography>
                <Switch name='multiple_venues' color='warning'
                    checked={formData['multiple_venues']}
                    onChange={(e) => toggleSwitch(e)} />
            </Stack>
            <Divider />

            {
                !formData['multiple_venues'] ? 
                <Stack spacing={2} sx={{ pt: 1 }}>
                    <TextField
                        label="Venue Name"
                        color="warning"
                        name="venueName"
                        value={formData?.eventVenue?.venueName}
                        onChange={handleVenue}
                    />
                    <TextField
                        label="Street Line"
                        color="warning"
                        name="streetLine"
                        value={formData?.eventVenue?.streetLine}
                        onChange={handleVenue}
                    />
                    <TextField
                        label="Landmark, Area"
                        color="warning"
                        name="landmark"
                        value={formData?.eventVenue?.landmark}
                        onChange={handleVenue}
                    />
                    <Stack direction='row' spacing={2}>
                        <TextField
                            label="City"
                            color="warning"
                            name="city"
                            value={formData?.eventVenue?.city}
                            onChange={handleVenue}

                        />
                        <TextField
                            label="Zip code"
                            color="warning"
                            name="zipCode"
                            value={formData?.eventVenue?.zipCode}
                            onChange={handleVenue}
                            inputProps={{ type: 'number' }}
                        />
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <TextField
                            label="State"
                            color="warning"
                            name="state"
                            value={formData?.eventVenue?.state}
                            onChange={handleVenue}
                        />
                        <TextField
                            label="Country"
                            color="warning"
                            name="country"
                            value={formData?.eventVenue?.country}
                            onChange={handleVenue}
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <TextField
                            fullWidth
                            color="warning"
                            name="googleMapLink"
                            label="Google Map Link"
                            value={formData?.eventVenue?.googleMapLink}
                            onChange={handleVenue}
                        />
                        <Link underline="none" target="_blank"
                            href='https://www.youtube.com'
                            sx={{
                                pl: '0.25rem',
                                fontSize: '0.85rem',
                                fontFamily: 'Poppins',
                                color: theme.palette.primaryColor
                            }}>
                            How to get a Google Map Link
                        </Link>
                    </Stack>
                </Stack>
                : null
            }
            {
                _.isEqual(formData.eventVenue, initialVenueDetails) ?
                    <Typography sx={{
                        fontSize: '0.9rem'
                    }}>
                        <span style={{color: theme.palette.primaryColor, marginLeft: '4px'}}>Note: </span> 
                        Venue can also be added later
                    </Typography>
                : null
            }
        </>
    );
};

export default PhysicalLocation;
