import { useTheme } from '@emotion/react';
import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import './auth-layout.scss';
import wowslyGirl from "../assets/Onboard-1.webp";

const AuthLayout = () => {
    const theme = useTheme();
    const { id } = useParams();
    return (
        <Grid container>
            <Grid item md={6} display={{ xs: "none", md: "block" }}>
                <Box
                    component="img"
                    src={wowslyGirl}
                    alt="Wowsly Image"
                    className="auth-layout-wowsly-girl-leftside"
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                <Stack className="auth-layout-right-side-container">
                    <Box
                        sx={{
                            backgroundColor: theme.palette.primaryColor,
                            minHeight: { xs: "25%", sm: id ? "20%" : "30%", lg: id ? "20%" : "30%" }
                        }}
                    />
                    <Stack className={id ?
                        'auth-layout-wowsly-girl-mobile-common' :
                        'auth-layout-wowsly-girl-mobile-common auth-layout-wowsly-girl-mobile-background-image'}>
                        <Outlet />
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default AuthLayout;
