import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import { PAGE_PATHS } from './page-path.constants';

export const DrawerTabs = [
    {
        id: 1,
        icon: <HomeIcon />,
        name: 'Dashboard',
        url: `${PAGE_PATHS.DASHBOARD}`
    },
    {
        id: 2,
        icon: <PersonIcon />,
        name: 'Profile',
        url: `${PAGE_PATHS.USER_ACCOUNT}`
    },
    {
        id: 3,
        icon: <AccountBalanceIcon />,
        name: 'Bank Details',
        url: PAGE_PATHS.BANK_DETAILS
    },
    {
        id: 4,
        icon: <PaymentIcon />,
        name: 'Payments',
        url: PAGE_PATHS.PAYMENTS
    },
]
