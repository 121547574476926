import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupSubscriptionPaymentDetails } from '../../../../../../../../services/wGroups/groupSubscription';
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import { stringAvatar } from "../../../../../../../../utils/functions/muiAvatar";
import { convertUtcToLocal } from "../../../../../../../../utils/functions/timeConversion";
import OneSubscriptionPaymentDetails from './OneSubscriptionPaymentDetails';

const ItemAvatar = memo(({ name }) => {
    return (
        <Avatar {...stringAvatar(name?.trim(), { singleLetter: true })} />
    )
});

const SubscriptionPaymentItem = ({ item }) => {
    const isSmallDevice = useMediaQuery("(max-width:480px)");
    const groupDetailsObj = useSelector(groupDetails);
    const [details, setDetails] = useState(null);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        if (!open) {
            setLoader(true);
            getGroupSubscriptionPaymentDetails(groupDetailsObj?.id, item.id).then((res) => {
                setLoader(false);
                setDetails(res.data.data);
            }).catch((err) => {
                console.log(err);
                setLoader(false);
            })
        }
    };

    return (
        <React.Fragment>
            <ListItem onClick={handleClick}
                sx={{
                    px: { xs: 1, md: 1.5 },
                    py: 0.5,
                    backgroundColor: open ? '#F1F1F1' : 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}
            >
                <ListItemAvatar>
                    <ItemAvatar name={item.invited_member_name} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Stack direction='row'>
                            {isSmallDevice ?
                                <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                                    {parseDecimalNumber(item.amount_to_pay, 3)}&nbsp;
                                    <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === item.currency)?.symbol }} />&nbsp;
                                </Typography>
                                : null
                            }
                            <Typography>
                                Received from {item.invited_member_name}
                            </Typography>
                        </Stack>
                    }
                    secondary={`${convertUtcToLocal(item.payment_time).format("Do MMMM YYYY, h:mm A")}`}
                />
                {!isSmallDevice ?
                    <Typography sx={{ color: 'green', fontWeight: 'bold' }}>
                        {parseDecimalNumber(item.amount_to_pay, 3)}&nbsp;
                        <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === item.currency)?.symbol }} />&nbsp;
                    </Typography>
                    : null
                }
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {loader ?
                        <Skeleton height='10rem' variant='wave' sx={{
                            m: 2, transform: 'scale(1,1)',
                        }} />
                        :
                        <OneSubscriptionPaymentDetails details={details} />
                    }
                </List>
            </Collapse>
            <Divider variant="inset" component="li" sx={{ ml: 0 }} />
        </React.Fragment>
    )
};

export default SubscriptionPaymentItem;
