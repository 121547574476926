import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoginDialog from "../../../../../../components/LoginDialog";
import { groupDetails } from "../../../../../../redux/slices/groupDetailSlice";
import { userStatus } from "../../../../../../redux/slices/userDataSlice";
import { getEvents } from "../../../../../../services/wEvents/event";
import { getMemberDetails } from "../../../../../../services/wGroups/groupMembers";
import { PAGE_PATHS } from '../../../../../../utils/constants/page-path.constants';
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import HorizontalScrollingCards from "../../../../../EventDetails/components/Menubar/components/SubEvents/components/HorizontalScrollMenu";

const GroupEvents = () => {
    const groupData = useSelector(groupDetails);
    const isLoggedIn = useSelector(userStatus);
    const navigate = useNavigate();
    const isAllPermission = ALL_PERMISSION_ROLES.includes(groupData?.current_user_role);
    const [groupEvents, setGroupEvents] = useState(null);

    const { memberId } = useParams();

    const [loginDialog, setLoginDialog] = useState(false);
    const [memberData, setMemberData] = useState(null);
    const handleDialogClose = useCallback(() => {
        setLoginDialog(false);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            getEvents({ groupId: groupData.id, memberId: memberId }).then((res) => {
                console.log('Group events ---->', res.data.data)
                setGroupEvents(res?.data.data);
            }).catch((err) => {
                console.log('error@@@@@@@@', err);
            })
        }
    }, [loginDialog]);

    useEffect(() => {
        if (!isLoggedIn) {
            getMemberDetails({ groupId: groupData?.id, uuid: groupData?.member_uuid }).then((result) => {
                setLoginDialog(true);
                setMemberData(result.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    const handleAddEvent = () => {
        navigate(`/${PAGE_PATHS.CREATE_EVENT}`, { state: { groupId: groupData?.id } })
    }

    return (
        <>
            <Box sx={{ pb: '1rem' }}>
                <Typography sx={{ mb: 1, color: '#1F274A', fontWeight: 'bold' }}>Events</Typography>
                {groupEvents?.length === 0 ?
                    <Typography sx={{ color: 'gray' }}>No events</Typography>
                    : <HorizontalScrollingCards category='GroupEvents' items={groupEvents ?? Array.from(new Array(3))} setItems={setGroupEvents} />
                }
                {isAllPermission ?
                    <Box>
                        <Button variant="text" color="warning" onClick={handleAddEvent} sx={{
                            textAlign: 'left', '&:hover': {
                                background: 'none',
                            }
                        }}>
                            + Add Event
                        </Button>
                    </Box>
                    : null
                }
            </Box>
            {loginDialog ? <LoginDialog open={true}
                handleClose={() => handleDialogClose()} guestData={memberData} /> : null}
        </>
    )
};

export default GroupEvents;
