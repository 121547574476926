import { DialogActions, DialogContent, Tab, Tabs, tabsClasses } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { EventSettingsContext } from "../..";
import MuiTabPanel from "../../../../../../../../components/muiTabPanel";
import ItemPagination from "./ItemPagination";
import PaidRecords from "./PaidRecords";
import ReceivedRecords from "./ReceivedRecords";

const EventPayments = () => {
    const eventPaymentTabs = ["Paid", "Received"];
    const { activeSlide } = useContext(EventSettingsContext);
    const [tabValue, setValue] = useState(0);
    const handleTabChange = (e, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        return () => {
            setValue(0); //Set 1st tab as default on unmounting
        };
    }, [activeSlide]);

    return (
        <>
            <DialogContent dividers={true} sx={{
                p: { xs: '0rem', md: '0rem' },
                height: { xs: '26.5rem', md: '31.5rem' }
            }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#FF8359"
                        }
                    }}
                    sx={{
                        backgroundColor: '#FFEBE4',
                        [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 }
                        },
                        '.Mui-selected': {
                            fontWeight: "bold !important",
                            color: '#FF8359 !important',
                            opacity: '1 !important'
                        },
                        '.MuiTabs-scrollButtons': {
                            display: { sm: 'none !important' },
                        }
                    }}
                >
                    {eventPaymentTabs.map((tab, index) => (
                        <Tab key={index} label={tab} sx={{
                            color: '#FF8359',
                            opacity: '0.6',
                            width: '50%',
                            textTransform: 'none',
                        }} />
                    )
                    )}
                </Tabs>

                <MuiTabPanel value={tabValue} index={0}>
                    <PaidRecords />
                </MuiTabPanel>
                <MuiTabPanel value={tabValue} index={1}>
                    <ReceivedRecords />
                </MuiTabPanel>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <ItemPagination tabType={tabValue === 0 ? 'Paid' : 'Received'} />
            </DialogActions>
        </>
    )
};

export default EventPayments;
