import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogContent, InputAdornment, Stack, Switch, TextField, Typography, Tabs, Tab } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import React, { useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TimezoneSelect from 'react-timezone-select';
import { toast } from 'react-toastify';
import * as yup from "yup";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import { eventDetails, update_event_details } from "../../../../../../redux/slices/eventDataSlice";
import { updateEventDetails } from "../../../../../../services/wEvents/event";
import { manageEditEventPayload } from "../../../../../../utils/functions/managePayload";
import { validateSpaces } from "../../../../../../utils/functions/validateEventLink";
import EditOnlineLocation from "./EditOnlineLocation";
import EditPhysicalLocation from "./EditPhysicalLocation";
import { disableDates } from "../../../../../../utils/functions/disableDates";

const EditEventForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const eventDetailsObj = useSelector(eventDetails)
    const [loader, setLoader] = useState(false);
    const [eventMode, setEventMode] = useState(eventDetailsObj?.is_online ? 'online' : 'offline');
    const [isMultipleVenue, setMultipleVenue] = useState(eventDetailsObj?.multiple_venues ? true : false);
    const handleRadioChange = (event) => {
        setEventMode(event.target.value);
    };
    const [tentativeValue, setTentativeValue] = useState(eventDetailsObj?.schedule_announcement_description ? 'tentative' : 'date');
    const startDateOptions = [{label: 'Event Date', value: 'date'}, {label:'Tentative', value: 'tentative'}];
    const tentativeRef = useRef(null);

    const schema = yup.object({
        
        title: yup.string().required("This field is required"),
        start_date: tentativeValue === 'date' ? yup.string().required("This field is required") : "",
        schedule_announcement_description: tentativeValue === 'date' ? "" : yup.string().required("This field is required"),
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
        control, watch,
        setValue
    } = useForm({
        resolver: yupResolver(schema)
    });

    const mySubmit = (data) => {
        setLoader(true);
        if (data.end_date && (new Date(data.start_date) >= new Date(data.end_date))) {
            setLoader(false);
            toast.error('Start date must be less than end date', {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        const is_online = eventMode === 'online';
        if(!isMultipleVenue){
            if (is_online) {
                const isLinkValid = validateSpaces(data.eventLink);
                if (!isLinkValid) {
                    toast.error("Online platform link should not contain spaces", {
                        position: "top-right",
                        theme: "dark"
                    });
                    setLoader(false);
                    return;
                }
            } else {
                const isLinkValid = validateSpaces(data.google_map_link);
                if (!isLinkValid) {
                    toast.error("Google map link should not contain spaces", {
                        position: "top-right",
                        theme: "dark"
                    });
                    setLoader(false);
                    return;
                }
            }
        }
        const payload = manageEditEventPayload(data, is_online, isMultipleVenue)

        updateEventDetails(payload, eventDetailsObj?.id).then((res) => {
            dispatch(update_event_details(res.data.data))
            handleClose(null, null, 'edit'); // Passed 2 null as there are no event and reason param for this case of dialog close
            toast.success("Event details updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setLoader(false);
        }).catch((err) => {
            console.log(err);
            setLoader(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    const handleTabChange = (event, newValue) => {
        setTentativeValue(newValue);
        if (newValue === 'tentative') {
            setTimeout(() => {
                tentativeRef.current.focus();
            }, 100);
            setValue('schedule_announcement_description', eventDetailsObj?.schedule_announcement_description ? eventDetailsObj?.schedule_announcement_description : 'To be announced');
            setValue('start_date', null);
            setValue('end_date', null);
            setValue('start_time', null);
            setValue('end_time', null);
        } else {
            setValue('schedule_announcement_description', null);
            setValue('start_date', eventDetailsObj?.start_date ? dayjs(eventDetailsObj?.start_date + eventDetailsObj?.start_time) : null);
            setValue('end_date', eventDetailsObj?.end_date ? dayjs(eventDetailsObj?.end_date + eventDetailsObj?.end_time) : null);
        }
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={true} sx={{ height: { xs: '26rem', md: '25rem' }, overflow: 'auto' }}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            color="warning"
                            label="Event Title"
                            defaultValue={eventDetailsObj?.title}
                            error={errors?.title ? true : false}
                            helperText={
                                errors?.title ? errors.title.message : null
                            }
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                maxLength: 30
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Typography>
                                        {(watch('title') ?? eventDetailsObj?.title).length}/30
                                    </Typography>
                                </InputAdornment>
                            }}
                            {...register("title")}
                        />
                        <Tabs
                            value={tentativeValue}
                            onChange={handleTabChange}
                            indicatorColor=""
                            sx={{
                                border: '2px solid #FF8359',
                                borderRadius: '10px',
                                minHeight: '30px',
                                width: {xs: '100%', md:'70%'},
                                '.Mui-selected': {
                                    fontWeight: "bold !important",
                                    color: '#FFFFFF !important',
                                    backgroundColor: '#FF8359',
                                    opacity: '1 !important'
                                },
                            }}
                        >
                            {startDateOptions.map((tab, index) => (
                                <Tab
                                    key={index}
                                    value={tab.value}
                                    label={<span style={{ textTransform: 'none' }}>{tab.label}</span>}
                                    sx={{
                                        color: '#FF8359',
                                        minHeight: '30px',
                                        textTransform: 'none',
                                        width: '50%',
                                    }}
                                />
                            ))}
                        </Tabs>
                        {tentativeValue === 'date' && (
                            <>
                                <Controller
                                    name="start_date"
                                    control={control}
                                    defaultValue={eventDetailsObj?.start_date ? dayjs(eventDetailsObj?.start_date + eventDetailsObj?.start_time) : null}
                                    render={({ field: { onChange, value } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                disablePast={true}
                                                value={value}
                                                onChange={onChange}
                                                label="Event start day & time"
                                                renderInput={(props) => (
                                                    <TextField {...props}
                                                        fullWidth
                                                        color="warning"
                                                        error={errors?.start_date ? true : false}
                                                        helperText={errors?.start_date ? 'This is required field' : null}
                                                        inputProps={{ ...props.inputProps }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />

                                <Controller
                                    name="end_date"
                                    control={control}
                                    defaultValue={eventDetailsObj?.end_date ? dayjs(eventDetailsObj?.end_date + eventDetailsObj?.end_time) : null}
                                    render={({ field: { onChange, value } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                disablePast={true}
                                                onChange={onChange}
                                                value={value}
                                                label="Event end day & time"
                                                shouldDisableDate={(d) => disableDates(d, new Date(watch('start_date')))}
                                                renderInput={(props) => (
                                                    <TextField {...props}
                                                        fullWidth
                                                        color="warning"
                                                        inputProps={{ ...props.inputProps }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </>
                        )}
                        {tentativeValue === 'tentative' && (
                            <TextField
                                fullWidth
                                color="warning"
                                label="Description"
                                defaultValue={eventDetailsObj?.schedule_announcement_description}
                                error={errors?.schedule_announcement_description ? true : false}
                                helperText={
                                    errors?.schedule_announcement_description ? errors.schedule_announcement_description.message : null
                                }
                                InputLabelProps={{ shrink: true }}
                                inputRef={tentativeRef}
                                {...register("schedule_announcement_description")}
                            />
                        )}

                        <Box>
                            <Typography sx={{ fontSize: '1rem' }}>
                                Event timezone
                            </Typography>
                            <Controller
                                name="timezone"
                                control={control}
                                defaultValue={eventDetailsObj?.timezone}
                                style={{ zIndex: 100 }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <TimezoneSelect
                                            placeholder='Event Timezone'
                                            value={value}
                                            onChange={onChange}
                                            className='selectTimezone'
                                        />
                                    </>
                                )}
                            />
                        </Box>

                        <Box>
                            <Typography sx={{ mb: 1 }}>Event Location</Typography>
                            <CustomRadioGroup value={eventMode} setValue={setEventMode} handleRadioChange={handleRadioChange} option1='offline' option2='online' />
                        </Box>

                        {eventMode === 'offline' ?
                            <>
                                    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                                        <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                            Event happening on multiple venues
                                        </Typography>
                                        <Switch
                                            color='warning'
                                            checked={isMultipleVenue}
                                            onChange={() => {
                                                setMultipleVenue(prev => !prev)}                                   
                                            }
                                        />
                                    </Stack>
                                    {
                                        !isMultipleVenue ?
                                            <EditPhysicalLocation eventDetailsObj={eventDetailsObj} 
                                            register={register}/>
                                        : null
                                    }
                            </> :
                            <>
                                <EditOnlineLocation eventDetailsObj={eventDetailsObj} register={register} errors={errors} />
                            </>
                        }

                    </Stack>
                </DialogContent>

                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {loader ? 'Saving...' : 'Save'}
                    </LoadingButton>
                </DialogActions>              
            </form>
        </>
    )
};

export default EditEventForm;
