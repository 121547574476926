import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import React from "react";
import CostBreakdown from "../../../../../../../../components/CostBreakdown";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { getExtraChargesCosting } from "../../../../../../../../utils/functions/getExtraChargesCosting";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import UploadImage from "../../../../../../../../components/UploadImage";
import { BACKEND_URL } from "../../../../../../../../utils/constants/apis.urls";
import { TICKET_TYPES } from "../../../../../../../../utils/constants/eventTickets";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import ViewFacilityCard from "../../../../../../../../components/ViewFacilityCard";

const ViewTicketDialog = ({ ticket, setAction }) => {
    const actualTicketPrice = parseDecimalNumber(ticket.amount, 2);
    const charges = getExtraChargesCosting(actualTicketPrice, ticket.gst_percent);
    const ticketPriceSymbol = ticketCurrencies.find((t) => t.value === ticket.currency)?.symbol;
    const eventDetailsObj = useSelector(eventDetails);
    const eventTicketType = eventDetailsObj?.is_paid ? 'paid' : 'free';
    const includedFacilities = ticket?.facilities?.filter(facility => facility.is_included) || [];
    const notIncludedFacilities = ticket?.facilities?.filter(facility => !facility.is_included) || [];

    const handleViewClose = () => {
        setAction((action) => ({ ...action, "view": false }));
    };

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleViewClose}
                PaperProps={{ sx: { width: { xs: "80%", sm: "70%", md: "50%" }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                    <IconButton onClick={() => handleViewClose()}
                        sx={{ position: "absolute", right: "0.2rem", top: "0.2rem" }}>
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: "1.5rem", sm: "1.5rem" } }}>
                        Ticket Details
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={1} sx={{ width: "100%" }}>
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                Ticket Name:
                            </Typography>
                            <Typography
                                sx={{
                                    ml: 1,
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    display: "inline-block"
                                }}
                            >
                                {ticket.title}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                Total Tickets:
                            </Typography>
                            <Typography sx={{ ml: 1, color: "#6F738B", display: "inline-block" }}>
                                {ticket.quantity}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                Sold Out:
                            </Typography>
                            <Typography sx={{ ml: 1, color: "#6F738B", display: "inline-block" }}>
                                {ticket.sold_out}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                Ticket Type:
                            </Typography>
                            <Typography sx={{ ml: 1, color: "#6F738B", display: "inline-block" }}>
                                {ticket.ticket_type}
                            </Typography>
                        </Box>

                        {ticket.description ?
                            <Box>
                                <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                    Ticket Description:
                                </Typography>
                                <Typography
                                    sx={{
                                        ml: 1,
                                        color: "#6F738B",
                                        display: "inline-block",
                                        fontSize: ".9rem"
                                    }}
                                >
                                    {ticket.description}
                                </Typography>
                            </Box>
                            : null
                        }
                        <Box>
                            <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                Max. Tickets Buyer Can Buy:
                            </Typography>
                            <Typography
                                sx={{
                                    ml: 1,
                                    color: "#6F738B",
                                    display: "inline-block"
                                }}
                            >
                                {ticket.max_ticket_limit ?? "--"}
                            </Typography>
                        </Box>

                        {
                            eventTicketType === 'paid' ?
                                <>
                                    <Divider sx={{ my: 1 }} />

                                    <Typography sx={{ my: 2, color: "#1F274A", fontSize: "1.2rem", fontWeight: "bold" }}>
                                        Price: {parseDecimalNumber(ticket.amount, 3)} <span
                                            dangerouslySetInnerHTML={{ __html: ticketPriceSymbol }} />
                                    </Typography>

                                    <CostBreakdown
                                        model="Ticket"
                                        modelObject={ticket}
                                        charges={charges}
                                        actualPrice={actualTicketPrice}
                                        gstPercent={ticket.gst_percent}
                                        priceSymbol={ticketPriceSymbol}
                                    />

                                    {ticket.extra_charges_from_buyer ?
                                        <Typography sx={{ color: "#6F738B", fontSize: ".9rem" }}>
                                            Buyer will pay extra charges on this price
                                        </Typography>
                                        : null
                                    }
                                </> : <></>
                        }

                        {
                            ticket?.ticket_type === TICKET_TYPES.QR_CODE ?
                                <>
                                    {
                                        includedFacilities.length > 0 ?
                                            <>
                                                <Box>
                                                    <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                                        Included Facilities:
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    width: '100%',
                                                    border: '1px solid #888',
                                                    borderRadius: '0.5rem',
                                                }}>
                                                    {    
                                                        includedFacilities?.map((item, i) => (
                                                            <>
                                                                <ViewFacilityCard key={i} facility={item} />
                                                                {includedFacilities.length - 1 !== i ? <Divider /> : null}
                                                            </>
                                                        ))
                                                    }
                                                </Box>
                                            </> :
                                            null
                                    }
                                    {
                                        notIncludedFacilities.length > 0 ?
                                            <>
                                                <Box>
                                                    <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                                        Additional Facilities:
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    width: '100%',
                                                    border: '1px solid #888',
                                                    borderRadius: '0.5rem',
                                                }}>
                                                    {
                                                        notIncludedFacilities?.map((item, i) => (
                                                            <>
                                                                <ViewFacilityCard key={i} facility={item} />
                                                                {notIncludedFacilities.length - 1 !== i ? <Divider /> : null}
                                                            </>
                                                        ))
                                                    }
                                                </Box>
                                            </> : 
                                            null
                                    }
                                </> :
                                null
                        }

                        {ticket.ticket_photo ? (
                            <Box
                                sx={{
                                    width: { xs: "60%", sm: "50%", lg: "40%" },
                                    marginInline: "auto !important"
                                }}
                            >
                                <Typography sx={{ textAlign: "center", fontWeight: "bold" }} mb={1}>
                                    Ticket Photo
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: ticket.ticket_photo ? "#171717" : "white",
                                        borderRadius: "10px",
                                        height: "9rem",
                                        position: "relative",
                                        border: "1px solid lightgrey"
                                    }}
                                >
                                    <UploadImage
                                        fieldName="ticket_photo"
                                        label="Ticket Photo"
                                        register={() => ({
                                            onChange: () => {
                                            }
                                        })}
                                        errors={{}}
                                        selectedImage={`${ticket.ticket_photo}`}
                                        setSelectedImage={() => {
                                        }}
                                        hideOptions
                                        allowCrop
                                        cropDimensions={JSON.parse(ticket.crop_dimensions)}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ViewTicketDialog;
