import React, { useEffect, useState } from "react";
import { getPayments } from "../../services/Payment/payments";
import { Box, List, Stack, Typography, useMediaQuery } from "@mui/material";
import NoRepliesGif from "../../assets/no-replies.gif";
import PaymentItem from "./PaymentItem";

const Payment = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const [paymentItems, setPaymentItems] = useState(null);
    useEffect(() => {
        getPayments().then((res) => {
            // console.log(res.data.data)
            setPaymentItems(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            {paymentItems?.length === 0 ? (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 3 }}
                >
                    <Box
                        component="img"
                        src={NoRepliesGif}
                        width={isSmallDevice ? "90%" : "50% !important"}
                        height={isSmallDevice ? "15rem" : "25rem"}
                    />
                    <Typography>No Records</Typography>
                </Stack>
            ) : (
                <Box sx={{
                    p: { xs: '0.4rem', sm: '0.5rem' },
                    borderRadius: '0.3rem',
                    m: { xs: '1rem', sm: '0.5rem' },
                    boxShadow: '0px 0px 5px rgba(0, 0, 100, 0.25)'
                }}>
                    <List
                        sx={{
                            p: 0,
                            width: "100%",
                            overflow: "auto",
                            bgcolor: "background.paper"
                        }}
                    >
                        {(paymentItems ?? Array.from(new Array(5))).map((r, index) => (
                            <PaymentItem item={r} key={index} isLast={index === paymentItems?.length - 1} />
                        ))}
                    </List>
                </Box>
            )}
        </>
    )
};

export default Payment;
