import AddIcon from '@mui/icons-material/Add';
import { DialogContent, Fab, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { EventSettingsContext } from "../..";
import TicketTabs from "./TicketTabs";

const EventTickets = () => {
    const { tickets, handleNextClick } = useContext(EventSettingsContext);

    return (
        <>
            <DialogContent sx={{ p: '0rem', height: { xs: '29rem', md: '34rem' } }}>
                {
                    tickets?.length === 0 ? <>
                        <Stack justifyContent='center' alignItems='center' spacing={2} sx={{ height: '100%' }}>
                            <Typography>Create New Ticket</Typography>
                            <Fab size="medium" sx={{ backgroundImage: 'linear-gradient(#FF9966, #FF5E62)' }}
                                onClick={() => handleNextClick()}>
                                <AddIcon />
                            </Fab>
                        </Stack>
                    </> : <>
                        <Stack spacing={1} sx={{ height: '100%' }}>
                            <TicketTabs />
                        </Stack>
                    </>
                }
            </DialogContent>
        </>
    )
};

export default EventTickets;
