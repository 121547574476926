import _ from "lodash"

const getGeneratedOrSendTicketCharges = (totalGuests) => {
    if (_.inRange(totalGuests, 0, 100)) {
        return 5
    } else if (_.inRange(totalGuests, 100, 500)) {
        return 4
    } else if (_.inRange(totalGuests, 500, 1000)) {
        return 3
    } else if (_.inRange(totalGuests, 1000, 5000)) {
        return 2
    } else if (_.inRange(totalGuests, 5000, 10000)) {
        return 1.5
    } else if (totalGuests > 10000) {
        return 1.25
    }
}

export default getGeneratedOrSendTicketCharges