import React, { useContext } from "react";
import { EventSettingsContext } from "../..";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { useSelector } from "react-redux";
import { getCheckInRecords } from "../../../../../../../../services/wEvents/eventGuests";
import { Pagination, Stack } from "@mui/material";

const CheckInRecordsPagination = ({ ticketId }) => {
    const eventData = useSelector(eventDetails)
    const { totalCheckInRecords, setCheckInRecords, per_page_records } = useContext(EventSettingsContext);
    const handleChange = (event, value) => {
        getCheckInRecords({ eventId: eventData?.id, ticketId: ticketId, page: value }).then((res) => {
            setCheckInRecords(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <Stack sx={{ py: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination count={Math.ceil(totalCheckInRecords.current / per_page_records.current)}
                    variant="outlined" color="warning"
                    onChange={handleChange} showFirstButton showLastButton />
            </Stack>
        </>
    )
};

export default CheckInRecordsPagination;
