import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Skeleton, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MemberRegistrationContext } from "../..";
import { groupDetails } from '../../../../../../../../redux/slices/groupDetailSlice';
import { submitGroupFormAnswers } from '../../../../../../../../services/wGroups/groupForm';
import { manageModelFormAnswerPayload } from "../../../../../../../../utils/functions/managePayload";

const MemberRegistrationSection = () => {
    const [loader, setLoader] = useState(false);
    const { memberId } = useParams();

    const { handleSlideChange, rForm, registrationData,
        setRegistrationData, setPaymentDetails } = useContext(MemberRegistrationContext);
    const groupDetailsObj = useSelector(groupDetails);

    const { handleSubmit, register, reset, control, formState: { errors } } = useForm();

    const mySubmit = (data) => {
        setLoader(true);
        setRegistrationData(data);
        let payload = manageModelFormAnswerPayload(data, { member_uuid: memberId });

        submitGroupFormAnswers(groupDetailsObj?.id, payload).then((res) => {
            setLoader(false);
            handleSlideChange(1);
            if (groupDetailsObj.is_paid) {
                //We are showing details filled in form so we need to set this in context
                const member_data = {
                    member_uuid: res.data?.['memberData'].member_uuid, // Because this can be same or new members uuid
                    member_name: res.data?.['QAs'].find((e) => e.question === 'Name')?.answer,
                    member_dial_code: res.data?.['QAs'].find((e) => e.question === 'Country Code')?.answer,
                    member_number: res.data?.['QAs'].find((e) => e.question === 'Mobile Number')?.answer,
                    member_email: res.data?.['QAs'].find((e) => e.question === 'Email')?.answer,
                }
                setPaymentDetails({ ...member_data });
            }
            toast.success(groupDetailsObj?.is_paid ? "Registration form saved" : "Member successfully joined group", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            })
            setLoader(false);
        })
    }

    const handleResetForm = () => {
        reset();
    }

    const renderItem = (item) => {
        if (item) {
            switch (item.type) {
                case 'text':
                    return (
                        <>
                            <TextField
                                fullWidth
                                size="small"
                                color="warning"
                                label={item.question}
                                // required={item.mandatory ? true : false}
                                defaultValue={registrationData?.[item.id]}
                                error={errors[item.id] ? true : false}
                                helperText={
                                    errors[item.id] ? 'This is required field' : null
                                }
                                {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                            />
                        </>
                    )
                case 'number':
                    return (
                        <>
                            <TextField
                                size="small"
                                color="warning"
                                label="Country Code"
                                inputProps={{ type: 'number', min: 1 }}
                                defaultValue={91}
                                required={item.mandatory ? true : false}
                                error={errors[item.id] ? true : false}
                                helperText={
                                    errors[item.id] ? 'This is required field' : null
                                }
                                {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                            />
                        </>
                    )
                case 'textarea':
                    return (
                        <>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                size="small"
                                color="warning"
                                label={item.question}
                                required={item.mandatory ? true : false}
                                defaultValue={registrationData?.[item.id]}
                                error={errors[item.id] ? true : false}
                                helperText={
                                    errors[item.id] ? 'This is required field' : null
                                }
                                {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                            />
                        </>
                    );
                case 'switch':
                    return (
                        <>
                            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    {item.question}
                                </Typography>
                                <Switch
                                    color='warning'
                                    defaultChecked={registrationData?.[item.id]}
                                    {...register(`${item.id}`)}
                                />
                            </Stack>
                        </>
                    )
                case 'radio':
                    return (
                        <>
                            <FormControl error={(errors[item.id]) ? true : false}>
                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                                <Controller
                                    rules={{ required: Boolean(item.mandatory) }}
                                    name={`${item.id}`}
                                    control={control}
                                    defaultValue={registrationData?.[item.id]} // This is for setting default value in useform key
                                    render={({ field }) => (
                                        <RadioGroup row
                                            defaultValue={registrationData?.[item.id] || ''} // This is for show checked option on UI & Provide a default value if registrationData?.[item.id] is undefined
                                            onChange={e => field.onChange(e.target.value)} // Manually update the value in the field
                                        >
                                            {item.options.map((option, i) => (
                                                <FormControlLabel key={i} value={option}
                                                    label={option} control={<Radio color="warning" />} />
                                            ))}
                                        </RadioGroup>
                                    )}
                                />
                                {errors[item.id] ? <FormHelperText>Please choose one option</FormHelperText> : null}
                            </FormControl>
                        </>
                    )
                default:
                    return <></>;
            }
        } else {
            return (
                <Stack spacing={1.5} sx={{ pt: 1 }}>
                    <Skeleton sx={{
                        transform: 'scale(1,1)',
                        width: '50%', height: '0.75rem'
                    }} />
                    <Skeleton sx={{ transform: 'scale(1,1)' }} />
                </Stack>
            )
        }
    }

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                {rForm ? <Typography fontWeight='bold' textAlign='center' sx={{ py: 1 }}>
                    {rForm.title}
                </Typography> :
                    <Skeleton sx={{ transform: 'scale(1,1)', width: '35%', height: '1.5rem' }} />}
                <Stack spacing={2} sx={{ py: '1rem' }}>
                    {(rForm ? rForm.fields : Array.from(new Array(3)))?.map((item, i) => (
                        <React.Fragment key={i}>
                            {renderItem(item)}
                        </React.Fragment>
                    ))}
                </Stack>

                {rForm ?
                    <DialogActions sx={{ pr: 0 }}>
                        <Button color='warning' variant="outlined"
                            sx={{ boxShadow: 'none' }} onClick={handleResetForm}>
                            Reset
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="warning"
                            variant="contained"
                            loading={loader ? true : false}
                            startIcon={loader ? <SaveIcon /> : null}
                            loadingPosition={loader ? "start" : null}
                        >
                            {loader ? 'Saving...' : groupDetailsObj?.is_paid ? 'Next' : 'Submit'}
                        </LoadingButton>
                    </DialogActions>
                    : null
                }
            </form>
        </>
    )
};

export default MemberRegistrationSection;
