import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import React from "react";
import { useSelector } from "react-redux";
import MuiTabPanel from "../../../../components/muiTabPanel";
import { groupDetails } from "../../../../redux/slices/groupDetailSlice";
import { mapGroupTabs } from "../../../../utils/constants/groups.menubar.tabs";
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";

const GroupDetailTabs = ({ tabValue, handleTabChange }) => {
    const groupData = useSelector(groupDetails);

    return (
        <>
            {Object.keys(groupData).length > 0 ?
                <>
                    <Stack
                        direction='row'
                        alignItems='center'
                        className='menubar-sticky'
                    >
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            // scrollable
                            scrollButtons
                            // breakpoint="sm"
                            // allowScrollButtonsMobile
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#FF8359"
                                }
                            }}
                            sx={{
                                flexGrow: 1,
                                backgroundColor: '#FFEBE4',
                                maxWidth: { sm: '100%' },
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    "&.Mui-disabled": { opacity: 0.3 }
                                },
                                '.Mui-selected': {
                                    fontWeight: "bold !important",
                                    color: '#FF8359 !important',
                                    opacity: '1 !important'
                                },
                                '.MuiTabs-scrollButtons': {
                                    display: { sm: 'none !important' },
                                }
                            }}
                        >
                            {groupData.tabs?.map((tab, index) => (
                                <Tab key={index} label={tab.title} sx={{
                                    color: '#FF8359',
                                    opacity: '0.6',
                                    textTransform: 'none',
                                }} />
                            )
                            )}
                        </Tabs>
                        {/* {ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) ?
                            <IconButton className='menubar-addTabBtn' sx={{
                                // right: 0,
                                // position: { xs: 'absolute' },
                                color: 'white',
                                borderRadius: 0,
                                backgroundColor: '#1F274A',
                                ":hover": {
                                    color: 'white',
                                    backgroundColor: '#1F274A'
                                }
                            }}>
                                <ControlPointIcon sx={{ fontSize: '2rem' }} />
                            </IconButton>
                            : null
                        } */}
                    </Stack>
                    {groupData.tabs?.map((tab, index) => (
                        <MuiTabPanel key={index} value={tabValue} index={index}>
                            {mapGroupTabs[tab?.title]}
                            {/* This will render relative component based on event API response */}
                        </MuiTabPanel>
                    ))}
                </>
                : <Skeleton height='10rem' sx={{ mx: 2 }} />
            }
        </>
    )
};

export default GroupDetailTabs;
