export const razorPayModalConfig = {
    paymentMethodsConfiguration: {
        display: {
            blocks: {
                banks: {
                    name: 'Most Used Methods',
                    instruments: [
                        {
                            method: 'wallet',
                            wallets: ["freecharge"]
                        },
                        {
                            method: 'upi'
                        },
                        // {
                        //     method: 'upi',
                        //     apps: ["paytm"]
                        // },
                        {
                            method: "card"
                        }
                    ],
                },
            },
            hide: [
                {
                    method: 'paylater'
                },
                {
                    method: 'card'
                }
            ],
            sequence: ['block.banks'],
            preferences: {
                show_default_blocks: true,
            }
        }
    }
}