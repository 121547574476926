import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { Chip } from "@mui/material";

export const statusMappings = {
    'to_be_send': <Chip size='small' icon={<PendingIcon color='white' />} label="Pending" sx={{ backgroundColor: "#FF8F00", color: 'white' }} />,
    'sending': <Chip size='small' icon={<PendingIcon color='white' />} label="Sending" color="info" />,
    'sent': <Chip size='small' icon={<CheckCircleIcon color='white' />} label="Sent" color="success" />,
    'failed': < Chip size='small' icon={< CancelIcon color='white' />} label="Failed" color="error" />,
    'invalid_number': <Chip size='small' icon={<ErrorIcon color='white' />} label="Invalid Number" color="error" />,
}