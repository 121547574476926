import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack, Typography, IconButton, Box, Chip } from "@mui/material";
import DeleteBankDialog from "./DeleteBankDialog";
import { toast } from "react-toastify";
import { deleteBank } from "../../services/bankDetails";
import { userDetails } from "../../redux/slices/userDataSlice";
import { useSelector } from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';

const BankCard = ({ bank, setBanks, setBankForm }) => {
    const userData = useSelector(userDetails);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const handleEditBank = () => setBankForm({ type: 'edit', visible: true, details: bank });
    const handleDeleteOpen = () => setDeleteDialog(true);
    const handleDeleteClose = () => setDeleteDialog(false);

    const handleBankDelete = () => {
        deleteBank(userData?.id, bank.id).then(() => {
            setDeleteDialog(false);
            setBanks((banks) => {
                let newItems = banks.filter((b) => b.id !== bank?.id)
                return newItems;
            })
            toast.success("Bank deleted", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <Stack spacing={1} sx={{
                p: { xs: 1, sm: 2 },
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.1)'
            }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{bank.bank_name}</Typography>
                    <Stack direction='row' spacing={{ xs: 0, sm: 0.5 }} alignItems='center'>
                        {!bank.is_verified ?
                            <IconButton onClick={handleEditBank}>
                                <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                            </IconButton> : null
                        }
                        <IconButton onClick={handleDeleteOpen}>
                            <DeleteIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                    </Stack>
                </Stack>

                <Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline' }}>Account number:</Typography>
                    <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{bank.account_number}</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline' }}>Account holder name:</Typography>
                    <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{bank.account_holder_name}</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline' }}>Account holder email:</Typography>
                    <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{bank.account_holder_email}</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline' }}>IFSC code:</Typography>
                    <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline' }}>{bank.ifsc_code}</Typography>
                </Box>

                <Chip color={bank.is_verified ? "success" : "error"}
                    label={bank.is_verified ? "Verified" : "Not verified"}
                    icon={bank.is_verified ? <VerifiedIcon /> : <DangerousIcon />}
                    sx={{ width: 'fit-content' }} />

            </Stack>
            {
                deleteDialog ? <DeleteBankDialog handleBankDelete={handleBankDelete}
                    handleDeleteClose={handleDeleteClose} /> : null
            }
        </>
    )
};

export default BankCard;
