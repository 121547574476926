import React from "react";
import { Button, Typography, Grid, Stack, Box, Skeleton } from "@mui/material";
import { CreateEventContext } from "../CreateEventContext";

const EventBox = ({ subCategory, activeId, onSelect }) => {
    const { formData } = React.useContext(CreateEventContext)
    return (
        <>
            <Grid
                item xs={4} sm={2} md={2} lg={1.5}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack>
                    {subCategory ?
                        <Button
                            onClick={() => onSelect(subCategory)}
                            sx={{
                                p: { xs: '1.2rem', sm: '1.5rem' },
                                color: 'black',
                                border: (activeId === subCategory.id || formData.category_id === subCategory.id) ? '2px solid #FF8359' : '2px solid #E5E4E9',
                                borderRadius: '4px',
                                ":hover": { backgroundColor: '#FF8359', color: 'white', transform: 'scale(1.1)' },
                                transition: 'transform .2s, background-color 0.5s ease-in-out',
                                backgroundColor: (activeId === subCategory.id || formData.category_id === subCategory.id) ? "#FF8359" : "transparent"
                            }}>
                            <Box
                                component="img"
                                src={subCategory.icon}
                                sx={{ width: '3rem', height: '3rem' }}
                            />
                        </Button>
                        : <Skeleton
                            variant="wave"
                            width='5.5rem'
                            height='5.5rem'
                            sx={{
                                transform: 'scale(1,1)',
                                border: '2px solid #E5E4E9',
                                borderRadius: '4px',
                            }} />
                    }
                    {subCategory ?
                        <Typography sx={{ maxWidth: '6rem', fontSize: { xs: '12px', sm: '14px' }, textAlign: 'center', mt: 1 }}>
                            {subCategory.title}
                        </Typography>
                        : <Skeleton sx={{
                            mt: 0.5,
                            height: 0.2,
                            border: '2px solid #E5E4E9',
                            borderRadius: '4px',
                        }} />
                    }
                </Stack>
            </Grid>
        </>
    );
};

export default EventBox;
