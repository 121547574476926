import React from "react";
import { useContext } from "react";
import { Stack, Typography } from '@mui/material';
import AddSubstituteGuest from "./components/AddDeleteOptions/AddSubstituteGuest";
import { SubstituteEventGuestsContext } from "../../SubstituteEventGuestsContext";

const SubstituteGuestHeader = () => {
    const { totalGuests } = useContext(SubstituteEventGuestsContext)

    return <>
        <Stack sx={{ m: 1 }} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography>Substitute Event guests ({totalGuests.current ?? 0})</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <AddSubstituteGuest />
            </Stack>
        </Stack>
    </>
};

export default SubstituteGuestHeader;
