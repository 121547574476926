import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import React, { useContext, useState } from "react";
import { Button, Popover, Stack, Typography } from "@mui/material";
import { EventGuestsContext } from "../../EventGuestContext";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { deleteEventGuest, getEventGuests } from "../../../../../../../../services/wEvents/eventGuests";

const DeleteEventGuest = ({ guestId, anchorEl, setAnchorEl, handleClose }) => {
    const [loading, setLoading] = useState(null);
    const eventData = useSelector(eventDetails);
    const { tabValue, totalGuests, setGuests, setSearchedGuest } = useContext(EventGuestsContext)
    const openPopover = Boolean(anchorEl);
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleGuestDelete = (guest_id) => {
        setLoading(true);
        setSearchedGuest(null);
        deleteEventGuest(eventData?.id, guest_id).then(() => {
            getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered' }).then((res) => {
                setGuests(res.data.data);
                totalGuests.current -= 1;
                setLoading(false);
                handleClosePopover();
                handleClose();
                toast.success("Event guest deleted!!", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            })
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <>
            <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ fontWeight: 'bold', p: '8px 8px' }}>Delete Guest</Typography>
                <Typography sx={{ px: 1 }}>Are you sure want to delete this guest?</Typography>
                <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ my: 1, p: 1 }}>
                    <Button size='small' color='warning' variant='outlined' onClick={handleClosePopover}>Cancel</Button>
                    <LoadingButton
                        size="small"
                        color="warning"
                        variant="contained"
                        onClick={() => handleGuestDelete(guestId)}
                        loading={loading ? true : false}
                        startIcon={loading ? <SaveIcon /> : null}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Deleting...' : 'Yes'}
                    </LoadingButton>
                </Stack>
            </Popover>
        </>
    )
};

export default DeleteEventGuest;
