import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Stack, DialogActions, Typography, Box } from "@mui/material";
import DialogBox from "../../../../../../../../../components/DialogBox";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { manageGroupAlbumPayload } from "../../../../../../../../../utils/functions/managePayload";
import { groupDetails } from "../../../../../../../../../redux/slices/groupDetailSlice";
import { editGroupAlbum } from "../../../../../../../../../services/wGroups/groupDetails";
import { createGroupAlbumForm } from "../../../../../../../../../utils/constants/createGroupAlbum";
import CustomRadioGroup from "../../../../../../../../../components/CustomRadioGroup";
import { albumFieldsToHide } from "../../../../../../../../../utils/constants/eventAlbumHideFields";

const EditAlbumContent = (handleClose, setItems, albumDetails) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();
    const [loader, setLoader] = useState(false);
    const groupData = useSelector(groupDetails)
    const [albumType, setAlbumType] = useState(albumDetails.type);
    const handleRadioChange = (event) => {
        setAlbumType(event.target.value);
    };

    const mySubmit = (data) => {
        setLoader(true);
        data.type = albumType;
        let payload = manageGroupAlbumPayload(data, true)
        if (albumType === 'link') {
            const spaces = data.album_link.split(' ').length;
            if (spaces > 1) {
                toast.error("Online platform link should not contain spaces", {
                    position: "top-right",
                    theme: "dark"
                });
                setLoader(false);
                return;
            }
        }

        editGroupAlbum(payload, groupData?.id, albumDetails?.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success("Album Updated!!", {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => {
                let ele = items.find(i => i.id === res.data?.data?.id);
                items[items.indexOf(ele)] = res.data.data;
                return [...items];
            })
            setLoader(false);
            handleClose();
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={2}>
                    <Box>
                        <Typography sx={{ mb: 1 }}>Album Type</Typography>
                        <CustomRadioGroup value={albumType} setValue={setAlbumType}
                            handleRadioChange={handleRadioChange}
                            option1='photos' option2='link' />
                    </Box>
                    {createGroupAlbumForm.data.map((item, index) => (
                        !((albumType === 'photos' && albumFieldsToHide.linkFields.includes(item.name)) ||
                            (albumType === 'link' && albumFieldsToHide.photosFields.includes(item.name))
                        ) ?
                            <RenderFormFields key={index} item={item}
                                register={register} errors={errors} control={control}
                                editForm={true} cardDetails={albumDetails} />
                            : null
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <LoadingButton 
                    type="submit"
                     color='warning' 
                     variant="contained"
                     loading={loader? true:false}
                     startIcon={loader? <SaveIcon/>:null}
                     loadingPosition={loader?"start":null}
                     >
                        Save Changes
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const EditAlbumDialog = ({ handleClose, setItems, albumDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Edit Album' content={EditAlbumContent(handleClose, setItems, albumDetails)}
        />
    )
};

export default EditAlbumDialog;
