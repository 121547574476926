import { Box, DialogActions, DialogContent, List, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import { GroupSettingsContext } from "../..";
import NoRepliesGif from '../../../../../../../../assets/no-replies.gif';
import SubscriptionPaymentItem from "./SubscriptionPaymentItem";
import ItemPagination from "./ItemPagination";

const SubscriptionPayments = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { subscriptionsPayments } = useContext(GroupSettingsContext);

    return (
        <>
            {subscriptionsPayments?.length > 0 ?
                <>
                    <DialogContent dividers={true} sx={{
                        p: { xs: '0rem', md: '0rem' },
                        height: { xs: '26.5rem', md: '31.5rem' }
                    }}
                    >
                        <List sx={{
                            p: 0,
                            width: '100%',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                        }}>
                            {subscriptionsPayments.map((r, index) => (
                                <SubscriptionPaymentItem item={r} key={index} />
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <ItemPagination />
                    </DialogActions>
                </>
                :
                <Stack justifyContent='center' alignItems='center' spacing={2} sx={{ mt: 3 }}>
                    <Box component='img'
                        src={NoRepliesGif}
                        width={isSmallDevice ? '90%' : '50% !important'}
                        height={isSmallDevice ? '15rem' : '17rem'}
                    />
                    <Typography>No one purchased your subscription!!</Typography>
                </Stack>
            }
        </>
    )
};

export default SubscriptionPayments;
