import React from "react";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { userDetails } from "../../../../../../../../../redux/slices/userDataSlice";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { getEventEmailTemplate, getTicketEmailTemplate } from "../../../../../../../../../utils/functions/getEmailTemplate";

const EmailEditor = ({ isReminder, inviteMsg, editorContent, setContent,selfCheckin, ticketData, ticketPrice, ticketCurrency }) => {
    const eventData = useSelector(eventDetails)
    const userData = useSelector(userDetails);
    const initialEmailContent = () => {
        if (selfCheckin !== undefined) {
            return getTicketEmailTemplate(selfCheckin, eventData, userData, ticketData,ticketPrice, ticketCurrency);
        } else if (inviteMsg?.emailMessage !== undefined) {
            return inviteMsg.emailMessage;
        } else {
            return getEventEmailTemplate(isReminder, eventData, userData);
        }
    };

    return (
        <Editor
            apiKey="un1p8eawnh9fp3rbcu15izywpbv2pwq3j92kiwavqbdkdrbg"
            value={editorContent}
            onEditorChange={(newValue, editor) => setContent(newValue)}
            initialValue={initialEmailContent()}
            init={{
                selector: 'textarea',
                readonly: false,
                entity_encoding: "raw",
                height: '500px',
                plugins: ['lists', 'link', 'table', 'emoticons', 'image', 'insertdatetime', 'media', 'fullscreen', 'preview', 'charmap',
                    'code', 'codesample'],
                menubar: false,
                toolbar: 'bold italic underline forecolor backcolor | fontfamily | fontsize | undo redo | ' +
                    'fullscreen preview | outdent indent removeformat |' +
                    'alignleft aligncenter alignright alignjustify | bullist numlist |' +
                    'charmap link emoticons image media | insertdatetime table code codesample',
                toolbar_mode: 'sliding',
                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                file_picker_types: 'image', // Only allow image files to be selected
                file_picker_callback: function (callback, value, meta) {
                    // Create a file input element
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    // When the user selects a file, read it and create a blob URI
                    input.onchange = function () {
                        const file = input.files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                            const blobUri = reader.result;
                            callback(blobUri, { title: file.name });
                        };
                    };

                    // Trigger the file input element
                    input.click();
                }
            }}
        />
    )
};

export default EmailEditor;
