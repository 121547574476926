import { Pagination, Stack } from "@mui/material";
import React, { useContext } from "react";
import { getGroupSubscriptionPayments } from "../../../../../../../../services/wGroups/groupSubscription";
import { GroupSettingsContext } from "../..";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";

const ItemPagination = () => {
    const groupDetailsObj = useSelector(groupDetails);
    const { setSubscriptionsPayments, totalSubscriptionPaymentRecords, per_page_records } = useContext(GroupSettingsContext);
    const handleChange = (event, value) => {
        getGroupSubscriptionPayments({ groupId: groupDetailsObj?.id, page: value }).then((res) => {
            setSubscriptionsPayments(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <Stack sx={{ py: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination
                    count={Math.ceil(totalSubscriptionPaymentRecords.current / per_page_records.current)}
                    variant="outlined"
                    color="warning"
                    onChange={handleChange}
                />
            </Stack>
        </>
    );
};

export default ItemPagination;
