import _ from "lodash";
import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import {
    Button,
    Box,
    Step,
    Stepper,
    StepLabel,
    Typography,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { sidebarStatus } from "../../../../../redux/slices/sidebarDataSlice";
import { CreateEventContext } from "./CreateEventContext";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiStepIcon-root.Mui-active": { color: "#FF8359" },
        "& .MuiStepIcon-root.Mui-completed": { color: "#28a745" }
        // "& .Mui-disabled .MuiStepIcon-root": { color: "#FF8359" }
    }
}));

const WebLinearStepper = ({ steps, activeStep, getStepContent, handleNext, handleBack }) => {
    const c = useStyles();
    const isSideBarOpen = useSelector(sidebarStatus);
    const { formData } = useContext(CreateEventContext);

    return (
        <>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                className={c.root}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2
                        }}
                    >
                        <Box sx={{ flex: "1 1 auto" }} />
                    </Box>
                </>
            ) : (
                <>
                    {getStepContent(activeStep)}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            p: '2px',
                            position: 'fixed',
                            bottom: 0,
                            right: 0,
                            zIndex: 1000,
                            backgroundColor: '#F2F2F2',
                            width: { sm: isSideBarOpen ? 'calc(100% - 240px)' : '100%' }
                        }}
                    >
                        <Button
                            size="large"
                            color="warning"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            sx={{ fontSize: '1rem', pl: 3, ":disabled": { fontSize: '1rem' } }}
                        >
                            <ArrowBackIosIcon />&nbsp;Back
                        </Button>

                        <Box sx={{ flex: "1 1 auto" }} />

                        <Button onClick={handleNext}
                            size="large"
                            color="warning"
                            sx={{
                                pr: 3,
                                fontSize: '1rem',
                                textTransform: 'none !important',
                                ":disabled": { fontSize: '1rem' },
                            }}
                        >
                            {activeStep === steps.length - 1
                                ? "Create"
                                : <>Next&nbsp;<ArrowForwardIosIcon size='small' /></>}
                        </Button>
                    </Box>
                </>
            )}

        </>

    );
};

export default WebLinearStepper;
