import { toast } from "react-toastify";
import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, TextField, Divider, IconButton, Stack } from "@mui/material";
import AddFormQuestionsDialog from "../../../../../EventDetailCards/components/EventSettings/components/EventRegistrationForms/AddFormQuestionsDialog";

const EditRsvpQuestions = ({ unregister, setValue, rsvpQuestions, setRsvpQuestions, setIdsToDelete, watch }) => {
    const [add, setAdd] = useState(false);

    console.log(watch());
    const handleAddRsvpQuestion = (data, chipData) => {
        const lastElementId = (rsvpQuestions?.slice(-1)[0]?.name ?? 0)
        setValue(`${lastElementId + 1}`, { type: data.questionType, question: data.question, options: chipData.length > 0 ? chipData : undefined, mandatory: false })
        setRsvpQuestions((questions) => [...questions, { name: lastElementId + 1, question: data.question, type: data.questionType, options: chipData.length > 0 ? chipData : undefined, mandatory: false }])
        setAdd(false);
        toast.success("Question added", {
            position: "top-right",
            theme: "dark"
        });
    }
    const handleDeleteItem = (index, item) => {
        rsvpQuestions.splice(index, 1);
        setRsvpQuestions([...rsvpQuestions]);
        item.id && setIdsToDelete((prev) => [...prev, item.id])
        unregister(`${item.name}`);
    }

    const handleChangeField = (e, index, fieldName) => {
        setValue(`${fieldName}.question`, e.target.value)
        rsvpQuestions[index].question = e.target.value
        setRsvpQuestions([...rsvpQuestions]);
    }

    const renderItem = (item, index) => {
        const fieldName = item.name
        switch (item.type) {
            case 'textarea':
                return (
                    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            size="small"
                            color="warning"
                            value={item.question}
                            onChange={(e) => handleChangeField(e, index, fieldName)}
                        />
                        <IconButton size='small' onClick={() => handleDeleteItem(index, item)} >
                            <DeleteIcon color="warning" />
                        </IconButton>
                    </Stack>
                );
            default:
                return (
                    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            value={item.question}
                            inputProps={{ readOnly: index === 0 ? 'true' : false }}
                            onChange={(e) => handleChangeField(e, index, fieldName)}
                        />
                        {index !== 0 ?
                            <IconButton size='small' onClick={() => handleDeleteItem(index, item)} >
                                <DeleteIcon color="warning" />
                            </IconButton> : null}
                    </Stack>
                );
        }
    }

    return (
        <>
            {rsvpQuestions.map((item, i) => (
                <React.Fragment key={i}>
                    {renderItem(item, i)}
                </React.Fragment>
            ))}
            <Divider sx={{ my: 1 }} />
            {add ? <Box sx={{
                margin: '1rem auto !important',
                width: '90%',
                borderRadius: '5px',
                boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
            }}>
                <AddFormQuestionsDialog setAdd={setAdd} handleAddFormQuestion={handleAddRsvpQuestion} />
            </Box> :
                <Button variant="text" color="warning" sx={{ width: 'auto' }} onClick={() => setAdd(true)}>+ Add Question</Button>
            }
        </>
    )
};

export default EditRsvpQuestions;
