import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { userDetails } from "../../../../redux/slices/userDataSlice";
import { Avatar, Box, Stack, Paper, Typography } from "@mui/material";
import avtar from '../../../../assets/messi3.webp'
import { getUserDetails } from "../../../../services/Authentication/user";
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import { change_user_details } from "../../../../redux/slices/userDataSlice";
import ls from 'local-storage'
import { BACKEND_URL } from "../../../../utils/constants/apis.urls";
import UserKYC from "./components/UserKYC";

const UserAccount = () => {
    const dispatch = useDispatch()
    const user_details = useSelector(userDetails)
    useEffect(() => {
        // Whether API hit needed here or not
        // getUserDetails({ userId: 9 }).then((res) => {
        //     console.log('User details======>', res);
        //     // ls('UserData', res.data)
        //     // dispatch(change_user_details(res.data))
        //     // Update state and render
        // }).catch((err) => {
        //     console.log("$$$$$$$$", err);
        //     toast.error(err.message || err.message?.errors, {
        //         position: "top-right",
        //         theme: "dark"
        //     });
        // })
    }, []);

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Box sx={{ p: 3, borderRadius: '0.5rem', boxShadow: '0px 0px 15px rgba(0, 0, 100, 0.25)' }}>
                    <Stack direction={{ md: 'row' }} alignItems='center' spacing={5} rowGap={2}>
                        <Avatar src={user_details?.profile_photo_path ?
                            `${user_details?.profile_photo_path}` : undefined}
                            alt={avtar}
                            sx={{ width: '10rem', height: '10rem' }} />
                        <Stack spacing={2}>
                            <Typography variant="h4"><strong>First Name:</strong> {user_details?.first_name}</Typography>
                            {
                                user_details?.last_name ?
                                    <Typography variant="h4"><strong>Last Name:</strong> {user_details?.last_name}</Typography>
                                    : null
                            }
                            <Typography variant="h4"><strong>Email:</strong> {user_details?.email}</Typography>
                            <Typography variant="h4"><strong>Mobile Number:</strong> {"+" + user_details?.dialing_code + " " + user_details?.mobile}</Typography>
                        </Stack>
                    </Stack>
                </Box>
                <UserKYC />
            </Box>
        </>
    );
};

export default UserAccount;
