import {
    Box,
    Button,
    DialogActions,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventGuestsContext } from "../../EventGuestContext";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { editEventGuest } from "../../../../../../../../services/wEvents/eventGuests";
import { manageEventGuestPayload } from "../../../../../../../../utils/functions/managePayload";
import UploadImage from "../../../../../../../../components/UploadImage";

const EditEventGuest = ({ guestDetails, setEdit }) => {
    const { setGuests } = useContext(EventGuestsContext);
    const [isDeletePhoto, setIsDeletePhoto] = useState(false);
    const [guestImage, setGuestImage] = useState(
        guestDetails?.event_user_photo_path || null
    );
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue
    } = useForm();

    const eventData = useSelector(eventDetails);
    const [loader, setLoader] = useState(false); // Add loading state
    const mySubmit = (data) => {
        setLoader(true); // Set loading to true when form is submitted
        let payload = manageEventGuestPayload(data, true);
        editEventGuest(payload, eventData?.id, guestDetails.id)
            .then((res) => {
                setGuests((guests) => {
                    let ele = guests.find((i) => i.id === res.data?.data?.id);
                    guests[guests.indexOf(ele)] = res.data?.data;
                    return [...guests];
                });
                toast.success("Event guest updated successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
                setEdit(false);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
                setLoader(false);
            });
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: "1rem" }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Enter Name"
                        name="guest_name"
                        inputProps={{ type: "text" }}
                        defaultValue={_.get(guestDetails, "name")}
                        error={_.get(errors, "guest_name") ? true : false}
                        helperText={
                            _.get(errors, "guest_name")
                                ? "This is required field"
                                : null
                        }
                        {...register("guest_name", { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Email ID"
                        name="email"
                        inputProps={{ type: "email" }}
                        defaultValue={_.get(guestDetails, "email")}
                        error={_.get(errors, "email") ? true : false}
                        helperText={
                            _.get(errors, "email")
                                ? "This is required field"
                                : null
                        }
                        {...register("email", { required: false })}
                    />

                    {/* No need to update guest number as of now */}
                    {/* <Stack direction='row' spacing={1}>
                        <Select
                            color="warning"
                            defaultValue={_.get(guestDetails, 'dialing_code')}
                            error={_.get(errors, 'dialing_code') ? true : false}
                            {...register('dialing_code', { required: true })}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                        width: "7rem",
                                        marginTop: "0.5rem",
                                        border: "1px solid #E4E4E4",
                                        p: 1.5,
                                        pt: 0.75,
                                        boxShadow: "0",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        scrollbarWidth: "0px",
                                        "&& .Mui-selected": {
                                            backgroundColor: "lightblue",
                                            border: "1px solid #E4E4E4",
                                            "&:hover": {
                                                backgroundColor: "#FAFBFD"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {countryCodes.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    <Box>+{code.dial_code}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            label='Phone Number'
                            defaultValue={_.get(guestDetails, 'mobile')}
                            inputProps={{ 
                                type: 'tel',
                                pattern: '[0-9]*'
                            }}
                            error={_.get(errors, 'mobile') ? true : false}
                            helperText={
                                _.get(errors, 'mobile') ? 'This is required field' : null
                            }
                            {...register('mobile', { required: true })}
                            sx={{ flex: 1 }}
                        />
                    </Stack> */}

                    {guestDetails?.extra_csv_details
                        ? Object.entries(guestDetails.extra_csv_details).map(
                              ([key, value]) => (
                                  <TextField
                                      fullWidth
                                      color="warning"
                                      label={key}
                                      name={key}
                                      inputProps={{ type: "text" }}
                                      defaultValue={value}
                                      error={_.get(errors, key) ? true : false}
                                      helperText={
                                          _.get(errors, key)
                                              ? "This is required field"
                                              : null
                                      }
                                      {...register(key, { required: false })}
                                  />
                              )
                          )
                        : null}

                    <>
                        <Typography sx={{ fontSize: "1rem" }}>
                            Profile Photo:
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor:
                                    guestImage && !isDeletePhoto
                                        ? "#171717"
                                        : "white",
                                borderRadius: "10px",
                                width: { xs: "60%", sm: "50%", lg: "40%" },
                                height: "9rem",
                                position: "relative",
                                border: "1px solid lightgrey"
                            }}
                        >
                            <UploadImage
                                component="img"
                                src={guestDetails?.event_user_photo_path}
                                alt="uploaded file"
                                sx={{ width: "100%", maxWidth: 200, mt: 1 }}
                                fieldName="guest_photo"
                                label="Guest Photo"
                                register={register}
                                errors={errors}
                                selectedImage={
                                    isDeletePhoto ? null : guestImage
                                }
                                setSelectedImage={(v) => {
                                    setGuestImage(v);
                                    if (isDeletePhoto) {
                                        setIsDeletePhoto(false);
                                    }
                                }}
                                hideVisibility={false}
                                hideOptions={false}
                                isDeletable={true}
                                onDelete={() => {
                                    setIsDeletePhoto(true);
                                    setGuestImage(null);
                                    setValue("guest_photo", null);
                                }}
                            />
                        </Box>
                    </>
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                            reset();
                            setEdit(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                        sx={{
                            boxShadow: "none"
                        }}
                    >
                        {loader ? "Saving Changes..." : "Save"}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    );
};

export default EditEventGuest;
