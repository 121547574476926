import EditIcon from '@mui/icons-material/Edit';
import {
    FormControl, FormControlLabel, IconButton, Radio, RadioGroup,
    Stack, Switch, TextField, Typography,
} from "@mui/material";
import React from "react";

const RFormView = ({ formTitle, formDetails, handleEditClick }) => {
    const renderItem = (item) => {
        switch (item.type) {
            case 'text':
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                )
            case 'textarea':
                return (
                    <>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                );
            case 'switch':
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                {item.question}
                            </Typography>
                            <Switch
                                color='warning'
                            />
                        </Stack>
                    </>
                )
            case 'radio':
                return (
                    <>
                        <FormControl>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                            <RadioGroup row>
                                {item.options.map((option, i) => (
                                    <FormControlLabel key={i} value={option} control={<Radio color="warning" />} label={option} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </>
                )
            default:
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                );
        }
    }

    return (
        <>
            <Stack spacing={2} sx={{
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 0 2px 2px rgba(0, 0, 50, 0.1)',
                p: { xs: '0.8rem 0.5rem', sm: '0.8rem 0.75rem' },
                overflow: 'auto'
            }}>
                <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                    {formTitle ?
                        <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{formTitle}</Typography> :
                        <Typography sx={{ fontSize: '1.2rem' }}>Form Details</Typography>
                    }
                    <IconButton onClick={handleEditClick}>
                        <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                    </IconButton>
                </Stack>
                <Stack>
                    <Stack spacing={2} sx={{ pb: '0.5rem' }}>
                        {formDetails?.map((item, i) => (
                            <React.Fragment key={i}>
                                {renderItem(item)}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
};

export default RFormView;
