import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState } from 'react';
import { Controller } from "react-hook-form";
import TimezoneSelect from 'react-timezone-select';
import { BACKEND_URL } from '../utils/constants/apis.urls';
import { disableDates } from '../utils/functions/disableDates';
import { getCurrentDate } from '../utils/functions/getCurrentDate';

const RenderFormFields = ({ item, eventData, cardDetails, register, watch, control, errors, editForm, copyTitle = false, file, setFile, onImageChoose }) => {
    const [files, setFiles] = useState(editForm ? (cardDetails?.media) : {});
    const handleImageChange = (event) => {
        setFiles(event.target.files);
    };
    const handleRemoveImage = (e, index) => {
        let fileListArray = Array.from(files)
        fileListArray.splice(index, 1);
        setFiles(fileListArray)
    }

    const renderComponent = () => {
        switch (item.type) {
            case 'text':
                return (
                    <TextField
                        fullWidth
                        color="warning"
                        label={item.label}
                        name={item.name}
                        defaultValue={editForm ? (
                            (copyTitle && item.name === 'title') ? `${_.get(cardDetails, item.name)} (Copy)` :
                                _.get(cardDetails, item.name)) : null
                        }
                        inputProps={{ type: item.name.includes('zip') ? 'number' : 'text' }}
                        error={_.get(errors, item.name) ? true : false}
                        helperText={
                            _.get(errors, item.name) ? 'This is required field' : null
                        }
                        required={((item.subtype === 'physical-location' && eventData?.is_online) || (item.subtype === 'online-location' && !eventData?.is_online)) ? false : item.required}
                        sx={{
                            display: (
                                (item.subtype === 'physical-location' && eventData?.is_online) ||
                                (item.subtype === 'online-location' && !eventData?.is_online)
                            ) ? 'none' : 'inline-flex',
                            zIndex: 0 //To display timezone dropdown properly
                        }}
                        {...register(item.name,
                            { required: ((item.subtype === 'physical-location' && eventData?.is_online) || (item.subtype === 'online-location' && !eventData?.is_online)) ? false : item.required })}
                    />
                )
            case 'textarea':
                return (
                    <TextField
                        fullWidth
                        multiline
                        defaultValue={editForm ? cardDetails[item.name] : null}
                        rows={item.rows}
                        color="warning"
                        label={item.label}
                        error={errors[item.name] ? true : false}
                        helperText={
                            errors[item.name] ? 'This is required field' : null
                        }
                        {...register(item.name, { required: item.required })}
                    />
                )
            case 'select':
                return (
                    <TextField
                        select
                        fullWidth
                        defaultValue={editForm ? cardDetails.type : 'family'}
                        color="warning"
                        label={item.label}
                        error={errors[item.name] ? true : false}
                        helperText={
                            errors[item.name] ? 'This is required field' : null
                        }
                        {...register(item.name, { required: item.required })}
                    >
                        {item.values.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            case 'switch':
                return (
                    <>
                        <Controller
                            name={item.name}
                            control={control}
                            defaultValue={editForm ? (cardDetails[item.name] ? true : false) : item.selected}
                            render={({ field: { onChange, value } }) => (
                                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                        {item.label}
                                    </Typography>
                                    <Switch
                                        color='warning'
                                        value={value}
                                        checked={value}
                                        onChange={onChange}
                                        disabled={file ? true : false}
                                    />
                                </Stack>
                            )}
                        />
                    </>
                )
            case 'datetime':
                const event_start_datetime = item.name === 'start_datetime' ?
                    (eventData?.start_date ? dayjs(eventData?.start_date + eventData?.start_time) : dayjs(getCurrentDate())) 
                    : null;
                const previous_dates_to_disable = item.name === 'start_datetime'
                    ? (eventData?.start_date ? new Date(eventData?.start_date) : getCurrentDate()) : new Date(watch('start_datetime')); // This is for disabling dates of end dates based on start dates
                let next_dates_to_disable = null;
                if (eventData?.end_date) {
                    next_dates_to_disable = new Date(eventData?.end_date);
                }
                const default_datetime = editForm ? dayjs(cardDetails[item.name], 'YYYY-MM-DD HH:mm') : event_start_datetime;
                return (
                    <Controller
                        name={item.name}
                        control={control}
                        defaultValue={default_datetime}
                        rules={{ required: item.required }}
                        render={({ field: { onChange, value } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    value={value}
                                    label={item.label}
                                    disablePast={true}
                                    onChange={onChange}
                                    disableHighlightToday={true}
                                    shouldDisableDate={(d) => disableDates(d, previous_dates_to_disable, next_dates_to_disable)}
                                    renderInput={(props) => (
                                        <TextField {...props}
                                            fullWidth
                                            color="warning"
                                            error={errors[item.name] ? true : false}
                                            helperText={errors[item.name] ? 'This is required field' : null}
                                            inputProps={{ ...props.inputProps }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><CalendarMonthIcon /></InputAdornment>
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                )
            case 'phone_number':
                const dial_code_name = item.name.split(" ")[0];
                const contact_name = item.name.split(" ")[1];
                return (
                    <Stack direction='row' spacing={1}>
                        <Select
                            color="warning"
                            defaultValue={editForm ? _.get(cardDetails, dial_code_name) : '91'}
                            error={_.get(errors, dial_code_name) ? true : false}
                            {...register(dial_code_name, { required: item.required })}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                        width: "7rem",
                                        marginTop: "0.5rem",
                                        border: "1px solid #E4E4E4",
                                        p: 1.5,
                                        pt: 0.75,
                                        boxShadow: "0",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        scrollbarWidth: "0px",
                                        "&& .Mui-selected": {
                                            backgroundColor: "lightblue",
                                            border: "1px solid #E4E4E4",
                                            "&:hover": {
                                                backgroundColor: "#FAFBFD"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {item.country_codes.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    <Box>+{code.dial_code}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            label={item.label}
                            defaultValue={editForm ? _.get(cardDetails, contact_name) : null}
                            inputProps={{ type: 'number' }}
                            error={_.get(errors, contact_name) ? true : false}
                            helperText={
                                _.get(errors, contact_name) ? 'This is required field' : null
                            }
                            {...register(contact_name, { required: item.required })}
                            sx={{ flex: 1 }}
                        />
                    </Stack>
                )
            case 'timezone':
                return (
                    <Box>
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.8rem', fontWeight: 400, pl: 1 }}>
                            Event timezone
                        </Typography>
                        <Controller
                            name={item.name}
                            control={control}
                            defaultValue={editForm ? cardDetails[item.name] : 'Asia/Kolkata'}
                            style={{ zIndex: 100 }}
                            rules={{ required: item.required }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <TimezoneSelect
                                        value={value}
                                        onChange={onChange}
                                        className='selectTimezone'
                                    />
                                </>
                            )}
                        />
                    </Box>
                )
            case 'image':
                return (
                    <>
                        <Box sx={{ display: 'flex', height: '6rem', position: 'relative' }}>
                            {(file) ? (<>
                                <Box sx={{ height: '100%', position: 'relative' }}>
                                    <Box
                                        component='img'
                                        src={(typeof (file) === 'object') ? URL.createObjectURL(file) : `${file}`}
                                        //Add this condition bcoz of show selected image on change or from db based on condition
                                        alt='Event Img.'
                                        className="event-upload-album"
                                        sx={{
                                            width: '6rem',
                                            height: '100%',
                                            borderRadius: '0.5rem'
                                        }}
                                    />
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '-12%',
                                        right: '-10%',
                                    }}>
                                        <IconButton
                                            variant="contained"
                                            component='label'
                                            onClick={() => setFile(null)}
                                            sx={{
                                                backgroundColor: "#F0F0F0",
                                                p: '1px',
                                                ":hover": {
                                                    boxShadow: 'none',
                                                    color: 'white',
                                                    backgroundColor: '#F0F0F0'
                                                }
                                            }}
                                        >
                                            <CancelIcon color='error' />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </>) : (
                                <>
                                    <Button
                                        variant="contained"
                                        component='label'
                                        color='warning'
                                        sx={{
                                            margin: 'auto',
                                            height: "2rem",
                                            borderRadius: '10px',
                                            color: 'white',
                                            ":hover": {
                                                boxShadow: 'none',
                                                color: 'white',
                                            }
                                        }}
                                    >{item.placeholder}
                                        <TextField
                                            inputProps={{
                                                type: 'file',
                                                name: item.name,
                                                accept: "image/png, image/jpg, image/jpeg",
                                                onChange: (e) => onImageChoose(e.target.files[0])
                                            }}
                                            sx={{ display: 'none' }}
                                            {...register(item.name)}
                                        />
                                    </Button>
                                </>
                            )}
                        </Box >
                    </>
                )
            case 'images':
                return (
                    files ? (
                        <Box sx={{ display: 'flex', height: '6rem', position: 'relative' }}>
                            {
                                Object.keys(files)?.length === 0 ?
                                    <Button
                                        variant="contained"
                                        component='label'
                                        color='warning'
                                        sx={{
                                            margin: 'auto',
                                            height: "2rem",
                                            borderRadius: '10px',
                                            // backgroundColor: "#FFFFFF",
                                            color: 'white',
                                            ":hover": {
                                                boxShadow: 'none',
                                                color: 'white',
                                                // backgroundColor: '#1F274A'
                                            }
                                        }}
                                    >{item.placeholder}
                                        <TextField
                                            inputProps={{
                                                type: 'file',
                                                name: item.name,
                                                // multiple: item.multiple,
                                                accept: "image/*",
                                                onChange: handleImageChange
                                            }}
                                            sx={{ display: 'none' }}
                                            {...register("file")}
                                        />
                                    </Button>
                                    : <>
                                        <Grid container gap={3} alignItems='center' sx={{ width: '100%', height: '100%' }}>
                                            {Array.from(files).map((file, index) => (
                                                <>
                                                    <Grid item xs={2} key={index} sx={{ height: '100%', position: 'relative' }}>
                                                        <Box
                                                            component='img'
                                                            src={(editForm && file?.path) ? `${BACKEND_URL}${file.path}` : URL.createObjectURL(file)}
                                                            //Add this condition bcoz of show selected image on change or from db based on condition
                                                            alt='Event Img.'
                                                            className="event-upload-album"
                                                            sx={{
                                                                width: '6rem',
                                                                height: '100%'
                                                            }}
                                                        />
                                                        <Box sx={{
                                                            position: 'absolute',
                                                            top: '-10%',
                                                            right: '-20%',
                                                        }}>
                                                            <IconButton
                                                                variant="contained"
                                                                component='label'
                                                                onClick={(e) => handleRemoveImage(e, index)}
                                                                sx={{
                                                                    backgroundColor: "#F0F0F0",
                                                                    p: '1px',
                                                                    ":hover": {
                                                                        boxShadow: 'none',
                                                                        color: 'white',
                                                                        backgroundColor: '#F0F0F0'
                                                                    }
                                                                }}
                                                            >
                                                                <CancelIcon color='error' />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                    </>
                            }
                        </Box>
                    ) : null
                )
            case 'socials':
                var defaultValue = editForm ? [cardDetails?.socials ? Object.keys(cardDetails.socials)[0] : item?.values[0]?.value] : [item?.values[0]?.value]
                return (<>
                    <Stack direction='row' spacing={2}>
                        <Select defaultValue={defaultValue[0]}
                            onChange={(e) => {
                                // console.log(e.target.value);
                                defaultValue = [e.target.value, ...[]]
                            }}
                            {...register('socialMediaType')}
                        >
                            {item.values.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            name={item.name}
                            label={item.label}
                            defaultValue={editForm ?
                                cardDetails[item.name] && Object.values(cardDetails[item.name])[0] : null
                            }
                            inputProps={{ type: item.name.includes('zip') ? 'number' : 'text' }}
                            error={_.get(errors, item.name) ? true : false}
                            helperText={
                                _.get(errors, item.name) ? 'This is required field' : null
                            }
                            sx={{
                                display: ((item.subtype === 'physical-location' && eventData?.is_online) || (item.subtype === 'online-location' && !eventData?.is_online)) ? 'none' : 'inline-flex',
                                zIndex: 0, //To display timezone dropdown properly,
                                flex: 1
                            }}
                            {...register(item.name,
                                { required: ((item.subtype === 'physical-location' && eventData?.is_online) || (item.subtype === 'online-location' && !eventData?.is_online)) ? false : item.required })}
                        />
                    </Stack>
                </>
                )
            default:
                return <></>;
        }

    }

    return (
        <>
            {renderComponent()}
        </>
    )
}

export default RenderFormFields;