import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { BACKEND_URL } from "../../../../utils/constants/apis.urls";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../redux/slices/groupDetailSlice";
import EventImg from '../../../../assets/Event.webp'
import { manageEditGroupPayload } from "../../../../utils/functions/managePayload";
import { updateGroupDetails } from "../../../../services/wGroups/group";
import { toast } from "react-toastify";
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";

const GroupLogoUpload = () => {
    const groupData = useSelector(groupDetails);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (groupData) {
            const groupImg = groupData?.group_logo_path ?? null
            setSelectedImage(groupImg)
        }
    }, [groupData]);

    const handleImageChange = (event) => {
        let data = {};
        let i = event.target.files[0];
        data['group_logo'] = i;
        const payload = manageEditGroupPayload(data);
        updateGroupDetails(payload, groupData?.id).then((res) => {
            console.log("Media updated", res.data);
            setSelectedImage(res?.data.data?.group_logo_path);
            toast.success("Group logo updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log("Error ocurred =>", err);
        })
    }

    return (
        <>
            {Object.keys(groupData).length > 0 ?
                <Box sx={{
                    borderRadius: { xs: 0, sm: '15px' },
                    backgroundColor: '#F0F0F0',
                    width: { xs: '100%', sm: '30rem' },
                    height: { xs: '215px', sm: '21rem' }
                }}>

                    <Box sx={{ display: 'flex', height: '100%', position: 'relative' }}>
                        {selectedImage ?
                            <>
                                <Box
                                    component='img'
                                    src={`${selectedImage}`}
                                    alt='Event Img.'
                                    className="event-upload-image"
                                />
                                {ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) ?
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '90%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}>
                                        <Button
                                            variant="contained"
                                            component='label'
                                            sx={{
                                                // px: '2rem',
                                                margin: 'auto',
                                                height: "2rem",
                                                borderRadius: '50px',
                                                backgroundColor: "#FFFFFF",
                                                color: 'black',
                                                boxShadow: '0 0 10px 10px rgba(0, 0, 100, 0.5)',
                                                ":hover": {
                                                    color: 'white',
                                                    backgroundColor: '#FF8359',
                                                    boxShadow: '0 0 10px 10px rgba(0, 0, 100, 0.5)',
                                                }
                                            }}
                                        >
                                            Edit Image
                                            <input
                                                hidden
                                                accept="image/jpeg, image/png"
                                                type="file"
                                                onChange={(event) => handleImageChange(event)}
                                            />
                                        </Button>
                                    </Box>
                                    : null
                                }
                            </> :
                            <>
                                <Stack alignItems='center' sx={{ margin: 'auto' }}>
                                    <Box component='img' src={EventImg} width='7rem' height='5rem' />
                                    {ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) ?
                                        <Button
                                            variant="contained"
                                            component='label'
                                            sx={{
                                                mt: '1rem',
                                                px: '2rem',
                                                height: "2rem",
                                                color: 'black',
                                                backgroundColor: "#FFFFFF",
                                                border: '0.5px solid black',
                                                boxShadow: 'none',
                                                borderRadius: '50px',
                                                ":hover": {
                                                    border: '0.5px solid #FF8359',
                                                    boxShadow: 'none',
                                                    color: 'white',
                                                    backgroundColor: '#FF8359'
                                                }
                                            }}
                                        >
                                            Upload Image
                                            <input
                                                hidden
                                                accept="image/jpeg, image/png"
                                                type="file"
                                                onChange={(event) => handleImageChange(event)}
                                            />
                                        </Button>
                                        : null
                                    }
                                </Stack>
                            </>
                        }
                    </Box >
                </Box > :
                <Skeleton
                    sx={{
                        width: '100%',
                        height: { xs: '215px', sm: '18rem' },
                        transform: 'scale(1, 1)',
                        borderRadius: { xs: 0, sm: '15px' },
                    }} />
            }
        </>
    )
};

export default GroupLogoUpload;
