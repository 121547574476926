import React from "react";
import { Box, Typography } from "@mui/material";
import TimezoneSelect from 'react-timezone-select'
import { CreateEventContext } from "../CreateEventContext";

const EventTimezone = () => {
    const { formData, setFormData } = React.useContext(CreateEventContext);
    const handleTimezoneChange = (newTimezone) => {
        setFormData({ ...formData, timezone: newTimezone.value })
    }

    return (
        <>
            <Box>
                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                    Event Timezone
                </Typography>
                <TimezoneSelect
                    placeholder='Event timezone'
                    value={formData.timezone}
                    onChange={handleTimezoneChange}
                    className='selectTimezone'
                />
            </Box>
        </>
    )
};

export default EventTimezone;
