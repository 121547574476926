import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { DialogActions, FormControl, FormHelperText, Grid, RadioGroup, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { MemberRegistrationContext } from "../..";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupSubscriptions, submitSelectedSubscription } from "../../../../../../../../services/wGroups/groupSubscription";
import { ticketCurrencies } from '../../../../../../../../utils/constants/ticketCurrency';
import { manageSubscriptionSelectPayload } from '../../../../../../../../utils/functions/managePayload';
import SubscriptionDetailsDialog from "./SubscriptionDetailsDialog";
import SubscriptionSelectionCard from "./SubscriptionSelectionCard";

const SelectSubscription = () => {
    const [viewSubscription, setViewSubscription] = useState(null);
    const [viewModal, setModal] = useState(false);
    const [loader, setLoader] = useState(false);

    const groupData = useSelector(groupDetails);
    const { handleSlideChange, rForm,
        subscriptions, setSubscriptions,
        registrationData, setRegistrationData,
        selectedSubscription, setSelectedSubscription,
        paymentDetails, setPaymentDetails,
        memberData, subscriptionRenew
    } = useContext(MemberRegistrationContext);

    const { handleSubmit, control, watch, getValues,
        formState: { errors }
    } = useForm({
        defaultValues: {
            selected_subscription: registrationData?.selected_subscription
        }
    });

    const form_selected_subscription = watch('selected_subscription');

    useEffect(() => {
        const subscription = subscriptions?.find((s) => s.id === Number(getValues('selected_subscription')));
        setSelectedSubscription(subscription);
    }, [form_selected_subscription]);

    useEffect(() => {
        getGroupSubscriptions(groupData?.id).then((res) => {
            setSubscriptions(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        if (memberData && subscriptionRenew) { // For members whose subscription is expired
            const member_data = {
                member_uuid: memberData?.uuid,
                member_name: memberData?.name,
                member_dial_code: memberData?.dialing_code,
                member_number: memberData?.mobile,
                member_email: memberData?.email
            }
            setPaymentDetails({ ...member_data });
        }
    }, [memberData]);

    const handleSubscriptionViewClick = (t) => {
        setModal(true);
        setViewSubscription(t);
    }

    const mySubmit = (data) => {
        setLoader(true);
        setRegistrationData((prevData) => ({ ...prevData, ...data }));
        let payload;
        payload = manageSubscriptionSelectPayload(data, paymentDetails?.member_uuid);

        submitSelectedSubscription(payload, groupData?.id).then((res) => {
            const response = res.data?.data;
            setPaymentDetails((paymentDetails) => ({
                ...paymentDetails,
                member_subscription_id: response?.id,
                total_amount_to_pay: response?.amount_to_pay,
                amount_currency: response?.currency,
                subscription_validity_type: selectedSubscription?.validity_type,
                subscription_validity_time: selectedSubscription?.validity_time,
                subscription_currency_symbol: ticketCurrencies.find((t) => t.value === response?.currency)?.symbol
            }));
            setLoader(false);
            handleSlideChange(2);
            toast.success("Subscription selected", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            })
        })
    }

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <FormControl
                    error={(errors['selected_subscription']) ? true : false}
                    sx={{ width: '100%' }}>
                    {rForm ?
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Choose group subscription</Typography>
                        : <Skeleton sx={{ transform: 'scale(1,1)', width: '25%', my: 2 }} />
                    }
                    <Controller
                        rules={{ required: true }}
                        name='selected_subscription'
                        control={control}
                        render={({ field }) => (
                            <RadioGroup {...field} sx={{ py: 2 }}>
                                <Grid container gap='1rem' alignItems="center" >
                                    {(subscriptions ?? Array.from(new Array(4)))?.map((s, i) => (
                                        <SubscriptionSelectionCard key={i} s={s}
                                            handleSubscriptionViewClick={handleSubscriptionViewClick} />
                                    ))}
                                    {viewModal ? <SubscriptionDetailsDialog
                                        subscription={viewSubscription} setModal={setModal} /> : null}
                                </Grid>
                            </RadioGroup>
                        )}
                    />
                    {errors['selected_subscription'] ? <FormHelperText sx={{ mt: 1 }}>Please choose one option</FormHelperText> : null}
                </FormControl>
                <DialogActions sx={{ pr: 0 }}>
                    {/* <Button
                        color='warning'
                        variant="outlined"
                        onClick={() => handleSlideChange(0)}>Go to previous step</Button> */}
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {loader ? 'Saving...' : 'Next'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
};

export default SelectSubscription;
