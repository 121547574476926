import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Button
} from "@mui/material";
import moment from "moment";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import QrGenerator from "../../../../../EventDetailCards/components/EventQrcode/QrGenerator";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";

const GuestDetailsTable = ({
    guestDetails,
    additionalDetails,
    eventData,
    isPrint,
    isDownload
}) => {
    const [selectedRows, setSelectedRows] = useState(["guestUuid"]); // Include 'guestUuid' by default
    useEffect(() => {
        if (guestDetails?.uuid) {
            setSelectedRows((prevSelectedRows) => [
                ...new Set([...prevSelectedRows, "guestUuid"])
            ]);
        }
    }, [guestDetails?.uuid]);

    const handleCheckboxChange = (event, key) => {
        if (event.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, key]);
        } else {
            setSelectedRows(selectedRows.filter((item) => item !== key));
        }
    };

   const PrintableContent = React.forwardRef((props, ref) => {
    const {
        selectedRows,
        guestDetails,
        additionalDetails,
        amountCurrencySymbol,
        parseDecimalNumber
    } = props;
    

    const generateQrCode = (value) => {
        return <QRCode value={value} size={113.385} />;
    };

    return (
        <div
            ref={ref}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "6cm",
                height: "100vh",
                maxWidth: "6cm",
                margin: "auto",
            }}
        >
            {selectedRows.includes("event_user_photo_path") && guestDetails?.event_user_photo_path && (
                <div style={{ textAlign: "center", marginBottom: "0.1cm" }}>
                    <img
                        src={guestDetails.event_user_photo_path}
                        alt="Profile Photo"
                        style={{
                            borderRadius: "50%",
                            width: "2.5cm",
                            height: "2.5cm"
                        }}
                    />
                </div>
            )}
            {selectedRows.map((key, index) => {
                if (key === "event_user_photo_path" || key === "guestUuid")
                    return null; // Skip profile photo and QR code in the list

                let value;

                if (key.includes("additional_form_replies") && additionalDetails?.additional_form_replies) {
                    const index = parseInt(key.split("-")[1], 10);
                    const item = additionalDetails.additional_form_replies[index];
                    value = item.type === "file" ? (
                        <img
                            src={item.answer}
                            alt={item.question}
                            style={{ width: "100%", maxWidth: "100px" }}
                        />
                    ) : item.type === "checkbox" ? (
                        JSON.parse(item.answer).join(", ")
                    ) : (
                        item.answer
                    );
                } else {
                    switch (key) {
                        case "name":
                            value = `${guestDetails?.name} ${guestDetails?.invited_by_user_name ? `(Substitute of ${guestDetails?.invited_by_user_name})` : ""}`;
                            break;
                        case "email":
                            value = guestDetails?.email;
                            break;
                        case "contact":
                            value = `+${guestDetails?.dialing_code} ${guestDetails?.mobile}`;
                            break;
                        case "registration_time":
                            value = moment(additionalDetails?.registration_time).format("Do MMMM YYYY, h:mm A");
                            break;
                        case "amount_paid":
                            value = (
                                <span>
                                    {parseDecimalNumber(additionalDetails?.ticket_data.amount_paid, 2)}{" "}
                                    <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </span>
                            );
                            break;
                        case "payment_time":
                            value = moment(new Date(additionalDetails?.ticket_data.payment_time * 1000)).format("D MMM YYYY, h:mm A");
                            break;
                        case "tickets_bought_time":
                            value = moment(additionalDetails?.ticket_data.tickets_bought_time).format("Do MMMM YYYY, h:mm A");
                            break;
                        case "registered_by":
                            value = additionalDetails?.registered_by;
                            break;
                        case "ticket_title":
                            value = additionalDetails?.ticket_data.ticket_title;
                            break;
                        case "tickets_bought":
                            value = additionalDetails?.ticket_data.tickets_bought;
                            break;
                        case "ticket_id":
                            value = additionalDetails?.ticket_data.ticket_id;
                            break;
                        case "payment_method":
                            value = additionalDetails?.ticket_data.payment_method;
                            break;
                        case "payment_id":
                            value = additionalDetails?.ticket_data.payment_id;
                            break;
                        default:
                            value = guestDetails[key] || additionalDetails[key];
                    }
                }

                return (
                    <div
                        key={index}
                        style={{
                            marginBottom: "0.1cm",
                            textAlign: "center"
                        }}
                    >
                        {key.includes("additional_form_replies") &&
                        additionalDetails?.additional_form_replies &&
                        additionalDetails?.additional_form_replies[parseInt(key.split("-")[1], 10)]?.type === "file" ? (
                            value
                        ) : (
                            <strong
                                style={{
                                    display: "block",
                                    width: "5cm",
                                    height: "1cm",
                                }}
                            >
                                {value}
                            </strong>
                        )}
                    </div>
                );
            })}
            {selectedRows.includes("guestUuid") && guestDetails?.uuid && (
                <div style={{ textAlign: "center", marginBottom: "0.1cm" }}>
                    {generateQrCode(guestDetails?.uuid)}
                </div>
            )}
        </div>
    );
});
    

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

//     const handleDownload = () => {
//     const doc = new jsPDF();
//     const contentRef = printRef.current;
//     const content = contentRef.querySelectorAll('div');

//     let yOffset = 10; // Start yOffset for text content

//     content.forEach((item) => {
//         const img = item.querySelector('img');
//         const text = item.querySelector('strong');

//         if (text) {
//             const textContent = text.innerText;
//             doc.text(textContent, 10, yOffset);
//             yOffset += 10; // Increment yOffset after text
//         }

//         if (img) {
//             const imgData = img.src;
//             doc.addImage(imgData, 'JPEG', 10, yOffset, 50, 50);
//             yOffset += 60; // Increment yOffset after image
//         }

//         if (item.querySelector('canvas')) {
//             const qrCanvas = item.querySelector('canvas');
//             const qrCodeDataUrl = qrCanvas.toDataURL();
//             // Place QR code in line with other elements
//             doc.addImage(qrCodeDataUrl, 'JPEG', 130, yOffset - 60, 50, 50);
//         }
//     });

//     doc.save("Guest_Details.pdf");
// };

    
    
    

    const paidRegistrationColumns = {
        "Ticket Name": "ticket_title",
        "Ticket ID": "ticket_id",
        [guestDetails?.generated_by_owner ? "Tickets" : "Tickets Purchased"]:
            "tickets_bought",
        "Amount Paid": "amount_paid",
        "Payment ID": "payment_id",
        "Payment Method": "payment_method"
    };

    let freeRegistrationColumns = {
        "Ticket Name": "ticket_title",
        "Ticket ID": "ticket_id",
        [guestDetails?.generated_by_owner ? "Tickets" : "Tickets Bought"]:
            "tickets_bought"
    };

    if (!eventData?.is_paid && additionalDetails?.ticket_data?.payment_id) {
        freeRegistrationColumns = {
            ...freeRegistrationColumns,
            "Amount Paid": "amount_paid",
            "Payment ID": "payment_id",
            "Payment Method": "payment_method"
        };
    }

    let amountCurrencySymbol = null;
    if (additionalDetails?.ticket_data?.amount_currency) {
        amountCurrencySymbol = ticketCurrencies.find(
            (t) => t.value === additionalDetails?.ticket_data?.amount_currency
        )?.symbol;
    }

    return (
        <Box sx={{ position: "relative" }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Detail</TableCell>
                            <TableCell>Value</TableCell>{" "}
                            {/* This line should end with </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={selectedRows.includes("name")}
                                    onChange={(e) =>
                                        handleCheckboxChange(e, "name")
                                    }
                                />
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell sx={{ padding: "0.6rem" }}>
                                {guestDetails?.name}{" "}
                                {guestDetails?.invited_by_user_name
                                    ? `(Substitute of ${guestDetails?.invited_by_user_name})`
                                    : null}
                            </TableCell>
                        </TableRow>
                        {guestDetails?.email && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes("email")}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "email")
                                        }
                                    />
                                </TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {guestDetails?.email}
                                </TableCell>
                            </TableRow>
                        )}
                        {guestDetails?.dialing_code && guestDetails?.mobile && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "contact"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "contact")
                                        }
                                    />
                                </TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    +{guestDetails.dialing_code}{" "}
                                    {guestDetails.mobile}
                                </TableCell>
                            </TableRow>
                        )}
                        {guestDetails?.event_user_photo_path && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "event_user_photo_path"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "event_user_photo_path"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Profile Photo</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    <Box
                                        component="img"
                                        src={guestDetails?.event_user_photo_path}
                                        alt="uploaded file"
                                        sx={{ width: "100%", maxWidth: 200 }}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.registration_time && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "registration_time"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "registration_time"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Registration Time</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {moment(
                                        additionalDetails?.registration_time
                                    ).format("Do MMMM YYYY, h:mm A")}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.ticket_data &&
                            Object.entries(
                                eventData?.is_paid
                                    ? paidRegistrationColumns
                                    : freeRegistrationColumns
                            ).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedRows.includes(
                                                value
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(e, value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{key}</TableCell>
                                    <TableCell sx={{ padding: "0.6rem" }}>
                                        {key === "Amount Paid" ? (
                                            <>
                                                {parseDecimalNumber(
                                                    additionalDetails?.ticket_data[value],
                                                    2
                                                )}{" "}
                                                <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                            </>
                                        ) : (
                                            additionalDetails?.ticket_data[value]
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        {additionalDetails?.ticket_data?.payment_time && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "payment_time"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "payment_time"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Payment Time</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {moment(
                                        new Date(
                                            additionalDetails?.ticket_data
                                                ?.payment_time * 1000
                                        )
                                    ).format("D MMM YYYY, h:mm A")}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.ticket_data
                            ?.tickets_bought_time && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "tickets_bought_time"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "tickets_bought_time"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Tickets Bought Time</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {moment(
                                        additionalDetails?.ticket_data
                                            ?.tickets_bought_time
                                    ).format("Do MMMM YYYY, h:mm A")}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.registered_by && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "registered_by"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "registered_by"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Registered By</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {additionalDetails?.registered_by}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.additional_form_replies?.map((reply, index) => (
                            reply.answer && (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedRows.includes(`additional_form_replies-${index}`)}
                                            onChange={(e) => handleCheckboxChange(e, `additional_form_replies-${index}`)}
                                        />
                                    </TableCell>
                                    <TableCell>{reply.question}</TableCell>
                                    <TableCell sx={{ padding: "0.6rem" }}>
                                        {reply.type === "checkbox" ? (
                                            JSON.parse(reply.answer).join(", ")
                                        ) : reply.type === "file" && reply.answer ? (
                                            <Box
                                                component="img"
                                                src={reply.answer}
                                                alt="uploaded file"
                                                sx={{
                                                    width: "100%",
                                                    maxWidth: 200
                                                }}
                                            />
                                        ) : (
                                            reply.answer
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        ))}

                        {guestDetails?.uuid && (
                            <TableRow>
                                <TableCell>
                                <Checkbox
                                    checked={selectedRows.includes("guestUuid")}
                                    onChange={(e) =>
                                        handleCheckboxChange(e, "guestUuid")
                                    }
                                />
                                </TableCell>
                                <TableCell>QR Code</TableCell>
                                <TableCell>
                                    <QrGenerator
                                        guestUuid={guestDetails?.uuid}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: "none" }}>
                <PrintableContent
                    ref={printRef}
                    selectedRows={selectedRows}
                    guestDetails={guestDetails}
                    additionalDetails={additionalDetails}
                    eventData={eventData}
                    amountCurrencySymbol={amountCurrencySymbol}
                    parseDecimalNumber={parseDecimalNumber}
                />
            </div>
            {isPrint && (
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    sx={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        backgroundColor: "#FF8359",
                        ":hover": {
                            backgroundColor: "#FF8359"
                        }
                    }}
                >
                    Print Selected Data
                </Button>
            )}
            {/* {isDownload && (
                <Button
                    variant="contained"
                    onClick={handleDownload}
                    sx={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        backgroundColor: "#FF8359",
                        ":hover": {
                            backgroundColor: "#FF8359"
                        }
                    }}
                >
                    Download Selected Data
                </Button>
            )} */}
        </Box>
    );
};

export default GuestDetailsTable;
