import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, ImageList, ImageListItem, useMediaQuery } from "@mui/material";
import React, { useCallback, useState } from "react";
import ImageViewer from 'react-simple-image-viewer';
import { BACKEND_URL } from "../../../../../../../../../utils/constants/apis.urls";

const GalleryImageList = ({ images, showDeleteOption, handleImageDelete }) => {
    const isSmallDevice = useMediaQuery("(max-width:900px)");

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <ImageList
            cols={isSmallDevice ? 2 : 4}
            gap={isSmallDevice ? 3 : 6}
        >
            {images?.map((image, index) => (
                <ImageListItem key={index} cols={image.cols || 1} rows={image.rows || 1}
                    sx={{ position: 'relative', p: showDeleteOption ? 1 : 0 }}
                >
                    <Box sx={{
                        height: { xs: '6rem', sm: '10rem', lg: '12.5rem' }, overflow: 'hidden',
                        ":hover": { cursor: 'pointer' }
                    }}>
                        <img
                            // src={`${image.path}?fit=crop&auto=format`}
                            // srcSet={`${image.path}?fit=crop&auto=format`}
                            src={`${image.path}`}
                            srcSet={`${image.path}`}
                            alt={image.file_name}
                            width='100%'
                            height='100%'
                            loading="lazy"
                            className="gallery-image"
                            onClick={() => openImageViewer(index)}
                        />
                    </Box>
                    {isViewerOpen && (
                        <ImageViewer
                            src={images.map(i => `${i.path}`)}
                            currentIndex={currentImage}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                        />
                    )}
                    {showDeleteOption ? (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                        }}>
                            <IconButton
                                id={image.id}
                                variant="contained"
                                component='label'
                                onClick={(e) => {
                                    handleImageDelete(image.id);
                                }
                                }
                                sx={{
                                    backgroundColor: "#F0F0F0",
                                    p: '0px',
                                    ":hover": {
                                        boxShadow: 'none',
                                        color: 'white',
                                        backgroundColor: '#F0F0F0'
                                    }
                                }}
                            >
                                <CancelIcon color='error' />
                            </IconButton>
                        </Box>
                    ) : null
                    }
                </ImageListItem>
            ))}
        </ImageList>
    )
};

export default GalleryImageList;
