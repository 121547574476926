import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import SetMemberInvitationDialog from "../../../GroupMemberInvitation/SetMemberInvitationDialog";
import { GroupMembersContext } from "../../../../GroupMembersContext";

const SetupMemberReminder = ({ title }) => {
    const { setInviteMsg, setScheduledDate, setMemberInviteActiveStep } = useContext(GroupMembersContext);
    let [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        document.body.classList.add("no-scroll");
        setDialogOpen(true);
    }
    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        document.body.classList.remove("no-scroll");
        setInviteMsg(null);
        setScheduledDate(null);
        setMemberInviteActiveStep(0);
        setDialogOpen(false);
    }

    return (
        <>
            <Box sx={{
                ":hover": { backgroundColor: '#FFEBE4', cursor: 'pointer' },
                px: '1.5rem', width: '100%', textAlign: 'center'
            }}>
                <Typography sx={{ lineHeight: '2rem' }} onClick={handleDialogOpen}>{title}</Typography>
            </Box>
            {
                dialogOpen ? <SetMemberInvitationDialog isReminder handleClose={handleDialogClose} /> : null
            }
        </>
    )
};

export default SetupMemberReminder;
