import React from "react";
import { Box } from '@mui/material';
import '../../event-details.scss'
import EventDetailTabs from "./EventDetailTabs";

const EventMenubar = ({ tabValue, handleTabChange }) => {
    return (
        <>
            <Box sx={{ boxSizing: 'border-box' }}>
                <EventDetailTabs tabValue={tabValue} handleTabChange={handleTabChange} />
            </Box>
        </>
    );
};

export default EventMenubar;
