import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomTooltip from "../../../../../../../../../../components/CustomTooltip";
import { GroupMembersContext } from "../../../../GroupMembersContext";
import DynamicMessage from "./DynamicMessage";
import EmailEditor from "./EmailEditor";

const MemberInvitationMessage = ({ isReminder }) => {
    const [editorContent, setContent] = useState(null); // For email message
    const { inviteMsg, setInviteMsg, setMemberInviteActiveStep } = useContext(GroupMembersContext);
    const { handleSubmit, setValue, register } = useForm();

    let [txtBox, setTxtBox] = useState({
        // notification: inviteMsg?.notification !== undefined ? inviteMsg?.notification : true,
        sms: inviteMsg?.sms !== undefined ? inviteMsg?.sms : false,
        whatsapp: inviteMsg?.whatsapp !== undefined ? inviteMsg?.whatsapp : true,
        email: inviteMsg?.email !== undefined ? inviteMsg?.email : true
    })

    const mySubmit = (data) => {
        let index1 = 1;
        let index2 = 1;
        if (editorContent) {
            index1 = editorContent.indexOf("{{Member Name}}");
            index2 = editorContent.indexOf("{{Group Weblink}}");
        }
        if (index1 === -1 || (!isReminder && index2 === -1)) {
            toast.error("Please follow instructions for email message!!", {
                position: "top-right",
                theme: "dark"
            });
        } else if (!txtBox?.sms && !txtBox?.whatsapp && !txtBox?.email) {
            toast.error("Please select at lease one option", {
                position: "top-right",
                theme: "dark"
            });
        } else {
            data = { ...data, ...txtBox };
            if (editorContent) {
                data.emailMessage = `<html><body>${editorContent}</body></html>`;
            }
            Object.keys(data).forEach((k) => data[k] == null && delete data[k]); //Because null values will override actual values when submitting form
            setInviteMsg({ ...inviteMsg, ...data });
            setMemberInviteActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <Stack spacing={3} sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
                    {/* <Box>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Notification
                            </Typography>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography sx={{ fontSize: '14px' }}>Free</Typography>
                                <Switch
                                    color='warning'
                                    checked={txtBox.notification}
                                    onChange={(e) => setTxtBox({ ...txtBox, notification: e.target.checked })}
                                />
                            </Stack>
                        </Stack>
                        {txtBox.notification ?
                            <TextField
                                fullWidth
                                multiline
                                color="warning"
                                label="Message"
                                sx={{ mt: 2 }}
                                defaultValue={inviteMsg?.notificationMessage !== undefined ? inviteMsg.notificationMessage :
                                    'There are many variations of passages of Lorem Ipsum available, but the majority have different.'}
                                error={_.get(errors, 'notificationMessage') ? true : false}
                                helperText={
                                    _.get(errors, 'notificationMessage') ? 'This is required field' : null
                                }
                                {...register('notificationMessage', { required: true })}
                            />
                            : null
                        }
                    </Box> */}
                    <Box>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                            <Stack direction='row' spacing={0.5} justifyContent='center' alignItems='center'>
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    SMS
                                </Typography>
                                <CustomTooltip
                                    content={
                                        <Stack spacing={1} sx={{ p: 0.5 }}>
                                            <Typography sx={{ fontSize: '0.75rem' }}>
                                                Due to TRAI update SMS feature is off temporary.
                                            </Typography>
                                        </Stack>
                                    }
                                    placement='bottom'
                                />
                            </Stack>
                            <Stack direction='row' alignItems='center' spacing={{ xs: 0, sm: 1 }}>
                                <Typography sx={{ fontSize: '14px' }}>Free</Typography>
                                <Switch
                                    disabled
                                    color='warning'
                                    checked={txtBox.sms}
                                    onChange={(e) => setTxtBox({ ...txtBox, sms: e.target.checked })}
                                />
                            </Stack>
                        </Stack>
                        {txtBox.sms ?
                            <DynamicMessage
                                isReminder={isReminder} register={register} setValue={setValue} fieldName={'smsMessage'}
                            /> : null}
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                WhatsApp
                            </Typography>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography sx={{ fontSize: '14px' }}>Free</Typography>
                                <Switch
                                    color='warning'
                                    checked={txtBox.whatsapp}
                                    onChange={(e) => setTxtBox({ ...txtBox, whatsapp: e.target.checked })}
                                />
                            </Stack>
                        </Stack>
                        {txtBox.whatsapp ?
                            <DynamicMessage
                                isReminder={isReminder} register={register} setValue={setValue} fieldName={'whatsappMessage'}
                            /> : null}
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Email
                            </Typography>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography sx={{ fontSize: '14px' }}>Free</Typography>
                                <Switch
                                    color='warning'
                                    checked={txtBox.email}
                                    onChange={(e) => setTxtBox({ ...txtBox, email: e.target.checked })}
                                />
                            </Stack>
                        </Stack>
                        {txtBox.email ?
                            <>
                                <Typography sx={{ fontSize: '0.9rem', color: 'gray', my: 1 }}>
                                    {
                                        `${isReminder
                                            ?
                                            "Note: Kindly refrain from modifying the variable name {{Member Name}}. Feel free to adjust its placement, apply styles, or make other modifications, but ensure that this variable is not excluded."
                                            : "Note: Kindly refrain from modifying the variables' names {{Member Name}} and {{Group Weblink}}. Feel free to adjust their placement, apply styles, or make other modifications, but ensure that these variables are not excluded."
                                        }`
                                    }
                                </Typography>
                                <Box sx={{ my: 2 }}>
                                    <EmailEditor isReminder={isReminder} inviteMsg={inviteMsg}
                                        editorContent={editorContent} setContent={setContent} />
                                </Box>
                            </>
                            : null
                        }
                    </Box>
                </Stack>

                <Button id='saveMemberInviteBtn' type="submit" size='small' color='warning'
                    variant="contained" sx={{
                        boxShadow: 'none',
                        visibility: 'hidden'
                    }}>
                    Save
                </Button>
            </form>
        </>
    )
};

export default MemberInvitationMessage;
