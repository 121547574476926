import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { MemberRegistrationContext } from "../..";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import SubscriptionPaymentIntegration from "./SubscriptionPaymentIntegration";

const MemberSubscriptionPayment = () => {
    const theme = useTheme();
    const groupData = useSelector(groupDetails);
    const { paymentDone, paymentDetails, handleSlideChange } = useContext(MemberRegistrationContext);
    const total_amount = paymentDetails.total_amount_to_pay;

    const rows = [
        {
            id: 1,
            title: "Member Name",
            value: paymentDetails.member_name,
        },
        {
            id: 2,
            title: "Member Mobile Number",
            value: `+${paymentDetails.member_dial_code} ${paymentDetails.member_number}`,
        },
        {
            id: 3,
            title: "Member Email",
            value: paymentDetails.member_email,
        },
        {
            id: 4,
            title: "Group Name",
            value: groupData?.title,
        },
        {
            id: 5,
            title: "Subscription Validity",
            value: paymentDetails.subscription_validity_type === 'lifetime' ? 'Lifetime'
                : `${paymentDetails?.subscription_validity_time} ${_.capitalize(paymentDetails.subscription_validity_type)}`,
        }
    ]

    return (
        <>
            <TableContainer sx={{
                border: '1px solid lightgrey',
                maxWidth: { xs: '90%', md: '80%', lg: '70%' }, margin: '1rem auto !important'
            }}>
                <Table stickyHeader>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover={true} key={row.id}>
                                <TableCell sx={{
                                    px: '3rem',
                                    width: '4rem',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc'
                                }}>
                                    {row.title}
                                </TableCell>
                                <TableCell sx={{
                                    width: '3rem',
                                    textAlign: 'center'
                                }} align="center">{row.value}</TableCell>
                            </TableRow>
                        ))}

                        <TableRow hover={true}>
                            <TableCell sx={{
                                color: '#1F274A', fontSize: '1.2rem',
                                fontWeight: 'bold', borderRight: '1px solid #ccc', textAlign: 'center'
                            }}>
                                Amount to pay
                            </TableCell>
                            <TableCell align="center" sx={{ color: '#FF8359', fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>
                                {parseDecimalNumber(total_amount, 3)} <span dangerouslySetInnerHTML={{ __html: paymentDetails.subscription_currency_symbol }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography sx={{
                mt: 1, textAlign: 'center',
                color: '#6F738B', fontSize: '0.8rem'
            }}>
                <span style={{
                    color: theme.palette.primaryColor
                }}>Note:</span> Minimum 1 Rs. payment will be taken as per razorpay standards!!
            </Typography>
            <Stack spacing={2} direction='row' justifyContent='center'
                sx={{ textAlign: 'center', my: 2 }}>
                {!paymentDone ?
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => handleSlideChange(1)}>Go to previous step</Button>
                    : null
                }
                <Box sx={{ textAlign: 'center' }}>
                    <SubscriptionPaymentIntegration
                        amountCurrencyForRazorPay={ticketCurrencies.find((t) => t.value === paymentDetails.amount_currency).currency}
                        totalAmountToPay={(total_amount < 1) ? 1 : total_amount}
                    />
                </Box>
            </Stack>
        </>
    )
};

export default MemberSubscriptionPayment;
