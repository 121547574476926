import { Button, IconButton } from "@mui/material";
import React from "react";

import {
    VisibilityContext,
    slidingWindow,
    getItemsPos
} from "react-horizontal-scrolling-menu";

function Arrow({
    children,
    disabled,
    onClick
}) {
    return (
        <Button
            color="warning"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
            sx={{
                minWidth: '2rem',
                fontWeight: '500',
                borderRadius: '50%',
                cursor: "pointer",
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: 'absolute',
                zIndex: 10,
                userSelect: "none",
                left: children === '<' && "0",
                right: children === '>' && "0",
                opacity: disabled ? "0" : "1",
            }}
        >
            {children}
        </Button>
    );
}

export function LeftArrow() {
    const {
        items,
        visibleItems,
        getItemById,
        isFirstItemVisible,
        scrollToItem,
        visibleElements,
        initComplete
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleElements]);

    // NOTE: for center items
    const prevGroupItems = slidingWindow(
        items.toItemsKeys(),
        visibleItems
    ).prev();
    const { center } = getItemsPos(prevGroupItems);
    const scrollPrevCentered = () =>
        scrollToItem(getItemById(center), "smooth", "center");

    return (
        <Arrow disabled={disabled} onClick={scrollPrevCentered}>
            {"<"}
        </Arrow>
    );
}

export function RightArrow() {
    const {
        getItemById,
        isLastItemVisible,
        items,
        scrollToItem,
        visibleItems,
        visibleElements
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !visibleElements.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (visibleElements.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleElements]);

    // NOTE: for center items
    const nextGroupItems = slidingWindow(
        items.toItemsKeys(),
        visibleItems
    ).next();
    const { center } = getItemsPos(nextGroupItems);
    const scrollNextCentered = () =>
        scrollToItem(getItemById(center), "smooth", "center");

    return (
        <Arrow disabled={disabled} onClick={scrollNextCentered}>
            {">"}
        </Arrow>
    );
}
