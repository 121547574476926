import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import React from "react";
import { statusMappings } from '../utils/constants/status.mappings';

const InvitedUserMessageStatus = ({ msgTypes, invitedUserDetails }) => {
    return (
        <Box sx={{ p: 0 }}>
            <TableContainer sx={{
                border: '1px solid lightgrey',
                maxWidth: { xs: '100%', md: '75%' }, margin: '1rem auto !important'
            }}>

                <Table>
                    <TableHead>
                        <TableRow hover={true}>
                            <TableCell align="center" sx={{
                                width: '50%',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                borderRight: '1px solid #ccc',
                                p: { xs: '0.5rem', md: '0.7rem' }
                            }}>Message Type</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                p: { xs: '0.5rem', md: '0.7rem' }
                            }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {msgTypes?.map((msgType, index) => (
                            <TableRow hover={true} key={index}>
                                <TableCell align="center" sx={{
                                    width: '50%',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    p: { xs: '0.5rem', md: '0.7rem' }
                                }}>
                                    {_.capitalize(msgType)}
                                </TableCell>
                                <TableCell align="center" sx={{
                                    textAlign: 'center',
                                    p: { xs: '0.5rem', md: '0.7rem' }
                                }}>
                                    {_.get(statusMappings, _.get(invitedUserDetails, `${msgType}_status`))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
};

export default InvitedUserMessageStatus;
