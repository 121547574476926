import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { InputAdornment, TextField } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from "lodash";
import React from "react";
import { CreateEventContext } from "../pages/Dashboard/components/CreateEvent/components/CreateEventContext";
import { disableDates } from "../utils/functions/disableDates";

const FormDateTimePicker = ({ label, fieldName, showPast, changeDate, isStartDate, isRequired }) => {
    const { formData, fieldErrors } = React.useContext(CreateEventContext);
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                    label={label}
                    name={fieldName}
                    disablePast={showPast ? false : true}
                    disableFuture={showPast ? true : false}
                    disableHighlightToday={isStartDate ? false : true}
                    defaultValue={formData.start_date ?? null}
                    value={_.get(formData, fieldName)}
                    onChange={changeDate(fieldName)}
                    // For end date disable dates before start date (if it's set) otherwise disable past dates before today
                    shouldDisableDate={(fieldName === 'end_date' && formData.start_date) ?
                        (d) => disableDates(d, formData.start_date) : (d) => disableDates(d, new Date())}
                    renderInput={(props) => (
                        <TextField {...props}
                            required={isRequired ?? true}
                            color="warning"
                            error={_.get(fieldErrors, fieldName)}
                            helperText={_.get(fieldErrors, fieldName) ? 'This is required field' : null}
                            inputProps={{ ...props.inputProps }} />
                    )}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><CalendarMonthIcon /></InputAdornment>
                    }}
                />
            </LocalizationProvider>
        </>
    );
};

export default FormDateTimePicker;
