import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack, Button, DialogActions, Typography, IconButton, Divider, Skeleton, ImageList, useMediaQuery } from "@mui/material";
import GalleryImageList from "./GalleryImageList";
import DialogBox from "../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { manageAlbumMediaPayload } from "../../../../../../../../../utils/functions/managePayload";
import { createAlbumMedia, deleteAlbumMedia, getAlbumImages } from "../../../../../../../../../services/wEvents/eventDetails";
import { ALL_PERMISSION_ROLES, READ_PERMISSION_ROLES } from "../../../../../../../../../utils/constants/roles";
import { userStatus } from "../../../../../../../../../redux/slices/userDataSlice";

const AlbumGalleryContent = (images, setImages, albumDetails, handleAlbumMedia) => {
    const isLoggedIn = useSelector(userStatus);
    const eventData = useSelector(eventDetails);
    const [isEdit, setEdit] = useState(false);
    const isSmallDevice = useMediaQuery("(max-width:900px)");

    const handleAddPhotos = (event) => {
        let payload = manageAlbumMediaPayload(event.target.files)
        createAlbumMedia(payload, albumDetails?.id).then((res) => {
            handleAlbumMedia(albumDetails?.id, res?.data?.data, { isDeleted: false })
            setImages([...images, ...res?.data?.data]); //To display newly uploaded image(s)
            toast.success("Images added successfully", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleImageDelete = (imageId) => {
        deleteAlbumMedia(albumDetails?.id, imageId).then(() => {
            handleAlbumMedia(albumDetails?.id, null, { isDeleted: true })
            setImages(images.filter((i) => i.id !== imageId));
            toast.success("Image deleted successfully", {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong!!", {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    return (
        <>
            <Stack id='bigWidthDialog' sx={{ mt: 3 }}>
                {images ?
                    <>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mt: -1.5 }}>
                            <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>{images?.length} Photos</Typography>
                            {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                                (images?.length > 0 ?
                                    (isEdit ?
                                        <Button color='warning' variant='outlined' onClick={() => setEdit(!isEdit)}>Cancel</Button>
                                        :
                                        <IconButton onClick={() => setEdit(!isEdit)} sx={{ p: 0 }}><DeleteIcon color='warning' sx={{
                                            width: '2rem', height: '1.5rem'
                                        }} /></IconButton>
                                    ) : null)
                                : null
                            }
                        </Stack>

                        {isEdit ?
                            <>
                                <GalleryImageList
                                    images={images}
                                    showDeleteOption={true}
                                    handleImageDelete={handleImageDelete} />
                            </> :
                            <>
                                <GalleryImageList images={images} />
                            </>
                        }
                        <Divider />

                        {
                            (ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ||
                                (isLoggedIn &&
                                    (READ_PERMISSION_ROLES.includes(eventData?.current_user_role) && albumDetails?.allow_guest_upload))
                            )
                                ?
                                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                                    <Button type='submit' size='small'
                                        color='warning' variant='outlined' component='label'
                                        sx={{
                                            boxShadow: 'none'
                                        }}>
                                        Upload more photos
                                        <input
                                            hidden
                                            multiple
                                            type="file"
                                            onChange={handleAddPhotos}
                                            accept="image/png, image/jpg, image/jpeg"
                                        />
                                    </Button>
                                </DialogActions> : null
                        }
                    </>
                    :
                    <ImageList cols={isSmallDevice ? 3 : 4} gap={isSmallDevice ? 6 : 10} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {Array(isSmallDevice ? 3 : 4).fill('').map((a, i) => (
                            <Skeleton key={i} variant="rectangular" animation="wave" width={'50%'}
                                height={isSmallDevice ? '7rem' : '9rem'} sx={{ mx: 0, mb: 3, mt: 0 }} />
                        ))}
                    </ImageList>
                }
            </Stack>
        </>
    )
}

const AlbumGalleryDialog = ({ open, handleClose, albumDetails, handleAlbumMedia }) => {
    const [images, setImages] = useState(null)
    useEffect(() => {
        if (open) {
            getAlbumImages(albumDetails?.id).then((res) => {
                setImages(res.data.data)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [open]);

    return (
        <DialogBox open={open} handleClose={() => handleClose(images)}
            title={`${albumDetails?.title} Album`} content={AlbumGalleryContent(images, setImages, albumDetails, handleAlbumMedia)}
        />
    )
};

export default AlbumGalleryDialog;
