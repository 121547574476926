import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Box, Stack, Typography, Collapse, Chip } from "@mui/material";
import KycForm from "./KycForm";
import { getKycs } from "../../../../../services/userKyc";
import { userDetails } from "../../../../../redux/slices/userDataSlice";

const UserKYC = () => {
    const userData = useSelector(userDetails);
    const [kycData, setKycData] = useState(null);
    const [showKycSection, setShowKycSection] = useState(false);
    const handleToggleCollapse = () => setShowKycSection(!showKycSection);

    useEffect(() => {
        getKycs(userData?.id).then((res) => setKycData(res.data.data.length > 0 && res.data.data[0]))
            .catch((err) => console.log(err))
    }, []);

    return (
        <Box sx={{
            p: { xs: 1.5, sm: 2 }, borderRadius: '0.5rem', boxShadow: '0px 0px 15px rgba(0, 0, 100, 0.25)',
            width: '100%', my: { xs: 3, sm: 2 }
        }}>
            <Stack value={showKycSection} direction='row'
                spacing={1}>
                <Stack direction='row' alignItems='center' spacing={1} sx={{ flex: 1 }}>
                    <Typography sx={{
                        fontWeight: 'bold', color: 'darkblue'
                    }}>
                        User KYC
                    </Typography>
                    <Chip size="small" color={kycData?.is_verified ? "success" : "error"}
                        label={kycData?.is_verified ? "Verified" : "Not verified"}
                        icon={kycData?.is_verified ? <VerifiedIcon /> : <DangerousIcon />}
                        sx={{ width: 'fit-content' }} />
                </Stack>
                {showKycSection
                    ? <RemoveIcon onClick={handleToggleCollapse} sx={{ ":hover": { cursor: 'pointer' } }} />
                    : <AddIcon onClick={handleToggleCollapse} sx={{ ":hover": { cursor: 'pointer' } }} />
                }
            </Stack>
            <Collapse in={showKycSection} unmountOnExit={true}>
                <Stack spacing={2} sx={{ mt: 1.5 }}>
                    <Typography>Upload any government authorised document photo</Typography>
                    <KycForm kycData={kycData} setKycData={setKycData} setShowKycSection={setShowKycSection} />
                </Stack>
            </Collapse>
        </Box>
    )
};

export default UserKYC;
