import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { groupDetails } from "../../../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupMembers, multipleDeleteGroupMembers } from "../../../../../../../../../../services/wGroups/groupMembers";
import { manageBulkDeleteGroupMembersPayload } from "../../../../../../../../../../utils/functions/managePayload";
import { GroupMembersContext } from "../../../../GroupMembersContext";

const DeleteGroupMembersContent = (handleClose) => {
    const [loading, setLoading] = useState(false);
    const groupData = useSelector(groupDetails);
    const { tabValue, totalMembers, setVisible,
        checkedMembers, setChecked, setMembers } = useContext(GroupMembersContext);

    const handleBulkDeleteGroupMembers = () => {
        setLoading(true);
        const payload = manageBulkDeleteGroupMembersPayload(checkedMembers);
        multipleDeleteGroupMembers(payload, groupData?.id).then((res) => {
            getGroupMembers({ groupId: groupData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'joined' }).then((res2) => {
                totalMembers.current = res2.data.meta.total;
                setMembers(res2.data.data);
                setChecked([]); // Make checked guests list empty
                setVisible(false); // Make checkbox invisible
                setLoading(false);
                toast.success(res.data.data.message, {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error("Something went wrong", {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.success(Object.values(err.response.data)[0][0] ?? "Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        })
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography>Are you sure you want to delete {checkedMembers.length} members?</Typography>
                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        onClick={handleBulkDeleteGroupMembers}
                        loading={loading ? true : false}
                        startIcon={loading ? <SaveIcon /> : null}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Deleting...' : 'Yes'}
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </>
    )
}

const BulkDeleteGroupMembersDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Delete Members' content={DeleteGroupMembersContent(handleClose)}
            />
        </>
    )
};

export default BulkDeleteGroupMembersDialog;
