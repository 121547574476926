import SaveIcon from '@mui/icons-material/Save';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from "@mui/lab";
import { Chip, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { GuestRegistrationContext } from "../..";
import logo from '../../../../../../../../assets/WowslyLogo.png';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import { createOrder } from "../../../../../../../../services/Payment/paymentGateway";
import { loadScript } from "../../../../../../../../utils/functions/loadScript";
import { EventDetailsContext } from '../../../../../..';
import { razorPayModalConfig } from '../../../../../../../../utils/constants/razorPayModalConfig';

const TicketPaymentIntegration = ({ totalAmountToPay, amountCurrencyForRazorPay, sendToWhatsapp }) => {
    const { guestId, commonEventTitle } = useParams();
    const eventData = useSelector(eventDetails);
    const theme = useTheme();
    const { registrationData, paymentDone, setPaymentDone, paymentDetails, setPaymentDetails, setSlide, setFormSubmitted } = useContext(GuestRegistrationContext);
    const { setTabValue } = useContext(EventDetailsContext);
    const [loading, setLoading] = useState(false);
    const activeUserId = eventData?.current_user_role === 'manager' ? eventData?.guest_id : (eventData?.current_user_role === 'owner' ? eventData?.event_owner_id : null);
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedFacilities = (!eventData?.is_multiple_tickets && registrationData?.facility_status.length > 0) ?
        registrationData?.facility_status.filter(facility => facility.selected).map(facility => facility.name) : [];
    const selectedFacilityIds = (!eventData?.is_multiple_tickets && registrationData?.facility_status.length > 0) ?
        registrationData?.facility_status.filter(facility => facility.selected).map(facility => facility.id) : [];

    const handlePayment = async () => {
        setLoading(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            alert('Failed to load Razorpay payment gateway. Please try again later.');
            return;
        }

        const payload = {
            receipt: 'Wowsly_Ticket_Purchase',
        }

        // calculating total amount to pay from backend
        const ticketDetailsStr = paymentDetails.selected_tickets
            .map(ticket => `${ticket.ticket_id}:${ticket.ticket_count}`)
            .join(',');
        payload.notes = {
            ticket_details: ticketDetailsStr,
            send_to_whatsapp: sendToWhatsapp
        }
        if (!eventData?.is_multiple_tickets) {
            const facilityDetailsStr = selectedFacilityIds.join(',');
            payload.notes['facility_ids'] = facilityDetailsStr;
        }

        createOrder(payload).then((result) => {
            setLoading(false);
            console.log(result);
            const order_id = result.data?.id;
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                amount: paymentDetails.amount_currency === 'rupees' ? totalAmountToPay * 100 : totalAmountToPay, // Amount in paise
                currency: amountCurrencyForRazorPay,
                name: 'Wowsly',
                description: 'Payment for event ticket',
                notes: {
                    'task': 'event_ticket_payment',
                    'event_id': eventData?.id,
                    'user_id': paymentDetails.user_id,
                    'invited_guest_uuid': (commonEventTitle || (eventData?.current_user_role !== null && ['owner', 'manager'].includes(eventData?.current_user_role))) ? paymentDetails.guest_uuid : guestId,
                    'guest_ticket_id': paymentDetails.guest_ticket_id,
                    'guest_ticket_name': paymentDetails.guest_name,
                    'guest_ticket_email': paymentDetails.guest_email,
                    'current_timezone': currentTimezone,
                    'amount_currency_symbol': paymentDetails.ticket_currency_symbol,
                    'send_to_whatsapp': sendToWhatsapp,
                    'registered_by': activeUserId,
                    'facility_details': selectedFacilities.length > 0 ? JSON.stringify(selectedFacilities) : null,
                    'ticket_details': ticketDetailsStr,
                    'facility_ids': !eventData?.is_multiple_tickets ? payload.notes['facility_ids'] : null
                },
                image: logo,
                order_id: order_id,
                handler: function (response) {
                    // Callback function triggered on successful payment
                    console.log("Done", response);
                    if ('razorpay_payment_id' in response
                        && 'razorpay_order_id' in response
                        && 'razorpay_signature' in response
                    ) {
                        const ticketMessage = sendToWhatsapp
                            ? "Payment successful, You'll receive your event ticket on your email and whatsapp!!"
                            : "Payment successful, You'll receive your event ticket on your email!!";
                        setLoading(false);
                        setPaymentDone(true);
                        setFormSubmitted(true);
                        // for owner and manager navigate to event page instead of showing successful registration
                        if (activeUserId) {
                            toast.success(ticketMessage, {
                                position: "top-right",
                                theme: "dark"
                            });
                            setTabValue(0); // navigate to info tab
                            return;
                        }
                        setSlide(0);
                        setPaymentDetails({ ...paymentDetails, paymentId: response.razorpay_payment_id });
                        toast.success(ticketMessage, {
                            position: "top-right",
                            theme: "dark"
                        });
                    } else {
                        toast.error("Payment failed due to some issues, please try again later!!", {
                            position: "top-right",
                            theme: "dark"
                        });
                        return;
                    }
                },
                prefill: {
                    name: paymentDetails.guest_name,
                    email: paymentDetails.guest_email,
                    contact: `+${paymentDetails.guest_dial_code}${paymentDetails.guest_number}`,
                },
                theme: {
                    color: theme.palette.primaryColor,
                },
                config: razorPayModalConfig.paymentMethodsConfiguration,
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
            razorpay.on("payment.failed", function (response) {
                console.log(response);
                setLoading(false);
                toast.error(response.error.description, {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            });
        });
    };

    return (
        <>
            {paymentDone ?
                <Chip
                    label="Paid" color='success' icon={<VerifiedIcon />}
                />
                :
                <LoadingButton
                    type="submit"
                    loading={loading}
                    onClick={handlePayment}
                    startIcon={loading ? <SaveIcon /> : ''}
                    loadingPosition={loading ? "start" : null}
                    sx={{
                        color: 'white',
                        // borderRadius: '1.1rem',
                        backgroundColor: loading ? 'lightgray' : '#1F274A',
                        ":hover": {
                            backgroundColor: '#1F274A',
                        },
                    }}
                >
                    {loading ? 'Processing..' : 'Buy tickets'}
                </LoadingButton>
            }
        </>
    )
};

export default TicketPaymentIntegration;
