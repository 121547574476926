import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Stack, Typography, Collapse, Skeleton } from "@mui/material";
import BankDetailsForm from "./BankDetailsForm";
import BanksList from "./BanksList";
import { getBanks } from "../../services/bankDetails";
import { userDetails } from "../../redux/slices/userDataSlice";

const BankDetails = () => {
    const userData = useSelector(userDetails);
    const [banks, setBanks] = useState(null);
    const [showBankSection, setShowBankSection] = useState(false);
    const [bankForm, setBankForm] = useState({
        type: 'add',
        visible: true,
        details: null
    });

    const handleToggleCollapse = () => setShowBankSection(!showBankSection);

    useEffect(() => {
        getBanks(userData?.id, { verified: false }).then((res) => {
            const allBanks = res.data.all_banks;
            setBanks(allBanks);
            setBankForm({ ...bankForm, visible: allBanks?.length === 0 ? true : false })
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{
                    p: { xs: 1.5, sm: 2 }, borderRadius: '1rem', boxShadow: '0px 0px 15px rgba(0, 0, 100, 0.25)',
                    width: { xs: '95%', sm: '75%', lg: '55%' }, my: { xs: 3, sm: 2 }
                }}>
                    <Stack value={showBankSection} direction='row'
                        justifyContent='space-between' spacing={1}>
                        {
                            banks ? 
                                <>                                
                                    <Typography sx={{
                                        width: '100%', fontWeight: 'bold', color: 'darkblue'
                                    }}>
                                        {banks?.length > 0 ? 'Your banks' : 'Link your bank'}
                                    </Typography>
                                    {showBankSection
                                        ? <RemoveIcon onClick={handleToggleCollapse} sx={{ ":hover": { cursor: 'pointer' } }} />
                                        : <AddIcon onClick={handleToggleCollapse} sx={{ ":hover": { cursor: 'pointer' } }} />
                                    }
                                </> :
                                <Skeleton width='100%' height='4rem' animation="wave"/>
                        }
                    </Stack>
                    <Collapse in={showBankSection} unmountOnExit={true}>
                        <Stack alignItems='center' sx={{ mt: 2.5 }}>
                            {bankForm.visible ?
                                <BankDetailsForm banks={banks} setBanks={setBanks}
                                    bankForm={bankForm} setBankForm={setBankForm} /> :
                                <BanksList banks={banks} setBanks={setBanks} setBankForm={setBankForm} />
                            }
                        </Stack>
                    </Collapse>
                </Box>
            </Box>
        </>
    )
};

export default BankDetails;
