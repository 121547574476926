import { Button, Stack, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { GroupMembersContext } from "../../GroupMembersContext";
import SetMemberInvitationDialog from "./SetMemberInvitationDialog";

const GroupMemberInvitation = () => {
    const { isCheckboxVisible, checkedMembers, setVisible,
        setChecked, setInviteMsg, setScheduledDate, setMemberInviteActiveStep } = useContext(GroupMembersContext);

    const handleCancel = () => {
        setChecked([]);
        setVisible(false)
    }

    let [open, setOpen] = useState(false);
    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        document.body.classList.remove("no-scroll");
        setInviteMsg(null);
        setScheduledDate(null);
        setMemberInviteActiveStep(0);
        setOpen(false);
    }

    return (
        <>
            <Stack direction='row' spacing={2}>
                {
                    isCheckboxVisible ?
                        <Button variant='outlined' color='warning' onClick={handleCancel}>
                            Cancel
                        </Button>
                        : null
                }
                {
                    checkedMembers.length > 0 ? <Tooltip title="Invite selected guests" arrow>
                        <Button variant='outlined' color='warning' onClick={handleOpen}>
                            Invite({checkedMembers.length})
                        </Button>
                    </Tooltip> : <Tooltip title="Invite all guests" arrow>
                        <Button variant='outlined' color='warning' onClick={handleOpen}>
                            Invite
                        </Button>
                    </Tooltip>
                }
                {open ? <SetMemberInvitationDialog handleClose={handleClose} /> : null}
            </Stack>
        </>
    )
};

export default GroupMemberInvitation;
