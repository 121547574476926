export const editAlbumForm = {
    "title": "Album Update",
    "description": "Event album edit form",
    "method": "POST",
    "action": "events/{event_id}/eventalbum",
    "data": [
        {
            "type": "header",
            "subtype": "h1",
            "label": "Add album",
            "access": false
        },
        {
            "type": "text",
            "required": true,
            "label": "Album Name",
            "placeholder": "Album Name",
            "className": "form-control",
            "name": "title",
            "access": false,
            "subtype": "text"
        },
        {
            "type": "text",
            "subtype": "album-link",
            "required": true,
            "label": "Album Link",
            "placeholder": "Album Link",
            "className": "form-control",
            "name": "album_link",
            "access": false
        },
        {
            "type": "switch",
            "required": true,
            "label": "Guest can upload?",
            "description": "Guest can upload?",
            "placeholder": "Use limit",
            "className": "form-control",
            "name": "allow_guest_upload"
        },
        {
            "type": "switch",
            "required": true,
            "label": "Guest can download?",
            "description": "Guest can download?",
            "placeholder": "Use limit",
            "className": "form-control",
            "name": "allow_download"
        },
        {
            "type": "switch",
            "required": true,
            "label": "is Album private?",
            "description": "is Album private?",
            "placeholder": "Use limit",
            "className": "form-control",
            "name": "is_private"
        },
    ]
}