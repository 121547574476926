import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";
import { ticketCurrencies } from "../../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../../utils/functions/getFormattedNumber";
import { convertUtcToLocal } from "../../../../../../../../../utils/functions/timeConversion";

const OneTicketPaymentDetails = ({ details }) => {
    const amountCurrencySymbol = ticketCurrencies.find((t) => t.value === details.amount_currency)?.symbol;

    const rows = [
        {
            id: 1,
            title: "Guest Name",
            value: details.guest_name,
        },
        {
            id: 2,
            title: "Guest Mobile Number",
            value: details.guest_mobile,
        },
        {
            id: 3,
            title: "Guest Email",
            value: details.guest_email,
        },
        {
            id: 4,
            title: "Payment Time",
            value: convertUtcToLocal(details.payment_time).format("Do MMMM YYYY, h:mm A"),
        },
        {
            id: 5,
            title: "Ticket Title",
            value: details.ticket_name,
        },
        {
            id: 6,
            title: "Number of Tickets",
            value: details.number_of_tickets,
        },
    ]

    return (
        <>
            <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                <TableContainer sx={{
                    border: '1px solid lightgrey',
                    maxWidth: '100%', margin: '1rem auto !important'
                }}>
                    <Table stickyHeader>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow hover={true} key={row.id}>
                                    <TableCell sx={{
                                        width: '45%',
                                        textAlign: 'center',
                                        p: { xs: '0.5rem', md: '0.7rem' },
                                        borderRight: '1px solid #ccc'
                                    }}>
                                        {row.title}
                                    </TableCell>
                                    <TableCell sx={{
                                        width: '55%',
                                        textAlign: 'center',
                                        p: { xs: '0.5rem', md: '0.7rem' },
                                    }} align="center">{row.value}</TableCell>
                                </TableRow>
                            ))}

                            <TableRow hover={true}>
                                <TableCell sx={{
                                    width: '45%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    p: { xs: '0.6rem', md: '0.75rem' }
                                }}>
                                    Per Ticket Price
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        width: '55%',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        p: { xs: '0.6rem', md: '0.75rem' }
                                    }}>
                                    {parseDecimalNumber(details.ticket_purchase_price, 3)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover={true}>
                                <TableCell sx={{
                                    width: '45%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc',
                                    p: { xs: '0.6rem', md: '0.75rem' }
                                }}>
                                    Total Amount Paid
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        width: '55%',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        p: { xs: '0.6rem', md: '0.75rem' }
                                    }}>
                                    {parseDecimalNumber(details.total_amount_paid, 3)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover={true}>
                                <TableCell sx={{
                                    width: '45%',
                                    color: '#1F274A', fontSize: '1.1rem',
                                    fontWeight: 'bold', borderRight: '1px solid #ccc',
                                    textAlign: 'center',
                                    p: '0.5rem'
                                }}>
                                    You will receive
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        width: '55%',
                                        color: '#FF8359', fontSize: '1.1rem',
                                        fontWeight: 'bold', textAlign: 'center',
                                        p: '0.5rem'
                                    }}>
                                    {parseDecimalNumber((details.ticket_amount_to_owner * details.number_of_tickets), 3)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
};

export default OneTicketPaymentDetails;
