import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { groupDetails, update_group_details } from "../../../../../../../../redux/slices/groupDetailSlice";
import { updateGroupDetails } from "../../../../../../../../services/wGroups/group";
import { READ_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import AboutGroupDialog from "./AboutGroupDialog";

const AboutGroupSection = () => {
    const dispatch = useDispatch();
    const groupData = useSelector(groupDetails);
    const [grpDesc, setGrpDesc] = useState(groupData?.description ?? '');
    const isReadOnlyPermission = READ_PERMISSION_ROLES.includes(groupData?.current_user_role);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const handleEdit = () => {
        setEdit(true)
    }
    const handleCancel = () => {
        setEdit(false)
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }
    const handleChange = (e) => {
        setGrpDesc(e.target.value);
    }

    const saveDescription = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('description', grpDesc);
        updateGroupDetails(formData, groupData?.id).then((res) => {
            dispatch(update_group_details(res.data.data));
            setEdit(false);
            setLoading(false);
            toast.success("Group details updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            handleClose('edit');
        }).catch((err) => {
            console.log("Error ocurred =>", err);
            setLoading(false);
            if (err.response.status === 413) {
                toast.error("Content is beyond limit of space!!", {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            }
        })
    }

    return (
        <>
            {(isReadOnlyPermission ? groupData?.description : true) ?
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography sx={{ mb: 1, color: '#1F274A', fontWeight: 'bold' }}>Details</Typography>
                    {edit ? <Stack direction='row' spacing={1}>
                        <Button color='warning' variant='outlined' onClick={handleCancel}>Cancel</Button>
                        <LoadingButton
                            color="warning"
                            variant="outlined"
                            onClick={saveDescription}
                            loading={loading ? true : false}
                            loadingPosition={loading ? "start" : null}
                            startIcon={loading ? <SaveIcon /> : null}
                        >
                            {loading ? 'Saving..' : 'Save'}
                        </LoadingButton>
                    </Stack> :
                        <>
                            {!isReadOnlyPermission ?
                                <IconButton color='warning' onClick={handleEdit} sx={{ ":hover": { backgroundColor: 'white' } }}>
                                    {groupData?.description ? <EditIcon /> : null}
                                </IconButton>
                                : null
                            }
                        </>
                    }
                </Stack>
                : null
            }

            <Box>
                {edit ? <>
                    <Box sx={{ my: 3 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={5}
                            color="warning"
                            value={grpDesc}
                            onChange={handleChange}
                        />
                    </Box>
                </> :
                    (
                        groupData?.description ?
                            <Box sx={{ border: '1px solid lightgrey', borderRadius: '10px', p: 2 }}>
                                <Typography className="event-desc">{groupData?.description}</Typography>
                            </Box> :
                            <>
                                {!isReadOnlyPermission ?
                                    <IconButton color='warning' onClick={handleEdit} sx={{ ":hover": { backgroundColor: 'white' } }}>
                                        <Typography sx={{ fontSize: '0.875rem' }}>+ Add Event Description</Typography>
                                    </IconButton>
                                    : null
                                }
                            </>
                    )
                }
                {groupData?.description && <Button color='warning' onClick={handleOpen}>See More</Button>}
            </Box>

            {(isReadOnlyPermission ? groupData?.description : true) ? <Divider sx={{ my: 1.5 }} /> : null}

            <AboutGroupDialog open={open} handleClose={handleClose} desc={groupData?.description} />

        </>
    )
};

export default AboutGroupSection;
