import { Box, Dialog, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-responsive-carousel';
import EventQrCodeDetails from './EventQrCodeDetails';
import ScanningReports from './ScanningReports';
import { ALL_PERMISSION_ROLES } from '../../../../../../utils/constants/roles';

const EventQrCodeDialog = ({ handleClose, eventDetailsObj, scanPermission, guestDetails, maximumValueObj, ticketDetails, checkInDetails }) => {
    const [activeSlide, setSlide] = useState(0);
    const isSelfCheckIn = eventDetailsObj?.is_self_check_in;
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventDetailsObj?.current_user_role);
    const [guestData, setGuestData] = useState(guestDetails ?? null);
    const [scanningReports, setScanningReports] = useState(null);

    const handlePrevClick = () => {
        setSlide(0);
    };
    const handleNextClick = () => {
        setSlide(1);
    };

    const slides = [
        {
            id: 1,
            title: isSelfCheckIn ? 
                (
                    isAllPermission ? "Scan Event Ticket" : (
                    guestData ? "Proceed to Check-in" : "QR Code Scanner"
                )) : (
                    isAllPermission ?
                        guestData ? "Guest Details" : "QR Code Scanner"
                        : "Scan Event Ticket"
                ),
            content: <EventQrCodeDetails
                handleClose={handleClose}
                eventDetailsObj={eventDetailsObj}
                scanPermission={scanPermission}
                guestData={guestData}
                setGuestData={setGuestData}
                maximumValueObj={maximumValueObj}
                ticketDetails={ticketDetails}
                checkInDetails={checkInDetails}
                handleNextClick={handleNextClick}
                setScanningReports={setScanningReports}
            />,
        },
        {
            id: 2,
            title: 'Scanning Reports',
            content: <ScanningReports scanningReports={scanningReports} />,
        },
    ];

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={(e, r) => handleClose(e, r, 'qrCode')}
                disableEnforceFocus
                disableEscapeKeyDown
            >
                <DialogTitle sx={{ p: '0.75rem 1rem' }}>
                    <IconButton
                        onClick={(e, r) => handleClose(e, r, 'qrCode')}
                        sx={{ position: 'absolute', right: '0.2rem', top: '0.7rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Stack direction='row' alignItems='center' sx={{ mt: 1 }}>
                        {(activeSlide === 1) ?
                            (
                                <>
                                    <ArrowBackIosIcon onClick={handlePrevClick} sx={{
                                        ":hover": {
                                            cursor: 'pointer'
                                        }
                                    }} />&nbsp;
                                </>
                            ) :
                            null
                        }
                        <Typography sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}>
                            {slides[activeSlide].title}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <Carousel autoPlay={false} selectedItem={activeSlide} swipeable={false}
                    showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
                    {slides.map(slide => (
                        <Box key={slide.id} sx={{ textAlign: 'left' }}>
                            {slide.content}
                        </Box>
                    ))}
                </Carousel>
            </Dialog>
        </>
    )
}

export default EventQrCodeDialog;