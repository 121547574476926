import { Box, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import QrReader from "react-qr-reader";
import { toast } from "react-toastify";
import { verifyGuestData, verifyGuestQR } from "../../../../../../services/wEvents/eventGuests";

const QrScanner = ({ eventDetailsObj, loader, setLoader, setGuestData, setTicketData, setCheckInData, selfCheckIn, setMaxValueObj,setCategoryCount }) => {
    
    const handleScan = (data) => {
        if (data) {
            if (selfCheckIn) {
                const chunks = data.split('/');
                // For http://localhost:3000/e/68/casual-discussion common link array would be following after split 
                // ["https:", "", "app.wowsly.com", "e", "68", "casual-discussion"] 5th element will be id of event 
                let payload = {};
                setLoader({ ...loader, qrVerify: true });
                payload = {
                    eventId: chunks.length === 1 ? data : chunks[4],
                }
                verifyGuestData(eventDetailsObj?.guest_uuid, payload).then((result) => {

                    const checkInCounts = _.map(result.data.check_in_data, 'check_in_count').reverse();
                    setLoader({ ...loader, qrVerify: false });
                    setGuestData(result.data.guest_data[0]);
                    setTicketData(result.data.ticket_data ?? null);
                    setCheckInData(checkInCounts);
                    // setMaxValue(result.data.available_tickets);
                    setMaxValueObj({
                        available_tickets: result.data.available_tickets,
                        facility_availability_status: result.data.facility_availability_status
                    })
                    toast.success(result.data.message, {
                        position: "top-right",
                        theme: "dark"
                    });
                }).catch((err) => {
                    console.log(err);
                    setLoader({ ...loader, qrVerify: false });
                    toast.error(err.response.data.error || 'Something went wrong, Try again!', {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            } else {
                let updatedData;
                try {
                    // Attempt to parse JSON
                    updatedData = JSON.parse(data);
                } catch (e) {}
                let payload = {};
                setLoader({ ...loader, qrVerify: true });
                if (typeof (updatedData) === 'object') { // In case of QR code is generated by owner it'll have ticket id
                    payload = {
                        qrGuestUuid: updatedData.guestUuid,
                        qrTicketId: updatedData.ticketId
                    }
                } else {
                    payload = {
                        qrGuestUuid: data,
                    }
                }
                verifyGuestQR(eventDetailsObj?.id, payload).then((result) => {
                    
                    const checkInCounts = _.map(result.data.check_in_data, 'check_in_count').reverse();
                    setLoader({ ...loader, qrVerify: false });
                    setGuestData(result.data.guest_data[0]);
                    setTicketData(result.data.ticket_data ?? null);
                    setCheckInData(checkInCounts);
                    setCategoryCount(result.data.available_category_tickets)
                    // setMaxValue(result.data.available_tickets);
                    setMaxValueObj({
                        available_tickets: result.data.available_tickets,
                        facility_availability_status: result.data.facility_availability_status
                    })
                    toast.success(result.data.message, {
                        position: "top-right",
                        theme: "dark"
                    });
                }).catch((err) => {
                    console.log(err);
                    setLoader({ ...loader, qrVerify: false });
                    toast.error(err.response.data.error || 'Something went wrong, Try again!', {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            }
        }
    };
    const handleError = (err) => {
        alert(err);
    };

    return (
        <>
            {loader.qrVerify ?
                <Stack justifyContent='center' alignItems='center'
                    sx={{
                        px: '1rem',
                        my: '2rem',
                        minHeight: '5rem'
                    }}>
                    <CircularProgress color="warning" />
                </Stack>
                :
                <Box>
                    <QrReader
                        delay={500}
                        onScan={handleScan}
                        onError={handleError}
                        facingMode="environment"
                        style={{ width: '100%' }}
                    />
                </Box>
            }

        </>
    )
};

export default QrScanner;
