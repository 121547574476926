import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import EditEventForm from "./EditEventForm";
import { Typography, IconButton, Dialog, DialogTitle } from "@mui/material";

const EditEventDialog = ({ handleClose }) => {
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={(e, r) => handleClose(e, r, 'edit')}
                aria-labelledby="scroll-dialog-title"
                PaperProps={{ sx: { width: { xs: '90%', md: '65%' }, maxWidth: '700px', m: { xs: 0 } } }}
            >
                <DialogTitle id="scroll-dialog-title">
                    <IconButton onClick={(e, r) => handleClose(e, r, 'edit')} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.4rem', md: '1.5rem' } }}>Edit event details</Typography>
                </DialogTitle>
                <EditEventForm handleClose={handleClose} />
            </Dialog>
        </>
    )
};

export default EditEventDialog;
