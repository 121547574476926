import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton, Dialog, DialogTitle } from "@mui/material";
import EditGroupForm from "./EditGroupForm";

const EditGroupDialog = ({ handleClose }) => {
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                scroll={'paper'}
                onClose={() => handleClose('edit')}
                aria-labelledby="scroll-dialog-title"
                PaperProps={{ sx: { width: { xs: '90%', md: '65%' }, maxWidth: '700px', m: { xs: 0 } } }}
            >
                <DialogTitle id="scroll-dialog-title">
                    <IconButton onClick={() => handleClose('edit')} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.4rem', md: '1.5rem' } }}>Edit group details</Typography>
                </DialogTitle>
                <EditGroupForm handleClose={handleClose} />
            </Dialog>
        </>
    )
};

export default EditGroupDialog;
