import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoginDialog from '../../components/LoginDialog';
import { clear_group_details, groupDetails, update_group_details } from "../../redux/slices/groupDetailSlice";
import { userStatus } from '../../redux/slices/userDataSlice';
import { getGroupDetails } from "../../services/wGroups/group";
import { getMemberDetails, joinGroup } from "../../services/wGroups/groupMembers";
import { PAGE_PATHS } from "../../utils/constants/page-path.constants";
import { READ_PERMISSION_ROLES } from "../../utils/constants/roles";
import GroupDetailCards from "./components/GroupDetailCards";
import GroupMenubar from "./components/GroupMenubar";

const GroupDetails = () => {
    const dispatch = useDispatch();
    const { id, memberId } = useParams() //id is for owner and memberId for members
    const navigate = useNavigate();
    const isLoggedIn = useSelector(userStatus);
    const groupData = useSelector(groupDetails);

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [loader, setLoader] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);
    const [memberData, setMemberData] = useState(null);
    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    const registrationTab = groupData?.tabs?.find((t) => t.title === 'Registration');
    const registrationTabIndex = groupData?.tabs?.indexOf(registrationTab);
    const handleClick = () => {
        if (registrationTab) {
            setTabValue(registrationTabIndex); //Set registration tab index on btn click
        } else {
            if (isLoggedIn) {
                setLoader(true);
                // API call for join group
                joinGroup({ member_uuid: groupData?.member_uuid }, groupData.id).then((result) => {
                    setLoader(false);
                    dispatch(update_group_details(result.data.data))
                    toast.success("Successfully joined group!!", {
                        position: "top-right",
                        theme: "dark"
                    });
                }).catch((err) => {
                    console.log(err);
                    toast.error("Something went wrong!!", {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            } else {
                setLoader(true);
                getMemberDetails({ groupId: groupData?.id, uuid: groupData?.member_uuid }).then((result) => {
                    setLoginDialog(true);
                    setLoader(false);
                    setMemberData(result.data.data);
                }).catch((err) => {
                    console.log(err);
                });
            }
        }
    }

    const isJoinGrpBtnVisible = Object.keys(groupData).length > 0 ? //Process only when group data fetched
        (groupData.is_member_joined ? false :
            (registrationTab ? (tabValue !== registrationTabIndex) :
                false //This is for free groups without registration
            )
        )
        : false;

    useEffect(() => {
        if (memberId) {
            getGroupDetails({ memberId: memberId }).then((res) => {
                dispatch(update_group_details(res.data.data))
            }).catch((err) => {
                console.log(err);
                navigate(PAGE_PATHS.DASHBOARD);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                })
            })
        } else {
            getGroupDetails({ groupId: id }).then((res) => {
                dispatch(update_group_details(res.data.data))
            }).catch((err) => {
                console.log(err);
                navigate(PAGE_PATHS.DASHBOARD);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                })
            })

        }
        return (() => {
            dispatch(clear_group_details())
        })
    }, [])

    return (
        <>
            <GroupDetailCards />

            <Divider orientation='horizontal' sx={{ mt: 3 }} />

            <GroupMenubar tabValue={tabValue} handleTabChange={handleTabChange} />

            {(READ_PERMISSION_ROLES.includes(groupData?.current_user_role) && isJoinGrpBtnVisible) ?
                <Stack alignItems='center' justifyContent='center' sx={{
                    width: '100%',
                    height: '3rem',
                    bottom: '0px',
                    zIndex: 10000, //To show it over every things in group details
                    position: 'sticky'
                }}>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={handleClick}
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                        sx={{
                            bgcolor: "#1F274A",
                            color: "white",
                            borderRadius: '1.5rem',
                            px: '2rem',
                            ":hover": {
                                bgcolor: "#1F274A",
                                boxShadow: '0 0 5px 5px rgba(0, 0, 50, 0.4)',
                            },
                            boxShadow: '0 0 5px 5px rgba(0, 0, 50, 0.4)',
                            width: 'auto'
                        }}
                    >
                        Join Group
                    </LoadingButton>
                </Stack>
                : null
            }

            {loginDialog ? <LoginDialog open={true}
                handleClose={() => handleDialogClose('login')} guestData={memberData} /> : null}
        </>
    )
};

export default GroupDetails;
