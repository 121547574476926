import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import HideTicketDialog from "./HideTicketDialog";
import EditTicketDialog from "./EditTicketDialog";
import ViewTicketDialog from "./ViewTicketDialog";
import { TICKET_TYPES } from "../../../../../../../../utils/constants/eventTickets";
import GenerateQrTicketsDialog from "./QRPasses/GenerateTickets/GenerateQRTicketsDialog";
import SendTicketsDialog from "./QRPasses/SendTickets/SendTicketsDialog";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";

const TicketCard = ({ ticket }) => {
    const initialActions = {
        view: false,
        edit: false,
        hide: false
    }
    const eventDetailsObj = useSelector(eventDetails);
    const eventTicketType = eventDetailsObj?.is_paid ? 'paid' : 'free';
    const [action, setAction] = useState(initialActions)
    const ticketPriceSymbol = ticketCurrencies.find((t) => t.value === ticket.currency)?.symbol;

    return (
        <>
            <Stack spacing={1} sx={{
                p: 2,
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.1)'
            }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{ticket.title}</Typography>
                    <Stack direction='row' spacing={0.5} alignItems='center'>
                        <IconButton disabled={ticket.is_hidden ? true : false} onClick={() => setAction({ ...initialActions, view: true })}>
                            <InfoIcon sx={{ color: ticket.is_hidden ? '#c8cce6' : '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                        <IconButton disabled={ticket.is_hidden ? true : false} onClick={() => setAction({ ...initialActions, edit: true })}>
                            <EditIcon sx={{ color: ticket.is_hidden ? '#c8cce6' : '#1F274A', fontSize: '1.3rem' }} />
                        </IconButton>
                        <Tooltip title={ticket.is_hidden ? "Unhide" : "Hide"} arrow>
                            <IconButton onClick={() => { setAction({ ...initialActions, hide: true }) }}>
                                {
                                    ticket.is_hidden ?
                                        <VisibilityOffIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} /> :
                                        <VisibilityIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                }
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>

                <Box sx={{ opacity: ticket.is_hidden ? 0.4 : 1 }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Total ticket:</Typography>
                    <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline-block' }}>{ticket.quantity}</Typography>
                </Box>
                <Box sx={{ opacity: ticket.is_hidden ? 0.4 : 1 }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Available:</Typography>
                    <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline-block' }}>{ticket.quantity - ticket.sold_out}</Typography>
                </Box>
                {ticket.ticket_type === TICKET_TYPES.QR_CODE ? (
                    <Box sx={{ opacity: ticket.is_hidden ? 0.4 : 1, pointerEvents: ticket.is_hidden ? 'none' : 'auto' }} display='flex' justifyContent='start' alignItems='center' gap={2}>
                        <GenerateQrTicketsDialog ticket={ticket} />
                        <SendTicketsDialog ticket={ticket} />
                    </Box>
                ) : null}
                {
                    eventTicketType === 'paid' ?
                        <>
                            <Divider sx={{ my: 1 }} />
                            <Typography disabled sx={{ my: 2, color: ticket.is_hidden ? '#c8cce6' : '#1F274A', fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {parseDecimalNumber(ticket.purchase_price, 3)} <span dangerouslySetInnerHTML={{ __html: ticketPriceSymbol }} />
                            </Typography>
                        </> :
                        <></>
                }

                {action.view ? <ViewTicketDialog setAction={setAction} ticket={ticket} /> : null}
                {action.edit ? <EditTicketDialog setAction={setAction} ticket={ticket} /> : null}
                {action.hide ? <HideTicketDialog setAction={setAction} ticket={ticket} /> : null}
            </Stack>
        </>
    )
};

export default TicketCard;
