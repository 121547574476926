import { Box, List, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InvitationGif from '../../../../../../../../../../assets/Invitation.gif';
import ReminderGif from '../../../../../../../../../../assets/Reminder.gif';
import MuiTabPanel from "../../../../../../../../../../components/muiTabPanel";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { getGuestsInvitation } from "../../../../../../../../../../services/wEvents/eventGuests";
import InvitationItem from './InvitationItem';

const InvitationsList = ({ invitationType, title, setDetails, tabType, setShowDetails, tabStyles }) => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const eventData = useSelector(eventDetails);
    const [value, setValue] = useState(tabType.current ?? 0);
    //Used tabType for when we click on Done on one invitation/reminder details it'll come to same tab where he left

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const RenderList = ({ type }) => {
        const [data, setData] = useState({
            invitations: null,
            reminders: null
        });
        const handleItemClick = (e, item) => {
            setDetails({ id: item.id });
            setShowDetails(true)
        }
        let isInvitation = type === 'invitation';

        useEffect(() => {
            if (isInvitation) {
                tabType.current = 0;
                if (title === 'Scheduled Invitations') {
                    getGuestsInvitation({ eventId: eventData?.id, inviteType: 'invitation', listType: 'scheduled' }).then((res) => {
                        console.log(res.data.data);
                        setData({ ...data, invitations: res.data.data });
                    }).catch((err) => {
                        console.log(err);
                    });
                } else if (title === 'Completed Invitations') {
                    getGuestsInvitation({ eventId: eventData?.id, inviteType: 'invitation', listType: 'completed' }).then((res) => {
                        setData({ ...data, invitations: res.data.data });
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            } else {
                tabType.current = 1;
                if (title === 'Scheduled Invitations') {
                    getGuestsInvitation({ eventId: eventData?.id, inviteType: 'reminder', listType: 'scheduled' }).then((res) => {
                        console.log(res.data.data);
                        setData({ ...data, reminders: res.data.data });
                    }).catch((err) => {
                        console.log(err);
                    });
                } else if (title === 'Completed Invitations') {
                    getGuestsInvitation({ eventId: eventData?.id, inviteType: 'reminder', listType: 'completed' }).then((res) => {
                        setData({ ...data, reminders: res.data.data });
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            }
        }, []);

        return (
            <List sx={{ width: '100%', bgcolor: 'background.paper', pt: 0 }}>
                {
                    (isInvitation ? data.invitations : data.reminders)?.length === 0 ?
                        <Box sx={{ textAlign: 'center' }}>
                            <Box
                                component='img'
                                width={isSmallDevice ? '100%' : '80%'}
                                height={isSmallDevice ? '20rem' : '25rem'}
                                src={isInvitation ? InvitationGif : ReminderGif}
                            />
                        </Box> :
                        <>
                            {
                                (
                                    (isInvitation ? data.invitations : data.reminders) ??
                                    Array.from(new Array(2))
                                ).map((d, index) => (
                                    <InvitationItem
                                        key={index}
                                        item={d}
                                        setData={setData}
                                        isInvitation={isInvitation}
                                        invitationType={invitationType}
                                        handleItemClick={handleItemClick}
                                    />
                                ))
                            }
                        </>
                }
            </List>
        )
    }

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#FF8359"
                    }
                }}
                sx={{
                    backgroundColor: '#FFEBE4',
                    maxWidth: { sm: '100%' },
                }}
            >
                <Tab className={tabStyles.root} label='Invitations' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
                <Tab className={tabStyles.root} label='Reminders' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
            </Tabs>
            <MuiTabPanel value={value} index={0}>
                <RenderList type='invitation' />
            </MuiTabPanel>
            <MuiTabPanel value={value} index={1}>
                <RenderList type='reminder' />
            </MuiTabPanel>
        </>
    )
};

export default InvitationsList;
