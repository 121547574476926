import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import mycsv from '../../../../../../../../../../assets/csvfile/Guest List Format Wowsly.csv';
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { getEventGuests, uploadEventGuestCsv } from "../../../../../../../../../../services/wEvents/eventGuests";
import { EventGuestsContext } from "../../../../EventGuestContext";

const UploadCsvContent = (handleClose) => {
    const theme = useTheme();
    const eventData = useSelector(eventDetails)
    const { tabValue, totalGuests, setGuests, setSearchedGuest } = useContext(EventGuestsContext);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const {
        handleSubmit,
        setValue
    } = useForm();

    const handleFileChange = (e) => {
        if (e) {
            setFile(e)
            setValue('guest_list', e)
        }
    }

    const mySubmit = (data) => {
        setLoading(true);
        setSearchedGuest(null);
        if (!data.guest_list) {
            setLoading(false);
            toast.error('Something went wrong!!', {
                position: "top-right",
                theme: "dark"
            });
        } else {
            const formData = new FormData();
            formData.append('guest_list', data.guest_list)
            uploadEventGuestCsv(formData, eventData?.id).then((res) => {
                getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered' }).then((res2) => {
                    totalGuests.current = res2.data.meta.total
                    setGuests(res2.data.data)
                    setLoading(false);
                    toast.info(res.data.data.message, {
                        position: "top-right",
                        theme: "dark"
                    });
                    handleClose();
                }).catch((err) => {
                    console.log(err);
                    setLoading(false);
                    toast.error('Something went wrong!!', {
                        position: "top-right",
                        theme: "dark"
                    });
                })
            }).catch((err) => {
                console.log(err)
                setLoading(false);
                toast.error(err?.response?.data?.error ?? 'Something went wrong in uploading csv', {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <Stack spacing={3}>
                    <Box>
                        <Typography sx={{ mt: 1, fontSize: '1rem', color: '#6F738B' }}>
                            Get started by downloading our sample guest CSV file.
                            Ensure you keep the column names and order intact.
                            Simply add your guest list data to the file and upload it here to complete the process.
                        </Typography>
                        <Button fullWidth href={mycsv} download="Guests List.csv" color='warning' variant="outlined"
                            sx={{ color: '#FF8359', my: 2, p: 2 }}>
                            Download Samples Guests List
                        </Button>
                    </Box>

                    <FileUploader
                        name="guest_list"
                        handleChange={handleFileChange}
                        types={["csv"]}
                        classes="event-upload-csv-drag-drop-area"
                        required={true}
                        maxSize={5}
                        children={
                            <Stack
                                spacing={1}
                                alignItems='center'
                                justifyContent='center'
                                sx={{
                                    height: '100%',
                                    borderRadius: '0.5rem',
                                    border: `3.5px dashed ${theme.palette.primaryColor}`,
                                    ":hover": {
                                        cursor: 'pointer'
                                    }
                                }}>
                                <DriveFolderUploadIcon sx={{
                                    fontSize: "2rem",
                                    color: theme.palette.primaryColor
                                }} />
                                <Typography sx={{ color: '#6F738B', fontSize: '0.9rem' }}>
                                    {file ? "Uploaded Successfully" : "Drop or Upload Guest CSV File"}
                                </Typography>
                            </Stack>
                        }
                        onSizeError={() => {
                            toast.error('Maximum upload size for file is 5MB', {
                                position: "top-right",
                                theme: "dark"
                            });
                        }}
                        dropMessageStyle={{ backgroundColor: 'red' }}
                    />

                    <Typography textAlign='center'>
                        {file ? `File name: ${file.name}` : "No files selected"}
                    </Typography>

                    <Typography sx={{ mt: 1, fontSize: '1rem', color: '#6F738B' }}>
                        Please ensure that the CSV file contains a mobile number for each user;
                        rows without a mobile number and country code will not be processed.
                        Additionally, any rows without a name will be substituted with the name 'Guest'.
                    </Typography>
                    <Divider />
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loading ? true : false}
                        startIcon={loading ? <SaveIcon /> : null}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Uploading..' : 'Upload'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const UploadCsvDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Upload Guests List' content={UploadCsvContent(handleClose)}
            />
        </>
    )
};

export default UploadCsvDialog;
