import { Button, DialogActions, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { groupDetails } from "../../../../../../../../../redux/slices/groupDetailSlice";
import { createGroupKeyPerson } from "../../../../../../../../../services/wGroups/groupDetails";
import { createKeyMemberForm } from "../../../../../../../../../utils/constants/createKeyMember";
import { manageGroupKeyPersonPayload } from "../../../../../../../../../utils/functions/managePayload";

const CreateKeyPersonContent = (handleClose, setItems) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();


    const[loader,setLoader]=useState(false);
    const groupData = useSelector(groupDetails);
    const [imageToCrop, setImageToCrop] = useState(null);
    const onImageChoose = (img) => {
        setImageToCrop(img);
    }

    const mySubmit = (data) => {
        setLoader(true);
        let payload = manageGroupKeyPersonPayload(data)
        console.log(Object.fromEntries(payload))
        createGroupKeyPerson(payload, groupData?.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success("Key Person Added Successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => [...items, res.data.data])
            setLoader(false);
            handleClose();
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            setImageToCrop(null);
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {createKeyMemberForm.data.map((item, index) => (
                        <RenderFormFields
                            key={index}
                            item={item}
                            register={register}
                            errors={errors}
                            control={control}
                            file={imageToCrop}
                            setFile={setImageToCrop}
                            onImageChoose={onImageChoose}
                        />
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button> <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true:false}
                        startIcon={loader?<SaveIcon/>:null}
                        loadingPosition={loader?"start" :null}
                        
                    >
                       Save Changes
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}

const CreateKeyPersonDialog = ({ handleClose, setItems }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Add Key Person' content={CreateKeyPersonContent(handleClose, setItems)}
        />
    )
};

export default CreateKeyPersonDialog;
