import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'
import { ticketCurrencies } from '../utils/constants/ticketCurrency';
import { parseDecimalNumber } from '../utils/functions/getFormattedNumber';

const ViewFacilityCard = ({ facility }) => {
    const facilityPriceSymbol = ticketCurrencies.find((f) => f.value === facility.currency)?.symbol;

    return (
        <>
            <Stack spacing={1} sx={{p:1.5}}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{facility.name}</Typography>
                    {
                        !facility.is_free ?
                            <Stack direction='row' spacing={1}>
                                <Typography sx={{ fontWeight: '500', fontSize: '16px', display: 'inline-block' }}>Amount:</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', display: 'inline-block', color: '#1F274A', }}>
                                    {parseDecimalNumber(facility.price, 3)} <span dangerouslySetInnerHTML={{ __html: facilityPriceSymbol }} />
                                </Typography>
                            </Stack> :
                            null
                    }
                </Stack>
                <Typography sx={{ fontWeight: '500', fontSize: '16px', display: 'inline-block' }}>{facility.scan_quantity !== null ? `${facility.scan_quantity} time${facility.scan_quantity > 1 ? "s" : ""}` : "Multiple times"} scan</Typography>
            </Stack>
        </>
    )
}

export default ViewFacilityCard;