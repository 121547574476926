import "./global-components.scss";
import _ from "lodash";
import ImageViewer from "react-simple-image-viewer";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, Dialog, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { DeleteRounded } from "@mui/icons-material";
import DialogBox from "./DialogBox";
import CropImage from "./CropImage";

const iconStyles = {
    color: "white",
    width: "1.3rem",
    height: "1.2rem",
}

const buttonStyles = {
    p: 1,
    border: "1px solid white",
}

const UploadImage = ({
                         fieldName,
                         label,
                         register,
                         errors,
                         selectedImage,
                         setSelectedImage,
                         hideOptions,
                         hideVisibility,
                         onDelete = () => {
                         },
                         isDeletable = false,
                         allowCrop = false,
                         cropDimensions = null,
                         updatePercentageCrop = (_) => {},
                         disabled = false
                     }) => {
    const { onChange, ...params } = register(fieldName);
    const isMobileScreen = useMediaQuery("(max-width:600px)");

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imageCropDialogOpen, setImageCropDialogOpen] = useState(false);

    const openImageViewer = useCallback(() => {
            setIsViewerOpen(true);
    }, [disabled]);

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    const deleteImage = () => {
        onDelete();
    };

    const openImageSelectDialog = () => {
        const img = document.getElementById('main-img');
        if (img) {
            img.click();
        }
    }

    const imageSrc = useMemo(() => {
        if (!selectedImage) return;
        return typeof (selectedImage) === "object" ? [URL.createObjectURL(selectedImage)] : [selectedImage];
    }, [selectedImage]);

    const cropStyles = useMemo(() => {
        if (allowCrop && cropDimensions) {
            return {
                ' .react-simple-image-viewer__modal-content': {
                    display: 'flex',
                    alignItems: 'center',
                },
                ' .react-simple-image-viewer__slide': {
                    width: 'fit-content',
                    height: 'fit-content',
                    marginInline: 'auto',
                    position: 'relative',

                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: `calc(${cropDimensions.width}% - 8px)`,
                        height: `calc(${cropDimensions.height}% - 8px)`,
                        top: `${cropDimensions.y}%`,
                        left: `${cropDimensions.x}%`,
                        border: '4px dashed #757575',
                        background: '#757575b3',
                        zIndex: 1,
                    }
                }
            }
        }
        return {}
    }, [allowCrop, cropDimensions]);

    return (
        <>
            <Stack id="image-box"
                   sx={{
                       justifyContent: "center",
                       alignItems: "center",
                       width: "100%",
                       height: "100%"
                   }}
            >
                {selectedImage ? (
                    <>
                        <Box
                            component="img"
                            src={typeof (selectedImage) === "object" ? URL.createObjectURL(selectedImage) : selectedImage}
                            alt="Event Img."
                            id="uploaded-image"
                            sx={{
                                objectFit: "cover",
                                borderRadius: "10px",
                                opacity: isMobileScreen || !hideVisibility ? { xs: 0.5, md: 1 } : 1,
                                width: "100%", height: "100%"
                            }}
                        />

                        <Stack
                            id="image-options"
                            direction="row"
                            spacing={2}
                            sx={{
                                p: "8px 3px 0px 0px",
                                position: "absolute",
                                display: { md: "none" }
                            }}
                        >
                            {!hideOptions &&
                                <>
                                    <IconButton
                                        sx={buttonStyles}
                                        component="label"
                                        onClick={(e) => {
                                            if (!disabled) {
                                                if (allowCrop) {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setImageCropDialogOpen(true);
                                                } else {
                                                    openImageSelectDialog();
                                                }
                                            }
                                        }}
                                        disabled={disabled}
                                    >
                                        <EditIcon sx={iconStyles}/>
                                    </IconButton>
                                    <input
                                        hidden
                                        id='main-img'
                                        {...params}
                                        accept="image/png, image/jpg, image/jpeg"
                                        type="file"
                                        onChange={(event) => {
                                            if (!disabled) {
                                                setSelectedImage(event.target.files[0]);
                                                onChange(event);
                                                if (cropDimensions) {
                                                    updatePercentageCrop(null);
                                                }
                                                setImageCropDialogOpen(allowCrop);
                                            }
                                        }}
                                    />
                                </>
                            }
                            {!hideVisibility ?
                                <IconButton sx={buttonStyles} onClick={openImageViewer} disabled={disabled}>
                                    <VisibilityIcon sx={iconStyles} />
                                </IconButton>
                                : null}
                            {isDeletable ? (
                                <IconButton sx={buttonStyles} onClick={deleteImage} disabled={disabled}>
                                    <DeleteRounded sx={iconStyles}/>
                                </IconButton>
                            ) : null}
                        </Stack>
                        {isViewerOpen &&
                            <Dialog open={true}>
                                <Box sx={cropStyles}>
                                    <ImageViewer
                                        src={imageSrc}
                                        currentIndex={0}
                                        disableScroll={true}
                                        closeOnClickOutside={true}
                                        onClose={closeImageViewer}
                                        backgroundStyle={{ zIndex: 1200 }}
                                    />
                                </Box>
                            </Dialog>
                        }
                        {imageCropDialogOpen ? (
                            <DialogBox
                                open={imageCropDialogOpen}
                                handleClose={() => setImageCropDialogOpen(false)}
                                title="Crop Ticket Image"
                                content={
                                    <Box>
                                        <Typography mb={2}>
                                            Please select area you want to embed QR code
                                        </Typography>
                                        <CropImage
                                            allowChangeImage
                                            aspectType="square"
                                            imageToCrop={selectedImage}
                                            uploadImage={() => setImageCropDialogOpen(false)}
                                            defaultPercentageCrop={cropDimensions}
                                            updatePercentageCrop={(c) => {
                                                updatePercentageCrop(c);
                                                setImageCropDialogOpen(false);
                                            }}
                                            handleDialogClose={() => setImageCropDialogOpen(false)}
                                            changeImage={() => {
                                                updatePercentageCrop(null)
                                                setImageCropDialogOpen(false);
                                                openImageSelectDialog();
                                            }}
                                        />
                                    </Box>
                                }
                            />
                        ) : null}
                    </>
                ) : (
                    <Stack alignItems="center" justifyContent="center" sx={{ margin: "auto !important" }}>
                        <Typography sx={{ color: _.get(errors, fieldName) ? "red" : "grey", fontFamily: "Poppins" }}>
                            {label}
                        </Typography>
                        <IconButton
                            color="warning"
                            component="label"
                            size="large"
                            sx={{
                                height: "2.5rem"
                            }}
                            disabled={disabled}
                        >
                            <input
                                hidden
                                {...params}
                                accept="image/png, image/jpg, image/jpeg"
                                type="file"
                                onChange={(event) => {
                                    if (!disabled) {
                                        setSelectedImage(event.target.files[0]);
                                        setImageCropDialogOpen(allowCrop);
                                        onChange(event);
                                    }
                                }}
                            />
                            <DriveFolderUploadIcon size="large" sx={{ fontSize: "2rem" }} />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
            {_.get(errors, fieldName) ? (
                <Typography
                    sx={{
                        color: "red",
                        fontWeight: 400,
                        lineHeight: 1.66,
                        fontSize: "0.8rem",
                        textAlign: "center"
                    }}
                >
                    {_.get(errors, fieldName).message}
                </Typography>
            ) : null}
        </>
    );
};

export default UploadImage;
