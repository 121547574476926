import { Box } from "@mui/material";
import React from "react";
import {
    ScrollMenu,
    getItemsPos,
    slidingWindow
} from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { userStatus } from "../../../../../../../../redux/slices/userDataSlice";
import GroupEventCard from "../../../../../../../GroupDetails/components/GroupMenubar/components/GroupEvents/GroupEventCard";
import GroupAlbumCard from "../../../../../../../GroupDetails/components/GroupMenubar/components/GroupInfo/components/GroupGallery/GroupAlbumCard";
import GroupKeyPersonCard from "../../../../../../../GroupDetails/components/GroupMenubar/components/GroupInfo/components/GroupKeyPersons/GroupKeyPersonCard";
import AlbumCard from "../AlbumsSection/AlbumCard";
import SubEventCard from "../EventProgramsSection/SubEventCard";
import KeyMemberCard from "../KeyMembersSection/KeyMemberCard";
import { LeftArrow, RightArrow } from "./Arrows";
import './scrollBox.scss';
import useDrag from "./useDrag";
import { sidebarStatus } from "../../../../../../../../redux/slices/sidebarDataSlice";

const HorizontalScrollingCards = ({ category, items, setItems, handleAlbumMedia }) => {
    const isLoggedIn = useSelector(userStatus);
    const isSideBarOpen = useSelector(sidebarStatus);
    // const [items, setItems] = React.useState(getItems);
    const { dragStart, dragStop, dragMove } = useDrag();
    // const handleDrag = ({ scrollContainer }) => (
    //     ev
    // ) =>
    //     dragMove(ev, (posDiff) => {
    //         if (scrollContainer.current) {
    //             scrollContainer.current.scrollLeft += posDiff;
    //         }
    //     });

    const renderCard = (item, index) => {
        switch (category) {
            case 'EventPrograms':
                return <SubEventCard
                    key={index}
                    setItems={setItems}
                    eventProgramDetails={item}
                />
            case 'KeyMembers':
                return <KeyMemberCard
                    key={index}
                    setItems={setItems}
                    memberDetails={item}
                />
            case 'Albums':
                return <AlbumCard
                    key={index}
                    setItems={setItems}
                    albumDetails={item}
                    handleAlbumMedia={handleAlbumMedia}
                />
            case 'GroupKeyPersons':
                return <GroupKeyPersonCard
                    key={index}
                    setItems={setItems}
                    groupKeyPersonDetails={item}
                />
            case 'GroupAlbums':
                return <GroupAlbumCard
                    key={index}
                    setItems={setItems}
                    albumDetails={item}
                    handleAlbumMedia={handleAlbumMedia}
                />
            case 'GroupEvents':
                return <GroupEventCard
                    key={index}
                    setItems={setItems}
                    groupEventDetails={item}
                />
            default:
                break;
        }
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <Box onMouseLeave={dragStop} className='scrollContainer'
                    sx={{
                        width: isLoggedIn ?
                            { md: isSideBarOpen ? 'calc(100vw - 19rem) !important' : '100%' } :
                            { md: 'calc(100vw - 3rem) !important' }
                    }}
                >
                    <ScrollMenu
                        LeftArrow={items?.length > 0 ? LeftArrow : null}
                        RightArrow={items?.length > 0 ? RightArrow : null}
                        onWheel={onWheel}
                    //     onMouseDown={() => dragStart}
                    //     onMouseUp={({
                    //         getItemById,
                    //         scrollToItem,
                    //         visibleItems
                    //     }) => () => {
                    //         // NOTE: for center items
                    //         dragStop();
                    //         const { center } = getItemsPos(visibleItems);
                    //         scrollToItem(getItemById(center), "smooth", "center");
                    //     }}
                    //     options={{ throttle: 0 }} // NOTE: for center items
                    // // onMouseMove={handleDrag}
                    >
                        {items && items.map((item, index) => (
                            renderCard(item, index)
                        ))}
                    </ScrollMenu>
                </Box>
            </div>
        </>
    );
}

const onWheel = ({ getItemById, items, visibleItems, scrollToItem }, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        // NOTE: for center items
        const nextGroupItems = slidingWindow(
            items.toItemsKeys(),
            visibleItems
        ).next();
        const { center } = getItemsPos(nextGroupItems);
        scrollToItem(getItemById(center), "smooth", "center");
    } else if (ev.deltaY > 0) {
        const prevGroupItems = slidingWindow(
            items.toItemsKeys(),
            visibleItems
        ).prev();
        const { center } = getItemsPos(prevGroupItems);
        scrollToItem(getItemById(center), "smooth", "center");
    }
}

export default HorizontalScrollingCards;
