import { GROUP_REGISTRATION_FORM_API } from "../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const getGroupForms = ({ groupId, member_uuid }) => {
    if (member_uuid) {
        return axiosInstance.get(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM_WITHOUT_LOGIN.replace(':group_id', groupId)}?uuid=${member_uuid}`);
    }
    return axiosInstance.get(GROUP_REGISTRATION_FORM_API.GROUP_FORM.replace(':group_id', groupId));
}

export const getGroupForm = ({ groupId, formId }) => {
    return axiosInstance.get(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM.replace(':group_id', groupId)}/${formId}`);
}

export const createGroupForm = (payload, groupId) => {
    return axiosInstance.post(GROUP_REGISTRATION_FORM_API.GROUP_FORM.replace(':group_id', groupId), payload);
}

export const deleteGroupForm = (groupId, formId) => {
    return axiosInstance.delete(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM.replace(':group_id', groupId)}/${formId}`);
}

export const bulkDeleteGroupFormFields = (groupId, payload) => {
    return axiosInstance.post(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM_FIELDS
        .replace(':group_id', groupId)}/bulkdelete`, payload);
}

export const insertOrUpdateGroupFormFields = (groupId, formId, payload) => {
    return axiosInstance.post(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM_FIELDS
        .replace(':group_id', groupId)}/${formId}/insertorupdate`, payload);
}

export const getGroupFormReplies = (groupId) => {
    return axiosInstance.get(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM_ANSWERS.replace(':group_id', groupId)}`);
}

export const downloadGroupFormReplies = (groupId) => {
    return axiosInstance.get(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM_ANSWERS_DOWNLOAD.replace(':group_id', groupId)}`);
}

export const submitGroupFormAnswers = (groupId, payload) => {
    return axiosInstance.post(`${GROUP_REGISTRATION_FORM_API.GROUP_FORM_ANSWERS.replace(':group_id', groupId)}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}