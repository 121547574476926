import { Box, DialogContent, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { EventSettingsContext } from "../..";
import NoRepliesGif from "../../../../../../../../assets/no-replies.gif";
import { downloadEventCheckIns, getCheckInRecords, getTotalCheckInCount } from "../../../../../../../../services/wEvents/eventGuests";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import moment from 'moment';
import { toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from "@mui/icons-material/Info";
import TicketCheckInsDetailsDialog from "./TicketCheckInsDetailsDialog";
import CheckInRecordsWithoutTickets from "./CheckInRecordsWithoutTickets";

const EventCheckInRecords = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { per_page_records, totalCheckInRecords, checkInRecords, setCheckInRecords, checkedInTickets, setTotalCheckInCount } = useContext(EventSettingsContext);
    const eventData = useSelector(eventDetails);
    const [activeTicketId, setActiveTicketId] = useState(null);
    
    const [viewDialog, setViewDialog] = useState(false);

    const handleEventCheckInSumaryDetails = (ticketId) => {
        getTotalCheckInCount({ eventId: eventData?.id, ticketId: ticketId }).then((res) => {
            setTotalCheckInCount(res.data)
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleTicketCheckInDetails = (ticketId) => {
        setViewDialog(true);
        getCheckInRecords({ eventId: eventData?.id, ticketId: ticketId, page: 1 }).then((res) => {
            console.log("res", res.data);
            setCheckInRecords(res.data.data);
            totalCheckInRecords.current = res.data.total
            per_page_records.current = res.data.per_page
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleCheckInsDownload = (ticketId, ticketName) => {
        downloadEventCheckIns(eventData?.id, ticketId).then((result) => {
            console.log(result);
            const fileBlob = new Blob([result.data], { type: 'text/csv' });
            const downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            const fileUrl = URL.createObjectURL(fileBlob);

            // Create a link to download the file
            downloadLink.href = fileUrl;
            downloadLink.download = `${eventData.title}-${ticketName}-Check In Records-${moment().format('D-M-YYYY HH-mm')}.csv`;
            // Add the link to the DOM and click it
            downloadLink.click();

            // Clean up the URL object
            URL.revokeObjectURL(fileUrl);
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        });
    }

    return (
        <>
            {
                checkedInTickets ? 
                    <>
                        {checkedInTickets.length === 0 ? (
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ mt: 5 }}>
                                <Box
                                    component="img"
                                    src={NoRepliesGif}
                                    width={isSmallDevice ? "90%" : "60% !important"}
                                    height={isSmallDevice ? "15rem" : "17rem"}
                                />
                            </Stack>    
                        ) :
                            <>
                                <DialogContent dividers sx={{
                                    p: { xs: '0rem', md: '0rem' },
                                    height: { xs: '26.5rem', md: '31.5rem' }
                                }}
                                >
                                    <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                                        <TableContainer sx={{
                                            border: '1px solid lightgrey',
                                            maxWidth: { xs: '100%', md: '90%'}, margin: '1rem auto !important'
                                        }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow hover={true}>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            borderRight: '1px solid #ccc',
                                                            p: { xs: '0.5rem', md: '0.7rem' }
                                                        }}>Id</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            borderRight: '1px solid #ccc',
                                                            p: { xs: '0.5rem', md: '0.7rem' },
                                                            width: '75%'
                                                        }}>Ticket Name</TableCell>
                                                        <TableCell align="center" sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            borderRight: '1px solid #ccc',
                                                            p: { xs: '0.5rem', md: '0.7rem' }
                                                        }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {checkedInTickets.map((row, index) => (
                                                        <TableRow hover={true} key={row.ticket_id}>
                                                            <TableCell sx={{
                                                                textAlign: 'center',
                                                                p: { xs: '0.5rem', md: '0.7rem' },
                                                                borderRight: '1px solid #ccc'
                                                            }}>
                                                                {index+1}
                                                            </TableCell>
                                                            <TableCell sx={{
                                                                textAlign: 'left',
                                                                p: { xs: '0.5rem', md: '0.7rem' },
                                                                borderRight: '1px solid #ccc'
                                                            }}>
                                                                {row.ticket_name}
                                                            </TableCell>
                                                            <TableCell sx={{
                                                                p: { xs: '0.5rem', md: '0.7rem' },
                                                                borderRight: '1px solid #ccc'
                                                            }}>
                                                                <Stack direction='row' alignItems='center'>
                                                                    <Tooltip title="View" arrow>
                                                                        <IconButton onClick={() => {
                                                                            setActiveTicketId(row.ticket_id)
                                                                            handleTicketCheckInDetails(row.ticket_id);
                                                                            handleEventCheckInSumaryDetails(row.ticket_id);
                                                                        }}>
                                                                            <InfoIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton onClick={() => handleCheckInsDownload(row.ticket_id, row.ticket_name)}>
                                                                            <DownloadIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </DialogContent>
                            </>
                        }
                    </> :
                    (checkInRecords ?
                        <CheckInRecordsWithoutTickets /> : 
                    <Skeleton sx={{
                            mx: '0.5rem !important',
                            p: '0rem !important',
                            transform: 'scale(1,2)'
                        }}
                        height='7rem'
                        animation="wave"
                    />)
            }
            {viewDialog ? <TicketCheckInsDetailsDialog activeTicketId={activeTicketId} setViewDialog={setViewDialog} /> : null}
        </>
    )
};

export default EventCheckInRecords;
