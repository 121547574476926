import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const SearchGroup = () => {
    const handleChange = (event, newValue) => {
        console.log("Okay", newValue);
    };

    return (
        <>
            <Autocomplete
                freeSolo
                disableClearable
                disablePortal
                options={[]}
                size="small"
                onInputChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Groups"
                        InputProps={{
                            className: "MuiAutocomplete-inputRoot",
                            type: "search",
                        }}
                    />
                )}
            />
        </>
    )
};

export default SearchGroup;
