import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import pluralize from 'pluralize';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventMembers } from "../../../../../../../../services/wEvents/eventDetails";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import EditEventViewTitleDialog from '../../EditEventViewTitleDialog';
import HorizontalScrollingCards from "../HorizontalScrollMenu";
import CreateMemberDialog from "./components/CreateMemberDialog";

export const EventKeyMembersContext = React.createContext({});

const KeyMembersSection = ({ viewId, title, addDivider }) => {
    const [dialog, setDialog] = useState({
        add: false,
        editTitle: false
    });
    const handleEdit = () => {
        setDialog({ ...dialog, editTitle: true });
    }
    const handleAdd = () => {
        document.body.classList.add("no-scroll");
        setDialog({ ...dialog, add: true });
    }
    const handleClose = (operation) => {
        document.body.classList.remove("no-scroll");
        setDialog({ ...dialog, [operation]: false });
    }

    const [dataItems, setItems] = useState([])
    const eventData = useSelector(eventDetails)
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    useEffect(() => {
        getEventMembers({ eventId: eventData?.id, viewId: viewId }).then((res) => {
            const eventMembers = res.data.data
            setItems(eventMembers)
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <EventKeyMembersContext.Provider value={{ viewId, title }}>
            {(isAllPermission ? true : dataItems?.length > 0) ?
                <Box>
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ pb: 0.5 }}>
                        <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{title}</Typography>
                        {isAllPermission ?
                            <IconButton color='warning' onClick={handleEdit}
                                sx={{ ":hover": { backgroundColor: 'white' } }}>
                                <EditIcon sx={{ width: '1.25rem' }} />
                            </IconButton>
                            : null
                        }
                        {dialog.editTitle ?
                            <EditEventViewTitleDialog
                                viewId={viewId}
                                viewTitle={title}
                                handleClose={() => handleClose('editTitle')} /> : null
                        }
                    </Stack>
                    <HorizontalScrollingCards category='KeyMembers' items={dataItems} setItems={setItems} />
                    {isAllPermission ?
                        <Box>
                            <Button variant="text" color="warning" onClick={handleAdd} sx={{
                                textAlign: 'left', '&:hover': {
                                    background: 'none',
                                },
                            }}>
                                + Add {pluralize.singular(title)}
                            </Button>
                            {dialog.add ? <CreateMemberDialog
                                handleClose={() => handleClose('add')} setItems={setItems} /> : null
                            }
                        </Box> : null
                    }
                </Box>
                : null
            }
            {(addDivider && (isAllPermission ? true : dataItems?.length > 0)) ? <Divider sx={{ my: 2 }} /> : null}
        </EventKeyMembersContext.Provider>
    )
};

export default KeyMembersSection;
