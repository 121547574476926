import React from 'react'
import { useState, useEffect } from 'react';
import Splash from './components/splash';
import Onboarding from './components/onboarding';

function Main() {

    const [splash, setSplash] = useState(true)
    
    useEffect(() => {
        setTimeout(() => {
            setSplash(false);
        }, 1000);
    }, [])
    
    return (
        <>
            {splash ? <Splash /> : <Onboarding />}
        </>
    )
}

export default Main