import { Pagination, Stack } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { getEvents } from "../../../../../services/wEvents/event";

const EventsPagination = ({ eventsCount, perPageEvents, setEvents }) => {
    const handleChange = (event, value) => {
        getEvents({ page: value }).then((res) => {
            console.log(res.data);
            setEvents(res?.data.data)
        }).catch((err) => {
            console.log('Error: ', err);
            toast.error("Something went wrong in fetching events", {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    return (
        <>
            <Stack sx={{ p: 2, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination
                    color="warning"
                    variant="outlined"
                    count={Math.ceil(eventsCount / perPageEvents)}
                    onChange={handleChange}
                />
            </Stack>
        </>
    )
};

export default EventsPagination;
