import { Box } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

const QrGenerator = ({ eventId, guestUuid }) => {
    return (
        <Box style={{
            height: "auto",
            margin: "0 auto",
            padding: '3rem',
            maxWidth: '20rem',
        }}>
            <QRCode
                size={500}
                value={eventId ? `${eventId}` : `${guestUuid}`}
                style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100%",
                }}
            />
        </Box>
    )
};

export default QrGenerator;
