import React, { useContext } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import noGuests from '../../../../../../../../assets/NoGuests.webp'
import { SubstituteEventGuestsContext } from "../../SubstituteEventGuestsContext";
import SubstituteGuestBox from "./SubstituteGuestBox";

const SubstituteGuestList = () => {
    const { guests } = useContext(SubstituteEventGuestsContext);
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    return (
        <>
            {guests?.length === 0 ?
                <Box sx={{ textAlign: 'center' }}>
                    <br />
                    <Box
                        component='img'
                        src={noGuests}
                        width={isSmallDevice ? '100%' : '30rem'}
                        height='25rem'
                    />
                </Box> :
                <>
                    <Grid
                        container
                        spacing={{ xs: 0, md: 3 }}
                        sx={{ px: '0.5rem', height: 'auto', maxHeight: '20rem', overflow: 'auto' }}
                    >
                        {(guests ?? Array.from(new Array(6))).map((g, index) => (
                            <SubstituteGuestBox key={index} guestDetails={g} />
                        ))}
                    </Grid>
                </>
            }
        </>
    )
};

export default SubstituteGuestList;
