import React from "react";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Typography, Stack } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { sendOtp } from "../../../../../services/Authentication/user";

const ResendOtp = ({ reset, user_data }) => {
    const [counter, setCounter] = useState(30);
    const [loading, setLoading] = useState(false);

    const callSendOTP = () => {
        setLoading(true);
        sendOtp({
            dialing_code: user_data?.CountryCode,
            mobile: user_data?.MobileNumber,
            otp_method: user_data?.otp_method
        }).then((res) => {
            console.log('------------>', res);
            setLoading(false);
            toast.info("New OTP has been sent!", {
                position: "top-right",
                theme: "dark"
            });
            setCounter(30);
        }).catch((err) => {
            console.log("$$$$$$$$", err);
            setLoading(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
            setCounter(30);
        })
        reset();
    }

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <>
            <Stack
                direction="row"
                justifyContent={(counter > 0) ? "space-between" : 'center'}
                alignItems="center"
                sx={{ my: 3 }}
            >
                <LoadingButton
                    loading={loading}
                    loadingPosition={loading ? "start" : null}
                    startIcon={loading ? <SaveIcon /> : ''}
                    disabled={counter !== 0}
                    onClick={callSendOTP}
                    sx={{ color: "#FF8359" }}
                >
                    <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>Resend OTP</Typography>
                </LoadingButton>
                <Typography variant="h5">
                    {(counter > 0) ? `00:${String(counter).padStart(2, "0")}` : null}
                </Typography>
            </Stack>
        </>
    );
};

export default ResendOtp;
