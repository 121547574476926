import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupAlbums } from "../../../../../../../../services/wGroups/groupDetails";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import HorizontalScrollingCards from "../../../../../../../EventDetails/components/Menubar/components/SubEvents/components/HorizontalScrollMenu";
import CreateGalleryDialog from "./components/CreateGalleryDialog";

const GroupGallery = () => {
    const groupData = useSelector(groupDetails);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(groupData?.current_user_role);
    const [dataItems, setItems] = useState([]);

    // Kept here just to update total num. of photos on uploading photos
    const handleAlbumMedia = (albumId, apiResponse, { isDeleted }) => {
        const findItem = (item) => {
            return item.id === albumId
        }
        setItems((items) => {
            let index = items.findIndex(findItem)
            isDeleted ? items[index].photos -= 1 : items[index].photos += apiResponse.length
            return [...items];
        })
    }

    useEffect(() => {
        getGroupAlbums({ groupId: groupData?.id }).then((res) => {
            const eventAlbums = res.data.data
            setItems(eventAlbums)
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    let [open, setOpen] = useState(false);
    const handleAdd = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    return (
        <>
            {(isAllPermission ? true : dataItems.length > 0) ?
                <Box>
                    <Typography sx={{ mb: 1, color: '#1F274A', fontWeight: 'bold' }}>Gallery</Typography>
                    <HorizontalScrollingCards
                        category='GroupAlbums'
                        items={dataItems}
                        setItems={setItems}
                        handleAlbumMedia={handleAlbumMedia}
                    />
                    {isAllPermission ?
                        <Box>
                            <Button variant="text" color="warning" onClick={handleAdd} sx={{
                                textAlign: 'left', '&:hover': {
                                    background: 'none',
                                },
                            }}>
                                + Create Album
                            </Button>
                            {open ? <CreateGalleryDialog
                                handleClose={handleClose}
                                setItems={setItems} />
                                : null
                            }
                        </Box> : null
                    }
                </Box> : null
            }

            {(isAllPermission ? true : dataItems.length > 0) ? <Divider sx={{ my: 1.5 }} /> : null}

        </>
    )
};

export default GroupGallery;
