import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../../../components/DialogBox";
import { groupDetails } from "../../../../../../redux/slices/groupDetailSlice";
import { deleteGroupEvent } from "../../../../../../services/wGroups/groupDetails";

const DeleteProgramContent = (handleClose, setItems, groupEventDetails) => {
    const {
        handleSubmit,
    } = useForm();

    const groupData = useSelector(groupDetails);
    const [loading, setLoading] = useState(false);

    const mySubmit = () => {
        setLoading(true);
        deleteGroupEvent(groupEventDetails?.id).then(() => {
            setItems((items) => {
                let newItems = items.filter((i) => i.id !== groupEventDetails?.id)
                return newItems;
            })
            setLoading(false);
            toast.success("Group event deleted", {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <form onSubmit={handleSubmit(mySubmit)} >
                <Stack spacing={3}>
                    <Typography>Are you sure you want to delete this event from {groupData?.title} group ?</Typography>
                    <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                        <Button variant="outlined" color='warning' onClick={handleClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="warning"
                            variant="contained"
                            startIcon={loading ? <SaveIcon /> : ''}
                            loadingPosition={loading ? "start" : null}
                            loading={loading ? true : false}
                        >
                            Ok
                        </LoadingButton>
                    </DialogActions>
                </Stack>
            </form>
        </>
    )
}


const DeleteGroupEvent = ({ handleClose, groupEventDetails, setItems }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Delete Program' content={DeleteProgramContent(handleClose, setItems, groupEventDetails)}
        />
    )
};

export default DeleteGroupEvent;
