import React, { useContext } from "react";
import { CreateGroupContext } from "../..";
import { Box, Typography, Stack, TextField, InputAdornment } from "@mui/material";
import GroupRadioOptions from "./GroupRadioOptions";
import GroupImage from "./GroupImage";
import GroupDatePicker from "./GroupDatePicker";

const GroupInfo = () => {
    const { formData, setFormData, fieldErrors, setFieldErrors } = useContext(CreateGroupContext);
    const handleGroupTextField = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        if (e.target.value === '') {
            setFieldErrors({ ...fieldErrors, [e.target.name]: true })
        } else {
            setFieldErrors({ ...fieldErrors, [e.target.name]: false })
        }
    }
    const changeYear = (field, selectedYear) => {
        setFormData({ ...formData, [field]: selectedYear })
    }

    React.useEffect(() => {
        return (() => {
            for (let key in fieldErrors) {
                fieldErrors[key] = false
            }
            setFieldErrors({ ...fieldErrors })
        })
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: { xs: '4rem', sm: '5rem' } }}>
                <Box sx={{ borderRadius: '10px', boxShadow: '0px 0px 25px #ccc', width: { xs: '95%', sm: '65%', lg: '50%' }, mt: { xs: 1, sm: 4 } }}>
                    <Stack sx={{ m: { xs: 1, sm: 2 } }} spacing={1}>
                        <Typography sx={{ mt: 1, ml: { xs: 1, sm: 0 }, fontSize: { xs: '1rem', sm: '1.5rem' } }}>
                            {formData.category} Details
                        </Typography>
                        <Stack spacing={2} sx={{ pt: 1 }}>
                            <TextField
                                required
                                label={formData.category + " organization name"}
                                color="warning"
                                name="title"
                                value={formData?.title}
                                onChange={handleGroupTextField}
                                error={fieldErrors?.title}
                                helperText={fieldErrors?.title ? 'This is required field' : null}
                                inputProps={{
                                    maxLength: 30
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Typography>{formData?.title.length}/30</Typography>
                                    </InputAdornment>
                                }}
                            />
                            <TextField
                                required
                                multiline
                                rows={3}
                                label="About organization"
                                color="warning"
                                name="description"
                                value={formData?.description}
                                onChange={handleGroupTextField}
                                error={fieldErrors?.description}
                                helperText={fieldErrors?.description ? 'This is required field' : null}
                            />

                            <GroupDatePicker changeYear={changeYear} />

                            <TextField
                                required
                                label="City"
                                color="warning"
                                name="city"
                                value={formData?.city}
                                onChange={handleGroupTextField}
                                error={fieldErrors?.city}
                                helperText={fieldErrors?.city ? 'This is required field' : null}
                            />
                            <TextField
                                required
                                label="State"
                                color="warning"
                                name="state"
                                value={formData?.state}
                                onChange={handleGroupTextField}
                                error={fieldErrors?.state}
                                helperText={fieldErrors?.state ? 'This is required field' : null}
                            />
                            <TextField
                                required
                                label="Country"
                                color="warning"
                                name="country"
                                value={formData?.country}
                                onChange={handleGroupTextField}
                                error={fieldErrors?.country}
                                helperText={fieldErrors?.country ? 'This is required field' : null}
                            />

                            <GroupRadioOptions />
                            <GroupImage />
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>
    )
};

export default GroupInfo;
