import { Button, Typography } from "@mui/material";
import React from "react";
import { CreateGroupContext } from "../..";

const GroupBox = ({ group, activeId, onSelect }) => {
    const { formData } = React.useContext(CreateGroupContext)
    return (
        <>
            <Button
                onClick={() => onSelect(group)}
                sx={{
                    p: { xs: '0.5rem', sm: '0.75rem' },
                    mr: 2,
                    color: 'black',
                    border: (activeId === group?.id || formData.category_id === group?.id) ? '2px solid #FF8359' : '2px solid #E5E4E9',
                    borderRadius: '4px',
                    ":hover": {
                        transform: 'scale(1.05)',
                        backgroundColor: '#FF8359',
                        border: '2px solid #FF8359'
                    },
                    transition: 'transform 0.3s, background-color 0.1s ease-in-out',
                    backgroundColor: (activeId === group?.id || formData.category_id === group?.id) ? "#FF8359" : "transparent"
                }}>
                <Typography sx={{ fontSize: { xs: '12px', sm: '1rem' }, textAlign: 'center' }}>
                    {group.title}
                </Typography>
            </Button>
        </>
    );
};

export default GroupBox;
