import { useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import { eventDetails } from "../../../../../../redux/slices/eventDataSlice";
import { getGuestDetails, getSubstituteEventGuests } from "../../../../../../services/wEvents/eventGuests";
import SubstituteGuestHeader from "./components/SubstituteGuestHeader";
import LoginDialog from "../../../../../../components/LoginDialog";
import { userStatus } from "../../../../../../redux/slices/userDataSlice";
import { useParams } from "react-router-dom";
import { SubstituteEventGuestsContext } from "./SubstituteEventGuestsContext";
import SubstituteGuestList from "./components/SubstituteGuestList";

const SubstituteGuests = () => {
    const eventData = useSelector(eventDetails)
    let totalGuests = useRef(null);
    const { commonEventTitle } = useParams();
    const isLoggedIn = useSelector(userStatus);

    const [guests, setGuests] = useState(null)
    const [maxValue, setMaxValue] = useState(null);

    const [loginDialog, setLoginDialog] = useState(false);
    const [guestData, setGuestData] = useState(null);
    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    useEffect(() => { //Separate use-effect for stop re-rendering of dialog state without login
        if (!isLoggedIn && !commonEventTitle) {
            getGuestDetails({ eventId: eventData?.id, uuid: eventData?.guest_uuid }).then((result) => {
                setLoginDialog(true);
                setGuestData(result.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            getSubstituteEventGuests({ eventId: eventData?.id, guestId: eventData?.guest_id }).then((res) => {
                totalGuests.current = res.data.data.length;
                setGuests(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [isLoggedIn]);

    return (
        <>
            {
                isLoggedIn &&
                    <SubstituteEventGuestsContext.Provider value={{
                        guests, setGuests, totalGuests, maxValue, setMaxValue
                    }}>
                        <Stack sx={{ position: 'relative' }}>
                            <SubstituteGuestHeader />
                            <Divider sx={{ my: 1 }} />
                            <SubstituteGuestList />
                            <Box id='container'></Box>
                        </Stack>
                    </SubstituteEventGuestsContext.Provider>
            }

            {loginDialog ? <LoginDialog open={true}
                handleClose={() => handleDialogClose('login')} guestData={guestData} /> : null}
        </>
    );
};

export default SubstituteGuests;
