import './global-components.scss';
import React from "react";
import { Button, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";

const GradientBtn = ({ label, redirectPath }) => {
    const navigate = useNavigate();

    const fabStyle = {
        position: 'fixed',
        left: '50%',
        bottom: '1rem',
        transform: 'translate(-50%,0%)',
        backgroundImage: 'linear-gradient(#FF9966, #FF5E62)',
        // backdropFilter: 'blur(10px)',
        display: { sm: 'none' }
    };
    const fab = {
        sx: fabStyle,
        icon: <AddIcon />,
    };

    return (
        <>
            <Button sx={{ display: { xs: 'none', sm: 'inline-block' } }} className="gradientBtn"
                onClick={() => navigate(`/${redirectPath}`)}>
                {label}
            </Button>
            <Fab sx={fab.sx} onClick={() => navigate(`/${redirectPath}`)}>
                {fab.icon}
            </Fab>
        </>
    );
};

export default GradientBtn;
