import { Tab, Tabs, tabsClasses } from '@mui/material';
import React from 'react';
import MuiTabPanel from '../../../../../../components/muiTabPanel';
import MemberList from './components/MemberList';

const GroupMembersTabs = ({ tabValue, handleTabChange }) => {
    const memberTabs = ['Invited', 'Joined'];
    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#FF8359"
                    }
                }}
                sx={{
                    // flexGrow: 1,
                    backgroundColor: '#FFEBE4',
                    // maxWidth: { sm: '100%' },
                    [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 }
                    },
                    '.Mui-selected': {
                        fontWeight: "bold !important",
                        color: '#FF8359 !important',
                        opacity: '1 !important'
                    },
                    '.MuiTabs-scrollButtons': {
                        display: { sm: 'none !important' },
                    }
                }}
            >
                {memberTabs.map((tab, index) => (
                    <Tab key={index} label={tab} sx={{
                        color: '#FF8359',
                        opacity: '0.6',
                        width: { xs: '50%', md: 'auto' },
                        textTransform: 'none',
                    }} />
                )
                )}
            </Tabs>

            {memberTabs.map((tab, index) => (
                <MuiTabPanel key={index} value={tabValue} index={index}>
                    <MemberList />
                    {/* This will render relative component based on event API response */}
                </MuiTabPanel>
            ))}

        </>
    )
}

export default GroupMembersTabs