import { Pagination, Stack } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { getGroups } from "../../../../../services/wGroups/group";

const GroupsPagination = ({ groupsCount, perPageGroups, setGroups }) => {
    const handleChange = (event, value) => {
        getGroups({ page: value }).then((res) => {
            console.log(res.data);
            setGroups(res?.data.data);
        }).catch((err) => {
            console.log('Error: ', err);
            toast.error("Something went wrong in fetching groups", {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    return (
        <>
            <Stack sx={{ p: 2, justifyContent: 'center', alignItems: 'center' }}>
                <Pagination
                    color="warning"
                    variant="outlined"
                    count={Math.ceil(groupsCount / perPageGroups)}
                    onChange={handleChange}
                />
            </Stack>
        </>
    )
};

export default GroupsPagination;
