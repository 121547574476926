import { Grid } from "@mui/material";
import React from "react";
import GroupBasicDetails from "./GroupBasicDetails";
import GroupLogoUpload from "./GroupLogoUpload";

const GroupDetailCards = () => {
    return (
        <Grid container alignItems="center" justifyContent={{ sm: 'space-between', lg: 'space-around', }} sx={{ mt: { xs: 0, sm: 3 }, px: { xs: 0, sm: 2 } }}>
            <Grid item xs={12} sm={4.5} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <GroupLogoUpload />
            </Grid>

            <Grid item xs={12} sm={7} lg={6.5} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mt: { xs: 0, sm: 0 } }}>
                <GroupBasicDetails />
            </Grid>
        </Grid>
    )
};

export default GroupDetailCards;
