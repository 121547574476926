import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { getGroupKeyPersons } from "../../../../../../../../services/wGroups/groupDetails";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import HorizontalScrollingCards from "../../../../../../../EventDetails/components/Menubar/components/SubEvents/components/HorizontalScrollMenu";
import CreateKeyPersonDialog from "./components/CreateKeyPersonDialog";

const GroupKeyPersons = () => {
    const groupData = useSelector(groupDetails);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(groupData?.current_user_role);
    const [dataItems, setItems] = useState([])

    useEffect(() => {
        getGroupKeyPersons({ groupId: groupData?.id }).then((res) => {
            setItems(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    let [open, setOpen] = useState(false);
    const handleAdd = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    return (
        <>
            {(isAllPermission ? true : dataItems?.length > 0) ?
                <Box>
                    <Typography sx={{ mb: 1, color: '#1F274A', fontWeight: 'bold' }}>Key Persons</Typography>
                    <HorizontalScrollingCards category='GroupKeyPersons' items={dataItems} setItems={setItems} />
                    {isAllPermission ?
                        <Box>
                            <Button variant="text" color="warning" onClick={handleAdd} sx={{
                                textAlign: 'left', '&:hover': {
                                    background: 'none',
                                },
                            }}>
                                + Add Person
                            </Button>
                            {open ? <CreateKeyPersonDialog handleClose={handleClose} setItems={setItems} /> : null}
                        </Box> : null
                    }
                </Box> : null
            }

            {(isAllPermission ? true : dataItems?.length > 0) ? <Divider sx={{ my: 1.5 }} /> : null}

        </>
    )
};

export default GroupKeyPersons;
