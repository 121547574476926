import _ from "lodash";
import React from "react";
import { Stack, Typography, Divider, Box } from "@mui/material";

const CostBreakdown = ({ model, watch, charges, actualPrice, gstPercent, priceSymbol, modelObject }) => {
    const gstOnTicketPrice = gstPercent ? _.round((gstPercent / 100) * actualPrice, 2) : 0;
    return (
        <>
            <Box sx={{ width: { xs: '100%', sm: '75%', lg: '65%' }, margin: '1rem auto !important' }}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Cost Breakdown Structure</Typography>
                <Stack sx={{ border: '2px solid lightgrey', borderRadius: '0.5rem', p: 2, mt: 1 }}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>{model} Price</Typography>
                        <Typography>{actualPrice} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                    </Stack>
                    {(watch ? (watch('currency') === 'rupees' && gstPercent !== 0) : (modelObject.currency === 'rupees' && gstPercent !== 0)) ?
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography>GST on {model} Price</Typography>
                            <Typography>{gstOnTicketPrice} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                        </Stack>
                        : null
                    }
                    <Divider sx={{ my: 1 }} />
                    <Typography sx={{ color: '#6F738B', fontSize: '.9rem' }}>Other Charges</Typography>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>System Handling Charges</Typography>
                        <Typography>{charges.systemFee} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>Razar Pay Fee</Typography>
                        <Typography>{charges.razorPayFee} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                    </Stack>

                    <Divider sx={{ my: 1 }} />

                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>Buyer will pay</Typography>
                        <Typography>
                            {
                                watch ? (watch('extra_charges_from_buyer') ?
                                    _.round(actualPrice + gstOnTicketPrice + charges.razorPayFee + charges.systemFee, 2)
                                    : _.round(actualPrice + gstOnTicketPrice, 2)
                                ) : modelObject.purchase_price
                            } <span dangerouslySetInnerHTML={{ __html: priceSymbol }} />
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>You will get</Typography>
                        <Typography>
                            {
                                watch ? (watch('extra_charges_from_buyer') ?
                                    _.round(actualPrice + gstOnTicketPrice, 2) :
                                    _.round((actualPrice + gstOnTicketPrice) - charges.razorPayFee - charges.systemFee, 2)
                                ) : modelObject.amount_to_owner
                            } <span dangerouslySetInnerHTML={{ __html: priceSymbol }} />
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </>
    )
};

export default CostBreakdown;
