import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import CheckInvitedGuestsDialog from "./CheckInvitedGuestsDialog";
import ReviewInviteMsgDialog from "./ReviewInviteMsgDialog";

const SendNowConfirmation = ({ loading, sendInvite, guestsToInvite, handleCloseConfirmation }) => {
    const isBigDevices = useMediaQuery("(min-width:600px)");
    const [dialog, setDialog] = useState({
        checkGuests: false,
        reviewMsg: false
    });
    const handleClickOpen = (operation) => {
        setDialog({ ...dialog, [operation]: true });
    };
    const handleClose = (operation) => {
        setDialog({ ...dialog, [operation]: false });
    };

    return (
        <>
            <Box sx={{ px: 2, py: 1 }}>
                <Typography sx={{ textAlign: 'left', py: '0.75rem' }}>Are you sure want to send invitation to {guestsToInvite} guests?</Typography>
                <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ my: 1, p: 1 }}>
                    {isBigDevices ? <Button size='small' color='warning' variant='outlined'
                        onClick={handleCloseConfirmation}>
                        Cancel
                    </Button> : null}
                    <Button size='small' color='warning' variant='outlined' onClick={() => handleClickOpen('checkGuests')}>Check Guests</Button>
                    <Button size='small' color='warning' variant='outlined' onClick={() => handleClickOpen("reviewMsg")}>Review Message</Button>
                    <LoadingButton
                        type="submit"
                        size='small'
                        color="warning"
                        variant='contained'
                        loading={loading}
                        onClick={() => sendInvite('sendNow')}
                        startIcon={loading ? <SaveIcon /> : ''}
                        loadingPosition={loading ? "start" : null}
                    >
                        {loading ? 'Sending...' : "Yes I'm ready to send"}
                    </LoadingButton>
                </Stack>
            </Box>
            {dialog.checkGuests ? <CheckInvitedGuestsDialog handleClose={handleClose} /> : null}
            {dialog.reviewMsg ? <ReviewInviteMsgDialog handleClose={handleClose} /> : null}
        </>
    )
}

export default SendNowConfirmation;
