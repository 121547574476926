import React from "react";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, TextField, Typography } from "@mui/material";

const ResetDocConfirmationDialog = ({ handleClose, onConfirmReset }) => {
    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '50%' },
                        m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '70%' },
                    }
                }}
            >
                <DialogContent sx={{ p: 2 }}>
                    <Typography sx={{ fontSize: '1.05rem' }}>You will lose all the changes, Do you want to continue?</Typography>
                </DialogContent>
                <DialogActions sx={{ pr: 1 }}>
                    <Button color='warning' variant="contained" onClick={onConfirmReset}
                        sx={{ boxShadow: 'none' }}>
                        Yes
                    </Button>
                    <Button variant="outlined" color='warning' onClick={handleClose}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default ResetDocConfirmationDialog;
