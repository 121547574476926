import * as React from "react";
import DashboardTabs from "./components/DashboardTabs";

const Dashboard = () => {
    return (
        <>
            <DashboardTabs />
        </>
    )
};

export default Dashboard;
