import { Avatar, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { stringAvatar } from "../../../../../../../../utils/functions/muiAvatar";
import SubstituteGuestDialog from "./SubstituteGuestDialog";

const SubstituteGuestBox = ({ guestDetails }) => {
    let [open, setOpen] = useState(false);
    const handleOpen = () => {
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setOpen(false);
    }

    return (
        <>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            p: 1,
                            ":hover": {
                                cursor: "pointer",
                                backgroundColor: "#F1F1F1"
                            }
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={1.25}
                            onClick={guestDetails ? handleOpen : null}
                            sx={{ flex: 1 }}
                        >
                            {guestDetails ?
                                <GuestAvatar name={guestDetails.name || ''} />
                                : <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            }
                            {guestDetails ?
                                <Typography sx={{ flex: 1 }}>{guestDetails?.name}</Typography>
                                : <Skeleton animation="wave" variant="rectangular" width='80%' height='2rem' />
                            }
                        </Stack>
                    </Stack>
                </Box>
                {open ? //To stop multiple renders as num. of items in list
                    <SubstituteGuestDialog guestDetails={guestDetails} handleClose={() => handleClose()} />
                    : null
                }
            </Grid>
        </>
    )
};

const GuestAvatar = memo(({ name }) => {
    return (
        <Avatar {...stringAvatar(name.trim())} />
    )
})

export default SubstituteGuestBox;
