import ls from 'local-storage'
import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: 'Sidebar',
    initialState: {
        isSidebarOpen: true,
    },
    reducers: {
        change_sidebar_status: (state, action) => {
            state.isSidebarOpen = action.payload
        }
    }
})

export const { change_sidebar_status } = sidebarSlice.actions

export const sidebarStatus = (state) => state.Sidebar.isSidebarOpen

export default sidebarSlice.reducer