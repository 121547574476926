import { createSlice } from "@reduxjs/toolkit";

export const groupDetailSlice = createSlice({
    name: 'Group',
    initialState: {
        groupDetails: {},
    },
    reducers: {
        update_group_details: (state, action) => {
            state.groupDetails = action.payload
        },
        clear_group_details: (state, action) => {
            state.groupDetails = {}
        }
    }
})

export const { update_group_details, clear_group_details } = groupDetailSlice.actions

export const groupDetails = (state) => state.Group.groupDetails

export default groupDetailSlice.reducer