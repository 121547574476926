import SaveIcon from '@mui/icons-material/Save';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from "@mui/lab";
import { Chip, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from '../../../../../../../../../assets/WowslyLogo.png';
import { eventDetails } from '../../../../../../../../../redux/slices/eventDataSlice';
import { userDetails } from "../../../../../../../../../redux/slices/userDataSlice";
import { createOrder } from "../../../../../../../../../services/Payment/paymentGateway";
import { ticketCurrencies } from '../../../../../../../../../utils/constants/ticketCurrency';
import { loadScript } from "../../../../../../../../../utils/functions/loadScript";
import { EventGuestsContext } from "../../../EventGuestContext";
import { razorPayModalConfig } from '../../../../../../../../../utils/constants/razorPayModalConfig';

const InvitationPayment = ({ totalAmountToPay, amountCurrency, gst_charges, extra_charges,
    total_guests, guests_for_email }) => {
    const razorPayCurrency = ticketCurrencies.find((t) => t.value === amountCurrency)?.currency;
    const theme = useTheme();
    const userData = useSelector(userDetails);
    const eventData = useSelector(eventDetails);
    const { inviteMsg, setInviteMsg, paymentDone, setPayment, setPaymentId } = useContext(EventGuestsContext);
    const [loading, setLoading] = useState(false);

    const handlePayment = async () => {
        setLoading(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            alert('Failed to load Razorpay payment gateway. Please try again later.');
            return;
        }

        const payload = {
            payment_amount: totalAmountToPay * 100, // For rupees send in paisa
            amount_currency: razorPayCurrency,
            receipt: 'Wowsly_Invites'
        }

        createOrder(payload).then((result) => {
            setLoading(false);
            console.log(result);
            const order_id = result.data?.id;
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                amount: totalAmountToPay * 100, // Amount in paise
                currency: razorPayCurrency,
                name: 'Wowsly',
                description: 'Payment for invitation message',
                notes: {
                    'task': 'event_invitation',
                    'event_id': eventData?.id,
                    'user_id': userData?.id,
                },
                image: logo,
                order_id: order_id,
                handler: function (response) {
                    // Callback function triggered on successful payment
                    console.log("Done", response);
                    if ('razorpay_payment_id' in response
                        && 'razorpay_order_id' in response
                        && 'razorpay_signature' in response
                    ) {
                        const data = {
                            amount_paid: totalAmountToPay,
                            currency: amountCurrency,
                            gst_charges: gst_charges,
                            extra_charges: extra_charges,
                            total_guests: total_guests,
                            guests_for_email: guests_for_email ?? null
                        };
                        setInviteMsg({ ...inviteMsg, ...data });
                        setPaymentId(response.razorpay_payment_id)
                        setPayment(true);
                        setLoading(false);
                        toast.success("Payment successful", {
                            position: "top-right",
                            theme: "dark"
                        });
                    } else {
                        toast.error("Payment failed due to some issues, please try again later!!", {
                            position: "top-right",
                            theme: "dark"
                        });
                        return;
                    }
                },
                prefill: {
                    name: userData?.full_name,
                    email: userData?.email ?? null,
                    contact: userData?.mobile,
                },
                theme: {
                    color: theme.palette.primaryColor,
                },
                config: razorPayModalConfig.paymentMethodsConfiguration,
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
            razorpay.on("payment.failed", function (response) {
                setLoading(false);
                toast.error(response.error.description, {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            });
        });
    };

    return (
        <>
            {paymentDone ?
                <Chip
                    label="Paid" color='success' icon={<VerifiedIcon />}
                />
                :
                <LoadingButton
                    type="submit"
                    loading={loading}
                    onClick={handlePayment}
                    startIcon={loading ? <SaveIcon /> : ''}
                    loadingPosition={loading ? "start" : null}
                    sx={{
                        color: 'white',
                        borderRadius: '1.1rem',
                        backgroundColor: loading ? 'lightgray' : '#1F274A',
                        ":hover": {
                            backgroundColor: '#1F274A',
                        }
                    }}
                >
                    {loading ? 'Processing..' : 'Pay'}
                </LoadingButton>
            }
        </>
    )
};

export default InvitationPayment;
