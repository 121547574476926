import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import HistoryDialog from "./HistoryDialog";

const InvitationHistory = ({ title, invitationType }) => {
    let [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        document.body.classList.add("no-scroll");
        setDialogOpen(true);
    }
    const handleDialogClose = () => {
        document.body.classList.remove("no-scroll");
        setDialogOpen(false);
    }

    return (
        <>
            <Box sx={{
                ":hover": { backgroundColor: '#FFEBE4', cursor: 'pointer' },
                px: '1.5rem', width: '100%', textAlign: 'center'
            }}>
                <Typography onClick={handleDialogOpen} sx={{
                    lineHeight: '2rem'
                }}>{title}</Typography>
            </Box>
            {
                dialogOpen ? <HistoryDialog title={title}
                    invitationType={invitationType} handleClose={handleDialogClose} /> : null
            }
        </>
    )
};

export default InvitationHistory;
