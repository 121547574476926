import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GuestRegistrationContext } from "../..";
import SuccessGif from '../../../../../../../../assets/success-register.gif';
import LoginDialog from "../../../../../../../../components/LoginDialog";
import { eventDetails, update_event_details } from "../../../../../../../../redux/slices/eventDataSlice";
import { userDetails } from "../../../../../../../../redux/slices/userDataSlice";
import { getEventDetails } from "../../../../../../../../services/wEvents/event";
import { getGuestDetails } from "../../../../../../../../services/wEvents/eventGuests";
import { PAGE_PATHS } from "../../../../../../../../utils/constants/page-path.constants";

const SuccessfulRegistration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(userDetails);
    const eventData = useSelector(eventDetails);
    const { guestId, commonEventTitle } = useParams();
    const { registrationData } = useContext(GuestRegistrationContext);

    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const [loginDialog, setLoginDialog] = useState(false);
    const handleOpen = () => {
        setLoginDialog(true);
    }
    const handleClose = () => {
        setLoginDialog(false);
    }
    const [guestData, setGuestData] = useState(null);

    const fetchEventDetailsViaGuestUuid = (uuid) => {
        getEventDetails({ guestId: uuid }).then((res) => {
            dispatch(update_event_details(res.data.data))
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            })
        })
    }

    useEffect(() => { // For fetching latest details after successful registration and show hidden features like showing qr-code
        if (commonEventTitle) { // For common event we'll details from uuid of registration data as login not required 
            setTimeout(() => {
                getGuestDetails({ eventId: eventData?.id, uuid: registrationData.uuid }).then((result) => {
                    if (!userData) {
                        setLoginDialog(true);
                        setGuestData(result.data.data);
                    } else {
                        fetchEventDetailsViaGuestUuid(registrationData.uuid);
                        navigate(`/${PAGE_PATHS.GUEST_EVENT_DETAILS.replace(':guestId', registrationData.uuid)}`);
                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("Something went wrong", {
                        position: "top-right",
                        theme: "dark"
                    })
                });
            }, 500);
        } else { // For private event we'll fetch only details from uuid of url as already login 
            fetchEventDetailsViaGuestUuid(guestId);
        }
    }, []);

    return (
        <Stack spacing={3} justifyContent='center' alignItems='center' sx={{ p: 2 }}>
            <Box component='img'
                src={SuccessGif}
                width={isSmallDevice ? '90%' : '22rem'}
                height={isSmallDevice ? '15rem' : '22rem'}
            />
            <Typography sx={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>You have successfully registered for this event!!</Typography>
            {(commonEventTitle && !userData) ? <Button onClick={handleOpen} variant="contained"
                sx={{ backgroundColor: '#1F274A', textTransform: 'none !important' }}>Login to see event details</Button> : null}
            {loginDialog ? <LoginDialog open={true}
                handleClose={handleClose} guestData={guestData} /> : null}
        </Stack>
    )
};

export default SuccessfulRegistration;
