import { ClickAwayListener, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        fontWeight: "bold !important",
    },
}));

const CustomTooltip = ({ content, placement }) => {
    const customStyle = useStyles();
    const isSmallDevices = useMediaQuery("(max-width:900px)");
    const [openTooltip, setOpenTooltip] = useState(false);

    return (<>
        {isSmallDevices ?
            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                <Tooltip
                    arrow
                    open={openTooltip}
                    title={content}
                    disableTouchListener
                    placement={placement ?? 'top'}
                    classes={{
                        tooltip: customStyle.customTooltip,
                    }}
                >
                    <IconButton onClick={() => setOpenTooltip(!openTooltip)} sx={{ p: 0 }}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </ClickAwayListener>
            :
            <Tooltip
                arrow
                title={content}
                placement={placement ?? 'right'}
                classes={{
                    tooltip: customStyle.customTooltip
                }}
            >
                <IconButton sx={{ p: 0 }}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
        }
    </>)
};

export default CustomTooltip;
