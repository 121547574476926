export const modelViews = [
    {
        "label": "Itinerary",
        "value": "eventprogram",
    },
    {
        "label": "Key Persons",
        "value": "eventmember",
    },
    {
        "label": "Gallery",
        "value": "eventalbum",
    }
]