export const codes = [
    { "code": "AD", "label": "Andorra", "dial_code": 376, "phoneLength": 6 },
    { "code": "AE", "label": "United Arab Emirates", "dial_code": 971, "phoneLength": 9 },
    { "code": "AF", "label": "Afghanistan", "dial_code": 93, "phoneLength": 9 },
    { "code": "AG", "label": "Antigua and Barbuda", "dial_code": 1268, "phoneLength": 10 },
    { "code": "AI", "label": "Anguilla", "dial_code": 1264, "phoneLength": 10 },
    { "code": "AL", "label": "Albania", "dial_code": 355, "phoneLength": 9 },
    { "code": "AM", "label": "Armenia", "dial_code": 374, "phoneLength": 6 },
    { "code": "AO", "label": "Angola", "dial_code": 244, "phoneLength": 9 },
    { "code": "AQ", "label": "Antarctica", "dial_code": 672, "phoneLength": 6 },
    { "code": "AR", "label": "Argentina", "dial_code": 54, "phoneLength": [6, 7, 8] },
    { "code": "AS", "label": "American Samoa", "dial_code": 1684, "phoneLength": 10 },
    { "code": "AT", "label": "Austria", "dial_code": 43, "phoneLength": [10, 11] },
    { "code": "AU", "label": "Australia", "dial_code": 61, "phoneLength": 9 },
    { "code": "AW", "label": "Aruba", "dial_code": 297, "phoneLength": 7 },
    { "code": "AZ", "label": "Azerbaijan", "dial_code": 994, "phoneLength": 9 },
    { "code": "BA", "label": "Bosnia and Herzegovina", "dial_code": 387, "phoneLength": 8 },
    { "code": "BB", "label": "Barbados", "dial_code": 1246, "phoneLength": 10 },
    { "code": "BD", "label": "Bangladesh", "dial_code": 880, "phoneLength": 10 },
    { "code": "BE", "label": "Belgium", "dial_code": 32, "phoneLength": 9 },
    { "code": "BF", "label": "Burkina Faso", "dial_code": 226, "phoneLength": 8 },
    { "code": "BG", "label": "Bulgaria", "dial_code": 359, "phoneLength": 9 },
    { "code": "BH", "label": "Bahrain", "dial_code": 973, "phoneLength": 8 },
    { "code": "BI", "label": "Burundi", "dial_code": 257, "phoneLength": 8 },
    { "code": "BJ", "label": "Benin", "dial_code": 229, "phoneLength": 8 },
    { "code": "BL", "label": "Saint Barthelemy", "dial_code": 590, "phoneLength": 9 },
    { "code": "BM", "label": "Bermuda", "dial_code": 1441, "phoneLength": 10 },
    { "code": "BN", "label": "Brunei Darussalam", "dial_code": 673, "phoneLength": 7 },
    { "code": "BO", "label": "Bolivia", "dial_code": 591, "phoneLength": 9 },
    { "code": "BR", "label": "Brazil", "dial_code": 55, "phoneLength": 11 },
    { "code": "BS", "label": "Bahamas", "dial_code": 1242, "phoneLength": 10 },
    { "code": "BT", "label": "Bhutan", "dial_code": 975, "phoneLength": 7 },
    { "code": "BV", "label": "Bouvet Island", "dial_code": 47, "phoneLength": 10 },
    { "code": "BW", "label": "Botswana", "dial_code": 267, "phoneLength": 7 },
    { "code": "BY", "label": "Belarus", "dial_code": 375, "phoneLength": 9 },
    { "code": "BZ", "label": "Belize", "dial_code": 501, "phoneLength": 7 },
    { "code": "CA", "label": "Canada", "dial_code": 1, "phoneLength": 10 },
    { "code": "CC", "label": "Cocos (Keeling) Islands", "dial_code": 61, "phoneLength": 10 },
    { "code": "CD", "label": "Congo, Democratic Republic of the", "dial_code": 243, "phoneLength": 7 },
    { "code": "CF", "label": "Central African Republic", "dial_code": 236, "phoneLength": 8 },
    { "code": "CG", "label": "Congo, Republic of the", "dial_code": 242, "phoneLength": 9 },
    { "code": "CH", "label": "Switzerland", "dial_code": 41, "phoneLength": 9 },
    { "code": "CI", "label": "Cote d'Ivoire", "dial_code": 225, "phoneLength": 8 },
    { "code": "CK", "label": "Cook Islands", "dial_code": 682, "phoneLength": 5 },
    { "code": "CL", "label": "Chile", "dial_code": 56, "phoneLength": 9 },
    { "code": "CM", "label": "Cameroon", "dial_code": 237, "phoneLength": 9 },
    { "code": "CN", "label": "China", "dial_code": 86, "phoneLength": 11 },
    { "code": "CO", "label": "Colombia", "dial_code": 57, "phoneLength": 10 },
    { "code": "CR", "label": "Costa Rica", "dial_code": 506, "phoneLength": 8 },
    { "code": "CU", "label": "Cuba", "dial_code": 53, "phoneLength": 8 },
    { "code": "CV", "label": "Cape Verde", "dial_code": 238, "phoneLength": 7 },
    { "code": "CW", "label": "Curacao", "dial_code": 599, "phoneLength": 7 },
    { "code": "CX", "label": "Christmas Island", "dial_code": 61, "phoneLength": 9 },
    { "code": "CY", "label": "Cyprus", "dial_code": 357, "phoneLength": 8 },
    { "code": "CZ", "label": "Czech Republic", "dial_code": 420, "phoneLength": 9 },
    { "code": "DE", "label": "Germany", "dial_code": 49, "phoneLength": 10 },
    { "code": "DJ", "label": "Djibouti", "dial_code": 253, "phoneLength": 10 },
    { "code": "DK", "label": "Denmark", "dial_code": 45, "phoneLength": 8 },
    { "code": "DM", "label": "Dominica", "dial_code": 1767, "phoneLength": 10 },
    { "code": "DO", "label": "Dominican Republic", "dial_code": 1809, "phoneLength": 10 },
    { "code": "DZ", "label": "Algeria", "dial_code": 213, "phoneLength": 9 },
    { "code": "EC", "label": "Ecuador", "dial_code": 593, "phoneLength": 9 },
    { "code": "EE", "label": "Estonia", "dial_code": 372, "phoneLength": 8 },
    { "code": "EG", "label": "Egypt", "dial_code": 20, "phoneLength": 10 },
    { "code": "EH", "label": "Western Sahara", "dial_code": 212, "phoneLength": 9 },
    { "code": "ER", "label": "Eritrea", "dial_code": 291, "phoneLength": 7 },
    { "code": "ES", "label": "Spain", "dial_code": 34, "phoneLength": 9 },
    { "code": "ET", "label": "Ethiopia", "dial_code": 251, "phoneLength": 9 },
    { "code": "FI", "label": "Finland", "dial_code": 358, "phoneLength": [7, 8, 9] },
    { "code": "FJ", "label": "Fiji", "dial_code": 679, "phoneLength": 7 },
    { "code": "FK", "label": "Falkland Islands (Malvinas)", "dial_code": 500, "phoneLength": 5 },
    { "code": "FM", "label": "Micronesia, Federated States of", "dial_code": 691, "phoneLength": 7 },
    { "code": "FO", "label": "Faroe Islands", "dial_code": 298, "phoneLength": 5 },
    { "code": "FR", "label": "France", "dial_code": 33, "phoneLength": 9 },
    { "code": "GA", "label": "Gabon", "dial_code": 241, "phoneLength": 7 },
    { "code": "GB", "label": "United Kingdom", "dial_code": 44, "phoneLength": 10 },
    { "code": "GD", "label": "Grenada", "dial_code": 1473, "phoneLength": 10 },
    { "code": "GE", "label": "Georgia", "dial_code": 995, "phoneLength": 9 },
    { "code": "GF", "label": "French Guiana", "dial_code": 594, "phoneLength": 9 },
    { "code": "GG", "label": "Guernsey", "dial_code": 44, "phoneLength": 10 },
    { "code": "GH", "label": "Ghana", "dial_code": 233, "phoneLength": 9 },
    { "code": "GI", "label": "Gibraltar", "dial_code": 350, "phoneLength": 8 },
    { "code": "GL", "label": "Greenland", "dial_code": 299, "phoneLength": 6 },
    { "code": "GM", "label": "Gambia", "dial_code": 220, "phoneLength": 7 },
    { "code": "GN", "label": "Guinea", "dial_code": 224, "phoneLength": 9 },
    { "code": "GP", "label": "Guadeloupe", "dial_code": 590, "phoneLength": 9 },
    { "code": "GQ", "label": "Equatorial Guinea", "dial_code": 240, "phoneLength": 9 },
    { "code": "GR", "label": "Greece", "dial_code": 30, "phoneLength": 10 },
    { "code": "GS", "label": "South Georgia and the South Sandwich Islands", "dial_code": 500, "phoneLength": 5 },
    { "code": "GT", "label": "Guatemala", "dial_code": 502, "phoneLength": 8 },
    { "code": "GU", "label": "Guam", "dial_code": 1671, "phoneLength": 10 },
    { "code": "GW", "label": "Guinea-Bissau", "dial_code": 245, "phoneLength": 9 },
    { "code": "GY", "label": "Guyana", "dial_code": 592, "phoneLength": 7 },
    { "code": "HK", "label": "Hong Kong", "dial_code": 852, "phoneLength": 8 },
    { "code": "HM", "label": "Heard Island and McDonald Islands", "dial_code": 672, "phoneLength": 10 },
    { "code": "HN", "label": "Honduras", "dial_code": 504, "phoneLength": 8 },
    { "code": "HR", "label": "Croatia", "dial_code": 385, "phoneLength": 9 },
    { "code": "HT", "label": "Haiti", "dial_code": 509, "phoneLength": 8 },
    { "code": "HU", "label": "Hungary", "dial_code": 36, "phoneLength": 9 },
    { "code": "ID", "label": "Indonesia", "dial_code": 62, "phoneLength": 11 },
    { "code": "IE", "label": "Ireland", "dial_code": 353, "phoneLength": 9 },
    { "code": "IL", "label": "Israel", "dial_code": 972, "phoneLength": 9 },
    { "code": "IM", "label": "Isle of Man", "dial_code": 44, "phoneLength": 10 },
    { "code": "IN", "label": "India", "dial_code": 91, "phoneLength": 10 },
    { "code": "IO", "label": "British Indian Ocean Territory", "dial_code": 246, "phoneLength": 7 },
    { "code": "IQ", "label": "Iraq", "dial_code": 964, "phoneLength": 10 },
    { "code": "IR", "label": "Iran, Islamic Republic of", "dial_code": 98, "phoneLength": 11 },
    { "code": "IS", "label": "Iceland", "dial_code": 354, "phoneLength": 7 },
    { "code": "IT", "label": "Italy", "dial_code": 39, "phoneLength": 10 },
    { "code": "JE", "label": "Jersey", "dial_code": 44, "phoneLength": 10 },
    { "code": "JM", "label": "Jamaica", "dial_code": 1876, "phoneLength": 10 },
    { "code": "JO", "label": "Jordan", "dial_code": 962, "phoneLength": [8, 9] },
    { "code": "JP", "label": "Japan", "dial_code": 81, "phoneLength": [9, 10] },
    { "code": "KE", "label": "Kenya", "dial_code": 254, "phoneLength": 10 },
    { "code": "KG", "label": "Kyrgyzstan", "dial_code": 996, "phoneLength": 9 },
    { "code": "KH", "label": "Cambodia", "dial_code": 855, "phoneLength": 9 },
    { "code": "KI", "label": "Kiribati", "dial_code": 686, "phoneLength": 8 },
    { "code": "KM", "label": "Comoros", "dial_code": 269, "phoneLength": 7 },
    { "code": "KN", "label": "Saint Kitts and Nevis", "dial_code": 1869, "phoneLength": 10 },
    { "code": "KP", "label": "Korea, Democratic People's Republic of", "dial_code": 850, "phoneLength": [4, 6, 7, 13] },
    { "code": "KR", "label": "Korea, Republic of", "dial_code": 82, "phoneLength": [7, 8] },
    { "code": "KW", "label": "Kuwait", "dial_code": 965, "phoneLength": 8 },
    { "code": "KY", "label": "Cayman Islands", "dial_code": 1345, "phoneLength": 7 },
    { "code": "KZ", "label": "Kazakhstan", "dial_code": 7, "phoneLength": 10 },
    { "code": "LA", "label": "Lao People's Democratic Republic", "dial_code": 856, "phoneLength": [8, 9] },
    { "code": "LB", "label": "Lebanon", "dial_code": 961, "phoneLength": [7, 8] },
    { "code": "LC", "label": "Saint Lucia", "dial_code": 1758, "phoneLength": 7 },
    { "code": "LI", "label": "Liechtenstein", "dial_code": 423, "phoneLength": 7 },
    { "code": "LK", "label": "Sri Lanka", "dial_code": 94, "phoneLength": 7 },
    { "code": "LR", "label": "Liberia", "dial_code": 231, "phoneLength": [8, 9] },
    { "code": "LS", "label": "Lesotho", "dial_code": 266, "phoneLength": 8 },
    { "code": "LT", "label": "Lithuania", "dial_code": 370, "phoneLength": 8 },
    { "code": "LU", "label": "Luxembourg", "dial_code": 352, "phoneLength": 9 },
    { "code": "LV", "label": "Latvia", "dial_code": 371, "phoneLength": 8 },
    { "code": "LY", "label": "Libya", "dial_code": 218, "phoneLength": 10 },
    { "code": "MA", "label": "Morocco", "dial_code": 212, "phoneLength": 9 },
    { "code": "MC", "label": "Monaco", "dial_code": 377, "phoneLength": 8 },
    { "code": "MD", "label": "Moldova, Republic of", "dial_code": 373, "phoneLength": 8 },
    { "code": "ME", "label": "Montenegro", "dial_code": 382, "phoneLength": 8 },
    { "code": "MF", "label": "Saint Martin (French part)", "dial_code": 590, "phoneLength": 6 },
    { "code": "MG", "label": "Madagascar", "dial_code": 261, "phoneLength": 7 },
    { "code": "MH", "label": "Marshall Islands", "dial_code": 692, "phoneLength": 7 },
    { "code": "MK", "label": "Macedonia, the Former Yugoslav Republic of", "dial_code": 389, "phoneLength": 8 },
    { "code": "ML", "label": "Mali", "dial_code": 223, "phoneLength": 8 },
    { "code": "MM", "label": "Myanmar", "dial_code": 95, "phoneLength": [7, 8, 9, 10] },
    { "code": "MN", "label": "Mongolia", "dial_code": 976, "phoneLength": 8 },
    { "code": "MO", "label": "Macao", "dial_code": 853, "phoneLength": 8 },
    { "code": "MP", "label": "Northern Mariana Islands", "dial_code": 1670, "phoneLength": 7 },
    { "code": "MQ", "label": "Martinique", "dial_code": 596, "phoneLength": 9 },
    { "code": "MR", "label": "Mauritania", "dial_code": 222, "phoneLength": 8 },
    { "code": "MS", "label": "Montserrat", "dial_code": 1664, "phoneLength": 10 },
    { "code": "MT", "label": "Malta", "dial_code": 356, "phoneLength": 8 },
    { "code": "MU", "label": "Mauritius", "dial_code": 230, "phoneLength": 8 },
    { "code": "MV", "label": "Maldives", "dial_code": 960, "phoneLength": 7 },
    { "code": "MW", "label": "Malawi", "dial_code": 265, "phoneLength": [7, 8, 9] },
    { "code": "MX", "label": "Mexico", "dial_code": 52, "phoneLength": 10 },
    { "code": "MY", "label": "Malaysia", "dial_code": 60, "phoneLength": 7 },
    { "code": "MZ", "label": "Mozambique", "dial_code": 258, "phoneLength": 12 },
    { "code": "NA", "label": "Namibia", "dial_code": 264, "phoneLength": 7 },
    { "code": "NC", "label": "New Caledonia", "dial_code": 687, "phoneLength": 6 },
    { "code": "NE", "label": "Niger", "dial_code": 227, "phoneLength": 8 },
    { "code": "NF", "label": "Norfolk Island", "dial_code": 672, "phoneLength": 6 },
    { "code": "NG", "label": "Nigeria", "dial_code": 234, "phoneLength": 8 },
    { "code": "NI", "label": "Nicaragua", "dial_code": 505, "phoneLength": 8 },
    { "code": "NL", "label": "Netherlands", "dial_code": 31, "phoneLength": 9 },
    { "code": "NO", "label": "Norway", "dial_code": 47, "phoneLength": 8 },
    { "code": "NP", "label": "Nepal", "dial_code": 977, "phoneLength": 10 },
    { "code": "NR", "label": "Nauru", "dial_code": 674, "phoneLength": 7 },
    { "code": "NU", "label": "Niue", "dial_code": 683, "phoneLength": 4 },
    { "code": "NZ", "label": "New Zealand", "dial_code": 64, "phoneLength": [8, 9] },
    { "code": "OM", "label": "Oman", "dial_code": 968, "phoneLength": 8 },
    { "code": "PA", "label": "Panama", "dial_code": 507, "phoneLength": 8 },
    { "code": "PE", "label": "Peru", "dial_code": 51, "phoneLength": 9 },
    { "code": "PF", "label": "French Polynesia", "dial_code": 689, "phoneLength": 8 },
    { "code": "PG", "label": "Papua New Guinea", "dial_code": 675, "phoneLength": 8 },
    { "code": "PH", "label": "Philippines", "dial_code": 63, "phoneLength": 10 },
    { "code": "PK", "label": "Pakistan", "dial_code": 92, "phoneLength": 10 },
    { "code": "PL", "label": "Poland", "dial_code": 48, "phoneLength": 9 },
    { "code": "PM", "label": "Saint Pierre and Miquelon", "dial_code": 508, "phoneLength": 6 },
    { "code": "PN", "label": "Pitcairn", "dial_code": 870, "phoneLength": 9 },
    { "code": "PR", "label": "Puerto Rico", "dial_code": 1, "phoneLength": 10 },
    { "code": "PS", "label": "Palestine, State of", "dial_code": 970, "phoneLength": 9 },
    { "code": "PT", "label": "Portugal", "dial_code": 351, "phoneLength": 9 },
    { "code": "PW", "label": "Palau", "dial_code": 680, "phoneLength": 7 },
    { "code": "PY", "label": "Paraguay", "dial_code": 595, "phoneLength": 9 },
    { "code": "QA", "label": "Qatar", "dial_code": 974, "phoneLength": 8 },
    { "code": "RE", "label": "Reunion", "dial_code": 262, "phoneLength": 10 },
    { "code": "RO", "label": "Romania", "dial_code": 40, "phoneLength": 10 },
    { "code": "RS", "label": "Serbia", "dial_code": 381, "phoneLength": 9 },
    { "code": "RU", "label": "Russian Federation", "dial_code": 7, "phoneLength": 10 },
    { "code": "RW", "label": "Rwanda", "dial_code": 250, "phoneLength": 9 },
    { "code": "SA", "label": "Saudi Arabia", "dial_code": 966, "phoneLength": 9 },
    { "code": "SB", "label": "Solomon Islands", "dial_code": 677, "phoneLength": 7 },
    { "code": "SC", "label": "Seychelles", "dial_code": 248, "phoneLength": 7 },
    { "code": "SD", "label": "Sudan", "dial_code": 249, "phoneLength": 7 },
    { "code": "SE", "label": "Sweden", "dial_code": 46, "phoneLength": 7 },
    { "code": "SG", "label": "Singapore", "dial_code": 65, "phoneLength": 8 },
    { "code": "SH", "label": "Saint Helena", "dial_code": 290, "phoneLength": 4 },
    { "code": "SI", "label": "Slovenia", "dial_code": 386, "phoneLength": 9 },
    { "code": "SJ", "label": "Svalbard and Jan Mayen", "dial_code": 47, "phoneLength": 8 },
    { "code": "SK", "label": "Slovakia", "dial_code": 421, "phoneLength": 9 },
    { "code": "SL", "label": "Sierra Leone", "dial_code": 232, "phoneLength": 8 },
    { "code": "SM", "label": "San Marino", "dial_code": 378, "phoneLength": 10 },
    { "code": "SN", "label": "Senegal", "dial_code": 221, "phoneLength": 9 },
    { "code": "SO", "label": "Somalia", "dial_code": 252, "phoneLength": [8, 9] },
    { "code": "SR", "label": "Suriname", "dial_code": 597, "phoneLength": [6, 7] },
    { "code": "SS", "label": "South Sudan", "dial_code": 211, "phoneLength": 7 },
    { "code": "ST", "label": "Sao Tome and Principe", "dial_code": 239, "phoneLength": 7 },
    { "code": "SV", "label": "El Salvador", "dial_code": 503, "phoneLength": 8 },
    { "code": "SX", "label": "Sint Maarten (Dutch part)", "dial_code": 1721, "phoneLength": 10 },
    { "code": "SY", "label": "Syrian Arab Republic", "dial_code": 963, "phoneLength": 7 },
    { "code": "SZ", "label": "Swaziland", "dial_code": 268, "phoneLength": 8 },
    { "code": "TC", "label": "Turks and Caicos Islands", "dial_code": 1649, "phoneLength": 10 },
    { "code": "TD", "label": "Chad", "dial_code": 235, "phoneLength": 6 },
    { "code": "TF", "label": "French Southern Territories", "dial_code": 262, "phoneLength": 10 },
    { "code": "TG", "label": "Togo", "dial_code": 228, "phoneLength": 8 },
    { "code": "TH", "label": "Thailand", "dial_code": 66, "phoneLength": 9 },
    { "code": "TJ", "label": "Tajikistan", "dial_code": 992, "phoneLength": 9 },
    { "code": "TK", "label": "Tokelau", "dial_code": 690, "phoneLength": 5 },
    { "code": "TL", "label": "Timor-Leste", "dial_code": 670, "phoneLength": 7 },
    { "code": "TM", "label": "Turkmenistan", "dial_code": 993, "phoneLength": 8 },
    { "code": "TN", "label": "Tunisia", "dial_code": 216, "phoneLength": 8 },
    { "code": "TO", "label": "Tonga", "dial_code": 676, "phoneLength": 5 },
    { "code": "TR", "label": "Turkey", "dial_code": 90, "phoneLength": 11 },
    { "code": "TT", "label": "Trinidad and Tobago", "dial_code": 1868, "phoneLength": 7 },
    { "code": "TV", "label": "Tuvalu", "dial_code": 688, "phoneLength": 5 },
    { "code": "TW", "label": "Taiwan, Province of China", "dial_code": 886, "phoneLength": 9 },
    { "code": "TZ", "label": "United Republic of Tanzania", "dial_code": 255, "phoneLength": 7 },
    { "code": "UA", "label": "Ukraine", "dial_code": 380, "phoneLength": 9 },
    { "code": "UG", "label": "Uganda", "dial_code": 256, "phoneLength": 7 },
    { "code": "US", "label": "United States", "dial_code": 1, "phoneLength": 10 },
    { "code": "UY", "label": "Uruguay", "dial_code": 598, "phoneLength": 8 },
    { "code": "UZ", "label": "Uzbekistan", "dial_code": 998, "phoneLength": 9 },
    { "code": "VA", "label": "Holy See (Vatican City State)", "dial_code": 379, "phoneLength": 10 },
    { "code": "VC", "label": "Saint Vincent and the Grenadines", "dial_code": 1784, "phoneLength": 7 },
    { "code": "VE", "label": "Venezuela", "dial_code": 58, "phoneLength": 7 },
    { "code": "VG", "label": "British Virgin Islands", "dial_code": 1284, "phoneLength": 7 },
    { "code": "VI", "label": "US Virgin Islands", "dial_code": 1340, "phoneLength": 10 },
    { "code": "VN", "label": "Vietnam", "dial_code": 84, "phoneLength": 9 },
    { "code": "VU", "label": "Vanuatu", "dial_code": 678, "phoneLength": 5 },
    { "code": "WF", "label": "Wallis and Futuna", "dial_code": 681, "phoneLength": 6 },
    { "code": "WS", "label": "Samoa", "dial_code": 685, "phoneLength": [5, 6, 7] },
    { "code": "XK", "label": "Kosovo", "dial_code": 383, "phoneLength": 8 },
    { "code": "YE", "label": "Yemen", "dial_code": 967, "phoneLength": 9 },
    { "code": "YT", "label": "Mayotte", "dial_code": 262, "phoneLength": 9 },
    { "code": "ZA", "label": "South Africa", "dial_code": 27, "phoneLength": 9 },
    { "code": "ZM", "label": "Zambia", "dial_code": 260, "phoneLength": 9 },
    { "code": "ZW", "label": "Zimbabwe", "dial_code": 263, "phoneLength": 9 }
]