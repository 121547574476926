import { Avatar, Box, Checkbox, Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { memo, useContext, useState } from "react";
import { stringAvatar } from "../../../../../../../../utils/functions/muiAvatar";
import { EventGuestsContext } from "../../EventGuestContext";
import GuestDialog from "./GuestDialog";
import { getEventGuestAdditionalDetails } from "../../../../../../../../services/wEvents/eventGuests";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";

const GuestBox = ({ guestDetails }) => {
    let [open, setOpen] = useState(false);
    const [additionalDetails, setDetails] = useState(null);
    const eventData = useSelector(eventDetails);

    const handleOpen = () => {
        getEventGuestAdditionalDetails({ eventId: eventData?.id, eventUserId: guestDetails?.id }).then((res) => {
            setDetails(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
        document.body.classList.add("no-scroll");
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("no-scroll");
        setDetails(null);
        setOpen(false);
    }

    const { isCheckboxVisible, checkedGuests, handleToggle } = useContext(EventGuestsContext)

    return (
        <>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <Box>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            p: 1,
                            ":hover": {
                                cursor: "pointer",
                                backgroundColor: "#F1F1F1"
                            }
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={1.25}
                            onClick={guestDetails ? handleOpen : null}
                            sx={{ flex: 1 }}
                        >
                            {guestDetails ?
                                <GuestAvatar name={guestDetails.name || ''} />
                                : <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            }
                            {guestDetails ?
                                <Typography sx={{ flex: 1 }}>{guestDetails?.name}</Typography>
                                : <Skeleton animation="wave" variant="rectangular" width='80%' height='2rem' />
                            }
                            {
                                (guestDetails && guestDetails.role === 'manager') ?
                                    <Chip size="small" label="Manager" color="warning" variant="outlined" /> :
                                    null
                            }
                        </Stack>
                        <Checkbox size="medium" color="warning"
                            onChange={handleToggle(guestDetails?.id)}
                            checked={checkedGuests.indexOf(guestDetails?.id) !== -1}
                            sx={{ display: isCheckboxVisible ? 'inline-flex' : 'none' }}
                        />
                    </Stack>
                </Box>
                {open ? //To stop multiple renders as num. of items in list
                    <GuestDialog guestDetails={guestDetails} additionalDetails={additionalDetails}
                    handleClose={handleClose} />
                    : null
                }
            </Grid>
        </>
    )
};

const GuestAvatar = memo(({ name }) => {
    return (
        <Avatar {...stringAvatar(name.trim())} />
    )
})

export default GuestBox;
